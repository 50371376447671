import axios from './BaseConfig';
import httpRequest from 'axios'

//interface
import { IUserImage, MediaBody } from './interfaces/MediaBody';

export const postMedia = async (body: MediaBody) => {
  try {
    const res = await axios.post('/media/download', body);
    return res.data.url
  } catch (e) {
    console.log('error', e);
  }
};

export const postMediaUser = async(body: IUserImage)=>{ 
  try {
    const res = await axios.post(`/users/${body.id}/image`, {
      fileName: body.fileName
    })
    return res.data.url
  } catch (error) {
    console.log('error', error);
  }
}

export const putMediaToS3 = async(url:string, formData:any) =>{ 
  try {
    const res = await httpRequest.put(url, formData)
    return res.status
    
  } catch (error) {
    console.log(error)
  }
}

export const putMediaUser = async(body: IUserImage)=>{ 
  try {
    const res = await axios.put(`/users/${body.id}/image`, {
      fileName: body.fileName
    })
  } catch (error) {
    console.log('error', error);
  }
}
