import { IFundamentals } from "interfaces/IEntraniento"
import manejo from 'assets/fundamentos/manejo.jpg';
import defensa from 'assets/fundamentos/defensa.jpg';
import trabajo from 'assets/fundamentos/trabajo.jpg';
import mecanica from 'assets/fundamentos/mecanica.jpg';
import favoritas from 'assets/fundamentos/favoritas.jpeg';
import acercarse from 'assets/fundamentos/acercarse.jpg';
import piesTiro from 'assets/fundamentos/piesTiro.jpg';
import pase from 'assets/fundamentos/pase.jpg';
import driles from 'assets/fundamentos/driles.jpg';

const array = [
    { name: 'Técnicas Favoritas', img: favoritas, idModule: 0, minutes: 0 },
    { name: 'Trabajo de pies básico', img: trabajo, idModule: 10 , minutes: 0},
    { name: 'Manejo de balón', img: manejo, idModule: 11, minutes: 0 },
    { name: 'Defensa', img: defensa, idModule: 3, minutes: 0 },
    { name: 'Técnica y mecánica de tiro', img: mecanica, idModule: 1, minutes: 0 },
    { name: 'Acercarse a situación de juego', img: acercarse, idModule: 2, minutes: 0 },
    { name: 'Trabajo de pies para tiro', img: piesTiro, idModule: 12, minutes: 0 },
    { name: 'Driles Continuos Tiro', img: driles, idModule: 15, minutes: 0 },
    { name: 'Pase', img: pase, idModule: 13, minutes: 0 }
  ];

export const handleFundamentals = (fundamentals:IFundamentals[])=>{ 

    const newArray = array.map( (fundamental, index) =>{ 

        let findFundamental = fundamentals.find( fundamental2 => fundamental2.idModule === fundamental.idModule)
        if(findFundamental){
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: findFundamental.minutes,
                moduleName: fundamental.name,
                img: fundamental.img
            }

            return { ...obj }
        }else{ 
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: fundamental.minutes,
                moduleName: fundamental.name,
                img: fundamental.img
            }
            return { ...obj }
        }
    })

    const findModule = fundamentals.find(fundamental => fundamental.idModule === 4)

    const newArrayParsed = newArray.map( fundamental =>{ 
        if(fundamental.idModule === 3 && findModule){
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: Number(fundamental.minutes) + Number(findModule.minutes),
                moduleName: fundamental.moduleName,
                img: fundamental.img
            }

            return { ...obj }
        }else{
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: fundamental.minutes,
                moduleName: fundamental.moduleName,
                img: fundamental.img
            }
            return { ...obj }
        }
    })

    const findModulePase2 = fundamentals.find(fundamental => fundamental.idModule === 14)

    const newArrayParsed2 = newArray.map( fundamental =>{ 
        if(fundamental.idModule === 13 && findModulePase2){
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: Number(fundamental.minutes) + Number(findModulePase2.minutes),
                moduleName: fundamental.moduleName,
                img: fundamental.img
            }

            return { ...obj }
        }else{
            let obj: IFundamentals = { 
                idModule:fundamental.idModule,
                minutes: fundamental.minutes,
                moduleName: fundamental.moduleName,
                img: fundamental.img
            }
            return { ...obj }
        }
    })

    return newArrayParsed2
}