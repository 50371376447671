import { IonContent, IonIcon, IonModal } from '@ionic/react';
import { ModuleFinished } from 'components/aprendizaje/ModuleFinished';
import { closeOutline } from 'ionicons/icons';
import { useRef } from 'react';
import './moduleFinishedModal.css';

interface IModal {
  isOpen: boolean;
  handleOpen: (typeModal: string) => void;
  handleClose: () => void;
}

export const ModuleFinishedModal: React.FC<IModal> = ({ isOpen, handleOpen, handleClose }) => {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  return (
    <IonModal id="modal-finish" isOpen={isOpen} ref={modal}>
      <div className="wrapper-finished">
        <div className="modal-header">
          <IonIcon onClick={dismiss} color="dark" style={{ width: 30, height: 30 }} slot="icon-only" icon={closeOutline}></IonIcon>
        </div>
        <ModuleFinished moduleName="Trabajo de pies básico" />
      </div>
    </IonModal>
  );
};
