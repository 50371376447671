import { useCallback, useState } from 'react';

//services
import { getCampus } from 'services/campus';

//interface
import { CampusParams } from 'services/interfaces/CampusParams';

export const useGetCampus = (): [[], (params: CampusParams) => Promise<void>] => {
  const [campus, setCampus] = useState<[]>([]);

  const handleGetCampus = useCallback(async (params: CampusParams) => {
    const resp = await getCampus(params);
    setCampus(resp);
  }, []);

  return [campus, handleGetCampus];
};
