//ionic
import { IonGrid, IonImg, IonProgressBar, IonText, useIonViewDidEnter } from '@ionic/react';

//css
import './progress-component.css';

//assets
import tiro from 'assets/siluetas/tiro.svg';
import defensa from 'assets/siluetas/denfensa.svg';
import pase from 'assets/siluetas/pase.svg';
import tecnica from 'assets/siluetas/tecnica-individual.svg';
import tactica from 'assets/siluetas/tactica-individual.svg';
import { useGetProgressLearning } from 'hooks/progress/useGetProgressLearning';
import { useEffect, useState } from 'react';
import { useGetProgressHistory } from 'hooks/progress/useGetProgressHistory';
import { HistorialAprendizaje } from './HistorialAprendizaje';

const TabAprendizaje: React.FC = () => {

  const { handleGetProgressLearning, progressLearning} = useGetProgressLearning()

  useEffect(() => {
    handleGetProgressLearning()
  }, [])  

  return (
    <>
    <IonGrid className="p-aprendizaje-container">
      <IonText className="p-aprendizaje-title">Programa de Aprendizaje</IonText>

      {progressLearning.length !== 0 &&
       progressLearning.map((item, index) => {
        return (
          <div className="p-aprendizaje-child">
            <div className="flex p-box">
              <div className="flex" style={{ justifyContent: 'space-between' }}>
                <div className="flex" style={{ flexDirection: 'column' }}>
                  <IonText className="p-aprendizaje-text">{item.name}</IonText>
                  <IonText className="p-aprendizaje-subtext">{Number(item.done)}/{Number(item.total)} técnicas</IonText>
                </div>

                <IonImg src={item.img} className="p-aprendizaje-img" />
              </div>

              <IonProgressBar className="p-aprendizaje-progress-bar" value={Number(item.done) / Number(item.total)}></IonProgressBar>
            </div>
          </div>
        );
      })}
    </IonGrid>
    <HistorialAprendizaje />
    </>
  );
};

export default TabAprendizaje;
