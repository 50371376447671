
import React, { useCallback, useState } from 'react'
import { IGetCertificate } from 'services/interfaces/SessionParams'
import { getCertificate } from 'services/sessions'

export const useGetCertificate = () => {

    const [ base64, setBase64 ] = useState<string>('')

    const handleGetCertificate = useCallback(
      async(params: IGetCertificate) => {
        getCertificate(params).then( response => {
            if(response.status === 200){
                const data = response.data.base64Img
                setBase64(data)
            
        }
        })
      },
      [],
    )
    
  return {
    base64,
    handleGetCertificate
  }
}
