import React, { useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  
} from "@stripe/react-stripe-js";

import '../checkout/stripePayForm.css';
import { IonButton } from "@ionic/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { stripeErrorAPI, stripeErrorState } from "recoil/StripeErrorAtom";
import { StripeError } from "@stripe/stripe-js";
import { usePostStripeSession } from "hooks/sessions/usePostStripeSession";
import { IStripePayment, IStripeSubscription, stripeTypePayment } from "services/interfaces/IStripe";
import { userInfoState } from "recoil/User";
import { planSelectedState } from "recoil/planAtom";
import { usePostStripeSubscription } from "hooks/subscription/usePostStripeSubscription";
import { useGetUserById } from "hooks/users/useGetUserById";
import { usePutSubscriptionPlan } from "hooks/subscription/usePutSubscriptionPlan";

const useOptions = () => {

  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize:"18px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    []
  );

  return options;
};

export const StripePayChangePlanForm = ({nextInvoice}:any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const setStripeError = useSetRecoilState(stripeErrorState)
  const setStripeStatus = useSetRecoilState(stripeErrorAPI)
  const userInfo = useRecoilValue(userInfoState)
  const [error, seterror] = useState(false)
  const { handlePutSubscriptionPlan } = usePutSubscriptionPlan()
  const { handleGetUserById } = useGetUserById()

  const handleSubmit = async (event:any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const element = elements.getElement(CardNumberElement)

    if(element){
        const name = event.target.name.value
        const payload = await stripe.createPaymentMethod({
            type:'card',
            card:element,
            billing_details:{
              name: name
            }
        });

        const token = await stripe.createToken(element)

        if(payload.error){
          const error:StripeError = { message: payload.error.message, type: payload.error.type}
          setStripeError(error)
        }else{
          const idStripe:String = token.token ? token.token.id : ""

          const result = await handlePutSubscriptionPlan({ idSuscriptionPlan:2, prorationDate: nextInvoice.prorationDate , amount: nextInvoice.total, idStripe})
          if(result.invoiceId !== ""){
            setStripeStatus({ code:String("succeeded"), declineCode:'api_connection_error'})
            handleGetUserById(Number(userInfo.idUser))
          } 
         
        }
    }

  };

  return (
    <form onSubmit={handleSubmit}>
      <label className="label-pay">
        Número de tarjeta*
        <CardNumberElement
          options={options}
          onReady={() => {
          }}
          onChange={(event) => {
            if(event.error){
              seterror(true)
            }else{
              seterror(false)
            }
          }}
          onBlur={() => {
          }}
          onFocus={() => {
          }}
        />
      </label>
      <label className="label-pay">
        Titular de la tarjeta*
        <input name="name" type="text" required className="input-stripe" style={{ width:'100%' }} />
      </label>
      <div style={{ display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'20px', width:'100%' }}>
        <label className="label-pay">
          Vencimiento*
          <CardExpiryElement
            options={options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              if(event.error){
                seterror(true)
              }else{
                seterror(false)
              }
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label className="label-pay">
          CVC*
          <CardCvcElement
            options={options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              if(event.error){
                seterror(true)
              }else{
                seterror(false)
              }
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
      </div>
      <IonButton color={'orange'} expand="block" type="submit" disabled={error} style={{ textTransform:'none', fontWeight:600 }}>
        Confirmar Pago
      </IonButton>
    </form>
  );
};