import { useIonRouter } from '@ionic/react'
import { useGetUserById } from 'hooks/users/useGetUserById'
import React, { useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { stripeErrorAPI } from 'recoil/StripeErrorAtom'
import { IStripePayment } from 'services/interfaces/IStripe'
import { postStripePayment } from 'services/sessions'

export const usePostStripeSession = () => {

    const navigation = useIonRouter()
    const setStripeErrorAPI = useSetRecoilState(stripeErrorAPI)
    const { handleGetUserById } = useGetUserById()

    const handlePostStripeSession = useCallback(
      async(body:IStripePayment, setIsOpen:any, id:number ) => {

        postStripePayment(body).then( response =>{ 
            if(response.status === 200){
                setIsOpen(false)
                navigation.push('/sesion-checkout', 'forward', 'replace')
                handleGetUserById(id)
            }
        })
        .catch( error => { 
            if(error.response.status === 404){
                const data = error.response.data
                setStripeErrorAPI({
                    code: data.msg.code,
                    declineCode: data.msg.declineCode
                })
            }
        })

      },
      [],
    )
    
  return {
    handlePostStripeSession
  }
}
