//ionic
import { IonGrid, IonIcon, IonText, IonToast } from '@ionic/react';

//css
import './progress-component.css';

//icons
import { pencil } from 'ionicons/icons';

//components
import GoalsModal from './GoalsModal';
import EmptyState from 'components/EmptyState/EmptyState';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';
import { toastState } from 'recoil/ToastAtom';

const PerformanceGoals: React.FC = () => {

  const { IsOpen, handleOpenModal, handleCloseModal } = useOpenModal();
  const userInfo = useRecoilValue(userInfoState)
  const [toast, setToast] = useRecoilState(toastState);

  return (
    <>
    <IonGrid className="p-goals-container">
      {userInfo.trainingGoals ? (
        <>
          <div className="flex" style={{ justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
            <IonText className="p-goals-title">Mis objetivos de rendimiento</IonText>
            <IonIcon style={{ width:24, height: 24 }} icon={pencil} onClick={() => handleOpenModal('perfomanceGoals')} />
          </div>
          {
            <IonText className="p-goals-li">
              {userInfo.trainingGoals}
            </IonText>
          }
        </>
      ) : (
        <div onClick={() => handleOpenModal('perfomanceGoals')}>
          <EmptyState title="¡Agregá tus objetivos!" subtitle="Te ayudarán a enfocarte en aumentar tu rendimiento" />
        </div>
      )}

      <GoalsModal goal={userInfo.trainingGoals} id={Number(userInfo.idUser)} isOpen={IsOpen.perfomanceGoals} handleClose={handleCloseModal} />
    </IonGrid>
    
    </>
  );
};

export default PerformanceGoals;
