import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { userExerciseAtom } from 'recoil/exercisesAtom'
import { IPostUserExercise, postUserExercise } from 'services/entrenamiento'

export const usePostUserExercise = () => {

    const setUserExercise = useSetRecoilState(userExerciseAtom)

    const handlePostUserExercise = useCallback(
      async(body: IPostUserExercise) => {
        
        postUserExercise(body).then( response => { 
          const data = response.data
          if(response.status){
            setUserExercise({ idUserTraining: data.idUserTraining })
          }
        })
      },
      [],
    )
    
  return {handlePostUserExercise}
}
