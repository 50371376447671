import axios from './BaseConfig';

//interface
import { FavoritesLimit } from './interfaces/FavoritesLimit';
import { IPostUserFavoriteBody } from './interfaces/IPostUserFavoriteBody';

export const getUserFavorites = async (params: FavoritesLimit, id: number) => {
  try {
    const res = await axios.get(`/users/${id}/bookmarks`, { params });
    return res.data.results;
  } catch (e) {
    console.log('error', e);
  }
};

export const deleteUserFavorite = async (id: number, idBookmark: number) => {
  try {
    const res = await axios.delete(`/users/${id}/bookmarks/${idBookmark}`);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};

export const postUserFavorite = async (id: number, body: IPostUserFavoriteBody) => {
  try {
    const res = await axios.post(`/users/${id}/bookmarks`, body);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};
