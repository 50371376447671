import React from 'react';

//ionic
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

//react router dom
import { Route } from 'react-router-dom';

//pages
import Progress from 'pages/progress/Progress';
import Home from 'pages/home/home';
import { AyudaMain } from 'pages/ayuda/AyudaMain';
import { MainAprendizaje } from 'pages/aprendizaje/MainAprendizaje';
import { BloqueAprendizaje } from 'pages/aprendizaje/bloque/BloqueAprendizaje';

//icons
import { helpCircle, home, statsChart } from 'ionicons/icons';

export const MainTabs = () => {
  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tabs/home" component={Home} />
          <Route exact path="/tabs/progress" component={Progress} />
          <Route exact path="/tabs/help" component={AyudaMain} />
          <Route exact path="/tabs/aprendizaje" component={MainAprendizaje} />
          <Route exact path="/tabs/aprendizaje/:id" component={BloqueAprendizaje} />
        </IonRouterOutlet>
        <IonTabBar style={{ height: '56px' }} slot="bottom" color={'orange'}>
          <IonTabButton tab="tab1" href="/tabs/home">
            <IonIcon aria-hidden="true" icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tabs/progress">
            <IonIcon aria-hidden="true" icon={statsChart} />
            <IonLabel>Progreso</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/tabs/help">
            <IonIcon aria-hidden="true" icon={helpCircle} />
            <IonLabel>Ayuda</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};
