import React, { useEffect, useState } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonButton } from '@ionic/react';

//icons
import close_modal from '../../../../assets/close_modal.svg';

import './subscribe-modal.css';
import { useSetRecoilState } from 'recoil';
import { planSelectedState } from 'recoil/planAtom'
import { useGetPaymentInfo } from 'hooks/subscription/useGetPaymentInfo';

interface ISubscribeModal {
  setShowModal: (value: boolean) => void;
  setShowSub?: (value: boolean) => void;
  showModal: boolean;
}

function SubscribeModal({ showModal, setShowModal, setShowSub }: ISubscribeModal) {

  const [isClicked, setisClicked] = useState({ basic: false, anual: false, trimestral: false})
  const setplanSelected = useSetRecoilState(planSelectedState)
  const { handleGetPaymentInfo, paymentInfo } = useGetPaymentInfo()
  useEffect(()=>{
    handleGetPaymentInfo()
  },[])

  return (
    <IonModal isOpen={showModal} className="ul-modal" 
    onDidDismiss={()=> {
      setShowModal(false)
      setisClicked({ basic: false, anual: false, trimestral: false })
    } 
    }
      >
      <IonGrid className="wrapper">
        <IonRow className="sm-icon-row">
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <IonText className="sm-subscribe-plan">Planes de Suscripción</IonText>
          <IonText className="sm-subscribe-text">Elige el plan a tu medida para aprovechar al máximo la app Campus Huevo Sanchez.</IonText>
        </div>

        <div className="sm-card-container" 
          style={{ marginTop: '10px', border: isClicked.anual ? '1px solid #ED4D07' : 'none' }} 
          onClick={()=> {
            setisClicked({ basic: false, anual: true, trimestral: false })
            setplanSelected({ id: 2 })
          }}
        >
          <IonText className="sm-price">USD ${paymentInfo.length !== 0 && paymentInfo[1].amount }</IonText>
          <IonText className="sm-plan">Anual</IonText>
          <IonText className="sm-plan-text">Acceso ilimitado al Programa de Aprendizaje, Entrenamientos, y Sesiones en Vivo.</IonText>
        </div>

        
        <div className="sm-card-container" 
          onClick={()=> {
            setisClicked({ basic: false, anual: false, trimestral: true })
            setplanSelected({ id: 3 })
          }} 
          style={{ marginTop: '24px', border: isClicked.trimestral ? '1px solid #ED4D07' : 'none' }}
        >
          <IonText className="sm-price">USD ${paymentInfo.length !== 0 && paymentInfo[2].amount }</IonText>
          <IonText className="sm-plan">Trimestral</IonText>
          <IonText className="sm-plan-text">Todos los beneficios del plan Anual por tres meses.</IonText>
        </div>

        <IonButton 
          onClick={()=>{
            if(setShowSub)setShowSub(true)
            setShowModal(false)
          }
          } 
          className="sm-button" fill="clear">
          Suscribirme
        </IonButton>

        <IonText className="sm-terms-text">Tiene renovación automática.</IonText>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <IonText className="sm-terms">Términos y condiciones</IonText>
          <span className="sm-terms" style={{ textDecoration: 'none', margin: '0 4px' }}>
            -
          </span>
          <IonText className="sm-terms">Política de Privacidad</IonText>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default SubscribeModal;
