import { useEffect, useState } from "react";
import './countdowntext.css'
import { useRecoilValue } from "recoil";
import { orientationAtom } from "recoil/OrientationAtom";

interface ICountDownText{ 
  onComplete: ()=>void
}

export const CountDownText: React.FC <ICountDownText>= ({onComplete})=>{ 

  const orientation = useRecoilValue(orientationAtom)

  useEffect(() => {
    setTimeout(() => {
      onComplete()
    }, 3200);
  }, [])
  

    return(
        <div className="cdtext-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px' }}>
            <p style={{ fontSize:18, fontWeight:700, fontFamily:'poppins' }}>
                ¡Preparate para el ejercicio!
            </p>
        </div>
    )
}