import { FacebookLogin } from '@capacitor-community/facebook-login'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { useIonRouter } from '@ionic/react'
import { useOpenModal } from 'hooks/modal/useOpenModal'
import React, { useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { tokenState } from 'recoil/tokenAtom'
import { deleteUser } from 'services/user'

export const useDeleteUser = () => {
    const [isDeleting, setIsDeleting] = useState(false)
    const setToken = useSetRecoilState(tokenState)
    const navigation = useIonRouter()
    const { handleOpenModal} = useOpenModal()

    const handleDeleteUser = useCallback(
      (id:number) => {
        deleteUser(id).then( result =>{ 
            if(result.status === 200){
                setIsDeleting(true)
        
                setTimeout(() => {
                    setIsDeleting(false)
                    setToken({ jwtToken: null, userId: null });
                    handleOpenModal('cuentaBorrada')
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('pw');
                    localStorage.removeItem('device');
                    localStorage.removeItem('client');
                    localStorage.removeItem('googleToken');
                    GoogleAuth.signOut();
                    FacebookLogin.logout();
                    navigation.push('/login/home', 'back', 'pop');
                }, 1500);
            }
        })
      },
      [],
    )
    
  return {
    isDeleting,
    handleDeleteUser
  }
}
