import { useState } from 'react';

//ionic
import { IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, useIonRouter, useIonViewDidEnter, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline, closeOutline } from 'ionicons/icons';

//components
import { QuickTrainingHeader } from './components/QuickTrainingHeader';
import { StepperQuickTraining } from 'components/Stepper-quick-training/StepperQuickTraining';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import ModalTrainingWarning from 'components/modal/advertencia-entrenamiento/ModalTrainingWarning';
import ModalFinalizacionEntrenamiento from 'components/modal/finalizacion-entrenamiento/ModalFinalizacionEntrenamiento';
import ModalAbandonoEntrenamiento from 'components/modal/abandono-entrenamiento/ModalAbandonoEntrenamiento';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { basisState, listExerciseAtom, trainingState } from 'recoil/TrainingAtom';
import { useGetQuickTrainingExercises } from 'hooks/entrenamiento-rapido/useGetQuickTrainingExercises';
import { modalWarningAtom } from 'recoil/modalAtom';
import { usePostUserExercise } from 'hooks/entrenamiento-rapido/usePostUserExercise';

export const QuickTrainingMain: React.FC = () => {
  const navigation = useIonRouter();

  const { IsOpen, handleOpenModal, handleCloseModal } = useOpenModal();

  const showModal = useRecoilValue(modalWarningAtom);
  const [showModalFirst, setshowModalFirst] = useState(showModal);
  const [training, setTraining] = useRecoilState(trainingState)
  const [basis, setBasis] = useRecoilState(basisState)
  const [ listExercise,setExercise] = useRecoilState(listExerciseAtom)

  const { exercises, handleGetQuickTrainingExercises } = useGetQuickTrainingExercises()
  const { handlePostUserExercise } = usePostUserExercise()

 useIonViewDidEnter(()=>{ 
  if(listExercise.length === 0){
    handleGetQuickTrainingExercises(training, basis)
  }
  let aro = training.tieneAro === "yes" ? 1 : 0
  let companero = training.tieneCompanero === "yes" ? 1 : 0
  
  if(listExercise.length === 0){
    handlePostUserExercise({ duration: Number(training.duracion), hoop: Number(aro), partner: Number(companero) })
  }
 })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <article style={{ display: 'flex', width: 300 }}>
            <IonButtons
              slot="start"
              onClick={() => {
                handleOpenModal('abandonoEntrenamiento')
              }}
            >
              <IonIcon style={{ width: 24, height: 24, marginLeft: 10 }} icon={closeOutline} />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft: 10, fontWeight: 700 }}>Finalizar</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <QuickTrainingHeader basis={basis} training={training} />
        <StepperQuickTraining data={exercises} />
        <ModalFinalizacionEntrenamiento IsOpen={IsOpen.finishedTraining} handleClose={handleCloseModal} />
        <ModalAbandonoEntrenamiento IsOpen={IsOpen.abandonoEntrenamiento} handleClose={handleCloseModal} />
        <ModalTrainingWarning setShowModal={setshowModalFirst} showModal={showModalFirst} />
      </IonContent>
    </IonPage>
  );
};
