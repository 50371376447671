import { IonButton, IonIcon, IonText } from "@ionic/react"
import { Fieldset } from "components/FieldSet/FieldSet"
import { FieldSetPassword } from "components/FieldSet/FieldSetPassword"
import { Form, Formik } from "formik"
import { IPasswordReset } from "services/interfaces/AuthLogin"
import { resetValidation } from "../LoginValidate"
import { checkmarkCircle } from "ionicons/icons"
import { useResetPassword } from "hooks/auth/useResetPassword"

interface IResetPassword{ 
    email:string
}

export const ResetPassword : React.FC <IResetPassword>= ({ email })=>{ 

    const { handleResetPassword } = useResetPassword()

    return(
        <div>
            <div style={{ width:'100%', height:128, border:'3px solid #168404', padding:'16px'}}>
                <div style={{ width:'100%', display:'flex', alignItems:'center'}}>
                    <IonIcon icon={checkmarkCircle} style={{ width:36, height:36, fill:'#168404', marginRight:15}} />
                    <IonText color={'dark'} style={{ fontSize:16, fontWeight:700, color:'#168404' }}> Envíamos tu clave temporal. </IonText>
                </div>
                <div style={{ width:'100%', display:'flex', alignItems:'center', marginTop:10 }}>
                    <IonText color={'dark'} style={{ fontSize:16, fontWeight:400 }}>
                         Por favor, revisa la bandeja de entrada y correos no deseados. 
                    </IonText>
                </div>
            </div>
            <Formik
                initialValues={{
                code: '',
                newPassword:'',
                rtNewPassword:''
                }}
                enableReinitialize={true}
                validationSchema={resetValidation}
                onSubmit={(values) => {
                
                const formValues:IPasswordReset = {
                    code: values.code,
                    newPassword: values.newPassword,
                    rtNewPassword: values.rtNewPassword
                }

                handleResetPassword({...formValues, email})
                }}
                >
                {({ setFieldTouched ,dirty, isValid, values,errors, touched }:any) => (
                <Form>
                    <article style={{ display:'flex', flexDirection:'column', alignItems:'center', marginTop:20 }}>
                        <Fieldset
                            name="code"
                            type="text"
                            label="Clave Temporal*"
                            placeholder=""
                            value={values.code}
                            errors={errors.code}
                            setFieldTouched={setFieldTouched}
                            touched={touched.code}
                            isTouched={false}
                        />
                        <FieldSetPassword
                            name="newPassword"
                            type="password"
                            label="Contraseña *"
                            placeholder="Ingrese su contraseña..."
                            value={values.newPassword}
                            errors={errors.newPassword}
                            setFieldTouched={setFieldTouched}
                            touched={touched.newPassword}
                            isTouched={true}
                        />
                        <FieldSetPassword
                            name="rtNewPassword"
                            type="password"
                            label="Contraseña *"
                            placeholder="Ingrese su contraseña..."
                            value={values.rtNewPassword}
                            errors={errors.rtNewPassword}
                            setFieldTouched={setFieldTouched}
                            touched={touched.rtNewPassword}
                            isTouched={true}
                        />
                    </article>
                    <article style={{ display:'flex', flexDirection:'column', alignItems:'center', marginBottom:20 }}>
                        <IonButton type='submit' disabled={!(dirty && isValid)} color={!(dirty && isValid) ? 'medium' : "orange"} style={{ width:'100%', marginTop:20 }}>Ingresar</IonButton>
                    </article>
                </Form>
                )}
            </Formik>
      </div>
    )
}