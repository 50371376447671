import { useCallback, useState } from 'react';

//recoil
import { useSetRecoilState } from 'recoil';
import { ratingStatusAtom } from 'recoil/ratingStatusAtom';

//services
import { getModules } from 'services/aprendizaje';

export const useGetModules = () => {
  const [modules, setModules] = useState([]);

  const setRatingStatus = useSetRecoilState(ratingStatusAtom);

  const handleGetModules = useCallback(
    async (id: number) => {
      try {
        const resp = await getModules(id);
        setModules(resp);
      } catch (e) {
        console.log(e);
      } finally {
        setRatingStatus(0);
      }
    },
    [setRatingStatus]
  );

  return { modules, handleGetModules };
};
