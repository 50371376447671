import { useCallback, useState } from 'react';

//services
import { getUserSessions } from 'services/sessions';

//interfaces
import { MySessions } from 'services/interfaces/MySessions';

export const useGetUserSessions = () => {
  const [sessions, setSessions] = useState([]);

  const handleGetUserSessions = useCallback(async (id: number, params: MySessions) => {
    const response = await getUserSessions(id , params);
    setSessions(response);
  }, []);

  return { sessions, handleGetUserSessions };
};
