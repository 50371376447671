import { IonContent, IonPage, IonButton, useIonRouter, IonImg, IonGrid, IonText } from '@ionic/react';

//images
import bienvenida from '../../assets/bienvenida.jpg';

//css
import './loginHome.css';
import CuentaBorradaModal from 'components/modal/cuenta-borrada/CuentaBorradaModal';
import { useOpenModal } from 'hooks/modal/useOpenModal';

export const LoginHome: React.FC = () => {
  const navigation = useIonRouter()
  const { IsOpen, handleCloseModal } = useOpenModal()

  const handleLogin = ()=>{
    localStorage.setItem('begin', 'true')
    navigation.push('/login/signup', 'forward', "replace")
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonImg className="ion-img" src={bienvenida} alt="welcome"></IonImg>

        <IonGrid className="ion-grid-container">
          <IonGrid className="ion-grid-child">
            <IonText className="ion-title">Campus Huevo Sanchez</IonText>
            <IonText className="ion-subtitle">Formación, enseñanza y desarrollo en basketball a cargo de expertos</IonText>
          </IonGrid>
          <IonButton fill="clear" className="ion-button" onClick={handleLogin}>
            Empezar
          </IonButton>
        </IonGrid>
        <CuentaBorradaModal handleClose={handleCloseModal} isOpen={IsOpen.cuentaBorrada} />
      </IonContent>
    </IonPage>
  );
};
