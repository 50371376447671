//ionic
import { IonGrid, IonText, IonButton } from '@ionic/react';

//css
import './progress-component.css';
import { useGetProgressHistory } from 'hooks/progress/useGetProgressHistory';
import { useEffect, useState } from 'react';

export const HistorialAprendizaje: React.FC = () => {

  const { handleGetProgressHistory, progressHistory } = useGetProgressHistory()
  const [limit, setLimit] = useState(1)


  useEffect(() => {
    handleGetProgressHistory(limit)
  }, [limit])

  return (
    <>
    <IonGrid className="p-historial-container">
      <IonText className="p-historial-title">Historial</IonText>
    {progressHistory.length !== 0 && 
      progressHistory.map( (progress:any) => { 

        return(
          <>
            <div className="p-historial-div">
              <IonText className="p-historial-day">{progress.name}</IonText>
            </div>
            {
              progress.data.map( (element:any) =>{
                return(
                  <div className="flex" style={{ alignItems: 'center', marginTop: '16px' }}>
                    <div className="p-circle" style={{ background: '#FFE1D4' }}></div>
                    <div className="flex" style={{ flexDirection: 'column' }}>
                      <IonText className="p-historial-exercise">{element.moduleName}</IonText>
                      <IonText className="p-historial-description">{element.name}</IonText>
                    </div>
                  </div>
                )
              })
            }
          </>
        )
      })
    }

      <IonButton onClick={()=> setLimit(prevState => prevState + 1)} className="ls-button" fill="clear">
        Ver más Historial
      </IonButton>
    </IonGrid>
    </>
  );
};
