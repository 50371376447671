import { useState, useEffect } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonIcon, IonButton } from '@ionic/react';

//icons
import { trashOutline } from 'ionicons/icons';
import close_modal from '../../assets/close_modal.svg';

//css
import './favorites-block.css';

//formik
import { Formik, Form, Field } from 'formik';

//hooks
import { usePostNote } from 'hooks/notes/usePostNotes';
import { usePutNote } from 'hooks/notes/usePutNotes';

//recoil
import { useDeleteNote } from 'hooks/notes/useDeleteNote';

interface ISubscribeModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  note: string | null;
  idNote: number | null;
  idExcercise: number;
}

const field_styles = { minHeight: '160px', outline: 'none', borderRadius: '20px', border: '1px solid #626875', padding: '10px 12px', width: '100%', marginTop: '4px' };
const button_styles = { width: 300, fontWeight: 700, marginTop: '42px', fontFamily: 'Lato', fontStyle: 'normal', fontSize: '14px', lineHeight: '20px', textAlign: 'center', letterSpacing: '0.004em' };

function NotesModal({ showModal, setShowModal, idNote, note, idExcercise }: ISubscribeModal) {
  const counterFormatter = (inputLength: number) => `${inputLength}/500`;

  const [inputLength, setInputLength] = useState<number>(0);

  const { handlePutNote } = usePutNote();
  const { handlePostNote } = usePostNote();
  const { handleDeleteNote } = useDeleteNote();

  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="f-icon-row">
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              if (idNote) {
                handleDeleteNote(idExcercise, idNote);
                setShowModal(false);
              }
            }}
          >
            <IonIcon icon={trashOutline} className="f-trash-icon" />
            <IonText className="f-trash-text">Borrar notas</IonText>
          </div>
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonText className="f-notes-title">Notas</IonText>
        <Formik
          initialValues={{
            note: note || '',
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            if (idNote) handlePutNote(idExcercise, { note: values.note }, idNote);
            else handlePostNote(idExcercise, { note: values.note });

            setShowModal(false);
          }}
        >
          {({ dirty, isValid, values, setFieldValue }: any) => {
            return (
              <Form>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Field
                    value={values.note}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFieldValue('note', event.target.value);
                      setInputLength(event.target.value.length);
                    }}
                    style={field_styles}
                    id={'note'}
                    name={'note'}
                    type={'text'}
                    as="textarea"
                    maxLength={500}
                  />
                  <IonText className="f-counter">{counterFormatter(inputLength)}</IonText>
                </div>
                <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IonButton type="submit" color={!(dirty && isValid) ? 'medium' : 'orange'} fill="solid" style={button_styles} disabled={!(dirty && isValid)}>
                    Guardar notas
                  </IonButton>
                </article>
              </Form>
            );
          }}
        </Formik>
      </IonGrid>
    </IonModal>
  );
}

export default NotesModal;
