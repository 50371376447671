import * as yup from 'yup';

export const contactValidation = yup.object().shape({
    email: yup
        .string()
        .email('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com')
        .min(1)
        .required('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com'),
    note: yup
        .string()
        .min(1)
        .required("Ingresa al menos una consulta"),
    phone: yup
        .string()
        .min(1)
        .required('El numero de contacto es requerido')
})