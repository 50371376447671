//ionic
import { IonRow, IonGrid, IonText, useIonRouter } from '@ionic/react';

//css
import './LiveSessions.css';

interface Session {
  idSession: number;
  title: string;
  category: string;
  label: string;
  contactInfo: string;
  description: string;
  eventDate: number;
  fullName: string;
  role: string;
}
interface SingleLiveSessionProps {
  session: Session;
}

const SingleLiveSession: React.FC<SingleLiveSessionProps> = ({ session }) => {
  const timestamp = session && session.eventDate;
  const fechaOriginal = new Date(timestamp);

  const nombresDias = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

  const nombreDia = nombresDias[fechaOriginal.getDay()];
  const dia = fechaOriginal.getDate();
  const mes = fechaOriginal.getMonth() + 1;

  const fechaFormateada = `${nombreDia} ${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}`;

  const navigation = useIonRouter();

  const title = session && session.title;

  return (
    <IonGrid style={{ display: 'flex', flexDirection: 'row' }} className="ls-box" onClick={() => navigation.push(`/sesiones/${session.idSession}`, 'forward', 'push')}>
      <IonRow className="ls-box-first-child" style = {{width: "20%"}}>
        <IonText className="ls-date">{fechaFormateada}</IonText>
      </IonRow>

      <IonRow className="ls-box-second-child" style = {{width: "80%"}}>
        <IonText className="ls-chat-name">{title}</IonText>

        <IonText class="ls-chat-description">
          {session.fullName}: {session.role}
        </IonText>
      </IonRow>
    </IonGrid>
  );
};

export default SingleLiveSession;
