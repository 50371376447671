
import { AxiosError } from 'axios'
import { useGetUserById } from 'hooks/users/useGetUserById'
import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { stripeErrorAPI } from 'recoil/StripeErrorAtom'
import { userInfoState } from 'recoil/User'
import { cancelAutomaticRenewal } from 'services/subscription'

export const useCancelAutomaticRenewal = () => {

    const { handleGetUserById } = useGetUserById()
    const userInfo = useRecoilValue(userInfoState)
    const setStripe = useSetRecoilState(stripeErrorAPI)

    const handleCancelAutomaticRenewal = useCallback( async()=>{ 

       const response = await cancelAutomaticRenewal().then( response=>{

        if(response.status === 200){
            handleGetUserById(Number(userInfo.idUser))
            setStripe({ code:'cancelSuccess', declineCode:''})
        }
       })
       .catch( (error:AxiosError) =>{ 

        return error.response?.status
       })

       return response
    },[])  
  
    return {
        handleCancelAutomaticRenewal
    }
}
