import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonMenuButton } from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import { PoliticasPrivacidadContenido } from "./PoliticasPrivacidadContenido"

export const PoliticasPrivacidad: React.FC = ()=>{ 

    return(
        <IonPage>
            <IonHeader collapse="fade">
                <IonToolbar>
                    <article style={{ display:'flex', width: 300 }}>
                    <IonButtons slot="start">
                        <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/tabs/help' />
                    </IonButtons>
                    <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Politica de Privacidad</p>
                    </article>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{ width:'100%', display:'flex', justifyContent:'center'}}>
                    <PoliticasPrivacidadContenido />
                </div>
            </IonContent>
        </IonPage>
    )
}