import { useState } from 'react';

//formik
import { Field } from 'formik';

//components
import { ErrorMessageText } from 'components/FieldSet/ErrorMessage';

//ionic
import { IonIcon } from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';

//css
import './mi_cuenta.css';

interface IFieldSet {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  isTouched: boolean;
  setFieldValue: (field: string, value: any) => void;
  setIsFormChanged: (value: boolean) => void;
}

export const TextfieldPassword = ({ touched, name, label, type, placeholder, value, errors, setFieldValue, setIsFormChanged }: IFieldSet) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);

  const handleBorderColor = () => {
    if (touched && value && errors) return '#B62D25';
    else if (touched && !value && errors) return '#B83A02';
    else return '#626875';
  };

  const handleTextColor = () => {
    if (touched && value && errors) return '#B62D25';
    else if (touched && !value && errors) return '#393A3F';
    else return '#393A3F';
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setIsFormChanged(true);
  };

  return (
    <article className="ma-article-textfield">
      <label className="ma-label" htmlFor={name}>
        {label}
      </label>
      <article>
        <Field id={name} name={name} value={value}>
          {({ field, form: { touched, errors, setFieldTouched }, meta }: any) => (
            <>
              <div style={{ borderColor: handleBorderColor(), outline: 'none' }} className="ma-field">
                <input
                  name={field.name}
                  value={field.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, setFieldValue)}
                  type={showHidePassword ? 'text' : 'password'}
                  style={{ all: 'unset', width: '100%' }}
                  onClick={() => setFieldTouched(field.name, true)}
                  onBlur={() => setFieldTouched(field.name, false)}
                  autoComplete="off"
                  placeholder={placeholder}
                />
                <IonIcon style={{ marginLeft: 20, width: 25, height: 25 }} onClick={() => changeShowHidePassword(!showHidePassword)} slot="icon-only" icon={!showHidePassword ? eyeOff : eye}></IonIcon>
              </div>
              {touched[name] && errors[name] && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors[name]} />}
            </>
          )}
        </Field>
      </article>
    </article>
  );
};
