//interfaces
import { IStylesMap } from 'interfaces/IFavorite';

//ionic
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonText, IonGrid, useIonRouter, IonIcon } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';

import './mainAprendizaje.css';

export interface IGridStyles {
  [category: string]: { marginTop: string };
}

const categoryMap = [
  { id: 5, name: 'Técnica Individual' },
  { id: 4, name: 'Táctica individual' },
  { id: 2, name: 'Defensa' },
  { id: 1, name: 'Tiro' },
  { id: 3, name: 'Pase' },
];

const stylesMap: IStylesMap = {
  'Técnica Individual': { left: '30%' },
  'Táctica individual': { left: '30%' },
  Defensa: { left: '21%' },
  Tiro: { left: '17%' },
  Pase: { left: '17%' },
};

const gridStyles: IGridStyles = {
  'Técnica Individual': { marginTop: '18px' },
  'Táctica individual': { marginTop: '10px' },
  Defensa: { marginTop: '10px' },
  Tiro: { marginTop: '10px' },
  Pase: { marginTop: '10px' },
};

export const MainAprendizaje: React.FC = () => {
  const navigation = useIonRouter();

  const handleRedirect = (id: number) => navigation.push(`/tabs/aprendizaje/${id}`, 'forward', 'replace');

  function transform_word(word: string) {
    const formattedWord = word
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    if (formattedWord === 'tecnica individual' || formattedWord === 'tactica individual') {
      return formattedWord.replace(/\s+/g, '-');
    }

    return formattedWord;
  }

  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <article style={{ display:'flex', width: 300 }}>
            <IonButtons slot="start" onClick={()=> {
              navigation.push('/tabs/home', 'forward' , 'pop')}}>
                  <IonIcon style={{ width:24, height:24, marginLeft:10 }} icon={arrowBackOutline} />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Programa de aprendizaje</p>
        </article>
      </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid className="a-container">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IonText className="a-title">Aprende todos los fundamentos esenciales</IonText>
            <IonText className="a-subtitle">De la mano de nuestro gran staff</IonText>
          </div>
          {categoryMap.map((item, index) => {
            return (
              <IonGrid onClick={() => handleRedirect(item.id)} className={`a-first-image-container a-${transform_word(item.name)}`} key={index} style={{ ...gridStyles[item.name] }}>
                <IonText className="a-image-text" style={{ ...stylesMap[item.name] }}>
                  {item.name}
                </IonText>
              </IonGrid>
            );
          })}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
