import { atom } from 'recoil';

export interface IMinuteExercise {
  idTraining: number,
  minutes: number,
}

export const exercisePointsAtom = atom({
  key: 'exercisePointsAtom',
  default: 0,
});

export const exerciseMinutesDoneAtom = atom({
  key: 'exerciseMinutesDone',
  default: [{
      idTraining:0,
      minutes:0
  }] as IMinuteExercise[]
});

export const userExerciseAtom = atom({
  key: 'userExercise',
  default: {
    idUserTraining: 0
  },
});

export const timerExerciseAtom = atom({
  key: 'timerExercise',
  default: false
})