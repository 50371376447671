import { atom } from 'recoil';
import { Iuser } from 'services/interfaces/Users';

export const initialStateUser:Iuser = {
    idUser: null,
    idLevel: 0,
    firstName: '',
    lastName: '',
    email: '',
    idSuscription: 0,
    points: 0,
    level: 0,
    key: '',
    type: '',
    pictureURL: null,
    canceledAt: null,
    trainingGoals: null
  }

export interface userLevel {
  idLevel: number,
  name: string,
  minPoints: number,
  maxPoints: number
}

export const userInfoState = atom({
  key: 'userinfo',
  default: initialStateUser,
});

export const userLevelsAtom = atom({
  key: 'userLevels',
  default: [] as userLevel[]
})
