import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, IonCheckbox } from '@ionic/react';

//icons
import close_modal from 'assets/close_modal.svg';

//hooks
import { usePostExercise } from 'hooks/entrenamiento-rapido/usePostExercise';

interface IModal {
  IsOpen: boolean;
  setShowModal: (showModal: boolean) => void;
  idExercise: number;
}

function StatusModal({ IsOpen, setShowModal, idExercise }: IModal) {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    setShowModal(false);
    modal.current?.dismiss();
  }

  const { handlePostExercise } = usePostExercise();

  return (
    <IonModal isOpen={IsOpen} ref={modal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row">
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="ed-modal-container">
          <IonText className="ed-well-done">¡Cuidado!</IonText>
          <IonText className="ed-subtitle">¿Quieres cambiar el estado de la técnica a no disponible? Al hacerlo, no la podrás usar en los Entrenamientos Rápidos</IonText>

          <IonButton
            type="submit"
            fill="clear"
            style={{ marginTop: '32px' }}
            className="ed-button"
            onClick={() => {
              handlePostExercise({ excludeExercises: idExercise });
              dismiss();
            }}
          >
            Si, cambiar a no disponible
          </IonButton>

          <div>
            <IonCheckbox labelPlacement="end" className="ed-checkbox">
              No volver a mostrar aviso
            </IonCheckbox>
          </div>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}

export default StatusModal;
