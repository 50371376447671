import { IonText } from '@ionic/react';
import { Divider } from 'components/Divider';

interface IVideoTrainingBody {
  details: string;
}

export const VideoTrainingBody: React.FC<IVideoTrainingBody> = ({ details }) => {
  return (
    <>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
        <Divider width="60%" marginTop="60px" />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '25px' }}>
          <IonText color={'dark'} style={{ fontSize: 24, fontWeight: 700 }}>
            {' '}
            Detalles claves{' '}
          </IonText>
        </div>
      </div>
      <div style={{ marginLeft: "32px" }}>
        <div className="dc-list-item" dangerouslySetInnerHTML={{ __html: String(details) }}></div>
      </div>
    </>
  );
};
