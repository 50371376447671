import { useCallback, useEffect, useState } from 'react';

//ionic
import { IonButton, IonIcon, IonText, useIonRouter } from '@ionic/react';

//react router dom
import { useParams } from 'react-router';

//components
import { StepEnum } from 'components/Stepper/Step';
import NotesModal from 'components/favorites/NotesModal';

//hooks
import { usePostUserFavorite } from 'hooks/favorites/usePostUserFavorite';
import { useDeleteUserFavorite } from 'hooks/favorites/useDeleteUserFavorite';

//interfaces
import { IExercise } from 'interfaces/IAprendizaje';

//icons
import { documentText, documentTextOutline, heart, heartOutline, playCircleOutline, timeOutline } from 'ionicons/icons';

//recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userInfoState } from 'recoil/User';

//css
import './exercise.css';
import { ExerciseDataAtom } from 'recoil/exerciseDataAtom';

interface IExerciseSingle {
  exercise: IExercise;
  index: number;
  exercises: IExercise[];
}

interface IParams {
  idBasis: string;
  idModule: string;
}

export const Exercise: React.FC<IExerciseSingle> = ({ exercises, exercise, index }) => {
  const [show, setShow] = useState(false);

  const user = useRecoilValue(userInfoState);
  const { idUser } = user;
  const { handleDeleteUserFavorite } = useDeleteUserFavorite();

  const { name, idExercise, note, idNote, bookmark } = exercise;
  const [status, setstatus] = useState<any>(null);

  const { handlePostUserFavorite } = usePostUserFavorite();

  const setExerciseData = useSetRecoilState(ExerciseDataAtom);

  const handleAviableExercise = () => {
    let label = '';
    let className = '';
    switch (index) {
      case 0:
      case 1:
        label = 'Disponible';
        className = 'f-aviable-exercise';
        break;

      default:
        label = 'Incluido con suscripción';
        className = 'f-unaviable-exercise';
        break;
    }

    return { label, className };
  };

  const handleStepBlocked = useCallback(() => {
    const firstIndexIncomplete = exercises.length !== 0 && exercises.findIndex((element: any) => element.completed === 0);
    if (firstIndexIncomplete === index) {
      setstatus(StepEnum.incomplete);
    } else if (exercises[index].completed === 1) {
      setstatus(StepEnum.completed);
    } else {
      setstatus(StepEnum.disabled);
    }
  }, [exercises, index]);

  const navigation = useIonRouter();

  const params: IParams = useParams();
  const { idBasis, idModule } = params;

  useEffect(() => {
    handleStepBlocked();
  }, [handleStepBlocked]);

  const isEnabled = user.idSuscription !== null && status !== StepEnum.disabled;

  const handleValidateSub = () => {
    if (user.idSuscription) {
      return true;
    } else if ((user.idSuscription === null && idExercise === 93) || idExercise === 94) {
      return true;
    } else {
      return false;
    }
  };
  const handleRedirect = () => {
    navigation.push(`/bloque/${idBasis}/modulo/${idModule}/ejercicio/${idExercise}`);
    setExerciseData({ idExercise, idBasis: Number(idBasis), idModule: Number(idModule) });
  };

  return (
    <div className="f-child-exercise">
      <div className="f-excercise-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {user.idSuscription === null ? <IonText className={handleAviableExercise().className}>{handleAviableExercise().label}</IonText> : null}
          <IonText className={status !== StepEnum.disabled ? 'f-excercise-name-module' : 'f-excercise-name-module-disabled'}>{`${index + 1}. ${name}`}</IonText>
        </div>
        {/* <div className="flex" style={{ alignItems: 'center' }}>
          <IonIcon icon={timeOutline} />
          <IonText className="f-time">3:11</IonText>
        </div> */}
      </div>

      <div style={{ marginTop: '16px' }} className="flex">
        <IonButton className="f-play-button" fill="clear" onClick={() => (status !== StepEnum.disabled && handleValidateSub() ? handleRedirect() : false)}>
          <IonIcon icon={playCircleOutline} style={{ marginRight: '4px', color: user.idSuscription !== null && status === StepEnum.disabled ? '#898E9C' : '#D64200' }} />
          <IonText className="f-button-text" style={{ textTransform: 'capitalize', color: user.idSuscription !== null && status === StepEnum.disabled ? '#898E9C' : '#D64200' }}>
            Reproducir
          </IonText>
        </IonButton>

        <div className="flex f-icon-container">
          <div className={isEnabled ? 'f-circle' : 'f-circle-disabled'} style={{ marginRight: '5px' }}>
            {bookmark === 1 ? (
              <IonIcon icon={heart} className={'f-icon'} onClick={() => status !== 'disabled' && handleDeleteUserFavorite(Number(idUser), idExercise)} />
            ) : (
              <IonIcon
                icon={heartOutline}
                className={'f-icon'}
                style={{ color: '#D64200' }}
                onClick={() => status !== 'disabled' && handlePostUserFavorite(Number(idUser), { idExercise: Number(idExercise) })}
              />
            )}
          </div>
          <div className={isEnabled ? 'f-circle' : 'f-circle-disabled'} onClick={() => (isEnabled ? setShow(true) : null)}>
            {idNote ? (
              <IonIcon style={{ color: '#D64200' }} icon={documentText} className={user.idSuscription !== null && status !== StepEnum.disabled ? 'f-icon' : 'f-icon-disabled'} />
            ) : (
              <IonIcon style={{ color: '#D64200' }} icon={documentTextOutline} className={user.idSuscription !== null && status !== StepEnum.disabled ? 'f-icon' : 'f-icon-disabled'} />
            )}
          </div>
        </div>
      </div>
      <NotesModal showModal={show} setShowModal={setShow} note={note} idNote={idNote} idExcercise={idExercise} />
    </div>
  );
};
