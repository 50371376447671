import { IonAccordion, IonAccordionGroup, IonAvatar, IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import '../components/seleccionAyuda.css'
import { SingleBoxHelp } from "../components/SingleBoxHelp"
import { helpFaq } from "constants/preguntasFrecuents"
import { Divider } from "components/Divider"
import React from "react"

export const PreguntasFrecuentes: React.FC = ()=>{

    const helpData = helpFaq

    return(
        <IonPage>
            <IonHeader collapse="fade">
                <IonToolbar>
                    <article style={{ display:'flex', width: 300 }}>
                    <IonButtons slot="start">
                        <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/tabs/help' />
                    </IonButtons>
                    <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Preguntas a Huevo y Staff</p>
                    </article>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{ width:'100%', display:'flex', justifyContent:'center'}}>
                    <IonAccordionGroup>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="six" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">La App Campus Huevo Sanchez</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.appHuevo.map( element =>( 
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="first" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Programa de Aprendizaje</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.aprendizaje.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.aprendizaje.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="second" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Entrenamiento Rápidos</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.entrenamiento.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.entrenamiento.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="third" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Sesiones en Vivo</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.sesiones.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.sesiones.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="fourth" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Próximos Campus</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.proximoCampus.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.proximoCampus.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="fifth" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Progreso</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.progreso.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.progreso.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="seven" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Cuenta y perfil</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content" style={{ backgroundColor:'#F3F3F3'}}>
                                    {
                                        helpData.cuenta.map( (element, idx) =>(
                                            <React.Fragment key={element.title}>
                                            <SingleBoxHelp text={element.text} title={element.title}  />
                                            {
                                                helpData.cuenta.length -1 !== idx &&
                                                <div style={{ marginBottom:'15px' }}>
                                                    <Divider width="100%" />
                                                </div>
                                            }
                                            </React.Fragment> 
                                        ))
                                    }
                                </div>
                            </IonAccordion>
                        </article>
                    </IonAccordionGroup>
                    </div>
            </IonContent>
        </IonPage>

    )
}