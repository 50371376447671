import { useCallback, useState } from "react"
import { resetPasswordUser } from "services/login"
import { useAuthWithCredentials } from "./useAuthWithCredentials"
import { isPlatform } from "@ionic/react"
import { Device } from "@awesome-cordova-plugins/device"

interface IResetPasswordMethod{ 
    code:string,
    newPassword:string,
    rtNewPassword:string,
    email:string
}

export const useResetPassword = ()=>{ 

    const [handleAuthWithCredentials] = useAuthWithCredentials()

    const handleResetPassword = useCallback(
      (body:IResetPasswordMethod) => {
        let bodyToSend = {
            email: body.email, 
            newPassword: body.newPassword,
            temporalPassword: body.code
        }
        resetPasswordUser(bodyToSend).then( response => {

            if(response.status === 200){

                let client = isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web'
                const uuid = isPlatform('ios') ? `${body.email}-${Device.model}` : Device.uuid
                let deviceUUID = uuid
                
                setTimeout(()=>{
                    handleAuthWithCredentials({ client, deviceUUID, email: body.email, password: body.newPassword }, true)
                }, 1500)
            }
        })
      },
      [],
    )
    
    return{
        handleResetPassword
    }
}