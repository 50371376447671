import React from 'react';

//ionic
import { IonIcon } from '@ionic/react';

//icons
import { star, starOutline } from 'ionicons/icons';

//hooks
import { usePostRating } from 'hooks/aprendizaje/usePostRating';
import { useGetModules } from 'hooks/aprendizaje/useGetModules';

interface IRatingProps {
  value: number;
  height?: string;
  width?: string;
  margin?: string;
  score?: boolean;
  idBasis?: number;
  idModule?: number;
}

const Rating: React.FC<IRatingProps> = ({ value = 0, height, width, margin, score = false, idBasis, idModule }) => {
  const [selectedRating, setSelectedRating] = React.useState(value);

  const { handlePostRating } = usePostRating();
  const { modules, handleGetModules } = useGetModules();

  const handleRatingClick = async (ratingValue: number) => {
    setSelectedRating(ratingValue);
    await handlePostRating(Number(idBasis), Number(idModule), { rating: ratingValue });
    handleGetModules(Number(idBasis));
  };

  return (
    <div>
      {Array.from({ length: 5 }).map((_, index) => {
        const ratingValue = index + 1;
        return (
          <IonIcon key={index} icon={ratingValue <= selectedRating ? star : starOutline} style={{ color: '#ED4D07', height, width, margin }} onClick={() => score && handleRatingClick(ratingValue)} />
        );
      })}
    </div>
  );
};

export default Rating;
