import { IonAvatar, IonContent, IonImg, IonProgressBar } from "@ionic/react"
import './sidebar.css';
import { AddAvatar } from "components/Icons/customized avatars/AddAvatar";

interface IUserAvatar { 
    url:string;
    pictureUrl: string | null;
    points: number,
    maxPoints: number
}

export const UserAvatar: React.FC <IUserAvatar>= ({ points,maxPoints,url, pictureUrl })=>{ 

    return(
        <div className="sb-progress-avatar">
            <IonContent>
                <IonProgressBar className="sb-progress-bar" value={points / maxPoints}></IonProgressBar>
                <IonAvatar className="sb-avatar sb-ion-avatar">
                    {
                        url ?
                        <IonImg src={url} alt="img" />
                        : pictureUrl ?
                        <IonImg src={pictureUrl} alt="img"></IonImg>
                        :
                        <AddAvatar width='40px' height='40px' />
                    }
                </IonAvatar>
                
            </IonContent>
        </div>
    )
}