import { IonButton, IonIcon, IonImg, IonModal, useIonRouter } from "@ionic/react"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import './AvatarSelector.css';
import { Divider } from "components/Divider";
import {Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera'
import { useRecoilState, useSetRecoilState } from "recoil";
import { ImageState, userState } from "recoil/UserImage";
import { ImageCropper } from "./image-cropper/ImageCropper";
import { imagesOutline, trashOutline } from "ionicons/icons";

interface IAvatarSelector{
    isEdit?:boolean,
    trigger:string
}

export const AvatarSelector: React.FC <IAvatarSelector> = ({ isEdit=false, trigger })=>{ 

    const modal = useRef<HTMLIonModalElement>(null);
    const [Base64Photo, setBase64Photo] = useRecoilState(ImageState)
    const [userImage, setUserImage] = useRecoilState(userState)

    useEffect(() => {
        Camera.requestPermissions({permissions:['photos']})
    }, [])

    const pickImageFromGallery = async()=>{ 
        const options:ImageOptions={ 
            source:CameraSource.Photos,
            resultType:CameraResultType.DataUrl
        }

        await Camera.getPhoto(options).then( result =>{ 
            if(result.dataUrl){
                modal.current?.setCurrentBreakpoint(1)
                setBase64Photo(result?.dataUrl);
            }
        })
        .catch( err => { 
            alert(err);
        })
    }

    return(
        <IonModal id="example-modal" onDidDismiss={()=> setBase64Photo('')} trigger={trigger} initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 1]} ref={modal}>
            {Base64Photo === '' ? 
            <div className="modalWrapper">
                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <p style={{ fontSize:16, fontWeight:700, color:'#4B4F58' }}>Foto de perfil</p>
                    { userImage.photo !== '' &&
                    <IonIcon 
                        style={{ width:24, height:24, color:'#1E1E1E' }} 
                        icon={trashOutline} 
                        onClick={()=>{
                            setUserImage({ photo: ''})
                            setBase64Photo('')
                            modal.current?.dismiss()
                            }
                        }
                    />}
                </div>
                <div style={{ display:'flex', justifyContent:'center'}}>
                    <Divider width="100%" />
                </div>
                <IonButton onClick={pickImageFromGallery} fill="clear" style={{ color:'#4B4F58' }}>
                    <IonIcon style={{ width:24, height:24, color:'#1E1E1E' }} slot="start" icon={imagesOutline} />
                    Elegir desde la galería
                </IonButton>
            </div>
            :
            <ImageCropper modal={modal.current} isEdit={isEdit} />    
        }
      </IonModal>
    )
}