import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/v1`;

const headers = {
  common: {
    'Content-Type': 'application/json',
  },
  post: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const instance = axios.create({ baseURL, headers });

instance.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem("token");
  if (config.url?.includes("/auth")) {
    config.headers.post = { "Content-Type": "application/json" };
  }
  else if(config.url === "/users" && config.method === "post" ) {
    config.headers.post = { "Content-Type": "application/json" };
  }
  else {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
