export interface IStripePayment{ 
    type: stripeTypePayment,
    amount: Number,
    email: String,
    idStripe: String | undefined,
    idSession: Number
}

export interface IStripeSubscription { 
    idStripe: String | undefined,
    idSuscriptionPlan: Number
}

export interface IStripeSubscriptionResponse {
    clientSecret: String | null
}

export enum stripeTypePayment { 
    session= "session",
    suscription="suscription"
}

export interface IStripeChangeSubscription { 
    idSuscriptionPlan: number,
    prorationDate: number,
    amount: number,
    idStripe: String | undefined
}