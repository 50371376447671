import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonText, IonToast, IonToolbar, IonMenuButton } from "@ionic/react";
import { EmailForm } from "components/forms/recuperar-contraseña/EmailForm";
import { ResetPassword } from "components/forms/recuperar-contraseña/ResetPassword";
import { useRecoverPassword } from "hooks/auth/useRecoverPassword";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { useRecoilState } from "recoil";
import { toastState } from "recoil/ToastAtom";

export const RecuperarContraseña : React.FC = ()=>{ 

    const [toast, setToast] = useRecoilState(toastState)
    const { handleRecoverPassword, isSended, email} = useRecoverPassword()

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <article style={{ display:'flex', width: 300 }}>
                    <IonButtons slot="start">
                        <IonBackButton className='ioBackButton' icon={closeOutline} color='dark' text={''} defaultHref='/login/signin' />
                    </IonButtons>
                    <p style={{ whiteSpace:'nowrap', fontSize: 22, marginLeft:10, fontWeight:700 }}>Recuperar contraseña</p>
                </article>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{ width:'100%', padding:'40px' }}>
                    {
                        isSended ?
                        <ResetPassword email={email} />
                        :
                        <>
                            <IonText style={{ fontSize:14, fontWeight:400, lineHeight:'20px' }} color={'dark'}> Ingresa tu email y te enviaremos una clave temporal para entrar a tu cuenta. </IonText>
                            <EmailForm submit={handleRecoverPassword} />
                        </>
                    }
                </div>

                <IonToast
                isOpen={toast.isOpen}
                message={toast.message}
                icon={toast.icon}
                cssClass={'css-toast'}
                buttons={toast.button}
                onDidDismiss={() => setToast({ isOpen:false })}
                duration={2000}
                ></IonToast>
            </IonContent>
        </IonPage>
      );
}