import * as yup from 'yup';

export const EditUserValidation = yup.object().shape({
  firstName: yup.string().min(1).required('Completa el nombre'),
  lastName: yup.string().min(1).required('Completa el apellido'),
  email: yup.string().email('Completa el email').min(1).required('Completa el email'),
  password: yup
    .string()
    .min(8, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
    .matches(/[0-9]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
    .matches(/[a-z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
    .matches(/[A-Z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
});
