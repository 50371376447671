import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, useIonRouter, IonMenuButton } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { NextSessions } from './components/NextSessions';
import './mainSesiones.css';

export const MainSesiones: React.FC = () => {
  const navigation = useIonRouter();

  return (
    <IonPage>
      <IonHeader collapse="fade">
        <IonToolbar>
          <article style={{ display: 'flex', width: 300 }}>
            <IonButtons
              slot="start"
              onClick={() => {
                navigation.push('/tabs/home', 'back', 'push');
              }}
            >
              <IonIcon style={{ width: 24, height: 24, marginLeft: 10 }} icon={arrowBackOutline} />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft: 10, fontWeight: 700 }}>Sesiones en vivo</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonToolbar color={'dark'} style={{ textAlign: 'center' }}>
          <p style={{ fontFamily: 'Poppins', fontStyle: 'normal' }}>Próximas Sesiones</p>
        </IonToolbar>
        <div className="ls-container" style={{ marginTop: 0 }}>
          <NextSessions />
        </div>
      </IonContent>
    </IonPage>
  );
};
