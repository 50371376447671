//ionic
import { IonText, IonList, IonRouterLink, useIonRouter } from '@ionic/react';

//css
import './sidebar.css';

//components
import { UserInfo } from './UserInfo';

//recoil
import { useSetRecoilState } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';
import { Accordion } from './Accordion';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { initialStateUser, userInfoState } from 'recoil/User';

type MenuRefType = {
  open: () => Promise<boolean>;
  close: () => Promise<boolean>;
};

interface SidebarProps {
  menuRef: React.RefObject<MenuRefType>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const closeMenu = (menuRef: React.RefObject<MenuRefType>) => async () => {
  await menuRef.current?.close();
};

export const Sidebar: React.FC<SidebarProps> = ({ menuRef, show, setShow }) => {
  const navigation = useIonRouter();
  const setToken = useSetRecoilState(tokenState);
  const setInfoUser = useSetRecoilState(userInfoState)

  const handleLogOut = async () => {
    setToken({ jwtToken: null, userId: null });
    setInfoUser(initialStateUser)
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('pw');
    localStorage.removeItem('device');
    localStorage.removeItem('client');
    localStorage.removeItem('googleToken');
    GoogleAuth.signOut();
    FacebookLogin.logout();
    navigation.push('/login/signin', 'forward', 'replace');
    closeMenu(menuRef);
  };

  return (
    <div className="sb-container">
      <div>
        <UserInfo menuRef={menuRef} />
        <IonList className="sb-ion-list">
          <IonRouterLink routerLink="/tabs/home" style={{ marginTop: '12px' }}>
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Home
            </IonText>
          </IonRouterLink>

          <IonRouterLink routerLink="/tabs/aprendizaje" className="sb-mt-24">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Programa de Aprendizaje
            </IonText>
          </IonRouterLink>

          <Accordion show={show} setShow={setShow} onClick={closeMenu(menuRef)} />

          <IonRouterLink routerLink="/sesiones" className="sb-mt-24">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Sesiones en Vivo
            </IonText>
          </IonRouterLink>

          <IonRouterLink routerLink="/campus" className="sb-mt-24">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Próximos Campus
            </IonText>
          </IonRouterLink>

          <div className="sb-divider" style={{ margin: '12px 0' }}></div>

          <IonRouterLink routerLink="/perfil">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Perfil
            </IonText>
          </IonRouterLink>

          <IonRouterLink routerLink="/tabs/progress" className="sb-mt-24">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Progreso
            </IonText>
          </IonRouterLink>

          <IonRouterLink routerLink="/tabs/help" className="sb-mt-24">
            <IonText className="sb-ion-text" onClick={closeMenu(menuRef)}>
              Ayuda
            </IonText>
          </IonRouterLink>
        </IonList>
      </div>

      <IonText className="sb-ion-text" onClick={handleLogOut}>
        Cerrar Sesión
      </IonText>
    </div>
  );
};
