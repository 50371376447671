import { Field } from 'formik';
import { ErrorMessageText } from './ErrorMessage';

interface IFieldSet{
    name:string,
    label:string,
    type?:string,
    placeholder?:string,
    value:any,
    errors:any,
    touched:any,
    setFieldTouched:any,
    isTouched: boolean
}

export const FieldSetTextArea = ({ touched, name, label, type, placeholder, value, errors, setFieldTouched, isTouched }:IFieldSet) => {

  const handleBorderColor = ()=>{
    if(touched && value && errors){
      return '#B62D25'
    }else if(touched && !value && errors){
      return '#B83A02'
    }else{
      return '#626875'
    }
  }

  const handleTextColor = ()=>{
    if(touched && value && errors){
      return '#B62D25'
    }else if(touched && !value && errors){
      return '#393A3F'
    }else{
      return '#393A3F'
    }
  }

  return (
    <article style={{ display:'flex', flexDirection:'column', alignItems:'flex-start', padding:'15px'}}>
      <label style={{ marginBottom:10 }} htmlFor={name}>{label}</label>
      <Field
          onClick={()=>{
            if(isTouched)
            setFieldTouched(name, true)

          }}
          style={{  backgroundColor:'white', padding:10, width:300, height:135, borderRadius:24, borderWidth:'1.6px', borderColor: handleBorderColor(), outline:'none' }}
          id={name} 
          name={name} 
          type={type} 
          placeholder={placeholder} 
          value={value}
          as="textarea"
        />
        { touched && errors && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors}  />}
    </article>
  )
};