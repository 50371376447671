import { useCallback, useState } from "react"
import { useSetRecoilState } from "recoil"
import { stripeErrorAPI } from "recoil/StripeErrorAtom"
import { IStripeSubscription, IStripeSubscriptionResponse } from "services/interfaces/IStripe"
import { postStripeSubscription } from "services/subscription"

export const usePostStripeSubscription = ()=>{ 

    const setStripeErrorAPI = useSetRecoilState(stripeErrorAPI)
    const handlePostStripeSubscription = useCallback(
      async (body:IStripeSubscription) => {
        
        const result = await postStripeSubscription(body).then((response) => {
            let responseStripe: string = ""
            if(response.status === 200){ 

                responseStripe = response.data.clientSecret
                return responseStripe
            }else{
              return responseStripe
            }
        })
        .catch( error => { 
            if(error.response.status === 404){
              const data = error.response.data
              setStripeErrorAPI({
                  code: data.msg.code,
                  declineCode: data.msg.declineCode
              })
            }
        })

        return result
      },
      [],
    )

    return { handlePostStripeSubscription }
    
}