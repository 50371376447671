import { useRef, useState } from 'react';
//ionic
import { IonText, IonIcon, IonToast } from '@ionic/react';

//css
import 'pages/entrenamiento/ejercicios-disponibles/ejercicios-disponibles.css';
import 'components/favorites/favorites-block.css';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';

//componente
import StatusModal from './StatusModal';

//hooks
import { usePostExercise } from 'hooks/entrenamiento-rapido/usePostExercise';
import { useRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';

interface IExerciseBox {
  icon: string;
  className: string;
  exercise: {
    idExercise: number;
    exerciseName: string;
    videoURL: string;
    idModule: number;
    moduleName: string;
    idBasis: number;
    excluded: number;
  };
}

export const ExerciseBox: React.FC<IExerciseBox> = ({ icon, className, exercise }) => {
  const [showModal, setShowModal] = useState(false);
  const { exerciseName, moduleName, excluded, idExercise } = exercise;

  const { handlePostExercise } = usePostExercise();

  const [toast, setToast] = useRecoilState(toastState);

  return (
    <>
      <div className="ed-excercise-box">
        <IonText className="ed-module-name">{moduleName}</IonText>
        <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <IonText className={className}>{exerciseName}</IonText>
          <IonIcon
            icon={icon}
            style={{ width: '42px', height: '42px' }}
            onClick={() => {
              if (excluded === 0) setShowModal(true);
              else handlePostExercise({ includeExercises: idExercise });
            }}
          />
        </div>
      </div>

      <StatusModal IsOpen={showModal} setShowModal={setShowModal} idExercise={idExercise} />
      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        icon={toast.icon}
        cssClass={'css-success-toast'}
        buttons={toast.button}
        onDidDismiss={() => setToast({ isOpen: false })}
        duration={2000}
      />
    </>
  );
};
