export const handleVideoTime = ( index : number, duracion: Number)=>{ 

    if(duracion === 15){
        switch (index) {
            case 5:
                return 0
            case 4:
                return 180
            case 3:
                return 360
            case 2:
                return 540
            case 1:
                return 720
            case 0:
                return 900
        
            default:
                return 0
        }
    }

    if(duracion === 30){
        switch (index) {
            case 10:
                return 0
            case 9:
                return 180
            case 8:
                return 360
            case 7:
                return 540
            case 6:
                return 720
            case 5:
                return 900
            case 4:
                return 1080
            case 3:
                return 1260
            case 2:
                return 1440
            case 1:
                return 1620
            case 0:
                return 1800
        
            default:
                return 0
        }
    }

    if(duracion === 45){
        switch (index) {
            case 15:
                return 0
            case 14:
                return 180
            case 13:
                return 360
            case 12:
                return 540
            case 11:
                return 720
            case 10:
                return 900
            case 9:
                return 1080
            case 8:
                return 1260
            case 7:
                return 1440
            case 6:
                return 1620
            case 5:
                return 1800
            case 4:
                return 1980
            case 3:
                return 2160
            case 2:
                return 2340
            case 1:
                return 2520
            case 0:
                return 2700
        
            default:
                return 0
        }
    }

    if(duracion === 60){
        switch (index) {
            case 19:
                return 180
            case 18:
                return 360
            case 17:
                return 540
            case 16:
                return 720
            case 15:
                return 900
            case 14:
                return 1080
            case 13:
                return 1260
            case 12:
                return 1440
            case 11:
                return 1620
            case 10:
                return 1800
            case 9:
                return 1980
            case 8:
                return 2160
            case 7:
                return 2340
            case 6:
                return 2520
            case 5:
                return 2700
            case 4:
                return 2880
            case 3:
                return 3060
            case 2:
                return 3240
            case 1:
                return 3420
            case 0:
                return 3600
        
            default:
                return 0
        }
    }
}