import React from 'react';

interface IDivider {
  width: string;
  marginTop?: string;
  marginBottom?:string
}

export const Divider = ({ width, marginTop, marginBottom }: IDivider) => {
  return <hr style={{ marginBottom, width: width, margin: '0px', flexShrink: 0, borderWidth: '0px 0px thin', borderStyle: 'solid', borderColor: '#898E9C', marginTop }}></hr>;
};
