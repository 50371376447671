import React from 'react';

export const AddAvatar = () => {
  return (
    <svg width="88" height="76" viewBox="0 0 88 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1241 61.6149C4.27221 55.1075 0 45.9098 0 35.7143C0 15.9898 15.9898 0 35.7143 0C55.4387 0 71.4286 15.9898 71.4286 35.7143C71.4286 45.9069 67.1588 55.1023 60.3102 61.6094C59.7039 62.1854 59.0774 62.7404 58.4319 63.2732C52.2584 68.368 44.3437 71.4286 35.7143 71.4286C26.1854 71.4286 17.528 67.6967 11.1241 61.6149ZM57.227 60.5504C53.6605 59.5502 48.8567 58.6859 47.1395 58.6877C44.2859 58.6906 43.5529 55.3705 43.5716 49.5169C45.0001 49.5134 47.8407 42.6561 47.8571 39.7989C49.2856 39.7988 50.6993 34.3419 50.7318 32.9133C50.7948 30.1386 50.0471 30.1402 49.3502 30.1417C49.3287 30.1418 49.3072 30.1418 49.2859 30.1418C50.0001 27.2846 50.0001 23.9419 50.0001 22.5184C50.0001 15.3705 45.0108 10.4043 35.7144 10.4858C26.4181 10.5672 21.4287 15.3705 21.4287 22.5184C21.4287 23.9419 21.4287 27.2846 22.143 30.1429C22.1228 30.1429 22.1028 30.1428 22.083 30.1427C21.402 30.1393 20.9486 30.137 20.9315 32.9167C20.925 34.3419 22.1429 39.8037 23.5714 39.8037C23.5714 42.6561 25.5309 49.5169 27.8573 49.5134C27.9779 55.3832 27.142 58.5001 24.2859 58.5938C22.5674 58.6503 17.767 59.54 14.2023 60.551C7.25273 54.5264 2.85714 45.6337 2.85714 35.7143C2.85714 17.5678 17.5678 2.85714 35.7143 2.85714C53.8608 2.85714 68.5714 17.5678 68.5714 35.7143C68.5714 45.6333 64.1761 54.5258 57.227 60.5504Z"
        fill="#898E9C"
      />
      <rect x="51.7144" y="40" width="36" height="36" rx="18" fill="white" />
      <path
        d="M83.2144 58C83.2144 50.5469 77.1675 44.5 69.7144 44.5C62.2612 44.5 56.2144 50.5469 56.2144 58C56.2144 65.4531 62.2612 71.5 69.7144 71.5C77.1675 71.5 83.2144 65.4531 83.2144 58Z"
        stroke="#ED4D07"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M69.7144 52.375V63.625" stroke="#ED4D07" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M75.3394 58H64.0894" stroke="#ED4D07" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
