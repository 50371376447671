import { IStripePaymentErrorAPI } from "recoil/StripeErrorAtom";

enum codeError { 
    card_declined = 'card_declined',
    expired_card = 'expired_card',
    incorrect_cvc = 'incorrect_cvc',
    processing_error = 'processing_error',
    incorrect_number = 'incorrect_number'
}

enum declineCodeError { 
    generic_decline = 'generic_decline',
    insufficient_funds = 'insufficient_funds',
    lost_card = 'lost_card',
    stolen_card = 'stolen_card'
}

const handleCardDeclinedStripe = (declineCode:string)=>{ 

    let titleDecline = ''
    let contentTextDecline = ''

    switch (declineCode) {
        case declineCodeError.generic_decline:
            titleDecline = 'Tarjeta rechazada'
            contentTextDecline = 'No pudimos procesar el pago. Prueba con otra tarjeta.'
            break;
        case declineCodeError.insufficient_funds:
            titleDecline = 'Fondos insuficientes'
            contentTextDecline = 'No hay fondos suficientes en esta cuenta. Prueba con otra tarjeta.'
            break;
        case declineCodeError.lost_card:
            titleDecline = 'Tarjeta extraviada'
            contentTextDecline = 'La tarjeta ingresada está reportada como extraviada. Prueba con otra tarjeta.'
            break;
        case declineCodeError.stolen_card:
            titleDecline = 'Tarjeta robada'
            contentTextDecline = 'La tarjeta ingresada está reportada como robada. Prueba con otra tarjeta.'
            break;
    
        default:
            titleDecline = ''
            contentTextDecline = ''
            break;
    }

    return { titleDecline, contentTextDecline }
}

export const handleErrorStripe = ({ code, declineCode }:IStripePaymentErrorAPI)=>{ 

    let title = ''
    let contentText = ''

    switch (code) {
        case codeError.card_declined:
            const { contentTextDecline, titleDecline } = handleCardDeclinedStripe(String(declineCode))
            title = titleDecline
            contentText = contentTextDecline
            break;
        case codeError.expired_card:
            title = 'Tarjeta vencida'
            contentText = 'La tarjeta ingresada ha caducado. Prueba con otra tarjeta.'
            break;
        case codeError.incorrect_cvc:
            title = 'CVC incorrecto'
            contentText = 'El CVC no es válido. Revisa la tarjeta e ingresa el número correcto.'
            break;
        case codeError.incorrect_number:
            title = 'Número incorrecto'
            contentText = 'El número de tarjeta es incorrecto. Revisa la tarjeta e ingresa los 16 dígitos.'
            break;
        case codeError.processing_error:
            title = 'Error de procesamiento'
            contentText = 'No pudimos procesar el pago. Prueba con otra tarjeta o intenta mas tarde.'
            break;
    
        default:
            title = ''
            contentText = ''
            break;
    }

    return { title, contentText }
}