import { IonIcon, IonModal, IonText } from "@ionic/react"
import { useRef } from "react";
import { StripePayForm } from 'components/forms/checkout/StripePayForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './stripeModalPayment.css'
import { closeOutline } from "ionicons/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { stripeErrorAPI, stripeErrorState } from "recoil/StripeErrorAtom";
import { StripePaymentError } from "./stripePaymentError";

interface IStripeModalPayent{ 
    isOpen:boolean,
    setIsOpen: (isOpen:boolean)=> void,
    email:string,
    idSession: Number | null
}

export const StripeModalPayment: React.FC <IStripeModalPayent> = ({ email, isOpen, setIsOpen, idSession})=> { 

    const modalRef = useRef(null)
    const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY), { locale:'es-419'});
    const [stripeError, setStripeError] = useRecoilState(stripeErrorAPI)
    
    return (
        <IonModal ref={modalRef} id="stripe-form-modal" isOpen={isOpen} onDidDismiss={()=> setIsOpen(false)}>
            <Elements stripe={stripePromise}>
                {
                    !stripeError.code ? 
                        <div className="modal-content-stripe">
                            <div className="modal-header-close">
                                <IonIcon 
                                    onClick={()=>{
                                        setIsOpen(false)
                                        setStripeError({
                                            code:null,
                                            declineCode:null
                                        })
                                    }} 
                                    color="dark" 
                                    style={{ width:30, height:30 }} 
                                    slot="icon-only" 
                                    icon={closeOutline}></IonIcon>
                            </div>
                            <div className="header-stripe-modal">
                                <IonText className="ion-text-header"> Total a pagar: </IonText>
                                <IonText className="ion-text-header"> USD $7,99 </IonText>
                            </div>
                            <StripePayForm idSession={idSession} setIsOpen={setIsOpen} email={email} />
                        </div>
                    :
                    <div className="modal-content-stripe">
                        <div className="modal-header-close">
                            <IonIcon 
                                onClick={()=>{
                                    setIsOpen(false)
                                    setStripeError({
                                        code:null,
                                        declineCode:null
                                    })
                                }} 
                                color="dark" 
                                style={{ width:30, height:30 }} 
                                slot="icon-only" 
                                icon={closeOutline}></IonIcon>
                        </div>
                        <StripePaymentError stripeError={stripeError} />
                    </div>
                }
            </Elements>
        </IonModal>
    )
}