import { useCallback, useState } from 'react';

//services
import { getCoaches } from 'services/aprendizaje';

export const useGetCoaches = () => {
  const [coaches, setCoaches] = useState([]);

  const handleGetCoaches = useCallback(async (id: number) => {
    const resp = await getCoaches(id);
    setCoaches(resp);
  }, []);

  return { coaches, handleGetCoaches };
};
