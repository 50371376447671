import React, { useEffect, useState } from 'react';

//ionic
import { IonGrid, IonText } from '@ionic/react';

//css
import './progress-component.css';

//components
import DoughnutChart from './DoughnutChart';
import { Divider } from 'components/Divider';
import { useGetProgressByTime } from 'hooks/progress/useGetProgressByTime';

const ProgressStatistics: React.FC = () => {
  const textos = ['Hoy', 'Semana', 'Mes', 'Máximo'];
  const [selected, setSelected] = useState<number>(0);
  const { handleGetProgressByTime, progressByTime } = useGetProgressByTime()
  const sumTotal = progressByTime?.exercises + progressByTime?.sessions + progressByTime.trainings
  const exercisePer = sumTotal === 0 ? 0 : (progressByTime.exercises / sumTotal) * 100
  const trainingPer = sumTotal === 0 ? 0 : (progressByTime.trainings / sumTotal) * 100
  const sessionPer =  sumTotal === 0 ? 0 : (progressByTime.sessions / sumTotal) * 100
  const handleSelected = (index: number) => setSelected(index);
  const handleStyles = (index: number) => (selected === index ? { color: '#D64200', fontWeight: 700 } : { color: '#4B4F58', fontWeight: 'normal' });

  const statisticsData = [
    { background: '#D64200', percentage: `${exercisePer.toFixed(2)}%`, points: `${progressByTime.exercises}`, program: 'P. de Aprendizaje' },
    { background: '#FFE1D4', percentage: `${trainingPer.toFixed(2)}%`, points: `${progressByTime.trainings}`, program: 'Entrenamiento Rápido' },
    { background: '#272727', percentage: `${sessionPer.toFixed(2)}%`, points: `${progressByTime.sessions}`, program: 'Sesiones en Vivo' },
  ];
  
  useEffect(()=>{
    handleGetProgressByTime(selected)
  },[selected])
  
  return (
    <IonGrid className="p-statistics-container">
      <div className="flex p-tabs">
        {textos.map((texto, index) => (
          <React.Fragment key={index}>
            <IonText style={{ ...handleStyles(index) }} onClick={() => handleSelected(index)} className="p-tabs-text">
              {texto}
            </IonText>
            {index !== textos.length - 1 && (
              <span className="p-tabs-text" style={{ margin: '0 12px' }}>
                |
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
        <div className="flex" style={{ marginTop: '24px', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="flex p-statistics-info">
            {statisticsData.map((item, index) => (
              <div className="flex" style={{ alignItems: 'center', marginTop: index > 0 ? '8px' : '0' }} key={index}>
                <div className="p-circle" style={{ background: item.background }}></div>
                <div className="flex" style={{ flexDirection: 'column' }}>
                  <IonText className="p-statistics-points">
                    <b style={{ fontSize: '16px' }}>{item.percentage}</b> - {item.points} pts.
                  </IonText>
                  <IonText className="p-statistics-program">{item.program}</IonText>
                </div>
              </div>
            ))}
          </div>

          <div className="p-doughnut-container">
            <DoughnutChart exercise={exercisePer} session={sessionPer} training={trainingPer} />
          </div>
        </div>
      <Divider width="100%" marginTop="24px" />
    </IonGrid>
  );
};

export default ProgressStatistics;
