import { useCallback, useState } from 'react';

//services
import { getBasisById } from 'services/aprendizaje';

interface Basis {
  idBasis: number | null;
  name: string;
  videoURL: string;
  createdAt: number | null;
  deletedAt: number | null;
}

export const useGetBasisById = () => {
  const [basis, setBasis] = useState<Basis>({ idBasis: null, name: '', videoURL: '', createdAt: null, deletedAt: null });

  const handleGetBasisById = useCallback(async (id: number) => {
    const resp = await getBasisById(id);
    setBasis(resp);
  }, []);

  return { basis, handleGetBasisById };
};
