import axios from './BaseConfig';

export const postModule = async (idUser: number, idModule: number) => {
  try {
    const res = await axios.post(`/users/${idUser}/modules/${idModule}`);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};
