import { IonContent, IonModal } from "@ionic/react";
import { IHeadPopover } from "components/Header/HeaderPopOver";
import { TerminosCondicionesContenido } from "pages/ayuda/terminos-condiciones/TerminosCondicionesContenido";
import { useRef } from "react";

export const TermsConditionModal: React.FC <IHeadPopover>= ({ triger })=>{ 

    const modal = useRef<HTMLIonModalElement>(null);

    return( 
        <IonModal trigger={triger} initialBreakpoint={1} breakpoints={[0, 0.5, 1]} ref={modal}>
            <IonContent>
                <div style={{ width:'100%', display:'flex', justifyContent:'center'}}>
                    <TerminosCondicionesContenido />
                </div>
            </IonContent>
        </IonModal>
    )
}