import { IonChip, IonText } from "@ionic/react";
import { ISessionObject } from "interfaces/ISession";

export const SessionDescription: React.FC<ISessionObject> = ({
	sessionById,
}) => {
	const label = sessionById.label.split(",");

	return (
		<div className="ion-session-description-container">
			<div className="ion-description-content">
				<IonText className="ion-description-title">A cargo de:</IonText>
				<IonText className="ion-description-text">
					{sessionById.fullName}: {sessionById.role}
				</IonText>
			</div>
			<div className="ion-description-content">
				<IonText className="ion-description-title">Descripción:</IonText>
				<IonText className="ion-description-text">
					{sessionById.description}
				</IonText>
			</div>
			<div className="ion-description-content">
				<IonText className="ion-description-title">
					Etiquetas del evento:
				</IonText>
				<div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
					{label &&
						label.map((element) => (
							<IonChip
								style={{
									width: "fit-content",
									whiteSpace: "nowrap",
									textTransform: "capitalize",
								}}
							>
								{element}
							</IonChip>
						))}
				</div>
			</div>
		</div>
	);
};
