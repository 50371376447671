//ionic
import { IonGrid, IonText, IonIcon, IonProgressBar } from '@ionic/react';
import Rating from 'components/aprendizaje/Rating';

//components

//icons
import { lockClosed, timeOutline } from 'ionicons/icons';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

import './singleModuleHeader.css';

interface IModule {
  module: {
    idModule: number;
    name: string;
    idBasis: number;
    createdAt: number;
    deletedAt: number | null;
    totalExercises: number;
    completedExercises: number;
    rating: string;
  };
  index: number;
}

export const SingleModuleHeader: React.FC<IModule> = ({ module, index }) => {
  const { totalExercises, completedExercises, name, rating } = module;
  const user = useRecoilValue(userInfoState);
  const { idSuscription } = user;

  const handleSubscription = () => {
    if (!idSuscription && module.idModule !== 10) {
      return (
        <IonText className="ba-circle-header h-ion-avatar ba-circle-text-module" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IonIcon icon={lockClosed} className="ba-lock-icon" />
        </IonText>
      );
    }

    return (
      <IonText className="ba-circle-header h-ion-avatar ba-circle-text-module" style={{ justifyContent: 'center' }}>
        {completedExercises} de {totalExercises}
      </IonText>
    );
  };

  return (
    <IonGrid className="ba-single-module-container">
      <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'end' }}>
        <div>
          <Rating value={Number(rating)} />
          <IonText className="module-name-header">{name}</IonText>
          {/* <div className="flex" style={{ alignItems: 'center', marginTop: '5px' }}>
            <IonIcon style={{ color: 'white' }} icon={timeOutline} />
            <IonText className="f-time-header">3:11</IonText>
          </div> */}
        </div>

        <div className="progress-avatar-module" style={{ marginLeft: 2 }}>
          <IonProgressBar className="progress-bar" value={completedExercises / totalExercises}></IonProgressBar>
          {handleSubscription()}
        </div>
      </div>
    </IonGrid>
  );
};
