import { atom } from "recoil";

interface IUserImage {
  photo: string | null
}
export const ImageState = atom({
    key: 'Image',
    default:'',
  });

export const userState = atom({
    key: 'userAtom',
    default: { photo:'' } as IUserImage,
  });