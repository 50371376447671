import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonText, useIonViewDidLeave } from '@ionic/react';
import { ModalVideo } from 'components/entrenamiento/modalVideo';

import { IExercisesQuick } from 'interfaces/IEntraniento';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useRecoilValue } from 'recoil';
import { keyAccordionAtom } from 'recoil/TrainingAtom';

//css
import './ExerciseQuickTraining.css';

interface IExerciseQuickTraining {
  setHeightLine: (height: boolean) => void;
  heightLine: boolean;
  exercise: IExercisesQuick;
}
export const ExerciseQuickTraining: React.FC<IExerciseQuickTraining> = ({ exercise, setHeightLine, heightLine }) => {
  const key = exercise.key;
  const keyAccordion = useRecoilValue(keyAccordionAtom)
  const [isPlaying, setisPlaying] = useState(false)
  
  useEffect(()=>{ 
    setisPlaying(keyAccordion === key)
  },[keyAccordion])

    return( 
        <>
            <IonAccordion value={key} style={{ width:'100%', marginBottom:10 }}>
                <IonItem slot="header" color="white">
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'start' }}>
                        <IonText style={{ fontSize:12, color:'#A81E16', fontWeight:400, marginBottom:2 }}> {exercise.basisName} </IonText>
                        <IonText style={{ fontSize:20, fontWeight:700 }}>{exercise.name}</IonText>
                    </div>
                </IonItem>
                <div className="ion-padding" slot="content" style={{ display:'flex', width:'100%', flexDirection:'column', backgroundColor:'white'}}>
                <ReactPlayer 
                className='exerciseTrainingPlayer'
                config={{ file: { 
                          attributes: {
                            controlsList: 'nodownload'
                          }
                        }}}
                url={`https://${process.env.REACT_APP_TRAINING_BASE_URL}${key}`} 
                width="100%" height="154px" playsinline={true} controls={false} playing={isPlaying} loop={true} />
                <IonText 
                    id={String(exercise.idTraining)}
                    onClick={(e)=>{ 
                        e.stopPropagation()
                        setisPlaying(false)
                      }} 
                    color={'orange'} style={{ fontSize:14, fontWeight:400, marginTop:10, textDecoration:'underline' }}> Ver video completo </IonText>
                </div>
        <ModalVideo url={exercise.videoURL} trigger={String(exercise.idTraining)} />
        </IonAccordion>
    </>
  );
};
