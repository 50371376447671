import { useGetUserById } from 'hooks/users/useGetUserById'
import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { toastState } from 'recoil/ToastAtom'
import { putTrainingGoals } from 'services/progress'
import CloseSvg from 'assets/md-close.svg';
import CheckIcon from 'assets/check_icon.svg';

export const usePutTrainingGoals = () => {

    const { handleGetUserById } = useGetUserById()
    const setToast = useSetRecoilState(toastState);

    const handlePutTrainingGoals = useCallback(
      (id: number, goals:string) => {
        putTrainingGoals(id, goals).then( response =>{ 
            if(response.status === 200){
                handleGetUserById(id)
                setToast({
                    isOpen: true,
                    color: 'success',
                    message: 'Guardaste tus objetivos',
                    icon: CheckIcon,
                    button: [
                      {
                        icon: CloseSvg,
                        side: 'end',
                        role: 'cancel',
                        cssClass: 'toast-button',
                      },
                    ],
                  });
            }
        })
      },
      [],
    )
    
  return {
    handlePutTrainingGoals
  }
}
