import { Field } from 'formik';
import { ErrorMessageText } from './ErrorMessage';
import { NumericFormat } from 'react-number-format'

interface IFieldSet{
    name:string,
    label:string,
    type?:string,
    placeholder?:string,
    value:any,
    errors:any,
    touched:any,
    setFieldTouched:any,
    isTouched: boolean
    setFieldValue: any
}

function NumberField({ field }:any) {
  return <NumericFormat style={{ background:'none', width:'100%', height:45, borderRadius:20, borderWidth:'1.6px', borderColor: 'inherit', outline:'none' }} {...field} decimalScale={0} allowNegative={false} />;
}

export const FieldSetNumber = ({ touched, name, label, type, value, errors, setFieldTouched, isTouched, setFieldValue }:IFieldSet) => {

  const handleBorderColor = ()=>{
    if(touched && value && errors){
      return '#B62D25'
    }else if(touched && !value && errors){
      return '#B83A02'
    }else{
      return '#626875'
    }
  }

  const handleTextColor = ()=>{
    if(touched && value && errors){
      return '#B62D25'
    }else if(touched && !value && errors){
      return '#393A3F'
    }else{
      return '#393A3F'
    }
  }


  return (
    <article style={{ display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
      <label style={{ marginBottom:10 }} htmlFor={name}>{label}</label>
      <Field
          onClick={()=>{
            if(isTouched)
            setFieldTouched(name, true)

          }}
          style={{ borderColor: handleBorderColor(), outline:'none' }}
          name={name}
          component={NumberField}
          value={value}
        />
        { touched && errors && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors}  />}
    </article>
  )
};