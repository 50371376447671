import { Fieldset } from "components/FieldSet/FieldSet";
import { Form, Formik } from "formik";
import { loginValidation, recoverValidation } from "../LoginValidate";
import { IPasswordRecover, IResetPassword } from "services/interfaces/AuthLogin";
import { IonButton, IonText, useIonRouter } from "@ionic/react";

interface IEmailForm{ 
    submit: (email:string)=> void
}
export const EmailForm : React.FC <IEmailForm>= ({ submit })=>{ 

    const navigation = useIonRouter()

    return(
        <div>
          <Formik
            initialValues={{
              email: ''
            }}
            enableReinitialize={true}
            validationSchema={recoverValidation}
            onSubmit={(values) => {
              
              const formValues:IPasswordRecover = {
                email: values.email,
              }

              submit(formValues.email)
            }}
            >
            {({ setFieldTouched ,dirty, isValid, values,errors, touched }:any) => (
              <Form>
                  <article style={{ display:'flex', flexDirection:'column', alignItems:'center', marginTop:20 }}>
                      <Fieldset
                          name="email"
                          type="email"
                          label="Email *"
                          placeholder="Ingrese su correo..."
                          value={values.email}
                          errors={errors.email}
                          setFieldTouched={setFieldTouched}
                          touched={touched.email}
                          isTouched={false}
                      />
                  </article>
                  <article style={{ display:'flex', flexDirection:'column', alignItems:'center', marginBottom:20 }}>
                      <IonButton type='submit' disabled={!(dirty && isValid)} color={!(dirty && isValid) ? 'medium' : "orange"} style={{ width:'100%', marginTop:20 }}>Enviar clave</IonButton>
                  </article>
                      <IonText onClick={()=> navigation.push('/login/signin', 'forward', 'pop')} 
                                style={{ textAlign:'left', marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}>
                                    Recordé mi contraseña
                        </IonText>
              </Form>
            )}
            </Formik>
        </div>
      )};