import { IonIcon } from '@ionic/react'
import './stepperQuickTraining.css'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import lockCircleIcon from 'assets/lock-icon.svg'
import { Divider } from 'components/Divider'
import { useEffect, useRef, useState } from 'react'
import { ExerciseQuickTraining } from 'pages/entrenamiento/entrenamiento-rapido/components/ExerciseQuickTraining/ExerciseQuickTraining'
import { IExercisesQuick } from 'interfaces/IEntraniento'


interface IStep { 
    state:StepEnum,
    data: any,
    index: any,
    exercise: IExercisesQuick
}

export enum StepEnum {
    disabled = "disabled",
    completed = 1,
    incomplete = 0
}

export const StepQuickTraining: React.FC <IStep> = ({ exercise,state, data, index })=>{ 
    
    const [ heightLine, setHeightLine ] = useState<boolean>(false)

    const handleStepBlocked = ()=>{ 
        const firstIndexIncomplete = data.length !== 0 && data.findIndex( (element:any) => element.completed === 0);
        if(firstIndexIncomplete === index){
            return StepEnum.incomplete
        }else if(data[index].completed === 1){
            return StepEnum.completed
        }else{
            return StepEnum.incomplete
        }
    }

    return(
        <>
            <div className='step-icon-row' >
                <IonIcon style={{ width:40, height:40, color:'#ED4D07', borderColor:'#ED4D07' }} icon={ handleStepBlocked() === 0 ? ellipseOutline : handleStepBlocked() === 1 ? checkmarkCircle : lockCircleIcon} />
                { data.length - 1 !== index ? <div style={{ height: '95%' }} className='step-line'></div> : null }
            </div>
            <div style={{ width:'100vw'}}>
                <ExerciseQuickTraining exercise={exercise} heightLine={heightLine} setHeightLine={setHeightLine} />
                { data.length - 1 !== index ? <Divider width='100%' /> : <div style={{ width:'100%', marginBottom:'60px' }}></div> }
            </div>
        </>
    )
}