//formik
import { Field } from 'formik';

//components
import { ErrorMessageText } from 'components/FieldSet/ErrorMessage';

//css
import './mi_cuenta.css';

interface IFieldSet {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  isTouched: boolean;
  loginMethod?: string;
  setFieldValue: (field: string, value: any) => void;
  setIsFormChanged: (value: boolean) => void;
}

export const Textfield = ({ touched, name, label, type, placeholder, value, errors, setFieldTouched, isTouched, loginMethod, setFieldValue, setIsFormChanged }: IFieldSet) => {
  const handleBorderColor = () => {
    if (touched && value && errors) return '#B62D25';
    else if (touched && !value && errors) return '#B83A02';
    else return '#626875';
  };

  const handleTextColor = () => {
    if (touched && value && errors) return '#B62D25';
    else if (touched && !value && errors) return '#393A3F';
    else return '#393A3F';
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setIsFormChanged(true);
  };

  return (
    <article className="ma-article-textfield">
      <label className="ma-label" htmlFor={name}>
        {label}
      </label>
      <Field
        onClick={() => {
          if (isTouched) setFieldTouched(name, true);
        }}
        style={{ borderColor: handleBorderColor() }}
        className="ma-field"
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={type === 'email' && loginMethod !== 'credentials' ? true : false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, setFieldValue)}
      />
      {touched && errors && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors} />}
    </article>
  );
};
