import { useState, useEffect } from 'react';

//ionic
import { IonImg, IonText, IonIcon } from '@ionic/react';

//icons
import check_icon from 'assets/ejercicios-disponibles/check.svg';
import no_check from 'assets/ejercicios-disponibles/no-check.svg';
import white_accordion_arrow from 'assets/white_accordion_arrow.svg';
import green_check from 'assets/ejercicios-disponibles/green-check.svg';
import grey_no_check from 'assets/ejercicios-disponibles/grey-no-check.svg';

//css
import 'components/favorites/favorites-block.css';
import 'pages/entrenamiento/ejercicios-disponibles/ejercicios-disponibles.css';

//interfaces
import { IStylesMap } from 'interfaces/IFavorite';

//componentes
import { ExerciseBox } from './ExerciseBox';

//hooks
import { useGetBasisExercises } from 'hooks/entrenamiento-rapido/useGetBasisExercises';

//recoil
import { useRecoilValue } from 'recoil';
import { createdExerciseAtom } from 'recoil/createdExerciseAtom';

interface ISingleBlock {
  block: {
    blockName: string;
    id: number;
  };
}

interface IExercise {
  excluded: number;
}

export const SingleBlock: React.FC<ISingleBlock> = ({ block }) => {
  const [show, setShow] = useState(false);

  const createdExercise = useRecoilValue(createdExerciseAtom);

  const [availableExercises, setAvailableExercises] = useState([]);
  const [notAvailableExercises, setNotAvailableExercises] = useState([]);

  const handleShow = () => setShow((previousState) => !previousState);

  function transform_word(blockName: string) {
    const formattedWord = blockName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    if (formattedWord === 'tecnica individual' || formattedWord === 'tactica individual') {
      return formattedWord.replace(/\s+/g, '-');
    }

    return formattedWord;
  }

  const stylesMap: IStylesMap = {
    'Técnica Individual': { left: '28%' },
    'Táctica individual': { left: '28%' },
    Defensa: { left: '18%' },
    Tiro: { left: '13%' },
    Pase: { left: '13%' },
  };

  const { blockName, id } = block;
  const { exercises, handleGetBasisExercises } = useGetBasisExercises();

  useEffect(() => {
    handleGetBasisExercises(id);
  }, [handleGetBasisExercises, id]);

  useEffect(() => {
    if (createdExercise === 200) handleGetBasisExercises(id);
  }, [handleGetBasisExercises, id, createdExercise]);

  useEffect(() => {
    const available_exercises = exercises && exercises.length !== 0 && exercises.filter((exercise: IExercise) => exercise.excluded === 0);
    if (Array.isArray(available_exercises)) {
      setAvailableExercises(available_exercises);
    }

    const not_available_exercises = exercises && exercises.length !== 0 && exercises.filter((exercise: IExercise) => exercise.excluded === 1);
    if (Array.isArray(not_available_exercises)) {
      setNotAvailableExercises(not_available_exercises);
    }
  }, [exercises]);

  return (
    <>
      <div className={`f-first-image-container ${transform_word(blockName)}`} style={{ borderRadius: show ? '4px 4px 0 0' : '4px', display: 'flex' }}>
        <div className="sb-accordion-first-child" onClick={handleShow}>
          <IonText className="f-image-text" style={{ ...stylesMap[blockName] }}>
            {blockName}
          </IonText>
          <IonImg src={white_accordion_arrow} className="f-arrow" alt="arrow" style={{ transform: show ? 'rotate(180deg)' : 'none' }} />
        </div>
      </div>

      <div className={`f-accordion-second-child${show ? ' show' : ''}`}>
        <div className="ed-green-box">
          <IonIcon icon={check_icon} style={{ width: '32px', height: '32px', marginRight: '8px' }} />
          <IonText className="ed-green-box-text">DISPONIBLES - se incluyen en entrenamientos</IonText>
        </div>

        {availableExercises && availableExercises.length !== 0 ? (
          availableExercises.map((exercise, index) => {
            const { idExercise } = exercise;
            return <ExerciseBox icon={green_check} className="ed-exercise-name" key={idExercise} exercise={exercise} />;
          })
        ) : (
          <div className="ed-excercise-box">
            <IonText className="ed-no-available-exercises">Todavia no tienes ejercicios disponibles</IonText>
          </div>
        )}

        <div className="ed-red-box">
          <IonIcon icon={no_check} style={{ width: '32px', height: '32px', marginRight: '8px' }} />
          <IonText className="ed-green-box-text">NO DISPONIBLES - no se incluyen en entrenamientos</IonText>
        </div>

        {notAvailableExercises && notAvailableExercises.length !== 0 ? (
          notAvailableExercises.map((exercise, index) => {
            const { idExercise } = exercise;
            return <ExerciseBox icon={grey_no_check} className="ed-exercise-name not-available" key={idExercise} exercise={exercise} />;
          })
        ) : (
          <div className="ed-excercise-box">
            <IonText className="ed-no-available-exercises">Todavia no tienes ejercicios no disponibles</IonText>
          </div>
        )}
      </div>
    </>
  );
};
