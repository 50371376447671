import { useEffect, useState } from 'react';

//ionic
import { IonPage, IonContent, IonHeader, IonToolbar, IonText, IonButtons, IonBackButton, IonGrid, IonAvatar, IonRow, IonImg, IonButton, IonToast, useIonViewDidLeave, IonIcon, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline, trashOutline } from 'ionicons/icons';

//css
import './mi_cuenta.css';

//components
import { Textfield } from './Textfield';
import { TextfieldPassword } from './TextfieldPassword';
import { AddAvatar } from 'components/Icons/avatars/AddAvatar';
import { AvatarSelector } from 'pages/login/components/AvatarSelector';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';

//recoil
import { userInfoState } from 'recoil/User';
import { toastState } from 'recoil/ToastAtom';
import { useRecoilState, useRecoilValue } from 'recoil';

//formik
import { Formik, Form } from 'formik';

//validation
import { EditUserValidation } from 'components/forms/EditUserValidation';
import { useEditUser } from 'hooks/users/useEditUser';
import { userState } from 'recoil/UserImage';
import BorrarCuentaModal from 'components/modal/borrar-cuenta/BorrarCuentaModal';
import { useOpenModal } from 'hooks/modal/useOpenModal';

export const MiCuenta: React.FC = () => {
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { IsOpen, handleCloseModal, handleOpenModal } = useOpenModal()
  const user = useRecoilValue(userInfoState);

  const key = user && user.key;
  const firstName = user && user.firstName;
  const lastName = user && user.lastName;
  const email = user && user.email;
  const idUser = user && user.idUser;
  const type = user && user.type;

  const [handlePostMedia, url] = usePostMedia();
  const [handleEditUser] = useEditUser();

  const initialValues = { firstName, lastName, email, password: '' };

  const [toast, setToast] = useRecoilState(toastState);
  const [userImage, setUserImage] = useRecoilState(userState)

  useEffect(() => {
    if (idUser && key) handlePostMedia({ key });
  }, [idUser, key, handlePostMedia]);

  const getChangedValues = (oldObj: any, newObj: any) => {
    const changedValues: any = {};
    for (const key in newObj) {
      if (key in newObj && newObj[key] !== oldObj[key]) {
        changedValues[key] = newObj[key];
      }else{
        changedValues[key]= oldObj[key]
      }
    }
    return changedValues;
  };

  useIonViewDidLeave(()=>{
    setUserImage({ photo: '' })
  })

  useEffect(()=>{
    if(userImage.photo !== ""){
      setIsFormChanged(true);
    }else{
      setIsFormChanged(false);
    }
  },[userImage.photo])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="ma-header-container">
            <IonButtons slot="start">
              <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs" />
            </IonButtons>
            <IonText className="ma-header-title">Mi Cuenta</IonText>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid className="ma-ion-grid">
          <IonRow>
            <IonAvatar style={{ width: 80, height: 80, marginBottom: '8px' }} id="form-edit-image">
              {userImage.photo !== "" ? <IonImg src={String(userImage.photo)} />  : url ? <IonImg src={url} /> : user.pictureURL ? <IonImg src={user.pictureURL} /> : <AddAvatar />}
            </IonAvatar>
          </IonRow>

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={EditUserValidation}
            onSubmit={(values) => {
              const body = { firstName: values.firstName, lastName: values.lastName, email: values.email, password: values.password };
              const changedValues = getChangedValues(initialValues, body);
              if (idUser) handleEditUser(idUser, changedValues, userImage);
              setIsFormChanged(false);
            }}
          >
            {({ setFieldTouched, values, errors, touched, setFieldValue }: any) => {
              return (
                <Form>
                  <AvatarSelector trigger='form-edit-image' isEdit={false} />
                  <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Textfield
                      name="firstName"
                      type="name"
                      label="Nombre"
                      placeholder=""
                      value={values.firstName}
                      errors={errors.firstName}
                      setFieldTouched={setFieldTouched}
                      touched={touched.firstName}
                      isTouched={true}
                      setFieldValue={setFieldValue}
                      setIsFormChanged={setIsFormChanged}
                    />
                    <Textfield
                      name="lastName"
                      type="lastName"
                      label="Apellido"
                      placeholder=""
                      value={values.lastName}
                      errors={errors.lastName}
                      setFieldTouched={setFieldTouched}
                      touched={touched.lastName}
                      isTouched={true}
                      setFieldValue={setFieldValue}
                      setIsFormChanged={setIsFormChanged}
                    />
                    <Textfield
                      name="email"
                      type="email"
                      label="Email"
                      placeholder=""
                      value={values.email}
                      errors={errors.email}
                      setFieldTouched={setFieldTouched}
                      touched={touched.email}
                      isTouched={true}
                      loginMethod={type}
                      setFieldValue={setFieldValue}
                      setIsFormChanged={setIsFormChanged}
                    />
                    {type === 'credentials' && (
                      <TextfieldPassword
                        name="password"
                        type="password"
                        label="Contraseña"
                        placeholder="*********"
                        value={values.password}
                        errors={errors.password}
                        setFieldTouched={setFieldTouched}
                        touched={touched.password}
                        isTouched={true}
                        setFieldValue={setFieldValue}
                        setIsFormChanged={setIsFormChanged}
                      />
                    )}
                  </article>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection:'column' }}>
                    <IonButton type="submit" fill="clear" disabled={!isFormChanged} className={isFormChanged ? 'ma-button' : 'ma-button-disabled'}>
                      Guardar cambios
                    </IonButton>
                    <div style={{ display:'flex', alignItems:'center', marginTop:20, width:'fit-content' }} onClick={()=>handleOpenModal('borrarCuenta')}>
                      <IonIcon color='danger' icon={trashOutline} style={{ width:24, height:24, marginRight:5 }}></IonIcon>
                      <IonText color={'danger'} style={{ fontSize:16, fontWeight:700 }}> Borrar cuenta </IonText>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </IonGrid>
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          icon={toast.icon}
          cssClass={'css-success-toast'}
          buttons={toast.button}
          onDidDismiss={() => setToast({ isOpen: false })}
          duration={2000}
        />
        <BorrarCuentaModal handleClose={handleCloseModal} isOpen={IsOpen.borrarCuenta} />
      </IonContent>
    </IonPage>
  );
};
