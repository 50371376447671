import { useEffect, useState } from 'react';

//ionic
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonText, IonAvatar, IonImg, IonMenuButton } from '@ionic/react';

//components
import HeaderPopOver from 'components/Header/HeaderPopOver';
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';
import { AnualBasico } from 'components/Mi Suscripcion/AnualBasico/AnualBasico';
import { AnualPremium } from 'components/Mi Suscripcion/AnualPremium/AnualPremium';
import { CuentaGratis } from 'components/Mi Suscripcion/CuentaGratis/CuentaGratis';
import { Trimestral } from 'components/Mi Suscripcion/Trimestral/Trimestral';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';
import { useGetUserById } from 'hooks/users/useGetUserById';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//recoil
import { useRecoilValue } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';

//components

export const MiSuscripcion: React.FC = () => {
  const token = useRecoilValue(tokenState);

  const userId = token && token.userId;
  const { handleGetUserById, user } = useGetUserById();
  const { idSuscription } = user;

  const [handlePostMedia, url] = usePostMedia();
  const key = user && user.key;

  useEffect(() => {
    handleGetUserById(Number(userId));
  }, [handleGetUserById, userId]);

  useEffect(() => {
    if (user.idUser && key) {
      handlePostMedia({ key });
    }
  }, [user, key, handlePostMedia]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="p-header-container" style={{ justifyContent: 'space-between' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <IonButtons slot="start">
                <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs/home" />
              </IonButtons>
              <IonText className="p-header-title">Mi Suscripción</IonText>
            </div>

            <IonAvatar slot="end" className="h-ion-avatar" id="popover-misuscripcion">
              {user.pictureURL ? <IonImg src={user.pictureURL} /> : url ? <IonImg src={url} /> : <AddAvatar width="36px" height="36px" />}
            </IonAvatar>
          </div>
        </IonToolbar>
        <HeaderPopOver triger="popover-misuscripcion" />
      </IonHeader>
      <IonContent fullscreen>
        {!idSuscription && <CuentaGratis />}
        {idSuscription === 1 && <AnualBasico />}
        {idSuscription === 2 && <AnualPremium />}
        {idSuscription === 3 && <Trimestral />}
      </IonContent>
    </IonPage>
  );
};
