import React from 'react';

//ionic
import { IonGrid, IonText } from '@ionic/react';

//css
import './WelcomeTitle.css';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

interface IWelcomeTitle {
  className: string;
  subtitle: string;
  classNameSub:string;
}

const WelcomeTitle: React.FC<IWelcomeTitle> = ({ className, subtitle , classNameSub}) => {
  const token = useRecoilValue(userInfoState);
  const firstName = token && token.firstName;
  return (
    <IonGrid className={className}>
      <IonText className="ion-text-title">Hola {firstName},</IonText>
      <IonText className={classNameSub}>{subtitle}</IonText>
    </IonGrid>
  );
};

export default WelcomeTitle;
