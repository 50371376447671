
export const maxValueGraph = (arrValue:any) =>{

    let max = 0
    arrValue && arrValue.forEach( (element:any) => {
        if(Number(element.minutes) > max){
            max = Number(element.minutes) + 2

        }
    })
    if(max === 0) max = 0;

    return Number(max)
}