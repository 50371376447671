import React, { useEffect } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonButton } from '@ionic/react';

//icons
import close_modal from '../../../../assets/close_modal.svg';

import './cambiar-plan.css';
import { useGetNextInvoiceSubscription } from 'hooks/subscription/useGetNextInvoiceSubscription';
import { formatCurrency } from 'helper/handleAmountParse';

interface ICambiarPlanModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  nextInvoice: any;
  handleOpenPayModal: (type:string)=> void;
}

function CambiarPlanModal({ showModal, setShowModal, nextInvoice, handleOpenPayModal }: ICambiarPlanModal) {
  
  const priceDecimal = formatCurrency(Number(nextInvoice.total/100)) 

  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="cp-icon-row">
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <IonText className="cp-subscribe-plan">Planes de Suscripción</IonText>
          <IonText className="cp-subscribe-text">Eleva tu plan para tener más beneficios en la app Campus Huevo Sanchez</IonText>
        </div>

        <div className="cp-card-container" style={{ marginTop: '40px' }}>
          <IonText className="cp-price">USD ${priceDecimal}</IonText>
          <IonText className="cp-plan">Anual</IonText>
          <IonText className="cp-plan-text">Acceso ilimitado al Programa de Aprendizaje, Entrenamientos, y Sesiones en Vivo.</IonText>
        </div>

        <IonButton className="cp-button" fill="clear" 
          onClick={()=>{
           setShowModal(false)
           handleOpenPayModal('changePlan')
           }}>
          Cambiar Plan
        </IonButton>

        <IonText className="cp-terms-text">La suscripción se paga una vez por año. Tiene renovación automática.</IonText>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
          <IonText className="cp-terms">Términos y condiciones</IonText>
          <span className="cp-terms" style={{ textDecoration: 'none', margin: '0 4px' }}>
            -
          </span>
          <IonText className="cp-terms">Política de Privacidad</IonText>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default CambiarPlanModal;
