import { atom } from 'recoil';

interface IExerciseData {
  idExercise: number | null;
  idBasis: number | null;
  idModule: number | null;
}

export const ExerciseDataAtom = atom({
  key: 'avatar',
  default: {
    idExercise: null,
    idBasis: null,
    idModule: null,
  } as IExerciseData,
});
