import { useCallback, useState } from 'react';

//interfaces
import { IExercise } from 'interfaces/IAprendizaje';

//services
import { getModuleExcercises } from 'services/aprendizaje';

//recoil
import { useSetRecoilState } from 'recoil';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';

export const useGetModuleExercises = () => {
  const [moduleExercises, setModuleExercises] = useState<IExercise[]>([]);

  const setFavoriteStatus = useSetRecoilState(favoriteStatusAtom);

  const handleGetModuleExercises = useCallback(
    async (idBasis: number, idModule: number) => {
      try {
        const response = await getModuleExcercises(idBasis, idModule);
        if (response?.status === 200) {
          setModuleExercises(response.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setFavoriteStatus(0);
      }
    },
    [setFavoriteStatus]
  );

  return { moduleExercises, handleGetModuleExercises };
};
