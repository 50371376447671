import { useCallback, useState } from 'react'
import { getSessionsById } from 'services/sessions'

export interface ISessionById { 
  idSession:number | null,
  title:string,
  category:string,
  label:string,
  contactInfo:string,
  description:string,
  eventDate:string | null,
  duration: number,
  fullName: string,
  imageURL?: string;
  role: string,
  keyCoach: string,
  idCoach: number,
  reservedSessions: number,
}

const initialState = {
  idSession: null,
  title:'',
  category:'',
  label:'',
  contactInfo:'',
  description:'',
  eventDate: null,
  duration:0,
  fullName: '',
  role: '',
  keyCoach: '',
  idCoach:0,
  reservedSessions: 0
}

export const useGetSessionsById = () => {

    const [sessionById, setSessionById] = useState<ISessionById>(initialState)
    
    const handleGetSessionById = useCallback(
      async(id:string) => {
        
        const result = await getSessionsById(id)
        if(result){ 
            setSessionById(result)
        }
      },
      [],
    )
    
  return { sessionById ,handleGetSessionById}
}
