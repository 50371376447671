import { IonText } from "@ionic/react"
import { ISessionById } from "hooks/sessions/useGetSessionsById"
import { Iuser } from "services/interfaces/Users"
import './sessionInfoPayment.css';

export interface ISessionPayment { 
    sessionById: ISessionById,
    userInfo: Iuser
}

export const SessionInfoPayment: React.FC <ISessionPayment>= ({ sessionById, userInfo })=>{ 

    const date = sessionById.eventDate ? new Date(sessionById.eventDate) : null
    const parseDate = date ? date.toLocaleString('es-ES', { month:'long', day:'2-digit', weekday:'long'}).replace(",", " ") : null
    const getStartHour = date?.getHours();
    const endDuration = sessionById?.duration;

    return( 
        <div className="session-payinfo-content">
            <IonText className="session-text-info">{ sessionById.title }</IonText>
            <IonText className="session-text-info" style={{ textTransform:'capitalize' }}>{ parseDate }</IonText>
            <IonText className="session-text-info">{getStartHour }hs - {  getStartHour ? getStartHour + endDuration : null}hs</IonText>
        </div>
    )
}