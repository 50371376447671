import { useCallback, useState } from "react"
import { useRecoilState } from "recoil"
import { userInfoState } from "recoil/User"
import { getUserInfo } from "services/user"

export const useGetUserById = ()=>{

    const [user, setUser] = useRecoilState(userInfoState)
    const handleGetUserById = useCallback(
      async( id:number ) => {
        if(id){
            const res = await getUserInfo(id)
          
            if(res){
                setUser(res)
            }
        }
      },
      [],
    )
    
    return { handleGetUserById, user }
}