import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, useIonRouter, useIonViewDidLeave } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './modal-finalizacion-entrenamiento.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { basisState, indexTrainingAtom, listExerciseAtom, trainingBeganAtom, trainingState } from 'recoil/TrainingAtom';
import { exerciseMinutesDoneAtom, exercisePointsAtom } from 'recoil/exercisesAtom';
import { usePostUserExercisePoint } from 'hooks/entrenamiento-rapido/usePostUserExercisePoint';

interface IModal {
  IsOpen: boolean;
  handleClose: () => void;
}

function ModalFinalizacionEntrenamiento({ IsOpen, handleClose }: IModal) {
  const navigation = useIonRouter();
  const modal = useRef<HTMLIonModalElement>(null);
  const setTraining = useSetRecoilState(trainingState);
  const [basis, setBasis] = useRecoilState(basisState);
  const setListExercise = useSetRecoilState(listExerciseAtom);
  const setExerciseDone = useSetRecoilState(exerciseMinutesDoneAtom);
  const [exercisePoints, setExercisePoints] = useRecoilState(exercisePointsAtom);
  const { handlePostUserExercisePoint } = usePostUserExercisePoint();
  const setIndexTraining = useSetRecoilState(indexTrainingAtom);
  const setTrainingBegan = useSetRecoilState(trainingBeganAtom);

  function dismiss() {
    handlePostUserExercisePoint();
    setTraining({
      duracion: 0,
      tieneAro: '',
      tieneCompanero: '',
    });
    setBasis([]);
    setListExercise([]);
    setExercisePoints(0);
    setExerciseDone([]);
    setIndexTraining(0);
    setTrainingBegan(false);
    handleClose();
    modal.current?.dismiss();
  }

  const handleRedirect = () => {
    navigation.push('/crear-entrenamiento');
    dismiss();
  };

  return (
    <IonModal isOpen={IsOpen} ref={modal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row">
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="fent-container">
          <IonText className="fent-well-done">{exercisePoints < 5 ? '¡A entrenar más!' : '¡Buen trabajo!'}</IonText>
          <IonText className="fent-subtitle">Completaste {String(exercisePoints)} minutos de entrenamiento y sumaste</IonText>
          <IonText className="fent-points">{String(exercisePoints)}</IonText>
          <IonText className="fent-points-text">Puntos de Campus Huevo Sanchez</IonText>
          <IonText className="fent-subtitle" style={{ marginTop: '25px' }}>
            Trabajaste los módulos de: <br />
            <b>{basis && basis.map((fundamental) => fundamental.moduleName).join(', ')}</b>
          </IonText>

          <IonButton type="submit" fill="clear" style={{ marginTop: '32px' }} className="fent-button" onClick={handleRedirect}>
            Crear nuevo entrenamiento
          </IonButton>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}

export default ModalFinalizacionEntrenamiento;
