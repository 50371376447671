import { IonButton, IonCheckbox, IonText } from '@ionic/react';
import { useRecoilState } from 'recoil';
import { modalWarningAtom } from 'recoil/modalAtom';

interface ICancelSubContent {
  setShowModal: (e: boolean) => void;
  showModal: boolean;
}

export const TrainingWarningContent: React.FC<ICancelSubContent> = ({ setShowModal }) => {
  const [modalShowAtom, setModalShowAtom] = useRecoilState(modalWarningAtom);

  return (
    <>
      <IonText className="cs-cancel-suscription">Aprovecha tu entrenamiento al máximo</IonText>
      <IonText className="cs-cancel-suscription-subtext">Puedes ver los ejercicios antes de comenzar para no pausar tu entrenamiento</IonText>

      <div className="cs-important-container">
        <IonText className="cs-important-text">Ten en cuenta:</IonText>
        <IonText className="cs-important-subtext">Los Entrenamientos Rápidos son personalizados y diferentes para que seas un jugador completo.</IonText>
      </div>

      <IonButton className="wt-confirm-button" fill="solid" color={'orange'} onClick={() => setShowModal(false)}>
        Entendido
      </IonButton>
      <div style={{ display: 'flex', justifyContent: 'start', width: '100%', marginTop: 10, marginLeft: 10 }}>
        <IonCheckbox
          onIonChange={(e) => {
            setModalShowAtom(!modalShowAtom);
          }}
          labelPlacement="end"
          style={{ fontSize: 14, fontWeight: 400 }}
        >
          No volver a mostrar aviso
        </IonCheckbox>
      </div>
    </>
  );
};
