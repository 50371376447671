import { IonButton, IonIcon, IonText, useIonRouter } from '@ionic/react';
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { ISessionObject } from 'interfaces/ISession';
import { checkmarkCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';

export const ReserveSection: React.FC<ISessionObject> = ({ sessionById, setIsOpen, userInfo, reservedSession }) => {
  const [loaded, setloaded] = useState(false);
  const date = sessionById.eventDate ? new Date(sessionById.eventDate) : null;
  const today = new Date().getTime();
  const navigation = useIonRouter();
  const { handleOpenModal } = useOpenModal();
  const sessionReserved = reservedSession.find((element: any) => Number(element.idSession) === Number(sessionById.idSession));
  const reservedSessions = sessionById && sessionById.reservedSessions;

  useEffect(() => {
    setTimeout(() => {
      setloaded(true);
    }, 400);
  }, []);

  const handleClick = () => {
    if (userInfo?.idSuscription) {
      navigation.push(`/sesion-reserva/${sessionById.idSession}/checkout`, 'forward', 'replace');
    } else {
      setIsOpen(true);
    }
  };

  const handleHourMinutes = () => {
    const dateUTC3 = date ? date.toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires' }) : null;

    if (dateUTC3) {
      const [, horaParte2] = dateUTC3.split(' ');
      const [hora, minutos] = horaParte2.split(':');

      return { hora, minutos };
    } else {
      return { hora: 0, minutos: 0 };
    }
  };

  const handleSumMinutes = () => {
    const date = sessionById.eventDate ? new Date(sessionById.eventDate) : null;
    if (date) {
      const horas = Math.floor(sessionById.duration);
      const minToSum = Math.round((sessionById.duration - horas) * 100);
      date.setHours(date.getHours() + horas);
      date.setMinutes(date.getMinutes() + minToSum);

      const dateUTC3 = date.toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires' });

      const [, horaParte2] = dateUTC3.split(' ');
      const [hora, minutos] = horaParte2.split(':');

      return { hora, minutos };
    } else {
      return { hora: 0, minutos: 0 };
    }
  };
  const parseDate = date ? date.toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires', month: 'long', day: '2-digit', weekday: 'long' }).replace(',', ' ') : '';

  return (
    <div className="ion-content-container">
      <div className="ion-content-reserve">
        <div className="ion-date-content">
          <IonText color={'dark'} style={{ fontSize: 14, fontWeight: 400 }}>
            {' '}
            {handleHourMinutes().hora}:{handleHourMinutes().minutos} - {handleSumMinutes().hora}:{handleSumMinutes().minutos}{' '}
          </IonText>
          <IonText color={'dark'} style={{ fontSize: 16, fontWeight: 700, textTransform: 'capitalize' }}>
            {' '}
            {parseDate}{' '}
          </IonText>
        </div>
        <div className="ion-button-content">
          {sessionReserved && loaded ? (
            <div className="box-reserved-session-profile">
              <IonIcon icon={checkmarkCircle} style={{ width: 30, height: 30, fill: '#168404', marginLeft: 5, marginRight: 5 }} />
              <IonText style={{ fontSize: 14, fontWeight: 700, color: '#168404', lineHeight: '20px' }}> Sesión Reservada </IonText>
            </div>
          ) : loaded && Number(sessionById?.eventDate) < Number(today) ? (
            <IonButton fill="outline" color={'orange'} className="ion-button-reserve" onClick={() => handleOpenModal('openCertificate')}>
              Ver certificado
            </IonButton>
          ) : (
            loaded && (
              <IonButton
                color={reservedSessions < 100 ? 'orange' : 'medium'}
                className="ion-button-reserve"
                onClick={() => {
                  if (reservedSessions < 100) handleClick();
                }}
              >
                Reservar Lugar
              </IonButton>
            )
          )}
        </div>
      </div>

      {reservedSessions >= 100 && <IonText className="max-participants">Se ha alcanzado el límite de 100 participantes.</IonText>}
    </div>
  );
};
