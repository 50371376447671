import { useState } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonToast, IonButton } from '@ionic/react';

//icons
import close_modal from '../../assets/close_modal.svg';

//formik
import { Formik, Form, Field } from 'formik';

//components
import Rating from './Rating';

//css
import './rating-modal.css';

//recoil
import { toastState } from 'recoil/ToastAtom';
import { useRecoilState } from 'recoil';

//hooks
import { usePostRating } from 'hooks/aprendizaje/usePostRating';

interface IRatingModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  module: {
    idModule: number;
    name: string;
    idBasis: number;
    createdAt: number;
    deletedAt: number | null;
    totalExercises: number;
    completedExercises: number;
    rating: string | number | null;
    note: string | null;
  };
}

const field_styles = { minHeight: '160px', outline: 'none', borderRadius: '20px', border: '1px solid #626875', padding: '10px 12px', width: '100%', marginTop: '4px' };
const button_styles = { width: 300, fontWeight: 700, marginTop: '42px', fontFamily: 'Lato', fontStyle: 'normal', fontSize: '14px', lineHeight: '20px', textAlign: 'center', letterSpacing: '0.004em' };

function RatingModal({ showModal, setShowModal, module }: IRatingModal) {
  const counterFormatter = (inputLength: number) => `${inputLength}/500`;

  const [inputLength, setInputLength] = useState<number>(0);
  const [addComment, setAddComment] = useState(false);

  const { name, rating, note, idBasis, idModule } = module;

  const [toast, setToast] = useRecoilState(toastState);

  const { handlePostRating } = usePostRating();

  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="f-icon-row" style={{ justifyContent: 'flex-end' }}>
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>

        <div className="r-container">
          <IonText className="r-title">Puntuar módulo</IonText>
          <IonText className="r-question">
            ¿Qué tan útil te resultó el módulo de “<b>{name}</b>”?
          </IonText>
          <Rating value={Number(rating)} height="35px" width="37.5px" margin="19.5px 0 0 0" score={true} idBasis={idBasis} idModule={idModule} />
          <IonText onClick={() => setAddComment(true)} className="r-add-comment" style={{ color: addComment && '#4B4F58' }}>
            + Agregar un comentario
          </IonText>
        </div>

        {addComment && (
          <>
            <IonText className="f-notes-title">Comentario</IonText>
            <Formik
              initialValues={{
                note,
              }}
              enableReinitialize={true}
              onSubmit={(values) => {
                setShowModal(false);
                if (values.note || values.note === '') handlePostRating(idBasis, idModule, { note: values.note });
              }}
            >
              {({ dirty, isValid, values, setFieldValue }: any) => {
                return (
                  <Form>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Field
                        value={values.note}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                          setFieldValue('note', event.target.value);
                          setInputLength(event.target.value.length);
                        }}
                        style={field_styles}
                        id={'note'}
                        name={'note'}
                        type={'text'}
                        as="textarea"
                        maxLength={500}
                      />
                      <IonText className="f-counter">{counterFormatter(inputLength)}</IonText>
                    </div>
                    <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <IonButton type="submit" color="orange" fill="solid" style={button_styles}>
                        Enviar comentario
                      </IonButton>
                    </article>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </IonGrid>

      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        icon={toast.icon}
        cssClass={'css-success-toast'}
        buttons={toast.button}
        onDidDismiss={() => setToast({ isOpen: false })}
        duration={2000}
      />
    </IonModal>
  );
}

export default RatingModal;
