import { useIonRouter } from '@ionic/react'
import { useSetRecoilState } from 'recoil'
import { toastState } from 'recoil/ToastAtom'
import { tokenState } from 'recoil/tokenAtom'
import { IAuthWithCredentials } from 'services/interfaces/AuthLogin'
import { authWithCredentials } from 'services/login'
import { alertCircle } from 'ionicons/icons';
import CloseSvg from 'assets/md-close.svg'
import { loadingAtom } from 'recoil/loadingAtom'

export const useAuthWithCredentials = () => {
    const setToken = useSetRecoilState(tokenState)
    const navigation = useIonRouter()
    const setToast = useSetRecoilState(toastState)
    const setLoading = useSetRecoilState(loadingAtom);

    const handleAuthWithCredentials = async(body:IAuthWithCredentials, isRecover=false)=>{
      const response = await authWithCredentials(body)
      if(response){
        setToken({
            jwtToken: response.jwtToken,
            userId: response.profile.idUser
        })
        localStorage.setItem("token", response.jwtToken)
        localStorage.setItem("user", body.email)
        localStorage.setItem("pw", body.password)
        localStorage.setItem("device", body.deviceUUID)
        localStorage.setItem("client", body.client)
        if(!isRecover){
          navigation.push('/tabs/home', 'forward', 'replace')
        }else{
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            navigation.push('/tabs/home', 'forward', 'replace');
          }, 3500);
        }
      }else{
        setToast({
          isOpen:true,
          color:"danger",
          message:"Datos incorrectos, revisa el email y la contraseña",
          icon:alertCircle,
          button:[{
            icon:CloseSvg,
            side:'end',
            role:'cancel',
            cssClass:'toast-button'
          }]
        })
      }
    }

    return [handleAuthWithCredentials]
}
