import { IonButton, IonIcon, IonText } from "@ionic/react"
import { alertCircle } from "ionicons/icons";
import './stripePaymentError.css';
import { useSetRecoilState } from "recoil";
import { IStripePaymentErrorAPI, stripeErrorAPI } from "recoil/StripeErrorAtom";
import { handleErrorStripe } from "helper/handleErrorStripe";

interface IStripePaymentError { 
    stripeError:IStripePaymentErrorAPI
}

export const StripePaymentError: React.FC <IStripePaymentError>= ({ stripeError }) =>{ 

    const setStripeError = useSetRecoilState(stripeErrorAPI)

    const { contentText, title } = handleErrorStripe(stripeError)

    return( 
            <div className="error-payment-container">
                <div>
                    <div className="error-payment-header">
                        <IonText className="error-payment-header-text"> {title} </IonText>
                    </div>
                    <div className="error-payment-icon">
                        <IonIcon icon={alertCircle} style={{ fill:'#B62D25', width:100, height:100 }}/>
                    </div>
                    <div className="error-payment-description">
                        <IonText className="error-payment-description-text"> {contentText} </IonText>
                    </div>
                </div>
                <div className="error-payment-action">
                    <IonButton onClick={()=>setStripeError({ code:null, declineCode:null })} color={'orange'} expand="block" style={{ textTransform:'none', fontWeight:500 }}> Reintentar Pago </IonButton>
                </div>
            </div>
    )
}