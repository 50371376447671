import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonText, IonToast, IonToolbar, IonMenuButton } from '@ionic/react'
import { ContactForm } from 'components/forms/contacto/ContactForm'
import { arrowBackOutline } from 'ionicons/icons'
import { useRecoilState } from 'recoil'
import { toastState } from 'recoil/ToastAtom'

export const Contacto: React.FC = () => {

    const [toast, setToast] = useRecoilState(toastState)

    return(
        <IonPage>
        <IonHeader>
          <IonToolbar>
            <article style={{ display:'flex', width: 300 }}>
              <IonButtons slot="start">
                <IonBackButton  className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/tabs/help' />
              </IonButtons>
              <p style={{ whiteSpace:'nowrap', fontSize: 22, marginLeft:10, fontWeight:700 }}>Contacto</p>
            </article>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
        <IonText color={'dark'}>
          <p style={{ fontSize:18, fontWeight:400, textAlign:'center', fontFamily:'Poppins'}}>¿Cómo te podemos ayudar?</p>
        </IonText>
            <ContactForm />
          <IonToast
            isOpen={toast.isOpen}
            message={toast.message}
            icon={toast.icon}
            cssClass={'css-toast'}
            buttons={toast.button}
            onDidDismiss={() => setToast({ isOpen:false })}
            duration={2000}
          ></IonToast>
        </IonContent>
      </IonPage>
    )
}