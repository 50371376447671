import { useCallback } from 'react';

//services
import { postMediaUser, putMediaToS3, putMediaUser } from 'services/media';

//interface
import { IUserImage } from 'services/interfaces/MediaBody';

export const usePostImageUser = () => {
  const handlePostMedia = useCallback(async (body: IUserImage, file:any) => {
    let fileToPost = { 
        id: body.id,
        fileName: body.fileName
    }
    const resp = await postMediaUser(fileToPost);

    if( resp ){ 
        const res = await putMediaToS3(resp, file)

        if(res){ 
            await putMediaUser(fileToPost)
        }
    }
  }, []);

  return [handlePostMedia];
};