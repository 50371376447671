import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonText, IonToast, IonToolbar, IonMenuButton } from "@ionic/react"
import { IParams } from "interfaces/IParams";
import { arrowBackOutline } from "ionicons/icons";
import { useParams } from "react-router";
import marianoImg from 'assets/sesion_en_vivo.png';
import huevoImg from 'assets/Presentacion__tactica.png';
import jorgeImg from 'assets/Jorge_sesiones.jpg';
import './sessionProfile.css';
import { ReserveSection } from "./ReserveSection";
import { SessionDescription } from "./SessionDescription";
import { useEffect, useState } from "react";
import { useGetSessionsById } from "hooks/sessions/useGetSessionsById";
import { ModalNoSubscription } from "../modal-subscription/ModalNoSubscription";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoState } from "recoil/User";
import { useGetUserSessions } from "hooks/sessions/useGetUserSessions";
import SubscribeModal from "components/Mi Suscripcion/Modales/SubscribeModal/SubscribeModal";
import { StripeModalPaymentSubscription } from "components/forms/subscription/StripeModalPayment";
import { CertificadoModal } from "components/modal/certificado-sesion/CertificadoModal";
import { useOpenModal } from "hooks/modal/useOpenModal";
import { useGetCertificate } from "hooks/sessions/useGetCertificate";
import { toastState } from "recoil/ToastAtom";

export const SessionProfile: React.FC = () =>{ 

    const {id} = useParams<IParams>()
    const { handleGetSessionById, sessionById } = useGetSessionsById()
    const { handleGetUserSessions, sessions } = useGetUserSessions();
    const userInfo = useRecoilValue(userInfoState)
    const [isOpen, setIsOpen] = useState(false)
    const [ showModalSub, setModalSub ] = useState(false)
    const [ showModalPayment, setModalPayment ] = useState(false)
    const { IsOpen: isOpenModal, handleCloseModal } = useOpenModal()
    const date = sessionById.eventDate ? new Date(sessionById.eventDate).toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires', month:'long', year:'numeric', day:'numeric' }) : null
    const { base64, handleGetCertificate } = useGetCertificate()
    const [toast, setToast] = useRecoilState(toastState);

    useEffect(() => {
      if(id){
        handleGetSessionById(id)
        handleGetUserSessions(Number(userInfo.idUser), { future: 1 })
      }
    }, [id])

    useEffect(() => {
      if(sessionById){
        handleGetCertificate({ coachName:sessionById.fullName, dateSession:String(date), sessionName:sessionById.title})
      }
    }, [sessionById])
    

    const handleImgCoach = ()=>{ 

      switch (sessionById.idCoach) {
        case 1:
          return huevoImg
        case 2:
          return marianoImg
        case 3:
          return jorgeImg      
        default:
          return ''
      }
    }
    
    return(
        <IonPage>
        <IonHeader collapse='fade'>
          <IonToolbar>
            <article style={{ display:'flex', width: 300 }}>
                <IonButtons slot="start">
                    <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/sesiones' />
                </IonButtons>
                <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Sesiones en vivo</p>
            </article>
          </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <div>
                <div className="ion-title-session">
                    <IonText color={'dark'} className="ion-title-header"> {sessionById.title} </IonText>
                </div>
                <IonImg className="ion-img-session" src={sessionById.imageURL || handleImgCoach()} alt="image-session" />
                <ReserveSection reservedSession={sessions} sessionById={sessionById} userInfo={ userInfo } setIsOpen={setIsOpen} />
                <SessionDescription sessionById={sessionById} />
            </div>
            { !userInfo.idSuscription ? <ModalNoSubscription isOpen={isOpen} setOpen={setIsOpen} setOpenSub={setModalSub}  /> : null}
            <SubscribeModal setShowSub={setModalPayment} setShowModal={setModalSub} showModal={showModalSub} />
            <StripeModalPaymentSubscription isOpen={showModalPayment} setIsOpen={setModalPayment} />
            <CertificadoModal 
              userName={`${userInfo.firstName} ${userInfo.lastName}`}
              coachName={sessionById.fullName}
              dateSession={String(date)}
              sessionName={sessionById.title}
              handleClose={handleCloseModal} 
              isOpen={isOpenModal.openCertificate}
              base64={base64}
              />
              <IonToast
                  isOpen={toast.isOpen}
                  message={toast.message}
                  icon={toast.icon}
                  cssClass={'css-success-toast'}
                  buttons={toast.button}
                  onDidDismiss={() => setToast({ isOpen: false })}
                  duration={2000}
                />
          </IonContent>
      </IonPage>
    )
}