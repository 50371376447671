
//ionic
import { IonModal, IonGrid, IonImg, IonRow } from '@ionic/react';

//icons
import close_modal from 'assets/close_modal.svg';

import './modal-training-warning.css';
import { TrainingWarningContent } from './TrainingWarningContent';

interface ICancelarSuscripcionModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
}

function ModalTrainingWarning({ showModal, setShowModal }: ICancelarSuscripcionModal) {

  
  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="cs-icon-row">
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>
          <TrainingWarningContent showModal={showModal} setShowModal={setShowModal} />
      </IonGrid>
    </IonModal>
  );
}

export default ModalTrainingWarning;