import React, { useEffect } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, IonAvatar, IonCheckbox } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

import './UpdagreLevelModal.css';

//recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';
import { userState } from 'recoil/UserImage';
import { modalShowHome, modalWarningAtom } from 'recoil/modalAtom';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';

//components
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';

interface IUpgradeLevelModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
}

function UpgradeLevelModal({ showModal, setShowModal }: IUpgradeLevelModal) {
  const token = useRecoilValue(userInfoState);

  const level = (token && token.level) || 'Principiante';
  const points = (token && token.points) || 0;
  const lastName = token && token.lastName;
  const firstName = token && token.firstName;
  const key = token && token.key;
  const idUser = token && token.idUser;

  const [handlePostMedia, url] = usePostMedia();

  useEffect(() => {
    if (idUser && key) handlePostMedia({ key });
  }, [idUser, key, handlePostMedia]);

  const userImage = useRecoilValue(userState);

  const [modalShowAtom, setModalShowAtom] = useRecoilState(modalShowHome);

  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row">
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>
        <IonAvatar className="ul-img" style={{ marginTop: 0 }}>
          {userImage.photo !== '' ? (
            <IonImg src={String(userImage.photo)} />
          ) : url ? (
            <IonImg src={url} />
          ) : token.pictureURL ? (
            <IonImg src={token.pictureURL} />
          ) : (
            <AddAvatar width="56px" height="56px" />
          )}
        </IonAvatar>

        <IonText className="ul-name">
          {firstName} {lastName}
        </IonText>

        <IonText className="ul-description">
          {level} - {points} puntos
        </IonText>

        <IonText className="ul-upgrade-level">¡Aumenta tu nivel con la app!</IonText>

        <IonText className="ul-text">Complementa el Programa de Aprendizaje con la práctica en Entrenamientos Rápidos para mejorar de manera óptima.</IonText>

        <IonButton fill="clear" className="ul-start" onClick={() => setShowModal(false)}>
          Empezar
        </IonButton>

        <div style={{ display: 'flex', justifyContent: 'start', width: '100%', marginTop: 10, marginLeft: 10 }}>
          <IonCheckbox
            onIonChange={(e) => {
              setModalShowAtom(!modalShowAtom);
            }}
            labelPlacement="end"
            style={{ fontSize: 14, fontWeight: 400 }}
          >
            No volver a mostrar aviso
          </IonCheckbox>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default UpgradeLevelModal;
