import axios from './BaseConfig';

//interface
import { IRatingBody } from './interfaces/IRatingBody';

export const getBasisById = async (id: number) => {
  try {
    const res = await axios.get(`/basis/${id}`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getModules = async (id: number) => {
  try {
    const res = await axios.get(`/basis/${id}/modules`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getModulesById = async (id: number, idModule: number) => {
  try {
    const res = await axios.get(`/basis/${id}/modules/${idModule}`);
    return res;
  } catch (e) {
    console.log('error', e);
  }
};

export const getCoaches = async (id: number) => {
  try {
    const res = await axios.get(`/basis/${id}/coaches`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getExerciseById = async (id: number) => {
  try {
    const res = await axios.get(`/exercises/${id}`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getModuleExcercises = async (idBasis: number, idModule: number) => {
  try {
    const res = await axios.get(`/basis/${idBasis}/modules/${idModule}/exercises`);
    return res;
  } catch (e) {
    console.log('error', e);
  }
};

export const postExerciseCompleted = async (idUser: number, idExercise: number) => {
  try {
    const res = await axios.post(`/users/${idUser}/exercises/${idExercise}`);
    return res;
  } catch (e) {
    console.log('error', e);
  }
};

export const postRating = async (idBasis: number, idModule: number, body: IRatingBody) => {
  try {
    const res = await axios.post(`/basis/${idBasis}/modules/${idModule}/rating`, body);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};
