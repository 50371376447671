import { TrainingItem } from "components/Stepper-quick-training/StepperQuickTraining";
import { IExercisesQuick } from "interfaces/IEntraniento";

export const handleListVideoTraining = (data:IExercisesQuick[])=>{ 

    const dataArray: TrainingItem[] = data;

    const groupedData: Record<string, TrainingItem[]> = dataArray.reduce((groups, item) => {
      const { basisName, ...rest } = item;
      if (!groups[basisName]) {
        groups[basisName] = [];
      }
      groups[basisName].push({ basisName, ...rest });
      return groups;
    }, {} as Record<string, TrainingItem[]>);
  
    const sortedAndGroupedData = Object.entries(groupedData).map(([basisName, items]) => {
      const sortedItems = items.sort((a, b) => a.idTraining - b.idTraining);
      return { basisName, items: sortedItems };
    });

    const finalList:any[] = []

    sortedAndGroupedData.forEach( element=>{ 
        element.items.forEach(newElement => { 
            let obj = { ...newElement, completed: 0}
            finalList.push(obj)
        })
      })
    
    return finalList
}