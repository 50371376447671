import { useEffect, useRef, useState } from "react";
//ionic
import {
	IonContent,
	IonMenu,
	IonPage,
	IonRouterOutlet,
	IonSplitPane,
} from "@ionic/react";

//routes
import { MainTabs } from "routes/MainTabs";

//react-router-ionic
import { IonReactRouter } from "@ionic/react-router";

//react-router-dom
import { Redirect, Route } from "react-router-dom";

//componentes
import { Perfil } from "pages/perfil/Perfil";
import { MiCuenta } from "pages/mi-cuenta/MiCuenta";
import { MainCampus } from "pages/campus/MainCampus";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Contacto } from "pages/ayuda/contact/Contacto";
import { Consejos } from "pages/ayuda/consejos/Consejos";
import { MainSesiones } from "pages/sesiones/MainSesiones";
import { MisSesiones } from "pages/mis-sesiones/MisSesiones";
import { MisFavoritos } from "pages/mis-favoritos/MisFavoritos";
import { MainModulo } from "pages/aprendizaje/modulo/MainModulo";
import { MiSuscripcion } from "pages/mi-suscripcion/MiSuscripcion";
import { MainEntrenamiento } from "pages/entrenamiento/MainEntrenamiento";
import { Fundamentos } from "pages/entrenamiento/fundamentos/Fundamentos";
import { DetallesClave } from "pages/aprendizaje/ejercicio/DetallesClaves";
import { PoliticasPrivacidad } from "pages/ayuda/politicas/PoliticasPrivacidad";
import { SessionProfile } from "pages/sesiones/components/session/SessionProfile";
import { PreguntasFrecuentes } from "pages/ayuda/preguntas-frecuentes/PreguntasFrecuentes";
import { TerminosCondiciones } from "pages/ayuda/terminos-condiciones/TerminosCondiciones";
import { SessionCheckout } from "pages/sesiones/components/session-checkout/SessionCheckout";
import { LoadingQuickTraining } from "pages/entrenamiento/loading-screen/LoadingQuickTraining";
import { QuickTrainingMain } from "pages/entrenamiento/entrenamiento-rapido/QuickTrainingMain";
import { CrearEntrenamiento } from "pages/entrenamiento/crear-entrenamiento/CrearEntrenamiento";
import { EjerciciosDisponibles } from "pages/entrenamiento/ejercicios-disponibles/EjerciciosDisponibles";
import { SessionCheckoutSucessful } from "pages/sesiones/components/session-successful/SessionCheckoutSucessful";
import { MainStartTraining } from "pages/entrenamiento/comenzar-entrenamiento/MainStartTraining";
import { useGetUserLevels } from "hooks/users/useGetUserLevels";

export const MainHeader: React.FC = () => {
	const [show, setShow] = useState(false);
	const menuRef = useRef<HTMLIonMenuElement>(null);
	const { handleGetUserLevels } = useGetUserLevels();

	useEffect(() => {
		handleGetUserLevels();
	}, []);

	return (
		<IonReactRouter>
			<IonSplitPane contentId="main" when="xl">
				<IonMenu
					ref={menuRef}
					contentId="main"
					onIonDidClose={() => setShow(false)}
				>
					<Sidebar menuRef={menuRef} show={show} setShow={setShow} />
				</IonMenu>
				<IonPage id="main">
					<IonContent className="ion-padding">
						<IonRouterOutlet>
							<Route path="/perfil" exact component={Perfil} />
							<Route path="/cuenta" exact component={MiCuenta} />
							<Route
								path="/suscripcion"
								exact
								component={MiSuscripcion}
							/>
							<Route
								path="/mis-sesiones"
								exact
								component={MisSesiones}
							/>
							<Route path="/favoritos" exact component={MisFavoritos} />
							<Route path="/tabs" component={MainTabs} />
							<Route
								exact
								path="/entrenamiento"
								component={MainEntrenamiento}
							/>
							<Route
								exact
								path="/crear-entrenamiento"
								component={CrearEntrenamiento}
							/>
							<Route
								exact
								path="/ejercicios-disponibles"
								component={EjerciciosDisponibles}
							/>
							<Route exact path="/fundamentos" component={Fundamentos} />
							<Route
								exact
								path="/loading-training"
								component={LoadingQuickTraining}
							/>
							<Route
								exact
								path="/quick-training"
								component={QuickTrainingMain}
							/>
							<Route
								exact
								path="/quick-training/start"
								component={MainStartTraining}
							/>
							<Route path="/sesiones" exact component={MainSesiones} />
							<Route
								path="/sesiones/:id"
								exact
								component={SessionProfile}
							/>
							<Route
								path="/sesion-reserva/:id/checkout"
								exact
								component={SessionCheckout}
							/>
							<Route
								path="/sesion-checkout"
								exact
								component={SessionCheckoutSucessful}
							/>
							<Route path="/campus" exact component={MainCampus} />
							<Route path="/help/faq" component={PreguntasFrecuentes} />
							<Route path="/help/advice" exact component={Consejos} />
							<Route
								path="/help/terms"
								exact
								component={TerminosCondiciones}
							/>
							<Route
								path="/help/polish"
								exact
								component={PoliticasPrivacidad}
							/>
							<Route path="/help/contact" exact component={Contacto} />
							<Route
								path={"/modulo/:idBasis/:idModule"}
								exact
								component={MainModulo}
							/>
							<Route
								path="/bloque/:idBasis/modulo/:idModulo/ejercicio/:idEjercicio"
								exact
								component={DetallesClave}
							/>
							<Redirect from="/" to="/tabs/home" exact />
						</IonRouterOutlet>
					</IonContent>
				</IonPage>
			</IonSplitPane>
		</IonReactRouter>
	);
};
