//ionic
import { IonText } from '@ionic/react';

//css
import './empty-state.css';

interface IEmptyState {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  marginTop?: string;
}

const EmptyState: React.FC<IEmptyState> = ({ title, subtitle, marginTop }) => {
  return (
    <div className="es-container" style={{ marginTop }}>
      <IonText className="es-title">{title}</IonText>
      <IonText className="es-subtitle">{subtitle}</IonText>
    </div>
  );
};

export default EmptyState;
