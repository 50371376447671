import { IonContent, IonHeader, IonPage, useIonViewDidEnter } from "@ionic/react"
import { CreateAnimation } from '@ionic/react';
import { BasketBall } from "./component/BasketBall";
import { Ellipse } from "./component/Ellipise";
import { useState } from "react";

interface ILoadingScreen { 
    title: string,
    subTitle: string,
    height?: string,
    justifyContent?: string
}

export const LoadingScreen: React.FC <ILoadingScreen> = ({ subTitle, title, height = '50%', justifyContent = 'end' })=>{ 

    const [isPlaying, setisPlaying] = useState(false)

    useIonViewDidEnter(()=>{
        setTimeout(() => {
            setisPlaying(true)
        }, 500);
    })
    return(
        <div style={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:justifyContent, alignItems:'center', height:height}}>
            <CreateAnimation
                duration={600}
                iterations={Infinity}
                direction='alternate'
                fromTo={[
                    { property: 'transform', fromValue: 'translateY(0px)', toValue: 'translateY(30px)' },
                  ]}
                  play={isPlaying}
                >
                    <div>
                        <BasketBall />
                    </div>
            </CreateAnimation>

            <CreateAnimation
                duration={600}
                iterations={Infinity}
                direction='alternate'
                fromTo={[
                    { property: 'transform', fromValue: 'scale(1,1)', toValue: 'scale(1.3,1)' },
                ]}
                play={isPlaying}
                >
                    <div style={{ marginTop:30 }}>
                        <Ellipse />
                    </div>
            </CreateAnimation>
                <div style={{ textAlign:'center', marginTop:35}}>
                    <p style={{ fontWeight:600, fontSize:18, fontFamily:'Poppins', margin:0}}>{title}</p>
                    <p style={{ fontWeight:400, fontSize:14, margin:0, marginTop:5 }}>{subTitle}</p>
                </div>
        </div>
    )

}