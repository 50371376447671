import axios from './BaseConfig';

//interface
import { CampusParams } from './interfaces/CampusParams';

export const getCampus = async (params: CampusParams) => {
  try {
    const res = await axios.get('/campus', { params });
    return res.data.results;
  } catch (e) {
    console.log('error', e);
  }
};
