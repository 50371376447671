import { useCallback } from 'react';

//services
import { postUserFavorite } from 'services/bookmarks';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';
import { IPostUserFavoriteBody } from 'services/interfaces/IPostUserFavoriteBody';

export const usePostUserFavorite = () => {
  const setToast = useSetRecoilState(toastState);
  const setFavoriteStatus = useSetRecoilState(favoriteStatusAtom);

  const handlePostUserFavorite = useCallback(
    async (id: number, body: IPostUserFavoriteBody) => {
      try {
        const status = await postUserFavorite(id, body);

        if (status === 200) {
          setFavoriteStatus(status);
          setToast({
            isOpen: true,
            color: 'success',
            message: 'Guardaste la técnica en “Mis Favoritos”',
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },
    [setToast, setFavoriteStatus]
  );

  return { handlePostUserFavorite };
};
