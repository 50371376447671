import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonText, IonToolbar, IonMenuButton } from "@ionic/react"
import { CheckoutSessionForm } from "components/forms/checkout/CheckOutSessionForm"
import { useGetSessionsById } from "hooks/sessions/useGetSessionsById"
import { IParams } from "interfaces/IParams"
import { arrowBackOutline } from "ionicons/icons"
import { useEffect } from "react"
import { useParams } from "react-router"
import { useRecoilValue } from "recoil"
import { userInfoState } from "recoil/User"
import './sessionCheckout.css';
import { TermsConditionModal } from "components/modal/TermsConditionModal"
import { PolishPrivacyModal } from "components/modal/PolishPrivacyModal"

export const SessionCheckout: React.FC = ()=>{ 

    const {id} = useParams<IParams>()
    const { handleGetSessionById, sessionById } = useGetSessionsById()
    const userInfo = useRecoilValue(userInfoState)

    useEffect(() => {
      if(id){
        handleGetSessionById(id)
      }
    }, [id])

    return(
        <IonPage>
        <IonHeader collapse='fade'>
          <IonToolbar>
            <article style={{ display:'flex', width: 300 }}>
                <IonButtons slot="start">
                    <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref={`/sesiones/${id}`} />
                </IonButtons>
                <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Sesiones en vivo</p>
            </article>
          </IonToolbar>
          </IonHeader>
          <IonContent >
            <div className="session-container">
              <div style={{ marginBottom:10, display:'flex' }}>
                <IonText color={'dark'} style={{ fontStyle:'Poppins', fontSize:18, fontWeight:600, width:'100%'}}>
                  Reservá tu lugar
                </IonText>
              </div>
              <div style={{ marginBottom:10, width:'296px' }}>
                <IonText color={'medium'} style={{ fontSize:16, fontWeight:400, textAlign:'left' }}>
                  Enviaremos los datos de acceso a la sesión al email que indiques:
                </IonText>
              </div>
              <div>
                <CheckoutSessionForm sessionById={sessionById} userInfo={userInfo} />
              </div>
              <div style={{ width:'85%', display:'flex', justifyContent:'center', marginTop:'40px'}}>
                <IonText id="terms-session-modal" color={'orange'} style={{ fontSize:12, fontWeight:400, textDecoration: "underline" }}>
                  Términos y condiciones
                </IonText>
                  <span style={{ fontSize:12, fontWeight:400, marginLeft:7, marginRight:7, color:'#272727' }}>-</span>
                <IonText id="polish-session-modal" color={'orange'} style={{ fontSize:12, fontWeight:400, textDecoration: "underline" }}>Política de Privacidad</IonText>
              </div>
            </div>
            <TermsConditionModal triger="terms-session-modal" />
            <PolishPrivacyModal triger="polish-session-modal" />
          </IonContent>
      </IonPage>
    )
}