import { useCallback, useState } from "react"
import { useSetRecoilState } from "recoil"
import { toastState } from "recoil/ToastAtom"
import { resetPassword } from "services/login"
import CloseSvg from 'assets/md-close.svg';
import { alertCircle } from 'ionicons/icons'

export const useRecoverPassword = ()=>{ 

    const [isSended, setisSended] = useState(false)
    const [email, setEmail] = useState('')
    const setToast = useSetRecoilState(toastState)

    const handleRecoverPassword = useCallback(
      (email:string) => {
        let body = {
            email
        }
        resetPassword(body).then( response => {

            if(response.status === 200){
                setisSended(true)
                setEmail(email)
            }
        }).catch(error =>{ 
            if(error){
                setToast({
                    isOpen:true,
                    color:"danger",
                    message:"El mail que ingresaste no tiene cuenta asociada en la app",
                    icon:alertCircle,
                    button:[{
                      icon:CloseSvg,
                      side:'end',
                      role:'cancel',
                      cssClass:'toast-button'
                    }]
                  })
            }
        })
      },
      [],
    )
    
    return{
        email,
        isSended,
        handleRecoverPassword
    }
}