import { useCallback } from 'react';

//services
import { deleteUserFavorite } from 'services/bookmarks';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';

export const useDeleteUserFavorite = () => {
  const setToast = useSetRecoilState(toastState);
  const setFavoriteStatus = useSetRecoilState(favoriteStatusAtom);

  const handleDeleteUserFavorite = useCallback(
    async (id: number, idBookmark: number) => {
      try {
        const status = await deleteUserFavorite(id, idBookmark);

        if (status === 200) {
          setFavoriteStatus(status);
          setToast({
            isOpen: true,
            color: 'success',
            message: 'Sacaste la técnica de "Mis Favoritos"',
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },
    [setToast, setFavoriteStatus]
  );

  return { handleDeleteUserFavorite };
};
