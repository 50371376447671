import { handleProgressLearningImg } from 'helper/handleProgressLearningImg'
import React, { useCallback, useState } from 'react'
import { IProgressLearningObject } from 'services/interfaces/IProgress'
import { getProgressLearningProgram } from 'services/progress'

export const useGetProgressLearning = () => {

    const [progressLearning, setProgressLearning] = useState<IProgressLearningObject[]>([])

    const handleGetProgressLearning = useCallback(
      () => {
        getProgressLearningProgram().then( response => { 

            if(response.status === 200){
                const data = response.data

                const dataParsed = data.map( (element:IProgressLearningObject) => {
                    const obj = element
                    obj.img = handleProgressLearningImg(element.idBasis)

                    return obj
                })

                setProgressLearning(dataParsed)
            }
        })
      },
      [],
    )
    
  return {
    progressLearning,
    handleGetProgressLearning
  }
}
