import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDoughnutChart{ 
  exercise: number,
  training:number,
  session:number
}

const DoughnutChart = ({ exercise, training, session}: IDoughnutChart) => {
  const data = {
    labels: ['P. de Aprendizaje', 'Entrenamiento Rápido', 'Sesiones en Vivo'],
    datasets: [
      {
        data: [exercise, training, session],
        backgroundColor: ['#D64200', '#FFE1D4', '#272727'],
        hoverBackgroundColor: ['#D64200', '#FFE1D4', '#272727'],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return <Doughnut data={data} options={options} width={116} height={116} />;
};

export default DoughnutChart;
