import { useState } from 'react';

//ionic
import { IonContent, IonPage } from '@ionic/react';

//components
import Header from 'components/Header/Header';
import HeaderPopOver from 'components/Header/HeaderPopOver';
import NextCampus from 'components/Home/NextCampus/NextCampus';
import LiveSessions from 'components/Home/LiveSessions/LiveSessions';
import QuickTraining from 'components/Home/QuickTraining/QuickTraining';
import UpgradeLevelModal from 'components/Home/Modal/UpgradeLevelModal';
import WelcomeTitle from '../../components/Home/WelcomeTitle/WelcomeTitle';
import LearningProgram from 'components/Home/LearningProgram/LearningProgram';
import ModalFinalizacionEntrenamiento from 'components/modal/finalizacion-entrenamiento/ModalFinalizacionEntrenamiento';

//recoil
import { useRecoilValue } from 'recoil';
import { statusAtom } from 'recoil/statusAtom';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { modalShowHome } from 'recoil/modalAtom';

const Home: React.FC = () => {
  const status = useRecoilValue(statusAtom);
  const [showModal, setShowModal] = useState(true);
  const { IsOpen, handleCloseModal } = useOpenModal();

  const modalShowAtom = useRecoilValue(modalShowHome);

  return (
    <IonPage>
      <Header triger="home-open" />
      <HeaderPopOver triger="home-open" />
      <IonContent>
        <WelcomeTitle classNameSub="ion-text-question" className="ion-grid-title-container" subtitle="¿qué quieres aprender hoy?" />
        <LearningProgram />
        <QuickTraining />
        <LiveSessions />
        <NextCampus />
      </IonContent>

      {status === 200 && !modalShowAtom && <UpgradeLevelModal showModal={showModal} setShowModal={setShowModal} />}
      <ModalFinalizacionEntrenamiento IsOpen={IsOpen.finishedQuickTraining} handleClose={handleCloseModal} />
    </IonPage>
  );
};

export default Home;
