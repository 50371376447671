import { useEffect, useState } from "react";
import './countdownstart.css'
import { orientationAtom } from "recoil/OrientationAtom";
import { useRecoilValue } from "recoil";

interface ICountDownTimer { 
    seconds: number,
    onComplete: ()=>void
}

export const CountDownStart: React.FC <ICountDownTimer>= ({ seconds, onComplete })=>{ 

    const [time, setTime] = useState(seconds);
    const orientation = useRecoilValue(orientationAtom)

  useEffect(() => {
      const timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      if (time <= 0) {
        onComplete();
      }

      return () => {
        clearInterval(timer);
      };
      
  }, [time]);

    return(
        <div className="cdt-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px' }}>
            <p style={{ fontSize:30, fontWeight:700 }}>
                {time}
            </p>
        </div>
    )
}