import { IonText } from "@ionic/react"
import './terminosCondiciones.css'

export const TerminosCondicionesContenido: React.FC = ()=>{

    return(
        <div style={{ display:'flex', flexDirection:'column', paddingRight:40, paddingLeft:40, marginTop:20 }}>
        <IonText className="header">
            TÉRMINOS Y CONDICIONES
        </IonText>
        <IonText className="text" style={{ marginBottom:'32px' }}>
            Bienvenido a la aplicación Campus Huevo Sánchez (la "Aplicación"). Estos Términos y Condiciones (los "Términos") rigen su uso de la Aplicación. Al acceder o utilizar la Aplicación, acepta quedar vinculado por estos Términos. Si no acepta estos Términos, no podrá utilizar la Aplicación
        </IonText>
        <IonText className="sub-header">
            Concesión de Licencia
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>1.</span> Sujeto a estos Términos, le otorgamos una licencia limitada, no exclusiva, no transferible y revocable para acceder y utilizar la Aplicación únicamente para uso personal y no comercial.
        </IonText>
        <IonText className="sub-header">
            Cuentas de Usuario
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>2.</span> Para utilizar ciertas características de la Aplicación, puede ser necesario crear una cuenta. Debe proporcionar información precisa y completa al crear su cuenta y es responsable de mantener la confidencialidad de su información de cuenta.
        </IonText>
        <IonText className="sub-header">
            Contenido de Usuario
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>3.</span> Usted es el único responsable de cualquier contenido que envíe, cargue o transmita a través de la Aplicación. Al enviar, cargar o transmitir cualquier contenido, nos concede una licencia mundial, no exclusiva, libre de regalías, sublicenciable y transferible para utilizar, reproducir, distribuir, preparar obras derivadas de, mostrar y realizar el contenido en relación con la Aplicación y nuestro negocio.
        </IonText>
        <IonText className="sub-header">
            Propiedad Intelectual
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>4.</span> La Aplicación y su contenido, incluyendo pero no limitado a texto, gráficos, imágenes, logotipos, iconos, software y la recopilación de todo el contenido en la Aplicación, son propiedad de Campus Huevo Sánchez o sus licenciantes y están protegidos por las leyes de propiedad intelectual, incluyendo derechos de autor y marcas registradas.
        </IonText>
        <IonText className="sub-header">
            Actividades Prohibidas
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>5.</span> No puede utilizar la Aplicación para ningún propósito ilegal o no autorizado. No puede copiar, modificar, distribuir, vender ni arrendar ninguna parte de la Aplicación o su contenido. No puede interferir con el funcionamiento adecuado de la Aplicación ni eludir ninguna medida de seguridad.
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>6.</span> Queda prohibido compartir la cuenta de usuario. El uso de la cuenta de usuario es personal e intransferible. El usuario se compromete a mantener la privacidad y seguridad de su cuenta de usuario y a no compartirla con terceros. El usuario es responsable de cualquier actividad realizada en su cuenta de usuario, incluso si la actividad es realizada por terceros no autorizados. Cualquier intento de compartir, vender o transferir la cuenta de usuario a terceros está estrictamente prohibido y dará lugar a la suspensión inmediata y permanente de la cuenta de usuario.
        </IonText>
        <IonText className="sub-header">
            Renuncia de Garantías
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>7.</span> La Aplicación se proporciona "tal cual" y sin garantía de ningún tipo. No garantizamos que la Aplicación sea libre de errores o interrupciones, que se corrijan defectos o que la Aplicación o su servidor estén libres de virus u otros componentes dañinos.
        </IonText>
        <IonText className="sub-header">
            Limitación de Responsabilidad
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>8.</span> En la medida máxima permitida por la ley aplicable, no seremos responsables de ningún daño directo, indirecto, incidental, especial, consecuente o ejemplar que surja de su uso de la Aplicación o cualquier contenido en la Aplicación.
        </IonText>
        <IonText className="sub-header">
            Indemnización
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>9.</span> Usted acepta indemnizar y mantener indemne a Campus Huevo Sánchez, sus afiliados y sus respectivos oficiales, directores, empleados, agentes, licenciantes y proveedores de cualquier reclamo, responsabilidad, daño, pérdida o gasto, incluyendo honorarios razonables de abogados, que surjan de su uso de la Aplicación, su contenido o su violación de estos Términos.
        </IonText>
        <IonText className="sub-header">
            Modificación de los Términos
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>10.</span> Nos reservamos el derecho de modificar estos Términos en cualquier momento,
            y cualquier modificación entrará en vigencia inmediatamente después de su publicación en la Aplicación. Su uso continuado de la Aplicación después de la publicación de los Términos modificados significa que acepta quedar vinculado por los Términos modificados.
        </IonText>
        <IonText className="sub-header">
            Terminación
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>11.</span> Nos reservamos el derecho de suspender o cancelar su acceso a la Aplicación en cualquier momento y por cualquier motivo, sin previo aviso y sin responsabilidad alguna.
        </IonText>
        <IonText className="sub-header">
            Ley Aplicable
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>12.</span> Estos Términos se regirán e interpretarán de acuerdo con las leyes del país en el que se encuentra ubicada la empresa Campus Huevo Sánchez.
        </IonText>
        <IonText className="sub-header">
            Acuerdo Completo
        </IonText>
        <IonText className="text" style={{ display:'flex'}}>
            <span style={{ marginRight:5 }}>13.</span> Estos Términos constituyen el acuerdo completo entre usted y Campus Huevo Sánchez con respecto al uso de la Aplicación y reemplazan cualquier acuerdo previo o contemporáneo entre usted y nosotros.
        </IonText>
        <IonText className="text">
            Si tiene alguna pregunta sobre estos Términos, contáctenos en info@campushuevosanchez.com.
        </IonText>
        </div>
    )
}