import { useEffect } from 'react';

//ionic
import { IonButtons, IonContent, IonHeader, IonPage, IonText, IonToolbar, useIonViewDidEnter, useIonViewDidLeave, IonToast, useIonRouter, IonIcon, IonMenuButton } from '@ionic/react';

//interfaces
import { IParamsModule } from 'interfaces/IParams';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//react-router
import { useParams } from 'react-router';

//components
import { ModuleHeader } from './components/ModuleHeader';
import { VerticalStepper } from 'components/Stepper/Stepper';
import { ModuleFinishedModal } from 'components/modal/ModuleFinishedModal';
import ModalFinalizacionEjercicio from '../ejercicio/ModalFinalizacionEjercicio';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { useGetModuleByID } from 'hooks/aprendizaje/useGetModuleByID';
import { useGetModuleExercises } from 'hooks/aprendizaje/useGetModuleExercises';

//recoil
import { toastState } from 'recoil/ToastAtom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { notesStatusAtom } from 'recoil/NotesStatusAtom';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';
import { moduleFinishedAtom } from 'recoil/moduleFinishedAtom';
import { exerciseFinishedAtom, multipleExerciseFinishedAtom } from 'recoil/exerciseFinishedAtom';
import ModalMultipleFinalizacionEjercicio from '../ejercicio/ModalMultipleFinalizacionEjercicio';
import { ratingModalAtom } from 'recoil/ratingModalAtom';
import RatingModal from 'components/aprendizaje/RatingModal';

export const MainModulo: React.FC = () => {
  const [toast, setToast] = useRecoilState(toastState);

  const { idBasis, idModule }: IParamsModule = useParams();

  const { ModuleById, handleGetModuleById } = useGetModuleByID();
  const { moduleExercises, handleGetModuleExercises } = useGetModuleExercises();

  const { IsOpen, handleCloseModal, handleOpenModal } = useOpenModal();

  const [exerciseFinished, setExerciseFinished] = useRecoilState(exerciseFinishedAtom);
  const [multipleExerciseFinished, setMultipleExerciseFinished] = useRecoilState(multipleExerciseFinishedAtom);
  const [moduleFinished, setModuleFinished] = useRecoilState(moduleFinishedAtom);

  const favoriteStatus = useRecoilValue(favoriteStatusAtom);
  const notesStatus = useRecoilValue(notesStatusAtom);
  const [ratingModal, setRatingModal] = useRecoilState(ratingModalAtom);

  useEffect(() => {
    if (favoriteStatus === 200 || notesStatus === 200) {
      handleGetModuleById(Number(idBasis), Number(idModule));
      handleGetModuleExercises(Number(idBasis), Number(idModule));
    }
  }, [favoriteStatus, handleGetModuleById, handleGetModuleExercises, idBasis, idModule, notesStatus]);

  const navigation = useIonRouter();

  const name = ModuleById && ModuleById.name;

  useIonViewDidEnter(() => {
    handleGetModuleById(Number(idBasis), Number(idModule));
    handleGetModuleExercises(Number(idBasis), Number(idModule));
  });

  useEffect(() => {
    if (exerciseFinished) handleOpenModal('finishedExercise');
    if (multipleExerciseFinished) handleOpenModal('multipleExerciseFinished');
    if (moduleFinished) handleOpenModal('finishedModule');

    handleGetModuleExercises(Number(idBasis), Number(idModule));
  }, [exerciseFinished, handleGetModuleExercises, handleOpenModal, idBasis, idModule, moduleFinished, multipleExerciseFinished]);

  useEffect(() => {
    if (ratingModal && !IsOpen.finishedModule) handleOpenModal('ratingModal');
  }, [handleOpenModal, ratingModal, IsOpen.finishedModule]);

  useIonViewDidLeave(() => {
    setExerciseFinished(false);
    setModuleFinished(false);
    setMultipleExerciseFinished(false);
    setRatingModal(false)
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <article style={{ display: 'flex', width: 300 }}>
            <IonButtons
              slot="start"
              onClick={() => {
                navigation.push(`/tabs/aprendizaje/${idBasis}`, 'forward', 'pop');
              }}
            >
              <IonIcon style={{ width: 24, height: 24, marginLeft: 10 }} icon={arrowBackOutline} />
            </IonButtons>
            <IonText className="f-header-title">{name}</IonText>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {ModuleById ? <ModuleHeader module={ModuleById} /> : null}
        <VerticalStepper data={moduleExercises} />
        <ModuleFinishedModal isOpen={IsOpen.finishedModule} handleOpen={handleOpenModal} handleClose={handleCloseModal} />
        <ModalFinalizacionEjercicio isOpen={IsOpen.finishedExercise} handleClose={handleCloseModal} />
        <ModalMultipleFinalizacionEjercicio isOpen={IsOpen.multipleExerciseFinished} handleClose={handleCloseModal} />
        {ratingModal && !IsOpen.finishedModule && ModuleById && (
          <RatingModal
            module={{
              idModule: ModuleById.idModule,
              name: ModuleById.name,
              idBasis: ModuleById.idBasis,
              createdAt: ModuleById.createdAt,
              deletedAt: ModuleById.deletedAt,
              totalExercises: ModuleById.totalExercises,
              completedExercises: ModuleById.completedExercises,
              rating: ModuleById.rating,
              note: ModuleById.note,
            }}
            showModal={IsOpen.ratingModal}
            setShowModal={handleCloseModal}
          />
        )}

        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          icon={toast.icon}
          cssClass={'css-success-toast'}
          buttons={toast.button}
          onDidDismiss={() => setToast({ isOpen: false })}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};
