import { useEffect, useRef, useState } from 'react';

//ionic
import { IonButtons, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonText, IonToolbar, IonButton, useIonRouter, IonIcon, useIonViewDidEnter, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';
import aro from '../../../assets/crear-entrenamiento/aro.svg';
import solo from '../../../assets/crear-entrenamiento/solo.svg';
import min15 from '../../../assets/crear-entrenamiento/15min.svg';
import min30 from '../../../assets/crear-entrenamiento/30min.svg';
import min45 from '../../../assets/crear-entrenamiento/45min.svg';
import min60 from '../../../assets/crear-entrenamiento/60min.svg';
import companero from 'assets/crear-entrenamiento/companero.svg';
import noTengoAro from '../../../assets/crear-entrenamiento/no-tengo-aro.svg';

//css
import './crear-entrenamiento.css';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { useGetTrainingTimes } from 'hooks/entrenamiento-rapido/useGetTrainingTimes';

//helper
import { handleHoop, handlePartner, maxTime } from 'helper/handleQuickTrainingTimes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { trainingState } from 'recoil/TrainingAtom';

//componentes
import ModalFinalizacionEntrenamiento from 'components/modal/finalizacion-entrenamiento/ModalFinalizacionEntrenamiento';
import { EjerciciosRecomendacionModal } from 'components/modal/entrenamiento-recomendacion/EjerciciosRecomendacion';
import { modalRecommendationAtom } from 'recoil/modalAtom';

export const CrearEntrenamiento: React.FC = () => {
  const duration_array = [
    { duration: 15, img: min15 },
    { duration: 30, img: min30 },
    { duration: 45, img: min45 },
    { duration: 60, img: min60 },
  ];

  const [entrenamiento, setEntrenamiento] = useRecoilState(trainingState);
  const modalRecommendation = useRecoilValue(modalRecommendationAtom)
  const [showModal, setShowModal] = useState<boolean>(modalRecommendation)

  const { duracion, tieneAro, tieneCompanero } = entrenamiento;

  const handleSeleccion = (key: string, value: any) => {
    setEntrenamiento((prevEntrenamiento) => ({
      ...prevEntrenamiento,
      [key]: value,
    }));
  };

  const { handleGetTrainingTimes, trainingTimes } = useGetTrainingTimes();

  const isEnabled = duracion && tieneAro && tieneCompanero;

  const navigation = useIonRouter();

  const maxTimeRef = useRef(0)

  const { IsOpen, handleCloseModal } = useOpenModal();

  const handleRedirect = () => {
    navigation.push('/fundamentos', 'forward', 'replace');
  };

  const [isAvailable, setIsAvailable] = useState({
    withHoop: false,
    withoutHoop: false,
  });

  const [isAvailablePartner, setIsAvailablePartner] = useState({
    withPartner: false,
    withoutPartner: false,
  });

  const [hoopText, setHoopText] = useState(false);
  const [timeText, setTimeText] = useState(false);
  const [partnerText, setPartnerText] = useState(false);

  useEffect(() => {
    if (duracion !== 0) {
      let result = handleHoop(duracion, trainingTimes);
      setIsAvailable(result);
    }else{
      setIsAvailable({withHoop:false, withoutHoop:false });
    }
    setIsAvailablePartner(handlePartner(duracion, tieneAro, trainingTimes));
  }, [duracion, tieneAro, trainingTimes]);

  useIonViewDidEnter(() => {
    handleGetTrainingTimes();
  });

  useEffect(() => {
    maxTimeRef.current = maxTime(trainingTimes)
  }, [trainingTimes])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <article style={{ display: 'flex', width: 300 }}>
            <IonButtons
              slot="start"
              onClick={() => {
                navigation.push('/tabs/home', 'back', 'pop');
                setEntrenamiento({
                  duracion: 0,
                  tieneAro: '',
                  tieneCompanero: '',
                });
              }}
            >
              <IonIcon style={{ width: 24, height: 24, marginLeft: 10 }} icon={arrowBackOutline} />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft: 10, fontWeight: 700 }}>Entrenamiento rápido</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className="ce-grid">
          <div className="ce-container">
            <IonText className="ce-title">¡Crea un entrenamiento a tu medida!</IonText>
            <IonText className="ce-subtitle">Pon en práctica lo aprendido en el Programa de Aprendizaje</IonText>

            <div className="flex" style={{ flexDirection: 'column', marginTop: '24px' }}>
              <IonText className="ce-title">Duración</IonText>
              <div className="flex" style={{ marginTop: '8px', justifyContent: 'space-between' }}>
                {duration_array.map(({ duration, img }, index) => {
                  //duration del map === duracion del estado local
                  const isSelected = duration === duracion;
                  return (
                    <div
                      onClick={() => {
                        if(duration <= maxTimeRef.current){
                          setEntrenamiento({
                            duracion: 0,
                            tieneAro: '',
                            tieneCompanero: '',
                          });
                          handleSeleccion('duracion', duration);
                          setTimeText(false)
                        }else{
                          setTimeText(true)
                        }
                      }}
                      key={index}
                      style={{ opacity: duration <= maxTimeRef.current ? 1 : 0.5}}
                      className={`ce-minutes-container ${isSelected ? 'ce-border' : ''}`}
                    >
                      <IonImg src={img} />
                      <IonText className="ce-text">{duration} min</IonText>
                    </div>
                  );
                })}
              </div>
              {timeText && <IonText className="ce-no-available-exercises">No hay suficientes ejercicios segun el filtro seleccionado</IonText>}
            </div>
            <div className="flex" style={{ flexDirection: 'column', marginTop: '16px' }}>
              <IonText className="ce-title">¿Tienes aro?</IonText>
              <div className="flex" style={{ marginTop: '8px', justifyContent: 'space-between' }}>
                <div
                  style={{ opacity: !isAvailable.withHoop ? 0.5 : 1 }}
                  className={`ce-box ${tieneAro === 'yes' ? 'ce-border' : ''}`}
                  onClick={() => {
                    if (isAvailable.withHoop) {
                      handleSeleccion('tieneAro', 'yes');
                      setHoopText(false);
                    } else setHoopText(true);
                  }}
                >
                  <IonImg src={aro} />
                  <IonText className="ce-text">Tengo aro</IonText>
                </div>

                <div
                  style={{ opacity: !isAvailable.withoutHoop ? 0.5 : 1 }}
                  className={`ce-box ${tieneAro === 'no' ? 'ce-border' : ''}`}
                  onClick={() => {
                    if (isAvailable.withoutHoop) {
                      handleSeleccion('tieneAro', 'no');
                      setHoopText(false);
                    } else setHoopText(true);
                  }}
                >
                  <IonImg src={noTengoAro} />
                  <IonText className="ce-text">No tengo aro</IonText>
                </div>
              </div>
              {hoopText && <IonText className="ce-no-available-exercises">No hay suficientes ejercicios segun el filtro seleccionado</IonText>}
            </div>

            <div className="flex" style={{ flexDirection: 'column', marginTop: '16px' }}>
              <IonText className="ce-title">¿Tienes compañero?</IonText>
              <div className="flex" style={{ marginTop: '8px', justifyContent: 'space-between' }}>
                <div
                  style={{ opacity: !isAvailablePartner.withPartner ? 0.5 : 1 }}
                  className={`ce-box ${tieneCompanero === 'yes' ? 'ce-border' : ''}`}
                  onClick={() => {
                    if (isAvailablePartner.withPartner) {
                      handleSeleccion('tieneCompanero', 'yes');
                      setPartnerText(false);
                    } else setPartnerText(true);
                  }}
                >
                  <IonImg src={companero} />
                  <IonText className="ce-text">Tengo compañero</IonText>
                </div>

                <div
                  style={{ opacity: !isAvailablePartner.withoutPartner ? 0.5 : 1 }}
                  className={`ce-box ${tieneCompanero === 'no' ? 'ce-border' : ''}`}
                  onClick={() => {
                    if (isAvailablePartner.withoutPartner) {
                      handleSeleccion('tieneCompanero', 'no');
                      setPartnerText(false);
                    } else setPartnerText(true);
                  }}
                >
                  <IonImg src={solo} />
                  <IonText className="ce-text">No tengo compañero</IonText>
                </div>
              </div>
              {partnerText && <IonText className="ce-no-available-exercises">No hay suficientes ejercicios segun el filtro seleccionado</IonText>}
            </div>
          </div>
          <IonButton
            type="submit"
            fill="clear"
            className={`${isEnabled ? 'ce-button' : 'ce-button-disabled'}`}
            onClick={() => {
              if (isEnabled) handleRedirect();
            }}
          >
            Continuar
          </IonButton>
        </IonGrid>
        <ModalFinalizacionEntrenamiento IsOpen={ IsOpen.finishedQuickTraining } handleClose={handleCloseModal} />
        {
          !IsOpen.finishedQuickTraining && 
          <EjerciciosRecomendacionModal setShowModal={setShowModal} isOpen={showModal} />
        }
      </IonContent>
    </IonPage>
  );
};
