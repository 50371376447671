import { useState } from 'react';

//ionic
import { IonContent, IonPage, IonToast } from '@ionic/react';

//css
import './progress.css';

//component
import Header from 'components/Header/Header';
import TabsSegment from 'components/Progress/TabsSegment';
import TabSesiones from 'components/Progress/TabSesiones';
import HeaderPopOver from 'components/Header/HeaderPopOver';
import TabAprendizaje from 'components/Progress/TabAprendizaje';
import ProgressHeader from 'components/Progress/ProgressHeader';
import PerformanceGoals from 'components/Progress/PerformanceGoals';
import TabEntrenamiento from 'components/Progress/TabEntrenamiento';
import ProgressStatistics from 'components/Progress/ProgressStatistics';
import { useRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';

const Progress: React.FC = () => {
  const [activeTab, setActiveTab] = useState('aprendizaje');
  const [toast, setToast] = useRecoilState(toastState);

  return (
    <IonPage>
      <Header triger="tab2-open" />
      <HeaderPopOver triger="tab2-open" />
      <IonContent fullscreen>
        <ProgressHeader />
        <ProgressStatistics />
        <PerformanceGoals />
        <TabsSegment activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 'aprendizaje' && <TabAprendizaje />}
        {activeTab === 'entrenamiento-rapido' && <TabEntrenamiento />}
        {activeTab === 'sesiones' && <TabSesiones />}
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          icon={toast.icon}
          cssClass={'css-success-toast'}
          buttons={toast.button}
          onDidDismiss={() => setToast({ isOpen: false })}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Progress;
