import { IonAccordion, IonAccordionGroup, IonAvatar, IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import '../components/seleccionAyuda.css'

export const Consejos: React.FC = ()=>{

    return(
        <IonPage>
            <IonContent fullscreen>
            <IonHeader>
                <IonToolbar>
                    <article style={{ display:'flex', width: 300 }}>
                    <IonButtons slot="start">
                        <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/tabs/help' />
                    </IonButtons>
                    <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Consejos</p>
                    </article>
                </IonToolbar>
            </IonHeader>
                <div style={{ width:'100%', display:'flex', justifyContent:'center'}}>
                    <IonAccordionGroup>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="first" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Consejo 2</IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    First Content
                                </div>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="second" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Consejo 3</IonLabel>
                                </IonItem>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="third" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Consejo 4</IonLabel>
                                </IonItem>
                            </IonAccordion>
                        </article>
                        <article style={{ marginTop:15 }}>
                            <IonAccordion color="white" value="fourth" className="accordion-parent">
                                <IonItem slot="header">
                                    <IonLabel className="accordion-label">Consejo 5</IonLabel>
                                </IonItem>
                            </IonAccordion>
                        </article>
                    </IonAccordionGroup>
                    </div>
            </IonContent>
        </IonPage>

    )
}