import { IonButton, IonText } from "@ionic/react"
import { useState } from "react"

interface ISingleBoxHelp{ 
    title:string,
    text:string
}

export const SingleBoxHelp: React.FC <ISingleBoxHelp>= ({ text, title })=>{ 

    const textTrim = text.slice(0,36)
    const [show, setShow] = useState(false)

    return(
        <div style={{ width:'100%', height:'auto', display:'flex', flexDirection:'column'}}>
            <div style={{ width:'100%', display:'flex', justifyContent:'start'}}>
                <IonText style={{ fontSize:14, fontWeight:700}} color={'dark'}> {title} </IonText>
            </div>
            <div style={{ width:'100%', display:'flex', justifyContent:'start', marginTop:5}}>
                <IonText style={{ fontSize:14, fontWeight:400}} color={'dark'}>
                    { !show ? `${textTrim}...` : text} 
                </IonText>
            </div>
            <div style={{ width:'100%', display:'flex', justifyContent:'start', marginTop:5 }}>
                {   
                    !show ?
                    <IonText onClick={()=> setShow(true)} style={{ fontSize:14, fontWeight:400}} color={'orange'}> Ver respuesta completa </IonText>
                    :
                    <IonText onClick={()=> setShow(false)} style={{ fontSize:14, fontWeight:400}} color={'orange'}> Ver menos </IonText>
                }
            </div>

        </div>
    )
}