import { IonText } from "@ionic/react";
import './politicasPrivacidad.css'

export const PoliticasPrivacidadContenido: React.FC = ()=>{ 

    return(
        <div style={{ display:'flex', flexDirection:'column', paddingRight:40, paddingLeft:40, marginTop:20 }}>
            <IonText className="header">
                POLÍTICA DE PRIVACIDAD PARA LA APLICACIÓN MÓVIL CAMPUS HUEVO SANCHEZ APP
            </IonText>
            <IonText className="text" style={{ marginBottom:'32px' }}>
                Bienvenido a nuestra aplicación móvil Campus Huevo Sanchez App. Nos comprometemos a proteger su privacidad y garantizar que su información personal se mantenga confidencial. Esta política de privacidad describe cómo recolectamos, usamos y compartimos su información personal al usar nuestra aplicación móvil.
            </IonText>
            <IonText className="sub-header">
                Información que recolectamos
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>1.</span> Podemos recolectar información personal que usted nos proporcione directamente, como su nombre, dirección de correo electrónico, dirección postal, número de teléfono y otra información similar. También podemos recolectar información automáticamente a través de su uso de nuestra aplicación móvil, como su dirección IP, el tipo de dispositivo que está utilizando, el sistema operativo, la información de la red móvil, la información de registro de uso, la ubicación geográfica y otra información similar.
            </IonText>
            <IonText className="sub-header">
                Uso de su información
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>2.</span> Utilizaremos su información personal para brindarle acceso a nuestra aplicación móvil, para personalizar su experiencia en la aplicación móvil y para mejorar nuestra aplicación móvil. Podemos compartir su información personal con terceros para brindarle los servicios solicitados, como los proveedores de servicios de pago y los proveedores de servicios de alojamiento. También podemos compartir su información personal con terceros para enviarle comunicaciones de marketing y promociones relacionadas con nuestra aplicación móvil.
            </IonText>
            <IonText className="sub-header">
                Cookies y tecnologías similares
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>3.</span> Utilizamos cookies y tecnologías similares para recolectar información sobre su uso de nuestra aplicación móvil y para personalizar su experiencia en la aplicación móvil. Puede configurar su navegador para rechazar todas las cookies o para indicar cuándo se está enviando una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar todas las funciones de nuestra aplicación móvil.
            </IonText>
            <IonText className="sub-header">
                Retención de la información
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>4.</span> Retendremos su información personal durante el tiempo necesario para brindarle los servicios solicitados y para cumplir con nuestras obligaciones legales y reglamentarias.
            </IonText>
            <IonText className="sub-header">
                Cambios a nuestra política de privacidad
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>5.</span> Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento y sin previo aviso. Si realizamos cambios significativos a esta política de privacidad, le notificaremos a través de nuestra aplicación móvil o por otros medios.
            </IonText>
            <IonText className="sub-header">
                Preguntas y comentarios
            </IonText>
            <IonText className="text" style={{ display:'flex'}}>
                <span style={{ marginRight:5 }}>7.</span> Si tiene alguna pregunta o comentario sobre esta política de privacidad, contáctenos en info@campushuevosanchez.com.
            </IonText>
            <IonText className="text" style={{ fontWeight:700 }}>
                Al utilizar nuestra aplicación móvil, acepta esta política de privacidad y nuestro tratamiento de su información personal según lo establecido en esta política de privacidad.
            </IonText>
        </div>
    )
}