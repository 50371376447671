//react
import { useEffect, useRef, useState } from 'react';

//ionic
import { IonButton, IonGrid, IonText, useIonRouter, IonPopover, IonContent } from '@ionic/react';

import './QuickTraining.css';

//hooks
import { useGetTrainingTimes } from 'hooks/entrenamiento-rapido/useGetTrainingTimes';

//helper
import { maxTime } from 'helper/handleQuickTrainingTimes';

const QuickTraining: React.FC = () => {
  const navigation = useIonRouter();

  const [maxMinute, setMaxMinute] = useState(0);

  const { handleGetTrainingTimes, trainingTimes } = useGetTrainingTimes();

  useEffect(() => {
    handleGetTrainingTimes();
  }, [handleGetTrainingTimes]);

  useEffect(() => {
    setMaxMinute(maxTime(trainingTimes));
  }, [trainingTimes]);

  return (
    <IonGrid className="qt-container">
      <IonText className="qt-title">Entrenamiento Rápido</IonText>
      <IonText className="qt-subtitle">¡Crea un entrenamiento a tu medida!</IonText>
      <IonText className="qt-description">Podrás perfeccionar las técnicas aprendidas en el Programa de Aprendizaje.</IonText>
      <IonButton
        id={maxMinute >= 15 ? '' : 'click-trigger'}
        fill="clear"
        className={maxMinute < 15 ? 'qt-button-disabled' : 'qt-button'}
        onClick={() => (maxMinute >= 15 ? navigation.push('/crear-entrenamiento', 'root', 'replace') : null)}
      >
        Crear Entrenamiento
      </IonButton>
      <IonPopover trigger={maxMinute >= 15 ? '' : 'click-trigger'} triggerAction="click">
        <IonContent className="ion-padding pop-over-entrenamiento">Aún no avanzaste lo suficiente en el Programa para crear un entrenamiento rápido</IonContent>
      </IonPopover>
      <IonText
        id={maxMinute > 0 ? '' : 'popover-entrenamientos'}
        onClick={() => (maxMinute > 0 ? navigation.push('/ejercicios-disponibles', 'forward', 'replace') : null)}
        className="qt-available-excercises"
        style={{ color: maxMinute === 0 && '#FFF' }}
      >
        Ver Ejercicios Disponibles
      </IonText>

      <IonPopover trigger={maxMinute > 0 ? '' : 'popover-entrenamientos'} triggerAction="click">
        <IonContent className="ion-padding pop-over-entrenamiento">Aún no avanzaste lo suficiente en el Programa para tener Ejercicios Disponibles</IonContent>
      </IonPopover>
    </IonGrid>
  );
};

export default QuickTraining;
