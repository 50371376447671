import { Formik, Form } from 'formik';
import { Fieldset } from 'components/FieldSet/FieldSet';
import { IonButton, isPlatform, useIonRouter } from '@ionic/react';
import { loginValidation } from './LoginValidate';
import { useAuthWithCredentials } from 'hooks/auth/useAuthWithCredentials';
import { IAuthWithCredentials } from 'services/interfaces/AuthLogin';
import { FieldSetPassword } from 'components/FieldSet/FieldSetPassword';
import { Device } from '@awesome-cordova-plugins/device';

export const SignUp = () => {
  const [ handleAuthWithCredentials ] = useAuthWithCredentials()
  const navigation = useIonRouter()

  return(
  <div>
    <Formik
      initialValues={{
        email: '',
        password:''
      }}
      enableReinitialize={true}
      validationSchema={loginValidation}
      onSubmit={(values) => {
        const uuid = isPlatform('ios') ? `${values.email}-${Device.model}` : Device.uuid
        const formValues:IAuthWithCredentials = {
          email: values.email,
          password: values.password,
          client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
          deviceUUID: uuid
        }

        handleAuthWithCredentials(formValues)
      }}
      >
      {({ setFieldTouched ,dirty, isValid, values,errors, touched }:any) => (
        <Form>
            <article style={{ display:'flex', flexDirection:'column', alignItems:'center', padding: "32px" }}>
                <Fieldset
                    name="email"
                    type="email"
                    label="Email *"
                    placeholder="Ingrese su correo..."
                    value={values.email}
                    errors={errors.email}
                    setFieldTouched={setFieldTouched}
                    touched={touched.email}
                    isTouched={false}
                />
                <FieldSetPassword
                    name="password"
                    type="password"
                    label="Contraseña *"
                    placeholder="Ingrese su contraseña..."
                    value={values.password}
                    errors={errors.password}
                    setFieldTouched={setFieldTouched}
                    touched={touched.password}
                    isTouched={true}
                />
            </article>
            <article style={{ display:'flex', flexDirection:'column', alignItems:'start', marginLeft:'40px' }}>
                <p onClick={()=> navigation.push('/recuperar-contraseña', 'forward', 'replace')} style={{ textAlign:'left', marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}> Recuperar contraseña </p>
            </article>
            <article style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <IonButton type='submit' disabled={!(dirty && isValid)} color={!(dirty && isValid) ? 'medium' : "orange"} style={{ width:300, marginTop:20 }}>Ingresar</IonButton>
            </article>
        </Form>
      )}
      </Formik>
  </div>
)};