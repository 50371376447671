import { useEffect, useRef } from 'react';

//ionic
import { IonAvatar, IonImg, IonIcon, IonText, IonProgressBar, IonPopover, useIonRouter } from '@ionic/react';

//svg
import edit_button from '../../assets/edit_button.svg';

//css
import './Header.css';

//icons
import { logOutOutline } from 'ionicons/icons';

//recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';
import { userInfoState, userLevelsAtom } from 'recoil/User';
import { usePostMedia } from 'hooks/media/usePostMedia';
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { handleLevels } from 'helper/handleLevels';

export interface IHeadPopover {
  triger: string;
}
const HeaderPopOver: React.FC<IHeadPopover> = ({ triger }) => {
  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const [token, setToken] = useRecoilState(tokenState);
  const user = useRecoilValue(userInfoState);
  const [handlePostMedia, url] = usePostMedia();
  const level = (user && user.level) || 'Principiante';
  const points = (user && user.points) || 0;
  const lastName = user && user.lastName;
  const firstName = user && user.firstName;
  const key = user && user.key;
  const navigation = useIonRouter();
  const userLevels = useRecoilValue(userLevelsAtom);
  const maxPointsRef = useRef(1);

  const handleLogOut = () => {
    setToken({ jwtToken: null, userId: null });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('pw');
    localStorage.removeItem('device');
    localStorage.removeItem('client');
    localStorage.removeItem('googleToken');
    navigation.push('/login/signin', 'forward', 'replace');
    GoogleAuth.signOut();
    FacebookLogin.logout();
    popoverRef.current?.dismiss();
  };

  useEffect(() => {
    if (user.idUser && key) {
      handlePostMedia({ key });
    }
  }, [user, key, handlePostMedia]);

  useEffect(() => {
    const { maxPoints } = handleLevels(userLevels, Number(user.idLevel));
    maxPointsRef.current = maxPoints;
  }, [user]);

  const handleRedirect = () => {
    popoverRef.current?.dismiss();
    navigation.push('/suscripcion', 'forward', 'replace');
  };

  return (
    <IonPopover trigger={triger} arrow={false} size="auto" ref={popoverRef} className="h-popover">
      <div className="h-main-container">
        <div className="h-popover-content">
          <div style={{ display: 'flex', width: '100%' }}>
            {/* avatar */}
            <div className="progress-avatar">
              {/* [FALTA] integrar el progreso del usuario */}
              <IonProgressBar className="progress-bar" value={points / maxPointsRef.current}></IonProgressBar>
              <IonAvatar className="avatar h-ion-avatar" id={triger} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {url ? <IonImg src={url} /> : user.pictureURL ? <IonImg src={user.pictureURL} /> : <AddAvatar width="36px" height="36px" />}
              </IonAvatar>
            </div>

            {/* datos del usuario */}
            <div className="h-popover-content-child">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <IonText className="h-name">
                  {firstName} {lastName}
                </IonText>
                <IonText className="h-description">
                  {level} - {points} puntos
                </IonText>
                {user.idSuscription === 1 ? (
                  <IonText onClick={handleRedirect} className="h-level">
                    Cuenta Anual Básica
                  </IonText>
                ) : user.idSuscription === 2 ? (
                  <IonText onClick={handleRedirect} className="h-level">
                    Cuenta Anual Premium
                  </IonText>
                ) : user.idSuscription === 3 ? (
                  <IonText onClick={handleRedirect} className="h-level">
                    Cuenta Trimestral
                  </IonText>
                ) : (
                  <IonText onClick={handleRedirect} className="h-level">
                    Sin suscripción: Cuenta Gratis
                  </IonText>
                )}
              </div>
            </div>
          </div>

          {/* boton de edicion */}
          <IonImg
            style={{ width: '17.5px', height: '17.5px' }}
            src={edit_button}
            alt="edit button"
            onClick={() => {
              popoverRef.current?.dismiss();
              navigation.push('/perfil');
            }}
          />
        </div>

        <div className="h-divider"></div>

        <div className="h-log-out-container" onClick={handleLogOut}>
          <IonIcon className="h-log-out-icon" icon={logOutOutline} />
          <IonText className="h-log-out-text">Cerrar Sesión</IonText>
        </div>
      </div>
    </IonPopover>
  );
};

export default HeaderPopOver;
