import React, { useCallback, useState } from 'react'
import { IProgressByTimeObject } from 'services/interfaces/IProgress'
import { getProgressByTime } from 'services/progress'

export const useGetProgressByTime = () => {

    const [progressByTime, setProgressByTime] = useState<IProgressByTimeObject>({ exercises:0, sessions:0, trainings:0 })

    const handleGetProgressByTime = useCallback(
      (selected:number) => {
        let period = ""
        switch (selected) {
            case 0:
                period="today"
                break;
            case 1:
                period="week"
                break;
            case 2:
                period="month"
                break;
            case 3:
                period="max"
                break;
        
            default:
                period="today"
                break;
        }

        getProgressByTime({ period }).then( response =>{ 
            
            if(response.status === 200){
                if(period === "today"){
                    const data = response.data.today
                    setProgressByTime({
                        exercises: data.exercises,
                        sessions: data.sessions,
                        trainings: data.trainings
                    })
                }else if(period === "week"){
                    const data = response.data.week
                    setProgressByTime({
                        exercises: data.exercises,
                        sessions: data.sessions,
                        trainings: data.trainings
                    })
                }else if(period === "month"){
                    const data = response.data.month
                    setProgressByTime({
                        exercises: data.exercises,
                        sessions: data.sessions,
                        trainings: data.trainings
                    })
                }else if(period === "max"){
                    const data = response.data.max
                    setProgressByTime({
                        exercises: data.exercises,
                        sessions: data.sessions,
                        trainings: data.trainings
                    })
                }
            }
        })
      },
      [],
    )
    
  return {
    progressByTime,
    handleGetProgressByTime
  }
}
