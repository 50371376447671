import React, { useCallback, useState } from 'react'
import { getTrainingHistory } from 'services/progress'

export const useGetTrainingHistory = () => {

    const [trainingHistory, setTrainingHistory] = useState([])

    const handleGettrainingHistory = useCallback(
      (limit:number) => {
        getTrainingHistory().then( response => {

            if(response.status === 200){

                const data = response.data
                const arrayParsed:any = []

                const filteredKeys = Object.keys(data).slice(0,limit)

                filteredKeys.forEach( (key )=>{
                    arrayParsed.push({ name: key, data: data[key]})
                })
                setTrainingHistory(arrayParsed)
            }
        })
      },
      [],
    )
    
  return {
    trainingHistory,
    handleGettrainingHistory
  }
}
