import { IonContent, IonPage } from '@ionic/react';

//components
import Header from 'components/Header/Header';
import HeaderPopOver from 'components/Header/HeaderPopOver';
import { SeleccionAyuda } from './components/SeleccionAyuda';
import WelcomeTitle from 'components/Home/WelcomeTitle/WelcomeTitle';

export const AyudaMain: React.FC = () => {
  return (
    <IonPage>
      <Header triger='help-open' />
      <HeaderPopOver triger='help-open' />
      <IonContent>
        <WelcomeTitle className="ion-grid-title-container-black" classNameSub="ion-text-question-black" subtitle="¿En qué te podemos ayudar hoy?" />
        <SeleccionAyuda />
      </IonContent>
    </IonPage>
  );
};
