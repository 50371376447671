import tiro from 'assets/siluetas/tiro.svg';
import defensa from 'assets/siluetas/denfensa.svg';
import pase from 'assets/siluetas/pase.svg';
import tecnica from 'assets/siluetas/tecnica-individual.svg';
import tactica from 'assets/siluetas/tactica-individual.svg';

export const handleProgressLearningImg = (idBasis:Number)=>{ 

    switch (idBasis) {
        case 1:
            return tiro
        case 2:
            return defensa
        case 3:
            return pase
        case 4:
            return tactica
        case 5:
            return tecnica
    
        default:
            return ''
    }
}