import { ITrainingTimes } from 'interfaces/IEntraniento'
import React, { useCallback, useState } from 'react'
import { getTrainingTimes } from 'services/quickTraining'

export const useGetTrainingTimes = () => {

    const [ trainingTimes, setTrainingTimes] = useState<ITrainingTimes>({
        withHoopWithoutPartner: 0,
        withHoopWithPartner: 0,
        withoutHoopWithoutPartner: 0,
        withoutHoopWithPartner: 0
    })

    const handleGetTrainingTimes = useCallback(
      async() => {
        await getTrainingTimes().then( response =>{ 

            if(response.status === 200){
                
                const data = response.data
                setTrainingTimes({...data})
            }
        })
        .catch( error => {
            throw new Error('Internal Error')
        })
      },
      [],
    )
    
  return {
    trainingTimes,
    handleGetTrainingTimes
  }
}
