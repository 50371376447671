import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { exerciseMinutesDoneAtom, userExerciseAtom } from 'recoil/exercisesAtom';
import { postExercisePoints } from 'services/entrenamiento';

export const usePostUserExercisePoint = () => {
  const minuteExercise = useRecoilValue(exerciseMinutesDoneAtom);
  const userExerciseId = useRecoilValue(userExerciseAtom);

  const handlePostUserExercisePoint = useCallback(() => {
    const minuteExerciseParsed = minuteExercise.filter((element) => element.minutes !== 0);
    if (minuteExerciseParsed && minuteExerciseParsed.length !== 0) postExercisePoints({ trainings: minuteExerciseParsed, idUserTraining: userExerciseId.idUserTraining });
  }, [minuteExercise, userExerciseId.idUserTraining]);

  return {
    handlePostUserExercisePoint,
  };
};
