import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { IonButton, IonIcon, isPlatform, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react'
import GoogleIcon from 'assets/md-googlegoogle-icon.svg'
import { useAuthWithGoogle } from 'hooks/auth/useAuthWithGoogle';
import { useEffect } from 'react';
import { Device } from '@awesome-cordova-plugins/device';

interface IGoogleButton {
  handleLoginGoogle: ()=> void
}

export const GoogleButton = ({handleLoginGoogle}:IGoogleButton) => {
  


  return (
      <IonButton onClick={handleLoginGoogle} color="medium" fill='outline' style={{ color:'#6A6A6A', width:300 }}>
          <IonIcon slot="start" icon={GoogleIcon}></IonIcon>
          Continuar con Google
      </IonButton>
  )
}
