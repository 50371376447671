import { atom } from 'recoil';

export const exerciseFinishedAtom = atom({
  key: 'exerciseFinished',
  default: false,
});

export const multipleExerciseFinishedAtom = atom({
  key: 'multipleExerciseFinished',
  default: false,
});
