import { useEffect } from 'react';
//ionic
import { IonText, IonGrid, IonRow, IonImg, IonRouterLink, IonIcon, useIonRouter, IonAvatar } from '@ionic/react';

//css
import '../../pages/perfil/perfil.css';

//icons
import { logOutOutline } from 'ionicons/icons';
import next_arrow from '../../assets/next_arrow.svg';

//recoil
import { userInfoState } from 'recoil/User';
import { tokenState } from 'recoil/tokenAtom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

//components
import { AddAvatar } from 'components/Icons/avatars/AddAvatar';
import { AvatarSelector } from 'pages/login/components/AvatarSelector';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';

const helper = [
  {
    text: 'Mi Cuenta',
    href: '/cuenta',
  },
  {
    text: 'Mi Suscripción',
    href: '/suscripcion',
  },
  {
    text: 'Mis Favoritos',
    href: '/favoritos',
  },
  {
    text: 'Mis Sesiones en Vivo',
    href: '/mis-sesiones',
  },
];

export const Menu: React.FC = () => {
  const user = useRecoilValue(userInfoState);

  const firstName = user && user.firstName;
  const lastName = user && user.lastName;
  const email = user && user.email;
  const idUser = user && user.idUser;
  const key = user && user.key;

  const [handlePostMedia, url] = usePostMedia();

  const navigation = useIonRouter();
  const setToken = useSetRecoilState(tokenState);

  const handleLogOut = () => {
    setToken({ jwtToken: null, userId: null });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('pw');
    localStorage.removeItem('device');
    localStorage.removeItem('client');
    localStorage.removeItem('googleToken');
    GoogleAuth.signOut()
    FacebookLogin.logout()
    navigation.push('/login/signin', 'forward', 'replace');
  };

  useEffect(() => {
    if (idUser && key) handlePostMedia({ key });
  }, [idUser, key, handlePostMedia]);

  return (
    <IonGrid className="p-ion-grid">
      <IonRow>
        <IonAvatar style={{ width: 80, height: 80 }} id="menu-edit">
        {
          url ?
            <IonImg src={url} />
          : user.pictureURL ?
          <IonImg src={user.pictureURL} />
          :
          <AddAvatar />
        }
        </IonAvatar>
        <AvatarSelector trigger={'menu-edit'} isEdit={true} />
      </IonRow>
      <IonRow className="p-ion-row">
        <IonText className="p-name">
          {firstName} {lastName}
        </IonText>
      </IonRow>

      <IonRow className="p-ion-row">
        <IonText className="p-email">{email}</IonText>
      </IonRow>

      <div className="p-rows-container">
        {helper.map((item, index) => {
          return (
            <IonRow key={index} style = {{width: "100%"}}>
              <IonRouterLink routerLink={item.href} className="p-router-link">
                <div className="p-go-to-container">
                  <IonText className="p-go-to-text">{item.text}</IonText>
                  <IonImg alt="arrow" src={next_arrow} className="p-arrow" />
                </div>
              </IonRouterLink>
            </IonRow>
          );
        })}

        <div className="p-log-out-container" onClick={handleLogOut}>
          <IonIcon className="p-log-out-icon" icon={logOutOutline} />
          <IonText className="p-log-out-text">Cerrar Sesión</IonText>
        </div>
      </div>
    </IonGrid>
  );
};
