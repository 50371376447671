import { IonGrid, IonText } from "@ionic/react";
import './moduleHeader.css'
import { SingleModuleHeader } from "./SingleModuleHeader";

interface IModuleHeader{
    module: any
 }
export const ModuleHeader: React.FC <IModuleHeader> = ({ module })=>{ 

    return (
        <IonGrid className="ion-grid-title-container-black-module">
          <SingleModuleHeader module={module} index={0} />
          <IonText style={{ color:'#E6E6E7' }} > Recuerda reveer y practicar cada técnica para incorporar mejor el aprendizaje. </IonText>
        </IonGrid>
      );
}