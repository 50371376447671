import { IonText } from "@ionic/react";
import NextSingleCampus from "components/Home/NextCampus/NextSingleCampus";
import { useGetCampus } from "hooks/campus/useGetCampus";
import { useEffect, useState } from "react";

export const CampusContent: React.FC = ()=>{

    const [limit, setLimit] = useState(2);
    const [campus, handleGetCampus] = useGetCampus();
  
    useEffect(() => {
      handleGetCampus({ limit });
    }, [handleGetCampus, limit]);

    return(
        <>
        {campus &&
            campus.length !== 0 &&
            campus.map((item, index) => {
            return <NextSingleCampus key={index} campus={item} />;
            })
        }
        </>
    )
}