import axios from './BaseConfig';

export interface NotesBody {
  note: string | null;
}

export const postNotes = async (idExcercise: number, body: NotesBody) => {
  try {
    const res = await axios.post(`/exercises/${idExcercise}/notes`, body);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};

export const putNotes = async (idExcercise: number, body: NotesBody, idNote: number) => {
  try {
    const res = await axios.put(`/exercises/${idExcercise}/notes/${idNote}`, body);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};

export const deleteNotes = async (idExcercise: number, idNote: number) => {
  try {
    const res = await axios.delete(`/exercises/${idExcercise}/notes/${idNote}`);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};
