import { useState, useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonIcon, IonButton } from '@ionic/react';

//icons
import close_modal from '../../assets/close_modal.svg';

//css
import './progress-component.css';

//formik
import { Formik, Form, Field } from 'formik';
import { usePutTrainingGoals } from 'hooks/progress/usePutTrainingGoals';

interface IGoalsModal {
  goal: string | null;
  id: number;
  isOpen: boolean;
  handleClose: () => void;
}

const field_styles = { minHeight: '160px', outline: 'none', borderRadius: '20px', border: '1px solid #626875', padding: '10px 12px', width: '100%', marginTop: '4px' };
const button_styles = { width: 300, fontWeight: 700, marginTop: '42px', fontFamily: 'Lato', fontStyle: 'normal', fontSize: '14px', lineHeight: '20px', textAlign: 'center', letterSpacing: '0.004em' };

function GoalsModal({ goal, isOpen, handleClose, id }: IGoalsModal) {
  const modalRef = useRef(null);

  const counterFormatter = (inputLength: number) => `${inputLength}/500`;
  const { handlePutTrainingGoals } = usePutTrainingGoals()

  const [inputLength, setInputLength] = useState<number>(0);

  return (
    <IonModal ref={modalRef} isOpen={isOpen} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="p-icon-row">
          <IonImg onClick={handleClose} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonText className="p-notes-title">Mis objetivos de rendimiento</IonText>
        <Formik
          initialValues={{
            goal: goal ?? "",
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            const goals = values.goal
            handlePutTrainingGoals(id, goals)
            handleClose();
          }}
        >
          {({ dirty, isValid, values, setFieldValue }: any) => {
            return (
              <Form>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Field
                    value={values.goal}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFieldValue('goal', event.target.value);
                      setInputLength(event.target.value.length);
                    }}
                    style={field_styles}
                    id={'goal'}
                    name={'goal'}
                    type={'text'}
                    as="textarea"
                    maxLength={500}
                    placeholder="Este mes me gustaria..."
                  />
                  <IonText className="p-counter">{counterFormatter(inputLength)}</IonText>
                </div>
                <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IonButton type="submit" color={!(dirty && isValid) ? 'medium' : 'orange'} fill="solid" style={button_styles} disabled={!(dirty && isValid)}>
                    Guardar objetivo
                  </IonButton>
                </article>
              </Form>
            );
          }}
        </Formik>
      </IonGrid>
    </IonModal>
  );
}

export default GoalsModal;
