import { useState } from 'react';

//iconic
import { IonGrid, IonText, IonButton } from '@ionic/react';

//css
import './cuenta-gratis.css';

//components
import SubscribeModal from '../Modales/SubscribeModal/SubscribeModal';
import { StripeModalPaymentSubscription } from 'components/forms/subscription/StripeModalPayment';
import { TermsConditionModal } from 'components/modal/TermsConditionModal';
import { PolishPrivacyModal } from 'components/modal/PolishPrivacyModal';

export const CuentaGratis: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalPayment, setModalPayment] = useState(false);

  return (
    <>
      <IonGrid className="cg-container">
        <IonText className="cg-title">Cuenta Gratis</IonText>
        <IonText className="cg-subtitle">• Acceso restringido al Programa de Aprendizaje.</IonText>

        <div className="cg-subscribe-container">
          <IonText className="cg-subscribe-title">¡Suscríbete a la app!</IonText>
          <IonText className="cg-subscribe-subtitle">Tendrás acceso ilimitado al Programa de Aprendizaje y al Entrenamiento Rápido y aún más beneficios.</IonText>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <IonButton type="submit" fill="clear" className="cg-button" onClick={() => setShowModal(true)}>
            Suscribirme
          </IonButton>
        </div>

        <IonText className="cg-subscribe-text">La suscripción se paga una vez por año. Tiene renovación automática.</IonText>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <IonText id="terms-gratis-modal" className="cg-terms">
            Términos y condiciones
          </IonText>
          <span className="cg-terms" style={{ textDecoration: 'none', margin: '0 4px' }}>
            -
          </span>
          <IonText id="polish-gratis-modal" className="cg-terms">
            Política de Privacidad
          </IonText>
        </div>
      </IonGrid>
      <TermsConditionModal triger="terms-gratis-modal" />
      <PolishPrivacyModal triger="polish-gratis-modal" />
      <SubscribeModal setShowSub={setModalPayment} showModal={showModal} setShowModal={setShowModal} />
      <StripeModalPaymentSubscription isOpen={showModalPayment} setIsOpen={setModalPayment} />
    </>
  );
};
