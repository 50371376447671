import { ITrainingTimes } from "interfaces/IEntraniento";

const compareTimes = (firstTime:Number, secondTime:Number) => { 

    let maxTime:Number = 0
    if( firstTime < secondTime){
        maxTime = secondTime
    }else{ 
        maxTime = firstTime
    }

    return maxTime

}

const handleParterTime = ( duration:Number, typePartner:Number) =>{ 

    if(typePartner >= duration){
        return true
    }else{
        return false
    }
}

export const handleHoop = (duration:Number, data:ITrainingTimes)=>{ 

    let withHoop = compareTimes(data.withHoopWithPartner, data.withHoopWithoutPartner)
    let withoutHoop = compareTimes(data.withoutHoopWithPartner, data.withoutHoopWithoutPartner)
    
    let available = { 
        withHoop: false,
        withoutHoop: false,
    }

    if(withHoop >= duration && duration !== 0){
        available.withHoop = true
    }

    if(withoutHoop >= duration && duration !== 0){
        available.withoutHoop = true
    }

    return available
}

export const handlePartner = (duration:Number, typeHoop:String, data:ITrainingTimes) =>{ 

    let withHoopWithPartner = data.withHoopWithPartner
    let withHoopWithoutPartner = data.withHoopWithoutPartner
    let withoutHoopWithPartner = data.withoutHoopWithPartner
    let withoutHoopWithoutPartner = data.withoutHoopWithoutPartner
    let available = {
        withPartner: false,
        withoutPartner: false
    }

    if(typeHoop === 'yes'){
        available.withPartner = handleParterTime(duration, withHoopWithPartner)
        available.withoutPartner = handleParterTime(duration, withHoopWithoutPartner)
    }else if(typeHoop === 'no'){ 
        available.withPartner = handleParterTime(duration, withoutHoopWithPartner)
        available.withoutPartner = handleParterTime(duration, withoutHoopWithoutPartner)
    }else{
        available.withPartner = false
        available.withoutPartner = false
    }

    return available
}

export const maxTime = (data:ITrainingTimes)=>{ 

    let withHoopWithPartner = Number(data.withHoopWithPartner)
    let withHoopWithoutPartner = Number(data.withHoopWithoutPartner)
    let withoutHoopWithPartner = Number(data.withoutHoopWithPartner)
    let withoutHoopWithoutPartner = Number(data.withoutHoopWithoutPartner)

    let maxTime = Math.max(withHoopWithPartner,withHoopWithoutPartner, withoutHoopWithPartner, withoutHoopWithoutPartner)

    return maxTime

}