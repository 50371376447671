import { userLevel } from "recoil/User";

export const handleLevels = (levels:userLevel[], idLevel:number )=>{ 

    let maxPoints = 0
    let minPoints = 0
    let level = ""

    levels.length !== 0 &&
    levels.forEach( (element,idx) =>{ 
        if(element.idLevel === idLevel){
            maxPoints = element.maxPoints
            minPoints = element.minPoints

            if(levels.length - 1 === idx){
                level = levels[idx].name
            }else{
                level = levels[idx + 1].name
            }
        }
    })

    return{ 
        level,
        maxPoints,
        minPoints
    }

}