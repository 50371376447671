import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, IonIcon } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './ejercicios-insuficientes.css';
import { useDeleteUser } from 'hooks/users/useDeleteUser';
import { useRecoilValue } from 'recoil';
import { userInfo } from 'os';
import { userInfoState } from 'recoil/User';
import { checkmarkCircle } from 'ionicons/icons';
import { LoadingScreen } from 'components/loading/LoadingScreen';
import { useOpenModal } from 'hooks/modal/useOpenModal';

interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

function CuentaBorradaModal({ isOpen, handleClose }: IModal) {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  return (
    <IonModal isOpen={isOpen} ref={modal} className="ul-modal" onDidDismiss={dismiss}>
      <IonGrid className="wrapper">
      <IonRow className="ul-icon-row" style={{ marginTop: 0 }}>
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
      </IonRow>
      <div style={{ padding:10, width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <article>
              <IonText style={{ fontSize:22, fontWeight:700, fontFamily:'Poppins' }}> Borraste tu cuenta </IonText>
          </article>
          <article style={{ marginTop:26, marginBottom:26 }}>
              <IonIcon style={{ width:90, height:90, fill:'#168404' }} icon={checkmarkCircle}  />
          </article>
          <article style={{ textAlign:'center' }}>
              <IonText color={'dark'} style={{ fontSize:16, fontWeight:400 }}>
              ¡Gracias por usar la app Campus Huevo Sanchez! Podrás volver crear una cuenta cuando quieras y seguir explorando nuestro contenido en basketball.
              </IonText>
          </article>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default CuentaBorradaModal;
