import { useState } from 'react';

//css
import './favorites-block.css';

//interfaces
import { IFavorite } from 'interfaces/IFavorite';

//ionic
import { IonText, IonIcon, IonButton } from '@ionic/react';

//icons
import { documentText, heart, playCircleOutline, timeOutline } from 'ionicons/icons';

//components
import NotesModal from './NotesModal';

//hooks
import { useDeleteUserFavorite } from 'hooks/favorites/useDeleteUserFavorite';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

interface IFavoriteData {
  favorite: IFavorite;
}

const Favorite: React.FC<IFavoriteData> = ({ favorite }) => {
  const [show, setShow] = useState(false);

  const user = useRecoilValue(userInfoState);
  const { idUser } = user;
  const { handleDeleteUserFavorite } = useDeleteUserFavorite();

  const { nameExercise, nameModule, idExercise, note, idNote } = favorite;
  return (
    <div className="f-child">
      <IonText className="f-module-name">{nameModule}</IonText>
      <div className="f-excercise-container">
        <IonText className="f-excercise-name">{nameExercise}</IonText>
        {/* <div className="flex" style={{ alignItems: 'center' }}>
          <IonIcon icon={timeOutline} />
          <IonText className="f-time">3:11</IonText>
        </div> */}
      </div>

      <div style={{ marginTop: '16px' }} className="flex">
        <IonButton className="f-play-button" fill="clear">
          <IonIcon icon={playCircleOutline} style={{ marginRight: '4px' }} />
          <IonText className="f-button-text">Reproducir</IonText>
        </IonButton>

        <div className="flex f-icon-container">
          <div className="f-circle" style={{ marginRight: '5px' }}>
            <IonIcon icon={heart} className="f-icon" onClick={() => handleDeleteUserFavorite(Number(idUser), idExercise)} />
          </div>
          <div className="f-circle" onClick={() => setShow(true)}>
            <IonIcon icon={documentText} className="f-icon" />
          </div>
        </div>
      </div>
      <NotesModal showModal={show} setShowModal={setShow} note={note} idNote={idNote} idExcercise={idExercise} />
    </div>
  );
};

export default Favorite;
