import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './ejercicios-insuficientes.css';

interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

function ModalEjerciciosInsuficientes({ isOpen, handleClose }: IModal) {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  return (
    <IonModal isOpen={isOpen} ref={modal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row" style={{ marginTop: 0 }}>
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="ei-container">
          <IonText className="ei-well-done">Ejercicios insuficientes</IonText>
          <IonText className="ei-subtitle">No hay suficientes ejercicios para entrenar según los filtros que seleccionaste.</IonText>

          <div className="ei-child-container">
            <IonText className="ei-child-title">Recomendamos:</IonText>
            <IonText className="ei-child-subtitle">Prueba seleccionando otros filtros o sigue avanzando con tu Programa de Aprendizaje para desbloquear todavía más ejercicios.</IonText>
          </div>

          <IonButton type="submit" fill="clear" style={{ marginTop: '24px' }} className="ei-button" onClick={dismiss}>
            Entendido
          </IonButton>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}

export default ModalEjerciciosInsuficientes;
