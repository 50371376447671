import { IonIcon, IonText } from "@ionic/react"
import { checkmarkCircle } from "ionicons/icons"
import './subscriptionSuccesful.css';

export const SubscriptionSuccesfulCancel : React.FC = ()=>{ 


    return(
            <div className="checkout-sucess-subscription">
                <article style={{ width:'80%', textAlign:'center'}}>
                    <IonText style={{ fontSize:22, fontWeight:700, fontFamily:'Poppins' }}>Cancelaste la renovación de tu suscripción</IonText>
                </article>
                <article style={{ marginTop:26, marginBottom:26 }}>
                    <IonIcon style={{ width:90, height:90, fill:'#168404' }} icon={checkmarkCircle}  />
                </article>
                <article style={{ marginBottom:92, width:300, textAlign:'center' }}>
                    <IonText color={'dark'} style={{ fontSize:16, fontWeight:400 }}>
                    Al finalizar tu período de suscripción perderás acceso a todo el contenido pago de la app.
                    </IonText>
                </article>
            </div>
    )
}