import { StripeError } from "@stripe/stripe-js";
import { atom } from "recoil";

interface IStripePaymentError { 
    message?:string;
}

export interface IStripePaymentErrorAPI { 
    code: string | null,
    declineCode: string | null
}

export const stripeErrorState = atom({
    key: 'stripeError',
    default:{
        message: ''
    } as StripeError,
  });

export const stripeErrorAPI = atom({
    key: 'stripeErrorApi',
    default:{
        code: null,
        declineCode: null
    } as IStripePaymentErrorAPI,
  });