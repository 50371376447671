import { useIonRouter } from '@ionic/react'
import { useGetUserById } from 'hooks/users/useGetUserById'
import React, { useCallback } from 'react'
import { postSessionByIdUser } from 'services/sessions'

export const usePostSessions = () => {

    const navigation = useIonRouter()
    const { handleGetUserById } = useGetUserById()

    const handlePostSessions = useCallback(
      async(id:string, idSession:string, email:string) => {
        
        const result = await postSessionByIdUser(id, idSession, email)

        if(result?.status === 200){
            navigation.push('/sesion-checkout', 'forward', 'push')
            handleGetUserById(Number(id))
        }
      },
      [handleGetUserById, navigation],
    )
    

  return {
    handlePostSessions
  }
}
