import { IonText } from "@ionic/react"
import { IFundamentals } from "interfaces/IEntraniento"
import { useState } from "react"

interface IFundamentoCard { 
    fundamental: IFundamentals,
    handleSelection: (fundamental:IFundamentals)=> void,
    index: number,
    exists: any,
    limitSelections: Number
}

export const FundamentosCard: React.FC <IFundamentoCard>= ( {fundamental, handleSelection, index, exists, limitSelections} )=>{ 
    const [isClicked, setisClicked] = useState(false)
    return(
            <div 
                onClick={()=> {
                    if(limitSelections !== exists.length && fundamental.minutes !== 0){
                        setisClicked(!isClicked)
                    }else{
                        setisClicked(false)
                    }
                    if(fundamental.minutes !== 0){
                        handleSelection(fundamental)
                    }
                }
            } 
            className={`flex ${isClicked ? 'ce-border-fu' : ''}`} style={{ opacity: fundamental.minutes === 0 ? 0.2 : 1, flexDirection: 'column', borderRadius: '4px', marginTop: '16px', boxSizing:'border-box', width: '47%'}} key={index}>
                <div className="fundamentos-img" style={{ backgroundImage: `url(${fundamental.img})` , width: '100%'}}></div>
                <IonText className="fundamentos-text" style={{ width: '100%' }} >{fundamental.moduleName}</IonText>
            </div>
    )
}