import { useEffect } from 'react';

//css
import './NextCampus.css';
import { usePostMedia } from 'hooks/media/usePostMedia';
import { IonText } from '@ionic/react';

interface Campus {
  idCampus: number;
  address: string;
  contactInfo: string;
  key: string;
  eventDate: number;
  title:string;
}
interface NextSingleCampusProps {
  campus: Campus;
}

const NextSingleCampus: React.FC<NextSingleCampusProps> = ({ campus }) => {
  const [handlePostMedia, url] = usePostMedia();
  const key = campus && campus.key;
  useEffect(() => {
    if(key)handlePostMedia({ key });
  }, [handlePostMedia, key]);
  return (
    <div>
      <IonText style={{ fontSize:18, fontWeight:600, fontFamily:'Poppins' }}> Campus {campus.title}</IonText>
      <img onClick={() => window.location.assign('http://www.huevosanchez.com.ar')} src={url} alt="campus" className="nc-img" />
    </div>
    )
};

export default NextSingleCampus;
