import { useState, useEffect } from 'react';

//ionic
import { IonText, IonImg } from '@ionic/react';

//css
import '../../pages/aprendizaje/bloque/bloque-aprendizaje.css';

//componentes
import TrainerModal from './TrainerModal';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';

interface ITrainerBox {
  coach: {
    idCoachBasis: number;
    idBasis: number;
    idCoach: number;
    fullName: string;
    role: string;
    description: string;
    key: string;
  };
  showHeader: boolean;
}

export const TrainerBox: React.FC<ITrainerBox> = ({ coach, showHeader }) => {
  const [showModal, setShowModal] = useState(false);

  const { fullName, role, key } = coach;

  const [handlePostMedia, url] = usePostMedia();

  useEffect(() => {
    if(key)handlePostMedia({ key });
  }, [handlePostMedia, key]);

  return (
    <>
      { showHeader && <IonText className="ba-incharge-of">A cargo de:</IonText> }

      <div className="ba-trainer-box" onClick={() => setShowModal(true)}>
        <IonImg src={url} className="ba-avatar" />

        <div className="ba-trainer-info">
          <IonText className="ba-trainer-name">{fullName}</IonText>
          <IonText className="ba-trainer-role">{role}</IonText>
        </div>
      </div>

      <TrainerModal coach={coach} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
