import * as yup from 'yup';

export const loginValidation = yup.object().shape({
    email: yup
        .string()
        .email('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com')
        .min(1)
        .required('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com'),
    password: yup
        .string()
        .min(8, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[0-9]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[a-z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[A-Z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .required('La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .nullable()
})

export const recoverValidation = yup.object().shape({
    email: yup
        .string()
        .email('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com')
        .min(1)
        .required('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com')
})

export const resetValidation = yup.object().shape({
    code: yup
        .string()
        .min(1)
        .required('Ingresa un email con formato válido, por ejemplo: nombre@ejemplo.com'),
    newPassword: yup
        .string()
        .min(8, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[0-9]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[a-z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[A-Z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .required('La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .nullable(),
    rtNewPassword: yup
        .string()
        .min(8, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[0-9]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[a-z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .matches(/[A-Z]/, 'La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .required('La contraseña debe tener un mínimo de 8 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.')
        .nullable()
})

