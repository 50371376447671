import { useCallback, useState } from 'react';

//services
import { getModulesById } from 'services/aprendizaje';

interface Module {
  idModule: number;
  name: string;
  idBasis: number;
  createdAt: number;
  deletedAt: number | null;
  rating: number | null;
  note: string | null;
  totalExercises: number;
  completedExercises: number;
}

export const useGetModuleByID = () => {
  const [ModuleById, setModuleById] = useState<Module | null>(null);

  const handleGetModuleById = useCallback(async (id: number, idModule: number) => {
    const response = await getModulesById(id, idModule);

    if (response?.status === 200) {
      const data = response.data;
      setModuleById(data);
    }
  }, []);

  return {
    ModuleById,
    handleGetModuleById,
  };
};
