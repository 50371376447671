import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { IonButton, IonIcon, isPlatform, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react'
import GoogleIcon from 'assets/md-googlegoogle-icon.svg'
import { useAuthWithGoogle } from 'hooks/auth/useAuthWithGoogle';
import { useEffect } from 'react';
import { Device } from '@awesome-cordova-plugins/device';
import { logoApple } from 'ionicons/icons';

interface IAppleButton {
  handleLoginApple: ()=> void
}

export const AppleButton = ({handleLoginApple}:IAppleButton) => {
  
  return (
      <IonButton onClick={handleLoginApple} color="medium" fill='outline' style={{ color:'#6A6A6A', width:300 }}>
          <IonIcon slot="start" icon={logoApple}></IonIcon>
          Continuar con Apple
      </IonButton>
  )
}