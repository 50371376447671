import { useIonRouter } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { toastState } from 'recoil/ToastAtom'
import { tokenState } from 'recoil/tokenAtom'
import { IAuthWithApple } from 'services/interfaces/AuthLogin'
import { authWithApple } from 'services/login'
import CloseSvg from 'assets/md-close.svg'

export const UseAuthWithApple = () => {

    const setToken = useSetRecoilState(tokenState)
    const navigation = useIonRouter()
    const setToast = useSetRecoilState(toastState)

    const handleAuthWithApple = useCallback(
      (body:IAuthWithApple) => {
        authWithApple(body).then( response => { 
            const result = response.data
            if(result){
                setToken({
                    jwtToken: result.jwtToken,
                    userId: result.profile.idUser
                })
                localStorage.setItem("token", result.jwtToken)
                navigation.push('/tabs/home', 'forward', 'replace')
            }else{
                setToast({
                  isOpen:true,
                  color:"danger",
                  message:"Ya existe un usuario con este email",
                  icon:alertCircle,
                  button:[{
                    icon:CloseSvg,
                    side:'end',
                    role:'cancel',
                    cssClass:'toast-button'
                  }]
                })
              }
        }).catch( e => alert('Ocurrio un problema al iniciar sesión'))
      },
      [],
    )
    
  return {
    handleAuthWithApple
  }
}
