import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ChartOptions } from 'chart.js';
import { useGetTrainingChart } from 'hooks/progress/useGetTrainingChart';
import { maxValueGraph } from 'helper/handleMaxGraph';
import { IonText } from '@ionic/react';
import { useGetTrainingMonth } from 'hooks/progress/useGetTrainingMonth';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const BarChart: React.FC = () => {

  const { handleGetTrainingChart, trainingChart } = useGetTrainingChart()
  const { handleGetTrainingMonth, trainingMonth } = useGetTrainingMonth()

  const [filterSelected, setfilterSelected] = useState('semana')

  useEffect(() => {
    if(filterSelected === 'semana'){
      handleGetTrainingChart()
    }else{
      handleGetTrainingMonth()
    }
  }, [filterSelected])

  const data = {
    labels: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    datasets: [
      {
        data: trainingChart.length !== 0 ? trainingChart.map(element => element.minutes) : [0, 0, 0, 0, 0],
        backgroundColor: '#FFE1D4',
        borderColor: '#FFE1D4',
        borderWidth: 1,
      },
    ],
  };

  const dataMonth = {
    labels: ['Sem 1', 'Sem 2', 'Sem 3', 'Sem 4', 'Sem 5'],
    datasets: [
      {
        data: trainingMonth.length !== 0 ? trainingMonth.map((element:any) => Number(element.minutes)) : [0, 0, 0, 0, 0],
        backgroundColor: '#FFE1D4',
        borderColor: '#FFE1D4',
        borderWidth: 1,
      },
    ],
  };
  
  const getOrCreateTooltip = (chart: { canvas: { parentNode: { querySelector: (arg0: string) => any; appendChild: (arg0: any) => void; }; }; }) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };
  
  const externalTooltipHandler = (context: { chart: any; tooltip: any; }) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: { lines: any; }) => b.lines);
  
      const tableHead = document.createElement('thead');
  
      const tableBody = document.createElement('tbody');
      const index = tooltip.dataPoints[0].dataIndex

      bodyLines.forEach((body: string, i: number) => {
        let dataChart = filterSelected === 'semana' ? trainingChart : filterSelected === 'mes' ? trainingMonth : trainingChart
        const label = `${body} min - ${dataChart[index].data}`
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'white';
        tr.style.borderWidth = "0";
        tr.style.borderColor = "white";
        tr.style.border = 'none'
  
        const td = document.createElement('td');
        td.style.borderWidth = "0";
        td.style.fontSize = "12px";
        td.style.color = "black";
        td.style.padding = "5px";
        const text = document.createTextNode(label);

        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.background = '#fafafa'
    tooltipEl.style.left = positionX + tooltip.caretX + 10 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 10 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  const handleSelect = (type:string)=>{ 
    if(type === 'semana'){
      setfilterSelected('semana')
    }else if(type === 'mes'){
      setfilterSelected('mes')
    }else{
      setfilterSelected('semana')
    }
  }
  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        min:0,
        max: maxValueGraph(filterSelected === 'semana' ? trainingChart : filterSelected === 'mes' ? trainingMonth : trainingChart) ,
        ticks: {
          color: '#F3F3F3',
        },
        
      },
      x: {
        ticks: {
          color: '#F3F3F3',
          font: {
            size: 18,
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip:{
        enabled: false,
        backgroundColor:'#FFF',
        bodyColor:'#000',
        external: externalTooltipHandler
       }
    },
    elements: {
      bar: {
        // width: 24,
        borderRadius: 4,
      },
    },
    responsive: true,
  };

  const styles = { background: '#393A3F', borderRadius: '0px 0px 4px 4px', width: '100%', padding: "8px 0" };

  return (
     <div>
      <article style={{ width:'100%', background:'#393A3F', padding:'0.5rem'}}>
        <IonText onClick={()=>handleSelect('semana')} color={filterSelected === 'semana' ? 'orange' :'white'} style={{ fontSize: 14, fontWeight:400 }}> Semana </IonText>
        <IonText color={'white'} style={{ fontSize: 14, fontWeight:400 }}> | </IonText>
        <IonText onClick={()=>handleSelect('mes')} color={filterSelected === 'mes' ? 'orange' :'white'} style={{ fontSize: 14, fontWeight:400 }}> Mes </IonText>
      </article>
       <Bar key={filterSelected} data={filterSelected === 'semana' ? data : filterSelected === 'mes' ? dataMonth : data} style={styles} options={options} />
     </div>
  )
};

export default BarChart;
