import { useCallback, useState } from 'react';

//services
import { postMedia } from 'services/media';

//interface
import { MediaBody } from 'services/interfaces/MediaBody';

export const usePostMedia = (): [(params: MediaBody) => Promise<void>, string] => {
  const [url, setUrl] = useState('');
  const handlePostMedia = useCallback(async (params: MediaBody) => {
    const resp = await postMedia(params);
    setUrl(resp);
  }, []);

  return [handlePostMedia, url];
};
