//ionic
import { IonImg, IonText } from '@ionic/react';

//css
import './quickTrainingHeader.css';

//assets
import { IFundamentals } from 'interfaces/IEntraniento';
import { IEntrenamiento } from 'recoil/TrainingAtom';
import { handleIconHoop, handleIconPartner } from 'helper/handleIconQuickTraining';

interface IQuickTraining{ 
  basis: IFundamentals[],
  training: IEntrenamiento
}

export const QuickTrainingHeader: React.FC <IQuickTraining> = ({ basis, training }) => {
  return (
    <div className="qt-container-header">
      <div className="qt-content-container">
        <div className="qt-content-first">
          <IonText color={'light'} style={{ fontSize: 16, fontWeight: 700 }}>
            Entrenamiento de {Number(training.duracion)} min
          </IonText>
          <div className="qt-content-icons">
            <IonImg src={handleIconHoop(training.tieneAro)} />
            <IonImg src={handleIconPartner(training.tieneCompanero)} />
          </div>
        </div>
        <div className="qt-content-second">
          <IonText color={'light'} style={{ fontSize: 14, fontWeight: 400 }}>
            {basis && basis.map( fundamental => fundamental.moduleName).join(', ')}
          </IonText>
        </div>
      </div>
    </div>
  );
};
