import { useCallback, useState } from 'react';

//recoil
import { useSetRecoilState } from 'recoil';
import { notesStatusAtom } from 'recoil/NotesStatusAtom';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';

//services
import { getUserFavorites } from 'services/bookmarks';

//interface
import { FavoritesLimit } from 'services/interfaces/FavoritesLimit';

export const useGetUserFavorites = () => {
  const [favorites, setFavorites] = useState([]);

  const setNotesStatus = useSetRecoilState(notesStatusAtom);
  const setFavoriteStatus = useSetRecoilState(favoriteStatusAtom);

  const handleGetUserFavorites = useCallback(
    async (params: FavoritesLimit, id: number) => {
      try {
        const response = await getUserFavorites(params, id);
        setFavorites(response);
      } catch (e) {
        console.log(e, 'error');
      } finally {
        setFavoriteStatus(0);
        setNotesStatus(0);
      }
    },
    [setFavoriteStatus, setNotesStatus]
  );

  return { favorites, handleGetUserFavorites };
};
