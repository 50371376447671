import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonText, IonToolbar, IonMenuButton } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { CampusContent } from './CampusContent';

export const MainCampus: React.FC = () => {
  return (
    <IonPage>
      <IonHeader collapse='fade'>
        <IonToolbar>
          <article style={{ display:'flex', width: 300 }}>
            <IonButtons slot="start">
                <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/tabs/home' />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Proximos campus</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ paddingRight: 20, paddingLeft:20 }}>
          <IonText color={'dark'}>
            <p style={{ fontSize:16, fontWeight:400 , textAlign:'left'}}>¡Anotate a nuestros campus presenciales y sigue aprendiendo cada vez más!</p>
          </IonText>
          <CampusContent />
        </div>
      </IonContent>
    </IonPage>
  );
};