import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, IonCheckbox, useIonViewDidEnter } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

import './ejercicios-insuficientes.css';
import { useRecoilState } from 'recoil';
import { modalRecommendationAtom } from 'recoil/modalAtom';
import { useGetCertificate } from 'hooks/sessions/useGetCertificate';

interface IModal {
  isOpen: boolean;
  setShowModal: (bool:boolean) => void;
}

export const EjerciciosRecomendacionModal: React.FC <IModal>= ({ isOpen, setShowModal }) => {

  const modal = useRef<HTMLIonModalElement>(null);
  const [ modalShowAtom, setModalShowAtom] = useRecoilState(modalRecommendationAtom)

  function dismiss() {
    setShowModal(false);
    modal.current?.dismiss();
  }
  return (
    <IonModal isOpen={isOpen} ref={modal} className="ul-modal" onDidDismiss={dismiss}>
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row" style={{ marginTop: 0 }}>
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="ei-container">
          <article style={{ width:'100%', textAlign:'center'}}>
            <IonText style={{ fontSize:22, fontWeight:700 }} color={'orange'}> Recuerda </IonText>
          </article>
          <article style={{ width:'100%', textAlign:'center', marginTop:'15px', marginBottom:'15px'}}>
            <IonText style={{ fontSize:13.5, width:200, fontWeight:400 }} color={'dark'}> Tus ejercicios disponibles se irán desbloqueando a medida que avances en tu Programa de Aprendizaje. </IonText>
          </article>
          <article style={{ width:'100%', textAlign:'center'}}>
            <IonText style={{ fontSize:13.5, fontWeight:400 }} color={'dark'}> Si no tienes suficientes ejercicios disponibles para ciertos filtros, prueba seleccionando otros o más, o sigue avanzando con tu Programa de Aprendizaje para desbloquear todavía más ejercicios. </IonText>
          </article>
          <IonButton type="submit" fill="clear" style={{ marginTop: '24px' }} className="ei-button" onClick={dismiss}>
            Entendido
          </IonButton>
          <div style={{ display:'flex', justifyContent:'start', width:'100%', marginTop:10 , marginLeft:10 }}>
          <IonCheckbox onIonChange={(e)=>{ setModalShowAtom(!modalShowAtom) }} labelPlacement="end" style={{ fontSize:14, fontWeight:400 }}>No volver a mostrar aviso</IonCheckbox>
        </div>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}
