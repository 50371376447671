import React, { useCallback, useRef, useState } from 'react'
import { getNextInvoiceSubscription } from 'services/subscription'

export const useGetNextInvoiceSubscription = () => {

    const [price, setprice] = useState({
      total: null,
      prorationDate: 1684525479
    })

    const handleGetNextInvoice = useCallback(
      async() => {
        
        getNextInvoiceSubscription().then( response => { 

            if(response.status === 200){ 

                const data = response.data
                setprice(data)
            }
        })
      },
      [],
    )

  return {
    price,
    handleGetNextInvoice
  }
}
