

export const handleLimitChoises = (duration:Number)=> { 

    switch (duration) {
        case 15:
            return 2
        case 30:
        case 45:
            return 3
        case 60:
            return 4
        default:
            return 0
    }
}