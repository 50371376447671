import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, useIonRouter } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './modal-finalizacion-ejercicio.css';

//recoil
import { ExerciseDataAtom } from 'recoil/exerciseDataAtom';
import { useRecoilValue } from 'recoil';

interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

function ModalMultipleFinalizacionEjercicio({ isOpen, handleClose }: IModal) {
  const navigation = useIonRouter();
  const modal = useRef<HTMLIonModalElement>(null);

  const exerciseData = useRecoilValue(ExerciseDataAtom);
  const { idBasis, idExercise, idModule } = exerciseData;

  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  const handleReviewTechnique = () => {
    navigation.push(`/bloque/${idBasis}/modulo/${idModule}/ejercicio/${idExercise}`);
    dismiss();
  };

  return (
    <IonModal isOpen={isOpen} ref={modal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row">
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="fe-container">
          <IonText className="fe-well-done">¡Muy bien!</IonText>
          <IonText className="fe-subtitle">Completaste nuevamente el video de aprendizaje y sumaste</IonText>
          <IonText className="fe-points">5</IonText>
          <IonText className="fe-points-text">Puntos Extras de Campus Huevo Sanchez</IonText>
          <IonText className="fe-subtitle" style={{ marginTop: '25px' }}>
            Recuerda verlo y practicarlo las veces que necesites para un mejor aprendizaje.
          </IonText>

          <IonButton className="fe-button" fill="clear" onClick={handleReviewTechnique}>
            Repasar técnica
          </IonButton>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}

export default ModalMultipleFinalizacionEjercicio;
