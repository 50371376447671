import { useEffect, useState } from 'react';
//iconic
import { IonGrid, IonText, IonButton, useIonViewDidEnter } from '@ionic/react';
//css
import './trimestral.css';
import CambiarPlanModal from '../Modales/CambiarPlan/CambiarPlanModal';
import CancelarSuscripcionModal from '../Modales/CancelarSuscripcion/CancelarSuscripcionModal';
import { StripeModalChangePlan } from 'components/forms/subscription/StripeModalChangePlan';
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { useGetNextInvoiceSubscription } from 'hooks/subscription/useGetNextInvoiceSubscription';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';
import { TermsConditionModal } from 'components/modal/TermsConditionModal';
import { PolishPrivacyModal } from 'components/modal/PolishPrivacyModal';
export const Trimestral: React.FC = () => {
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const { IsOpen, handleCloseModal, handleOpenModal } = useOpenModal();
  const { handleGetNextInvoice, price } = useGetNextInvoiceSubscription();
  const eventDate = new Date();
  const day = eventDate.toLocaleString('es', { day: 'numeric' });
  const month = eventDate.toLocaleString('es', { month: 'long' });
  const capitalizedMonth = capitalizeWord(month);
  const year = eventDate.getFullYear().toString();
  const dayNumber = eventDate.getDate();
  const next3Month = new Date(new Date().setMonth(new Date().getMonth() + 3)).toString();
  const nextYear = new Date(new Date().setMonth(new Date().getMonth() + 3)).getFullYear.toString();
  const userInfo = useRecoilValue(userInfoState);
  function capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  useIonViewDidEnter(()=>{
    handleGetNextInvoice();
  })
  console.log(price)
  return (
    <IonGrid className="ab-container">
      <IonText className="ab-title">Trimestral</IonText>
      <IonText className="ap-subtitle">•  Todos los beneficios del plan Anual por tres meses.</IonText>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '32px' }} onClick={() => setChangePlanModal(true)}>
        <IonButton type="submit" fill="clear" className="ab-button">
          Cambiar Plan
        </IonButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '48px' }}>
      {userInfo.canceledAt ? 
        <IonText className="ab-duration">Trimestral - pago automático cancelado</IonText>
        :
        <IonText className="ab-duration">Trimestral con pago automático</IonText>
      }
        <IonText className="ab-date">
          Inicio: {day} de {capitalizedMonth} {year}
        </IonText>
        <IonText className="ab-date">
          {userInfo.canceledAt ? `Fin: ${dayNumber} de ${capitalizedMonth} ${year}` : `Proximo pago programado: ${dayNumber} de ${next3Month} ${nextYear}`}
        </IonText>
      </div>
      {userInfo.canceledAt ? 
        null
       : (
        <IonButton className="ab-renovation-button" fill="clear" onClick={() => setCancelSubscription(true)}>
          Cancelar suscripción
        </IonButton>
      )}
      <IonText className="ab-subscribe-text">Tiene renovación automática.</IonText>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <IonText id="terms-basic-modal" className="ab-terms">
          Términos y condiciones
        </IonText>
        <span className="ab-terms" style={{ textDecoration: 'none', margin: '0 4px' }}>
          -
        </span>
        <IonText id="polish-basic-modal" className="ab-terms">
          Política de Privacidad
        </IonText>
      </div>
      <TermsConditionModal triger="terms-basic-modal" />
      <PolishPrivacyModal triger="polish-basic-modal" />
      <CambiarPlanModal nextInvoice={price} showModal={changePlanModal} setShowModal={setChangePlanModal} handleOpenPayModal={handleOpenModal} />
      <StripeModalChangePlan nextInvoice={price} isOpen={IsOpen.changePlan} handleOpen={handleOpenModal} handleCloseModal={handleCloseModal} />
      <CancelarSuscripcionModal showModal={cancelSubscription} setShowModal={setCancelSubscription} />
    </IonGrid>
  );
};