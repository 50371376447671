import { IonText } from "@ionic/react"
import { MedalCompleteIcon } from "components/Icons/MedalCompleteIcon"
import './moduleFinished.css'

interface IModuleFinished{ 
    moduleName: string
}

export const ModuleFinished: React.FC <IModuleFinished>= ({ moduleName })=>{ 

    return (
        <div className="module-finished-content">
            <div className="m-header-content">
                <IonText color={'orange'} className="m-header-text"> ¡Espectacular! </IonText>
            </div>
            <div className="m-content-first">
                <IonText color={'dark'} className="m-text-first"> 
                    Con el último video completaste el módulo de <strong>{`"${moduleName}"`} </strong>
                    y sumaste 15 puntos extra
                </IonText>
            </div>
            <div className="m-content-image">
                <MedalCompleteIcon height="115" width="73" />
            </div>
            <div className="m-content-first">
                <IonText color={'dark'} className="m-text-second"> 
                    ¡Sigue sumando y aumenta tu nivel!
                </IonText>
            </div>
            <div className="m-content-first">
                <IonText color={'dark'} className="m-text-third"> 
                    *El modulo seguirá estando disponible en todo momento. ¡Revisalo las veces que necesites!
                </IonText>
            </div>
        </div>
    )
}