import React, { useEffect } from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';

//icons
import close_modal from '../../assets/close_modal.svg';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';

interface ITrainerModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  coach: {
    idCoachBasis: number;
    idBasis: number;
    idCoach: number;
    fullName: string;
    role: string;
    description: string;
    key: string;
  };
}

function TrainerModal({ showModal, setShowModal, coach }: ITrainerModal) {
  const { fullName, role, description, key } = coach;

  const [handlePostMedia, url] = usePostMedia();

  useEffect(() => {
    if(key)handlePostMedia({ key });
  }, [handlePostMedia, key]);
  return (
    <IonModal isOpen={showModal} className="ul-modal">
      <IonGrid className="wrapper" style={{ maxHeight: '520px', overflow: 'scroll' }}>
        <IonRow className="ul-icon-row">
          <IonImg onClick={() => setShowModal(false)} src={close_modal} alt="cross icon" />
        </IonRow>

        <div className="ba-trainer-box-modal" onClick={() => setShowModal(true)}>
          <IonImg src={url} className="ba-avatar" />

          <IonText className="ba-trainer-name">{fullName}</IonText>
          <IonText className="ba-trainer-role">{role}</IonText>

          <div className="ba-trainer-description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default TrainerModal;
