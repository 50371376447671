import { useEffect } from 'react';

//ionic
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonText, IonAvatar, IonImg, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//components
import { Menu } from '../../components/Perfil/Menu';
import HeaderPopOver from 'components/Header/HeaderPopOver';
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';
import { useGetUserById } from 'hooks/users/useGetUserById';

//recoil
import { tokenState } from 'recoil/tokenAtom';
import { useRecoilValue } from 'recoil';

export const Perfil: React.FC = () => {
  const token = useRecoilValue(tokenState);

  const userId = token && token.userId;
  const { handleGetUserById, user } = useGetUserById();

  const [handlePostMedia, url] = usePostMedia();
  const key = user && user.key;

  useEffect(() => {
    handleGetUserById(Number(userId));
  }, [handleGetUserById, userId]);

  useEffect(() => {
    if (user.idUser && key) {
      handlePostMedia({ key });
    }
  }, [user, key, handlePostMedia]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="p-header-container" style={{ justifyContent: 'space-between' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <IonButtons slot="start">
                <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs/home" />
              </IonButtons>
              <IonText className="p-header-title">Mi Perfil</IonText>
            </div>

            <IonAvatar slot="end" className="h-ion-avatar" id="popover-miperfil">
              {url ? <IonImg src={url} /> : user.pictureURL ? <IonImg src={user.pictureURL} /> : <AddAvatar width="36px" height="36px" />}
            </IonAvatar>
          </div>
        </IonToolbar>
        <HeaderPopOver triger="popover-miperfil" />
      </IonHeader>
      <IonContent fullscreen>

        <Menu />
      </IonContent>
    </IonPage>
  );
};
