//iconic
import { useIonRouter } from '@ionic/react';

//services
import { editUser } from 'services/user';
import { IUserEdit } from 'services/interfaces/Users';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';

//hooks
import { useGetUserById } from './useGetUserById';
import { usePostImageUser } from 'hooks/media/usePostImageUser';
import { dataURLtoFile } from 'pages/login/components/image-cropper/helper/DataUrlToFile';
import { IUserImage } from 'services/interfaces/MediaBody';
import { userState } from 'recoil/UserImage';

export const useEditUser = () => {
  const navigation = useIonRouter();
  const setToast = useSetRecoilState(toastState);
  const setImageUser = useSetRecoilState(userState)

  const { handleGetUserById } = useGetUserById();
  const [handlePostMedia] = usePostImageUser()

  const handleEditUser = async (id: number, user: IUserEdit, userImage:any ) => {
    const updatedUser = { ...user };
    if (!updatedUser.password) delete updatedUser.password;

    if(userImage.photo !== ""){
      let file = dataURLtoFile(userImage, `${Math.random().toString(36).slice(2)}`)
      let body:IUserImage = { 
        fileName: Math.random().toString(36).slice(2),
        id: id
      }
      await handlePostMedia(body, file)
    }

    const status = await editUser(id, updatedUser);


    if (status === 200) {
      handleGetUserById(id);
      
      setToast({
        isOpen: true,
        color: 'success',
        message: 'Guardaste los cambios en tu cuenta',
        icon: CheckIcon,
        button: [
          {
            icon: CloseSvg,
            side: 'end',
            role: 'cancel',
            cssClass: 'toast-button',
          },
        ],
      });
    }
    
    setTimeout(() => {
      setImageUser({photo:''})
      navigation.push('/perfil', 'forward', 'pop');
    }, 2500);
  };
  return [handleEditUser];
};
