import { IonContent, IonHeader, IonPage, IonToolbar, useIonRouter, IonButtons, IonBackButton, IonToast, useIonViewDidEnter, isPlatform, useIonViewWillEnter, IonMenuButton } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import './loginSignIn.css';
import { GoogleButton } from './components/GoogleButton';
import { FacebookButton } from './components/FacebookButton';
import { Divider } from 'components/Divider';
import { SignUp } from 'components/forms/LoginForm';
import { useRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';
import { useAuthWithCredentials } from 'hooks/auth/useAuthWithCredentials';
import { IAppleJWT, IAuthWithApple, IAuthWithCredentials } from 'services/interfaces/AuthLogin';
import { LoadingScreen } from 'components/loading/LoadingScreen';
import { useAuthWithGoogle } from 'hooks/auth/useAuthWithGoogle';
import { useEffect } from 'react';
import { Device } from '@awesome-cordova-plugins/device';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AppleButton } from './components/AppleButton';
import { ASAuthorizationAppleIDRequest, SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple'
import { UseAuthWithApple } from 'hooks/auth/UseAuthWithApple';
import decode from 'jwt-decode'

export const LoginSignIn: React.FC = () => {

  const navigation = useIonRouter()
  const [toast, setToast] = useRecoilState(toastState)
  const user = localStorage.getItem('user')
  const pw = localStorage.getItem('pw')
  const device = localStorage.getItem('device')
  const client = localStorage.getItem('client')
  const tokenGoogle = localStorage.getItem('googleToken')
  const [handleAuthWithCredentials] = useAuthWithCredentials()

  const {handleAuthWithGoogle} = useAuthWithGoogle()

  const {handleAuthWithApple} = UseAuthWithApple()

  const clientId = isPlatform('ios') ? 
  '771222230088-lke7bnckqef6rnaadn0auqsq2hdftnp6.apps.googleusercontent.com'
  :
  '771222230088-8aokcm4sc9lkm90e09j53o8vdbo3iu2t.apps.googleusercontent.com'

  useEffect(()=>{
      GoogleAuth.initialize({ clientId: clientId
    })
  },[])

  const handleLoginGoogle = async()=>{
    
    const result = await GoogleAuth.signIn()
    if(result){
      const uuid = isPlatform('ios') ? `${result.email}-${Device.model}` : Device.uuid
      const body = {
        token: String(result?.authentication.idToken),
        client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
        deviceUUID: uuid
      }
      handleAuthWithGoogle(body)
    }else{
      alert('Error')
    }

  }

  const handleBackToCreate = ()=>{
    navigation.push('/login/signup', 'back', "replace")
  }

  const handleLoginWithApple = async()=>{ 
    const result = await SignInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    })
    if(result){ 
      const decodedJwt:IAppleJWT = decode(result.identityToken)
      const uuid = isPlatform('ios') ? `${decodedJwt.email}-${Device.model}` : Device.uuid
      const body:IAuthWithApple = { 
        client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
        deviceUUID: uuid,
        email: result.email !== '' ? String(result.email) : String(decodedJwt.email),
        familyName: result.fullName?.familyName !== '' ? String(result.fullName?.familyName) : null,
        givenName: result.fullName?.givenName !== '' ? String(result.fullName?.givenName) : null,
        identityToken: String(result.identityToken),
        middleName: result.fullName?.middleName !== '' ? String(result.fullName?.middleName) : null
      }

      handleAuthWithApple(body)
    }
  }

  useIonViewDidEnter(()=>{ 
    if(user && client && device && pw){
      const body: IAuthWithCredentials = {
        client: client,
        deviceUUID: device,
        email: user,
        password: pw
      }

      handleAuthWithCredentials(body)
    }

    if(tokenGoogle){
      handleLoginGoogle()
    }
    
  })

  return (
    <IonPage>
      { (!user || !tokenGoogle ) && <IonHeader>
          <IonToolbar>
            <article style={{ display:'flex', width: 300 }}>
              <IonButtons slot="start">
                  <IonBackButton className='ioBackButton' icon={arrowBackOutline} color='dark' text={''} defaultHref='/login/signup' />
              </IonButtons>
              <p style={{ whiteSpace:'nowrap', fontSize: 22, marginLeft:10, fontWeight:700 }}>Inicia sesión</p>
            </article>
          </IonToolbar>
        </IonHeader>}
      <IonContent fullscreen>
        {
           (user || tokenGoogle ) ? 
          <LoadingScreen subTitle='Espera unos segundos' title='Ingresando a tu perfil...' />
          :
          <>
            <div>
                <SignUp />
            </div>
            <div style={{ padding:20, width:'100%'}}>
              <div style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'center' }}>
                <Divider width={'35%'} />
                <p style={{ width:'20%', fontSize:18, textAlign:'center' }}> o </p>
                <Divider width={'35%'} />
              </div>
              <div style={{ display:'flex', alignItems:'center', flexDirection:'column', marginTop:20 }}>
                <GoogleButton handleLoginGoogle={handleLoginGoogle} />
                {/* <FacebookButton /> */}
                { isPlatform('ios') && <AppleButton handleLoginApple={handleLoginWithApple} />}
                <p onClick={handleBackToCreate} style={{ fontSize:14, fontWeight:400 }}>
                    ¿No tienes cuenta?
                    <span style={{marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}>
                        Crear cuenta
                    </span>
                </p>
              </div>
            </div>
            <IonToast
              isOpen={toast.isOpen}
              message={toast.message}
              icon={toast.icon}
              cssClass={'css-toast'}
              buttons={toast.button}
              onDidDismiss={() => setToast({ isOpen:false })}
              duration={2000}
            ></IonToast>
          </>
        }
      </IonContent>
    </IonPage>
  );
};
