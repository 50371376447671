import axios from './BaseConfig';
import { IStripePayment } from './interfaces/IStripe';

//interface
import { MySessions } from './interfaces/MySessions';
import { IGetCertificate, SessionParams } from './interfaces/SessionParams';

export const getSessions = async (params: SessionParams) => {
  try {
    const res = await axios.get('/sessions', { params });
    return res.data.results;
  } catch (e) {
    console.log('error', e);
  }
};

export const getSessionsById = async (id: string) => {
  try {
    const res = await axios.get(`/sessions/${id}`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const postSessionByIdUser = async (id: string, idSession: string, email:string) => {
  try {
    const res = await axios.post(`/users/${id}/sessions/${idSession}`,{ email:email });
    return res;
  } catch (e) {
    console.log('error', e);
  }
};

export const getUserSessions = async (id: number, params: MySessions) => {
  try {
    const res = await axios.get(`/users/${id}/sessions`, { params });
    return res.data.results;
  } catch (e) {
    console.log('error', e);
  }
};

export const postStripePayment = async (body:IStripePayment) => {
     return await axios.post(`/payments/register`, { ...body });
};

export const getCertificate = async ( params:IGetCertificate)=>{ 
  return await axios.get('/sessions/pdf', {
    params:{
      ...params
    }
   });
}
