//ionic
import { IonGrid, IonText } from '@ionic/react';

//css
import './progress-component.css';

//components
import BarChart from './BarChart';
import { HistorialEntrenamiento } from './HistorialEntrenamiento';

const TabEntrenamiento: React.FC = () => {
  return (
    <>
    <IonGrid className="p-aprendizaje-container">
      <IonText className="p-aprendizaje-title">Entrenamiento Rápido</IonText>
      <BarChart />
    </IonGrid>
    <HistorialEntrenamiento />
    </>
  );
};

export default TabEntrenamiento;
