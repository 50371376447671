import { handleFundamentals } from 'helper/handleFundamentals'
import { IFundamentals } from 'interfaces/IEntraniento'
import React, { useCallback, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { trainingState } from 'recoil/TrainingAtom'
import { IGetFundamentals, getFundamentals } from 'services/quickTraining'

export const useGetFundamentals = () => {

    const [fundamentals, setFundamentals] = useState<IFundamentals[]>([])
    const entrenamiento = useRecoilValue(trainingState)

    const handleGetFundamentals = useCallback(
      async() => {
        let paramsToSend:IGetFundamentals = {
            hoop: entrenamiento.tieneAro === "yes" ? 1 : 0,
            partner: entrenamiento.tieneCompanero === "yes" ? 1 : 0
        }

       let result = await getFundamentals(paramsToSend).then( response => { 

            if(response.status === 200){ 
                return response.data
            }
        })
        .catch(error=>{ 
          if(error){
            return []
          }
          throw new Error('internal error')
        })
            let arrayParsed = handleFundamentals(result)
            setFundamentals(arrayParsed)
      },
      [],
    )
    
  return {
    handleGetFundamentals,
    fundamentals
  }
}
