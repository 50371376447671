import { Field } from 'formik';
import { ErrorMessageText } from './ErrorMessage';
import { IonIcon } from '@ionic/react';
import { eye, eyeOff } from "ionicons/icons";
import { useState } from 'react';

interface IFieldSet{
    name:string,
    label:string,
    type?:string,
    placeholder?:string,
    value:any,
    errors:any,
    touched:any,
    setFieldTouched:any,
    isTouched: boolean
}

export const FieldSetPassword = ({ touched, name, label, type, placeholder, value, errors, setFieldTouched, isTouched }:IFieldSet) => {

    const [showHidePassword, changeShowHidePassword] = useState(false);

  const handleBorderColor = ()=>{
    if(touched && value && errors){
      return '#B62D25'
    }else if(touched && !value && errors){
      return '#B83A02'
    }else{
      return '#626875'
    }
  }

  const handleTextColor = ()=>{
    if(touched && !value && errors){
      return '#393A3F'
    }else{
      return '#393A3F'
    }
  }


  return (
    <article style={{ display:'flex', flexDirection:'column', alignItems:'flex-start', paddingTop:'15px', width:'100%'}}>
      <label style={{ marginBottom:10 }} htmlFor={name}>{label}</label>
        <Field
            id={name}
            name={name}
            value={value}
        >
        {({
            field,
            form: { touched, errors, setFieldTouched },
            meta,
            }:any) => (
                <>
                    <div style={{ display:'flex', alignItems:'center', minWidth:'100%', height:45, borderRadius:24, border:'1px solid black', borderColor: handleBorderColor(), outline:'none' }}>
                        <input
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type={showHidePassword ? "text" : "password"}
                            style={{ all:'unset', marginTop:'5px', marginLeft:'10px', width:'78%'}}
                            onClick={()=>{
                                setFieldTouched(field.name, true)
                            }}
                            onBlur={()=> {
                                setFieldTouched(field.name, false)
                            }
                            } />
                            <IonIcon style={{ marginLeft:20, width:25, height:25 }} onClick={()=>changeShowHidePassword(!showHidePassword)} slot="icon-only" icon={showHidePassword ? eyeOff : eye}></IonIcon>
                    </div>
                    { touched[name] && errors[name] && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors[name]}  />}
                </>
            )}
        </Field>
    </article>
  )
};
