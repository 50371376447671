import { useCallback } from 'react';

//services
import { putNotes } from 'services/notes';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';
import { notesStatusAtom } from 'recoil/NotesStatusAtom';

export interface NotesBody {
  note: string | null;
}

export const usePutNote = () => {
  const setToast = useSetRecoilState(toastState);
  const setNotesStatus = useSetRecoilState(notesStatusAtom);

  const handlePutNote = useCallback(
    async (idExcercise: number, body: NotesBody, idNote: number) => {
      try {
        const status = await putNotes(idExcercise, body, idNote);
        if (status === 200) {
          setNotesStatus(status);
          setToast({
            isOpen: true,
            color: 'success',
            message: 'Guardaste la nota',
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },
    [setToast, setNotesStatus]
  );

  return { handlePutNote };
};
