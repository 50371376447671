//ionic
import { IonText, IonImg, useIonRouter, useIonViewDidEnter } from '@ionic/react';

//css
import './sidebar.css';

//icons
import close_icon from '../../assets/close_icon.svg';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState, userLevelsAtom } from 'recoil/User';
import { useEffect, useRef } from 'react';
import { usePostMedia } from 'hooks/media/usePostMedia';
import { UserAvatar } from './UserAvatar';
import { handleLevels } from 'helper/handleLevels';

type MenuRefType = {
  open: () => Promise<boolean>;
  close: () => Promise<boolean>;
};

interface UserInfoProps {
  menuRef: React.RefObject<MenuRefType>;
}

const closeMenu = (menuRef: React.RefObject<MenuRefType>) => async () => {
  await menuRef.current?.close();
};

export const UserInfo: React.FC<UserInfoProps> = ({ menuRef }) => {
  const token = useRecoilValue(userInfoState);
  const maxPointsRef = useRef(1)
  const level = (token && token.level) || 'Principiante';
  const points = (token && token.points) || 0;
  const lastName = token && token.lastName;
  const firstName = token && token.firstName;
  const key = token && token.key;

  const [handlePostMedia, url] = usePostMedia();
  const userLevels = useRecoilValue(userLevelsAtom)

  const navigation = useIonRouter();

  useEffect(() => {
    if (token.idUser && key) {
      handlePostMedia({ key });
    }
  }, [token, key, handlePostMedia]);

  useEffect(() => {
    const { maxPoints } = handleLevels(userLevels, Number(token.idLevel))
    maxPointsRef.current = maxPoints
  }, [token])


  const handleRedirect = () => {
    menuRef.current?.close();
    navigation.push('/suscripcion', 'forward', 'replace');
  };

  return (
    <>
      <div className="sb-content-container">
        {/* close icon */}
        <div className="sb-ion-img-wrapper">
          <IonImg className="sb-close-icon" src={close_icon} alt="edit button" onClick={closeMenu(menuRef)} />
        </div>

        <div className="sb-popover-content">
          <div style={{ display: 'flex', width: '100%' }}>
            {/* avatar */}
            {/* [FALTA] integrar el progreso del usuario */}
            <UserAvatar maxPoints={maxPointsRef.current} points={points} pictureUrl={token.pictureURL} url={url} />
            {/* datos del usuario */}
            <div className="sb-popover-content-child">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <IonText className="sb-name">
                  {firstName} {lastName}
                </IonText>
                <IonText className="sb-description">
                  {level} - {points} puntos
                </IonText>
                {token.idSuscription === 1 ? (
                  <IonText onClick={handleRedirect} className="sb-level">
                    Cuenta Anual Básica
                  </IonText>
                ) : token.idSuscription === 2 ? (
                  <IonText onClick={handleRedirect} className="sb-level">
                    Cuenta Anual Premium
                  </IonText>
                ) : token.idSuscription === 3 ? (
                  <IonText onClick={handleRedirect} className="sb-level">
                    Cuenta Trimestral
                  </IonText>
                ) : (
                  <IonText onClick={handleRedirect} className="sb-level">
                    Sin suscripción: Cuenta Gratis
                  </IonText>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sb-divider"></div>
    </>
  );
};
