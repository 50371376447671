import { useEffect, useRef, useState } from "react";

//ionic
import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonText,
	IonGrid,
	useIonViewDidLeave,
	useIonRouter,
	useIonViewWillLeave,
	IonButton,
	useIonViewDidEnter,
	IonMenuButton,
} from "@ionic/react";

//icons
import { arrowBackOutline } from "ionicons/icons";

//react-router-dom
import { useParams } from "react-router-dom";

//hooks
import { useGetCoaches } from "hooks/aprendizaje/useGetCoaches";
import { useGetModules } from "hooks/aprendizaje/useGetModules";
import { useGetBasisById } from "hooks/aprendizaje/useGetBasisById";

//css
import "./bloque-aprendizaje.css";

//componentes
import EmptyState from "components/EmptyState/EmptyState";
import { TrainerBox } from "../../../components/aprendizaje/TrainerBox";
import { SingleModule } from "../../../components/aprendizaje/SingleModule";
import SubscribeModal from "components/Mi Suscripcion/Modales/SubscribeModal/SubscribeModal";
import { StripeModalPaymentSubscription } from "components/forms/subscription/StripeModalPayment";

//recoil
import { userInfoState } from "recoil/User";
import { useRecoilState, useRecoilValue } from "recoil";
import { orientationAtom } from "recoil/OrientationAtom";
import { ratingStatusAtom } from "recoil/ratingStatusAtom";

//react player
import ReactPlayer from "react-player";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";

//hooks
import useScreenSize from "hooks/screen-size/useScreenSize";
import { useGetModuleByID } from "hooks/aprendizaje/useGetModuleByID";

interface IParams {
	id: string;
}

export const BloqueAprendizaje: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const [showModalPayment, setModalPayment] = useState(false);

	const params: IParams = useParams();
	const { id } = params;

	const user = useRecoilValue(userInfoState);
	const { idSuscription } = user;

	const ratingStatus = useRecoilValue(ratingStatusAtom);

	const { modules, handleGetModules } = useGetModules();
	const { basis, handleGetBasisById } = useGetBasisById();
	const { coaches, handleGetCoaches } = useGetCoaches();
	const [isPlaying, setisPlaying] = useState(false);
	const [orientation, setOrientation] = useRecoilState(orientationAtom);
	

	useEffect(() => {
		if (id) {
			const parsedId = parseInt(id);
			handleGetBasisById(parsedId);
			handleGetModules(parsedId);
			handleGetCoaches(parsedId);
		}
	}, [id, handleGetBasisById, handleGetModules, handleGetCoaches]);

	const { name, videoURL } = basis;

	useEffect(() => {
		if (id && ratingStatus === 200) {
			const parsedId = parseInt(id);
			handleGetBasisById(parsedId);
			handleGetModules(parsedId);
			handleGetCoaches(parsedId);
		}
	}, [
		id,
		handleGetBasisById,
		handleGetModules,
		handleGetCoaches,
		ratingStatus,
	]);

	useIonViewDidLeave(() => {
		setisPlaying(false);
	});

	useIonViewWillLeave(() => {
		setisPlaying(false);
	});

	useIonViewDidEnter(() => {
		setisPlaying(true);
	});

	useEffect(() => {
		const handleOrientationChange = () => {
			setOrientation(ScreenOrientation.type);
		};
		window.addEventListener("orientationchange", handleOrientationChange);
		return () => {
			window.removeEventListener(
				"orientationchange",
				handleOrientationChange,
			);
		};
	}, [ScreenOrientation.type]);

	const [playerHeight, setPlayerHeight] = useState(0);
	const playerContainerRef = useRef<HTMLDivElement>(null);
	const [playerContainerWidth, setPlayerContainerWidth] = useState(0);

	const url = "https://basket-files-prod.s3.us-east-2.amazonaws.com/videosLocal/";

	useEffect(() => {
		const handleResize = () => {
			if (
				playerContainerRef.current &&
				playerContainerRef.current.offsetWidth
			) {
				const newWidth = playerContainerRef.current.offsetWidth;
				setPlayerContainerWidth(newWidth);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		setPlayerHeight(playerContainerWidth / (16 / 9));
	}, [playerContainerWidth]);

	return (
		<IonPage>
			{orientation === "portrait-primary" && (
				<IonHeader>
					<IonToolbar>
						<div className="f-header-container">
							<IonButtons slot="start">
								<IonBackButton
									className="ioBackButton"
									icon={arrowBackOutline}
									color="dark"
									text={""}
									defaultHref="/tabs/aprendizaje"
								/>
							</IonButtons>
							<IonText
								className="f-header-title"
								style={{ textTransform: "capitalize" }}
							>
								{name}
							</IonText>
						</div>
					</IonToolbar>
				</IonHeader>
			)}
			<IonContent fullscreen>
				<IonGrid className="ba-container">
					<div
						style={{
							width: "100%",
							height: "100%",
							position: "relative",
						}}
						ref={playerContainerRef}
					>
						<div
							style={{
								width: "100%",
								position: "absolute",
								backgroundColor: "transparent",
								height: 60,
								zIndex: 100000,
							}}
						></div>
						<div
							style={{
								width: "50%",
								position: "absolute",
								backgroundColor: "transparent",
								height: 40,
								zIndex: 100000,
								bottom: 0,
								right: 0,
							}}
						></div>
							<>
							<ReactPlayer 
								className="video-training-player"
								playing={true}
								controls={true} 
								url={url + name.toLowerCase().replace(/[\s_-]+/g, "+") + "/" + name.replace(/[\s_-]+/g, "+") + ".mp4"}
								width='100%'
								playsinline
								height={orientation === 'portrait-primary' ? '220px' : '60%'}
								onContextMenu={(e:any) => e.preventDefault() }
								config={{
									file:{
										attributes: { controlsList: 'nodownload' }
									}
								}}								
								/>
							 </>
					</div>
					<IonGrid className="ba-child">
						{coaches &&
							coaches.length !== 0 &&
							coaches.map((item, index) => {
								return <TrainerBox coach={item} key={index} showHeader={index === 0} />;
							})}

						{!idSuscription && (
							<div onClick={() => setShowModal(true)}>
								<EmptyState
									title="¡Suscríbete a la app!"
									subtitle="Podrás acceder a todos los módulos de aprendizaje y más beneficios."
									marginTop="32px"
								/>
							</div>
						)}

						<div
							className="flex"
							style={{
								marginTop: "16px",
								flexDirection: "column",
								borderBottom: `1px solid #C2C4C8`,
							}}
						>
							{modules &&
								modules.length !== 0 &&
								modules.map((module, index) => {
									return (
										<SingleModule
											module={module}
											key={index}
											index={index}
										/>
									);
								})}
						</div>
					</IonGrid>
				</IonGrid>

				<SubscribeModal
					setShowSub={setModalPayment}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
				<StripeModalPaymentSubscription
					isOpen={showModalPayment}
					setIsOpen={setModalPayment}
				/>
			</IonContent>
		</IonPage>
	);
};
