import { useRef, useState } from "react"

//ionic
import { IonButton, IonText } from "@ionic/react"

//components
import { Fieldset } from "components/FieldSet/FieldSet"

//formik
import { Form, Formik } from "formik"

//components
import { checkoutMailValidation } from "./checkoutMailValidation"
import { StripeModalPayment } from "pages/sesiones/components/session-checkout/StripeModalPayment"
import { ISessionPayment, SessionInfoPayment } from "pages/sesiones/components/session-checkout/SessionInfoPayment"

//hook
import { usePostSessions } from "hooks/sessions/usePostSessions"

//recoil
import { useRecoilState } from "recoil"
import { emailAtom } from "recoil/EmailAtom"

export const CheckoutSessionForm: React.FC <ISessionPayment>= ({ sessionById, userInfo}) =>{ 

  const { handlePostSessions } = usePostSessions()
  const [isOpen, setIsOpen] = useState(false)

  const [email,setEmail] = useRecoilState(emailAtom)
  
  return (

    <div>
    <Formik
      initialValues={{
        email: userInfo.email
      }}
      enableReinitialize={true}
      validationSchema={checkoutMailValidation}
      onSubmit={(values) => {
        setEmail(values.email)
        if(userInfo.sessions === 0){ 
          setIsOpen(true)
        }else{ 
          handlePostSessions(String(userInfo.idUser), String(sessionById.idSession), values.email )
        }
      }}
      >
      {({ setFieldTouched ,dirty, isValid, values,errors, touched }:any) => (
        <Form>
          <div style={{ display:'flex', flexDirection:'column', alignItems:'start'}}>
            <article style={{ display:'flex', flexDirection:'column', alignItems:'start', marginRight:'60px' }}>
                <Fieldset
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Ingrese su correo..."
                    value={values.email}
                    errors={errors.email}
                    setFieldTouched={setFieldTouched}
                    touched={touched.email}
                    isTouched={false}
                />
                <IonText color={'dark'} style={{ fontSize:12, fontWeight:400, width: '87%', padding:'3px' }}>
                  Este es el email de tu cuenta, pero puedes ingresar uno diferente donde recibir los datos.
                </IonText>
            </article>
            <article style={{ display:'flex', marginTop:20, width:'85%' }}>
              <SessionInfoPayment sessionById={sessionById} userInfo={userInfo}  />
            </article>
            <article style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'85%' }}>
                <IonButton 
                  type='submit' 
                  disabled={!(isValid)}
                  color={!(isValid) ? 'medium' : "orange"} 
                  style={{ width:'216px', marginTop: "24px" }}
                  >
                 Reservar
                </IonButton>                
            </article>
          </div>
        </Form>
      )}
      </Formik>
      <StripeModalPayment idSession={sessionById.idSession} email={email} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>

  )
}