import React, { useCallback, useState } from 'react'
import { getPaymentInfo } from 'services/subscription'

interface IPaymentInfo{
    idSuscription: number,
    name: string,
    description: string,
    amount: number,
    sessions: number,
    priceId: string
}

export const useGetPaymentInfo = () => {

    const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo[]>([])

    const handleGetPaymentInfo = useCallback(
      () => {
        getPaymentInfo().then( response =>{ 
            if(response.status === 200){
                const data = response.data

                setPaymentInfo(data)
            }
        })
      },
      [],
    )
    
  return {
    paymentInfo,
    handleGetPaymentInfo
  }
}
