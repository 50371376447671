export const modalOptions = {
  finishedModule: false,
  finishedExercise: false,
  changePlan: false,
  ejerciciosInsuficientes: false,
  finishedTraining: false,
  abandonoEntrenamiento: false,
  perfomanceGoals: false,
  exerciseStatus: false,
  finishedQuickTraining: false,
  completeVideo: false,
  openCertificate: false,
  multipleExerciseFinished: false,
  ratingModal: false,
  borrarCuenta: false,
  cuentaBorrada: false
};

export const modalKeys = {
  finishedModule: 'finishedModule',
  finishedExercise: 'finishedExercise',
  changePlan: 'changePlan',
  ejerciciosInsuficientes: 'ejerciciosInsuficientes',
  finishedTraining: 'finishedTraining',
  abandonoEntrenamiento: 'abandonoEntrenamiento',
  perfomanceGoals: 'perfomanceGoals',
  exerciseStatus: 'exerciseStatus',
  finishedQuickTraining: 'finishedQuickTraining',
  completeVideo: 'completeVideo',
  openCertificate: 'openCertificate',
  multipleExerciseFinished: 'multipleExerciseFinished',
  ratingModal: 'ratingModal',
  borrarCuenta:'borrarCuenta',
  cuentaBorrada:'cuentaBorrada'
};
