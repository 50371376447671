import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, IonIcon } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './ejercicios-insuficientes.css';
import { useDeleteUser } from 'hooks/users/useDeleteUser';
import { useRecoilValue } from 'recoil';
import { userInfo } from 'os';
import { userInfoState } from 'recoil/User';
import { checkmarkCircle } from 'ionicons/icons';
import { LoadingScreen } from 'components/loading/LoadingScreen';

interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

function BorrarCuentaModal({ isOpen, handleClose }: IModal) {
  const modal = useRef<HTMLIonModalElement>(null);
  const { handleDeleteUser, isDeleting } = useDeleteUser()
  const userInfo = useRecoilValue(userInfoState)
  
  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  return (
    <IonModal isOpen={isOpen} ref={modal} className="ul-modal" onDidDismiss={dismiss}>
      {!isDeleting ? 
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row" style={{ marginTop: 0 }}>
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="ei-container">
          <IonText className="ei-well-done">Borrar cuenta</IonText>
          <IonText className="ei-subtitle" style={{ marginTop:10 }}>¿Realmente quieres borrar tu cuenta? Eliminaremos todos tus registros, datos personales, avances e historial en la app. No los podrás recuperar.</IonText>

          <div className="ei-child-container">
            <IonText className="ei-child-title">Importante:</IonText>
            <IonText className="ei-child-subtitle">Si tienes una suscripción, se dará de baja al borrar tu cuenta.</IonText>
          </div>

          <IonButton fill="outline" style={{ marginTop: '24px', height:40 }} color={'danger'} onClick={()=>handleDeleteUser(Number(userInfo.idUser))}>
            Sí, borrar cuenta
          </IonButton>

          <IonButton fill="solid" style={{ marginTop: '24px' }} color={'orange'} className="ei-button" onClick={dismiss}>
            No, mantener cuenta
          </IonButton>
        </IonGrid>
      </IonGrid>
      :
      <IonGrid className="wrapper">
        <LoadingScreen justifyContent='center' height='452px' subTitle='Espera unos segundos' title='Borrando tu cuenta...' />
      </IonGrid>
      }
    </IonModal>
  );
}

export default BorrarCuentaModal;
