import solo from 'assets/quick-training/Solo.svg';
import notengoaro from 'assets/quick-training/Sin_Aro.svg';
import tengoaro from 'assets/quick-training/Aro.svg';
import companero from 'assets/quick-training/Companero.svg';

export const handleIconHoop = (withHoop:String)=>{ 

    switch (withHoop) {
        case "yes":
            return tengoaro
        case "no":
            return notengoaro
        default:
            return ''
            break;
    }
}

export const handleIconPartner = (withPartner:String)=>{ 

    switch (withPartner) {
        case "yes":
            return companero
        case "no":
            return solo
        default:
            return ''
    }
}