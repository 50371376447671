import axios from './BaseConfig';
import { IStripeChangeSubscription, IStripeSubscription } from './interfaces/IStripe';

export const postStripeSubscription = async (body:IStripeSubscription) => {
     return await axios.post(`/payments/suscriptions`, { ...body });
};

export const getNextInvoiceSubscription = async()=>{ 
     return await axios.get(`/payments/suscriptions/proration?idSuscriptionPlan=2`)
}

export const putSubscriptionPlan = async(body:IStripeChangeSubscription)=>{ 
     return await axios.put(`/payments/suscriptions`, { ...body })
}

export const cancelAutomaticRenewal = async()=>{ 
     return await axios.delete(`/payments/suscriptions`)
}

export const getPaymentInfo = async()=>{
     return await axios.get('/payments/suscriptions')
}