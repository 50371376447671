
import { IExercisesQuick, IFundamentals, ITraining } from 'interfaces/IEntraniento'
import React, { useCallback, useState } from 'react'
import { IEntrenamiento } from 'recoil/TrainingAtom'
import { ITrainingExercises, getQuickTrainingExercises } from 'services/quickTraining'

export const useGetQuickTrainingExercises = () => {

    const [exercises, setExercises] = useState<IExercisesQuick[]>([])

    const handleGetQuickTrainingExercises = useCallback(
      async(training:IEntrenamiento, basis:IFundamentals[]) => {
        
        const idBasis = basis && basis.length !== 0 ? basis.map( element => element.idModule) : []
        const findModule = idBasis.find( element => element === 3)
        if(findModule){
          idBasis.push(4)
        }
        const findModulePase = idBasis.find( element => element === 13)
        if(findModulePase){
          idBasis.push(14)
        }
        const params:ITrainingExercises = {
            duration: Number(training.duracion),
            fundamentals: idBasis.join(','),
            hoop: training.tieneAro === "yes" ? 1 : 0,
            limit: 50,
            partner: training.tieneCompanero === "yes" ? 1 : 0
        }

        const result = await getQuickTrainingExercises(params).then( response => { 

            if(response.status === 200){ 
                const data = response.data
                return data.results
            }
        })
        .catch( error => {
            if(error){
                throw new Error('Internal Error')
            }
        })

        if(result){
            setExercises(result)
        }
      },
      [],
    )
    
  return {
    handleGetQuickTrainingExercises,
    exercises
  }
}
