import { useCallback } from 'react';

//services
import { postExercise } from 'services/entrenamiento';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';
import { createdExerciseAtom } from 'recoil/createdExerciseAtom';

interface IPostExercise {
  excludeExercises?: string | number;
  includeExercises?: string | number;
}

export const usePostExercise = () => {
  const setToast = useSetRecoilState(toastState);
  const setCreateExercise = useSetRecoilState(createdExerciseAtom);

  const handlePostExercise = useCallback(
    async (body: IPostExercise) => {
      try {
        const status = await postExercise(body);
        const message = body && body.excludeExercises ? 'Marcaste la técnica como no disponible' : 'Marcaste la técnica como disponible';

        if (status === 200) {
          setCreateExercise(status);
          setToast({
            isOpen: true,
            color: 'success',
            message,
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },
    [setToast, setCreateExercise]
  );

  return { handlePostExercise };
};
