import { useCallback, useState } from 'react';

//recoil
import { useSetRecoilState } from 'recoil';
import { createdExerciseAtom } from 'recoil/createdExerciseAtom';

//services
import { getBasisExercises } from 'services/entrenamiento';

export const useGetBasisExercises = () => {
  const [exercises, setExercises] = useState([]);

  const setCreateExercise = useSetRecoilState(createdExerciseAtom);

  const handleGetBasisExercises = useCallback(
    async (id: number) => {
      try {
        const response = await getBasisExercises(id);
        setExercises(response);
      } catch (e) {
        console.log(e, 'error');
      } finally {
        setCreateExercise(0);
      }
    },
    [setCreateExercise]
  );

  return { exercises, handleGetBasisExercises };
};
