import { useEffect, useRef } from 'react';

//ionic
import { IonGrid, IonProgressBar, IonAvatar, IonImg, IonText } from '@ionic/react';

//css
import './progress-component.css';

//component

import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState, userLevelsAtom } from 'recoil/User';

//hooks
import { usePostMedia } from 'hooks/media/usePostMedia';
import { handleLevels } from 'helper/handleLevels';

const ProgressHeader: React.FC = () => {
  const user = useRecoilValue(userInfoState);
  const key = user && user.key;
  const level = (user && user.level) || 'Principiante';
  const points = (user && user.points) || 0;
  const lastName = user && user.lastName;
  const firstName = user && user.firstName;
  const userLevels = useRecoilValue(userLevelsAtom)
  const maxPointsRef = useRef(1)
  const levelsRef = useRef("")
  const [handlePostMedia, url] = usePostMedia();

  useEffect(() => {
    if (user.idUser && key) {
      handlePostMedia({ key });
    }
  }, [user, key, handlePostMedia]);

  useEffect(() => {
    const { maxPoints, level } = handleLevels(userLevels, Number(user.idLevel))
    maxPointsRef.current = maxPoints
    levelsRef.current = level
  }, [user])

  return (
    <IonGrid className="p-container">
      <IonGrid className="p-title-container">
        <div className="p-progress-avatar">
          {/* [FALTA] integrar el progreso del usuario */}
          <IonProgressBar className="p-progress-bar" value={points / maxPointsRef.current}></IonProgressBar>
          <IonAvatar style = {{display: "flex", justifyContent: "center", alignItems: "center"}} className="p-avatar p-ion-avatar">{url ? <IonImg src={url} /> : user.pictureURL ? <IonImg src={user.pictureURL} /> : <AddAvatar width="80px" height="80px" />}</IonAvatar>
        </div>

        <div className="flex" style={{ flexDirection: 'column', width: '60%' }}>
          <IonText className="p-user-name">
            {firstName} {lastName}
          </IonText>
          <IonText className="p-user-points">
            {level} - {points} puntos
          </IonText>
          <IonText className="p-user-points-description">Te faltan {maxPointsRef.current - points} puntos para pasar al nivel {levelsRef.current}</IonText>
        </div>
      </IonGrid>
    </IonGrid>
  );
};

export default ProgressHeader;
