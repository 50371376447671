import { useEffect, useState } from 'react';

//ionic
import { IonButton, IonGrid, IonRow, IonText, useIonRouter } from '@ionic/react';

import './LiveSessions.css';

//components
import SingleLiveSession from './SingleLiveSession';

//hooks
import { useGetSessions } from '../../../hooks/sessions/useGetSessions';



const LiveSessions: React.FC = () => {
  const [limit, setLimit] = useState(2);
  const [sessions, handleGetSessions] = useGetSessions();
  const navigation = useIonRouter();
  const today = new Date();
  const sessionsNotExpired = sessions.filter(session => {
    var d = new Date(session['eventDate']);
    return d.toISOString() > today.toISOString()
  });

  useEffect(() => {
    handleGetSessions({ future: limit });
  }, [handleGetSessions, limit]);

  return (
    <IonGrid className="ls-container">
      <IonText className="ls-title">Sesiones en Vivo</IonText>

      {sessions &&
        sessionsNotExpired &&
        sessions.length !== 0 &&
        sessionsNotExpired.map((item, index) => {
          return <SingleLiveSession session={item} key={index} />;
        })}
        { !sessions ||
          !sessions.length ||
          !sessionsNotExpired &&
        <IonGrid className="ls-box empty-live" style={{ display: 'flex', flexDirection: 'row' }} >
          <IonRow className="ls-box-first-child">
            <IonText className="ls-chat-name">Próximamente se publicará programación</IonText>
          </IonRow>
        </IonGrid>
        }
      
      <IonButton className="ls-button" fill="clear" onClick={() => navigation.push('/sesiones', 'forward', 'replace')}>
        Más Sesiones en Vivo
      </IonButton>
    </IonGrid>
  );
};

export default LiveSessions;
