import ReactPlayer from "react-player"
import { TimerProgressBar } from "../timer-progress/TimerProgress"
import { useEffect, useState } from "react";
import { IExercisesQuick } from "interfaces/IEntraniento";
import { IEntrenamiento, indexTrainingAtom, listExerciseAtom } from "recoil/TrainingAtom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { VideoTrainingBody } from "../video-training-body/VideoTrainingBody";
import { handleVideoTime } from "helper/handleVideoTime";
import { exerciseMinutesDoneAtom } from "recoil/exercisesAtom";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation'
import { CountDownTimer } from "../CountdownTimer/CountDownTimer";
import { CountDownStart } from "../CountDownStart/CountDownStart";
import { CountDownText } from "../CountDownText/CountDownText";
import { orientationAtom } from "recoil/OrientationAtom";
import { ModalVideo } from "components/entrenamiento/modalVideo";
import bienvenida from 'assets/Loading_huevo_2.gif'

export interface IVideotraining {
    listExercise: IExercisesQuick[],
    training: IEntrenamiento,
}

export interface IMinuteExercise {
    idTraining: number,
    minutes: number,
}

export const VideoTraining: React.FC <IVideotraining>= ({ listExercise, training })=>{ 

    const [ url, setUrl ] = useState<string>("")
    const [key, setKey] = useState<string>('')
    const [ loop, setLoop ] = useState<boolean>(true)
    const [index, setIndex] = useRecoilState(indexTrainingAtom)
    const [isPlaying, setIsPlaying] = useState(false)
    const [ progress, setProgress ] = useState(0)
    const setListExercise = useSetRecoilState(listExerciseAtom)
    const [ minutesExercise, setMinutesExercise ] = useRecoilState(exerciseMinutesDoneAtom)
    let time:number = training.duracion.valueOf() * 60
    const [timeRemaining, setTimeRemaining] = useState<any>(time);
    const [ orientation, setOrientation] = useRecoilState(orientationAtom)
    const [isReady, setIsReady] = useState(false);
    const [isBuffered, setIsBuffered ] = useState(false)
    const [showCountdown, setShowCountdown] = useState(false);

    const handleVideoToPlay = ()=>{ 
       const firstCompleteIndex = listExercise.findIndex(element => element.completed === 0)
       if(firstCompleteIndex !== -1){
        setIndex(index)
        setTimeRemaining(handleVideoTime(index, training.duracion ))
        setKey(listExercise[index].key)
       }
       setLoop(true)
    }

    const handleMinutesToPost = ()=>{ 

        let arrayToPost:IMinuteExercise[] = listExercise.map( (element,idx)=>{ 

            if(idx === index && minutesExercise[index]){
                let minutes:number = minutesExercise[index].minutes
                minutes+=1
                return{
                    idTraining: element.idTraining,
                    minutes: minutes
                }
            }else{ 
               return {
                idTraining: element.idTraining,
                minutes: minutesExercise[idx] ? minutesExercise[idx].minutes : 0
                }
            }
            
        })

        setMinutesExercise(arrayToPost)
    }

    const handlePreparadoComplete = () => {
        setIsBuffered(false)
        setIsReady(true);
        setShowCountdown(true);
    };
    
    const handleCountdownComplete = () => {
    setShowCountdown(false);
    };

    useEffect(() => {
      handleVideoToPlay()
    }, [index])

    useEffect(() => {
        if(key !== ''){
            setUrl(`https://${process.env.REACT_APP_TRAINING_BASE_URL}${key}`)
        }
    }, [key])

    useEffect(()=>{ 
        if(progress === 180){

            let exerciseToComplete = listExercise.map( (element, idx) => {
                if(idx === index){
                    return{ 
                        ...element,
                        completed: 1
                    }
                }else{
                    return { ...element }
                }
            })

            if(exerciseToComplete){
                setListExercise(exerciseToComplete)
            }

            if(listExercise.length - 1 !== index){
                setIndex(prevState => prevState + 1)
                setProgress(0)
                setIsReady(false)
            }
        }

        if(progress % 60 === 0 && progress !== 0 && timeRemaining !== 0){
            handleMinutesToPost()
        }

    },[ progress ])


    useEffect(() => {
        
        const handleOrientationChange = ()=>{
            setOrientation(ScreenOrientation.type)
        }
        window.addEventListener("orientationchange", handleOrientationChange);      
        
        return ()=>{ 
            window.removeEventListener('orientationchange', handleOrientationChange)
        }
        
    }, [ScreenOrientation.type]);

    return( 
        <div style={{ width:'100%'}}>
            {isBuffered && !isReady && <CountDownText onComplete={handlePreparadoComplete} />}
            {isReady && showCountdown && <CountDownStart seconds={10} onComplete={handleCountdownComplete} />}
            {isReady && !showCountdown && <CountDownTimer minutes={3} />}
                <ReactPlayer 
                    className="video-training-player"
                    key={url}
                    playing={isPlaying}
                    controls={false} 
                    url={url} 
                    width='100%'
                    playsinline
                    height={orientation === 'portrait-primary' ? '220px' : '60%'} 
                    loop={loop}
                    onReady={()=>{
                        setIsBuffered(true)}}
                    config={{
                        file:{
                            attributes:{
                                poster:bienvenida
                            }
                        }
                    }}
                />
                <TimerProgressBar
                    progress={progress}
                    setProgress={setProgress} 
                    setIndex={setIndex} 
                    index={index} 
                    listExercise={listExercise} 
                    setIsPlaying={setIsPlaying} 
                    basisName={listExercise[index].basisName} 
                    name={listExercise[index].name} 
                    totalTime={time}
                    setCurrentTime={setTimeRemaining}
                    currentTime={timeRemaining}
                    isReady={isReady}
                    setIsReady={setIsReady}
                    showCountdown={showCountdown}
                />
            {
                orientation === 'portrait-primary' &&
                    <>
                        <VideoTrainingBody details={listExercise[index].detailsTraining ? listExercise[index].detailsTraining : listExercise[index].detailsExercise } />                
                    </>
            }
            <ModalVideo url={listExercise[index].videoURL} trigger={String(listExercise[index].basisName)} />
        </div>
    )
}