import { useEffect } from 'react';

//ionic
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonText, IonGrid, IonToast, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//css
import './mis-favoritos.css';

//recoil
import { userInfoState } from 'recoil/User';
import { toastState } from 'recoil/ToastAtom';
import { favoriteStatusAtom } from 'recoil/favoriteStatusAtom';
import { useRecoilValue, useRecoilState } from 'recoil';

//hooks
import { useGetUserFavorites } from 'hooks/favorites/useGetUserFavorites';

//interface
import { IFavorite } from 'interfaces/IFavorite';

//componentes
import EmptyState from 'components/EmptyState/EmptyState';
import FavoritesBlock from 'components/favorites/FavoritesBlock';
import { notesStatusAtom } from 'recoil/NotesStatusAtom';

const categoryMap = {
  1: 'Tiro',
  2: 'Defensa',
  3: 'Pase',
  4: 'Táctica individual',
  5: 'Técnica Individual',
};

export const MisFavoritos: React.FC = () => {
  const user = useRecoilValue(userInfoState);
  const { idUser } = user;

  const [toast, setToast] = useRecoilState(toastState);

  const notesStatus = useRecoilValue(notesStatusAtom);
  const favoriteStatus = useRecoilValue(favoriteStatusAtom);

  const { favorites, handleGetUserFavorites } = useGetUserFavorites();

  useEffect(() => {
    if (idUser) handleGetUserFavorites({ limit: 1000 }, idUser);
  }, [handleGetUserFavorites, idUser]);

  useEffect(() => {
    if (favoriteStatus === 200 || notesStatus === 200) {
      handleGetUserFavorites({ limit: 1000 }, Number(idUser));
    }
  }, [handleGetUserFavorites, idUser, favoriteStatus, notesStatus]);

  const groupedExercises: { [idBasis: string]: IFavorite[] } = favorites.reduce((result, exercise) => {
    const { idBasis } = exercise;
    const category = categoryMap[idBasis];
    if (result[category]) {
      result[category].push(exercise);
    } else {
      result[category] = [exercise];
    }
    return result;
  }, {} as { [idBasis: string]: IFavorite[] });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="f-header-container">
            <IonButtons slot="start">
              <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs" />
            </IonButtons>
            <IonText className="f-header-title">Mis Favoritos</IonText>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid className="f-container">
          {Object.entries(groupedExercises) && Object.entries(groupedExercises).length !== 0 ? (
            <>
              <IonText className="f-block">Bloques</IonText>
              <IonGrid className="f-images-container">
                {Object.entries(groupedExercises).map(([blockName, favorites], index) => {
                  return <FavoritesBlock key={index} blockName={blockName} blockData={favorites} />;
                })}
              </IonGrid>
            </>
          ) : (
            <EmptyState title="Todavía no tienes técnicas favoritas" subtitle="Guarda tus videos preferidos del Programa de Aprendizaje como favoritos" marginTop="40px" />
          )}
        </IonGrid>

        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          icon={toast.icon}
          cssClass={'css-success-toast'}
          buttons={toast.button}
          onDidDismiss={() => setToast({ isOpen: false })}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};
