import { handleListVideoTraining } from 'helper/handleListVideoTraining'
import { IExercisesQuick } from 'interfaces/IEntraniento'
import React, { useCallback, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { listExerciseAtom } from 'recoil/TrainingAtom'

export const useGetListExerciseTraining = () => {

    const setListExercise = useSetRecoilState(listExerciseAtom)

    const handleGetListExerciseTraining = useCallback(
      (data:IExercisesQuick[]) => {
        
        const result = handleListVideoTraining(data)

        if(result){
            setListExercise(result)
        }
      },
      [],
    )
    
  return {
    handleGetListExerciseTraining
  }
}
