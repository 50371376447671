//ionic
import { IonGrid } from '@ionic/react';

//css
import './progress-component.css';

//components
import { HistorialSesiones } from './HistorialSesiones';

//components

const TabSesiones: React.FC = () => {
  return <HistorialSesiones />;
};

export default TabSesiones;
