import { useRef, useState } from 'react';

//ionic
import { IonButtons, IonContent, IonGrid, IonHeader, IonPage, IonText, IonToolbar, IonButton, useIonRouter, IonIcon, useIonViewDidEnter, IonPopover, useIonPopover, useIonViewDidLeave, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//css
import './fundamentos.css';

//components
import ModalEjerciciosInsuficientes from 'components/modal/ejercicios-insuficientes/EjerciciosInsuficientes';

//hooks
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { useGetFundamentals } from 'hooks/entrenamiento-rapido/useGetFundamentals';
import { basisState, trainingState } from 'recoil/TrainingAtom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { handleLimitChoises } from 'helper/handleLimitChoises';
import { FundamentosCard } from './FundamentosCard';
import { FundamentosPopover } from './FundamentosPopover';
import { IFundamentals } from 'interfaces/IEntraniento';

export const Fundamentos: React.FC = () => {
  const { IsOpen, handleOpenModal, handleCloseModal } = useOpenModal();
  const { fundamentals, handleGetFundamentals } = useGetFundamentals()
  const [trainingInfo, setEntrenamiento] = useRecoilState(trainingState);
  const setBasis = useSetRecoilState(basisState)
  const selectedFundamental = useRef<Number[]>([])
  const selectedBasis = useRef<IFundamentals[]>([])
  const [minutesSum, setMinutesSum] = useState<number>(0)
  let limitSelections: Number = handleLimitChoises(trainingInfo.duracion)
  const navigation = useIonRouter();
  const [present, dismiss] = useIonPopover(FundamentosPopover, {
    onDismiss: (data: any, role: string) => dismiss(data, role),
    
  });

  const handleRedirect = () => {
    setBasis(selectedBasis.current)
    navigation.push('/loading-training', 'forward', 'replace');
  };

  useIonViewDidEnter(()=>{ 
    handleGetFundamentals()
  })

  useIonViewDidLeave(()=>{
    selectedFundamental.current = []
    selectedBasis.current = []
  })

  const handleSelection = (fundamental:IFundamentals) => {

    let exists = selectedFundamental.current.find( selectedFundamental => selectedFundamental === fundamental.idModule)
    if(selectedFundamental.current.length !== limitSelections && exists === undefined){
      selectedFundamental.current.push(fundamental.idModule)
      selectedBasis.current.push(fundamental)
      setMinutesSum((prevState)=> prevState + Number(fundamental.minutes) )
    }

    if(exists){
      const array = selectedFundamental.current
      const arrayBasis = selectedBasis.current
      const index = array.indexOf(fundamental.idModule)
      if(index !== -1){
        array.splice(index, 1)
        arrayBasis.splice(index, 1)
      }
      selectedFundamental.current = array
      selectedBasis.current = arrayBasis
      setMinutesSum((prevState)=> prevState - Number(fundamental.minutes) )
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <article style={{ display:'flex', width: 300 }}>
              <IonButtons slot="start" onClick={()=> {
                setEntrenamiento({
                  duracion: 0,
                  tieneAro: '',
                  tieneCompanero: ''
                })
                navigation.push('/crear-entrenamiento', 'forward' , 'replace')
                
                }}>
                <IonIcon style={{ width:24, height:24, marginLeft:10 }} icon={arrowBackOutline} />
              </IonButtons>
              <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Programa de aprendizaje</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className="ce-grid">
          <div className="ce-container">
            <IonText className="ce-title">¡Crea un entrenamiento a tu medida!</IonText>
            <IonText className="ce-subtitle">Selecciona los fundamentos a entrenar</IonText>
            <div className="flex" style={{ marginTop: '2px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {fundamentals.length !== 0 && fundamentals.map((item, index) => {
                return (
                  <FundamentosCard fundamental={item} handleSelection={handleSelection} index={index} exists={selectedFundamental.current} limitSelections={limitSelections} />
                )
              })}
            </div>
          </div>
            <IonButton
              type="submit"
              fill="clear"
              id={`${minutesSum >= Number(trainingInfo.duracion) ? '' : 'hover-trigger'}`}
              style={{ marginTop: '28px' }}
              className={`${minutesSum >= Number(trainingInfo.duracion) ? 'ce-button' : 'ce-button-disabled'}`}
              onClick={(e:any) => {
                if (minutesSum >= Number(trainingInfo.duracion)) 
                handleRedirect()
                else{
                  handleOpenModal('ejerciciosInsuficientes')
                }
              }}
            >
              Crear Entrenamiento
            </IonButton>
        </IonGrid>
      </IonContent>

      <ModalEjerciciosInsuficientes isOpen={IsOpen.ejerciciosInsuficientes} handleClose={handleCloseModal} />
    </IonPage>
  );
};
