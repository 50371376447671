import { IonAvatar, IonButton, IonIcon, IonImg, isPlatform, useIonRouter } from '@ionic/react'
import { Fieldset } from 'components/FieldSet/FieldSet'
import { FieldSetPassword } from 'components/FieldSet/FieldSetPassword'
import { Form, Formik } from 'formik'
import { mail } from 'ionicons/icons'
import { signUpValidation } from './SignUpValidate'
import { AvatarSelector } from 'pages/login/components/AvatarSelector'
import { useRecoilValue } from 'recoil'
import { avatarState } from 'recoil/AvatarAtom'
import { IUserCreate } from 'services/interfaces/Users'
import { useCreateUser } from 'hooks/users/useCreateUser'
import { userState } from 'recoil/UserImage'
import { AddAvatar } from 'components/Icons/avatars/AddAvatar'
import { Device } from '@awesome-cordova-plugins/device';

export const SignUpForm = () => {

    const navigation = useIonRouter()
    const { handleCreateUser} = useCreateUser()
    const handleGoToLogin = ()=>{
        navigation.push('/login/signin', 'forward', "replace")
      }
    const avatarValue = useRecoilValue(avatarState)
    const userImage = useRecoilValue(userState)
    return(
      <>
        <article style={{ width:'100%', display:'flex', justifyContent:'center' }}>
          <IonAvatar style={{ width:80, height:80 }} id="create-user-image">
            {userImage.photo !== '' ? <IonImg src={String(userImage.photo)} /> : <AddAvatar />}
          </IonAvatar>
        </article>
        <Formik
          initialValues={{
            email: '',
            password:'',
            firstName:'',
            idAvatar: 0,
            lastName:''
          }}
          enableReinitialize={true}
          validationSchema={signUpValidation}
          onSubmit={(values) => {
            const uuid = isPlatform('ios') ? `${values.email}-${Device.model}` : Device.uuid
            const formValues:IUserCreate = {
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              idAvatar: avatarValue.id ?? 1,
              lastName: values.lastName,
              client: isPlatform('ios') ? 'iOS' : isPlatform('android') ? 'android' : 'web',
              deviceUUID: uuid
            }
    
            handleCreateUser(formValues, userImage)
          }}
          >
          {({ setFieldTouched ,dirty, isValid, values,errors, touched }:any) => (
            <Form>
              <AvatarSelector trigger='create-user-image' />
                <article style={{ display:'flex', flexDirection:'column', alignItems:'center', padding: "32px"  }}>
                    <Fieldset
                        name="firstName"
                        type="name"
                        label="Nombre *"
                        placeholder=""
                        value={values.firstName}
                        errors={errors.firstName}
                        setFieldTouched={setFieldTouched}
                        touched={touched.firstName}
                        isTouched={true}
                    />
                    <Fieldset
                        name="lastName"
                        type="lastName"
                        label="Apellido *"
                        placeholder=""
                        value={values.lastName}
                        errors={errors.lastName}
                        setFieldTouched={setFieldTouched}
                        touched={touched.lastName}
                        isTouched={true}
                    />
                    <Fieldset
                        name="email"
                        type="email"
                        label="Email *"
                        placeholder=""
                        value={values.email}
                        errors={errors.email}
                        setFieldTouched={setFieldTouched}
                        touched={touched.email}
                        isTouched={true}
                    />
                    <FieldSetPassword
                        name="password"
                        type="password"
                        label="Contraseña *"
                        placeholder=""
                        value={values.password}
                        errors={errors.password}
                        setFieldTouched={setFieldTouched}
                        touched={touched.password}
                        isTouched={true}
                    />
                </article>
                <article style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <IonButton type='submit' color={!(dirty && isValid) ? 'medium' : "orange"} fill='solid'  style={{ width:300, fontWeight:700 }} disabled={!(dirty && isValid)}>
                  <IonIcon slot="start" icon={mail}></IonIcon>
                      Crear cuenta con Email
                  </IonButton>
                  <p onClick={handleGoToLogin} style={{ fontSize:14, fontWeight:400 }}> ¿Ya tienes cuenta?<span style={{marginLeft:5, fontSize:14, fontWeight:400, color: '#B83A02' , textDecoration:'underline'}}>Iniciar sesión</span> </p>
                </article>
            </Form>
          )}
        </Formik>
      </>
    )
}
