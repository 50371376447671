import { IonIcon } from '@ionic/react'
import './stepper.css'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import lockCircleIcon from 'assets/lock-icon.svg'
import { Divider } from 'components/Divider'
import { useRecoilValue } from 'recoil'
import { userInfoState } from 'recoil/User'


interface IStep { 
    state:StepEnum,
    children: any,
    data: any,
    index: any
}

export enum StepEnum {
    disabled = "disabled",
    completed = 1,
    incomplete = 0
}

export const Step: React.FC <IStep> = ({ children, state, data, index })=>{ 

    const user = useRecoilValue(userInfoState);
    
    const handleStepBlocked = ()=>{ 
        const firstIndexIncomplete = data.length !== 0 && data.findIndex( (element:any) => element.completed === 0);
        
        if(user.idSuscription === null && data[index].idExercise === 95){
            return StepEnum.disabled
        }
        else if(firstIndexIncomplete === index){
            return StepEnum.incomplete
        }else if(data[index].completed === 1){
            return StepEnum.completed
        }else {
            return StepEnum.disabled
        }
    }

    return(
        <div style={{ width:'100%', display:'flex'}}>
            <div className='step-icon-row'>
                <IonIcon style={{ width:40, height:40, color:'#ED4D07', borderColor:'#ED4D07' }} icon={ handleStepBlocked() === 0 ? ellipseOutline : handleStepBlocked() === 1 ? checkmarkCircle : lockCircleIcon} />
                { data.length - 1 !== index ? <div className='step-line'></div> : null }
            </div>
            <div style={{ width:'100%' }}>
                {children}
                { data.length - 1 !== index ? <Divider width='100%' /> : null }
            </div>
        </div>
    )
}