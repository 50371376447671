import { useCallback } from 'react';

//services
import { postExerciseCompleted } from 'services/aprendizaje';

export const usePostExerciseCompleted = () => {
  const handlePostExerciseCompleted = useCallback(async (idUser: number, idExercise: number) => {
    await postExerciseCompleted(idUser, idExercise);
  }, []);

  return { handlePostExerciseCompleted };
};
