import axios from './BaseConfig';
import { IProgressByTime } from './interfaces/IProgress';

export const getProgressByTime = async(params:IProgressByTime)=>{ 

    return await axios.get('/progress/points', { 
        params:{
            period: params.period
        }
    })
}

export const getProgressLearningProgram = async()=>{ 

    return await axios.get('/progress/exercises/total')
}

export const getProgressHistory = async()=>{ 
    return await axios.get('/progress/exercises/history')
}

export const getTrainingHistory = async()=>{
    return await axios.get('/progress/trainings/history')
}

export const putTrainingGoals = async(id:number, goal:string)=>{ 
    return await axios.put(`/users/${id}`, { trainingGoals: goal })
}

export const getTrainingChart = async()=>{
    return await axios.get('/progress/trainings/week')
}

export const getTrainingMonth = async()=>{
    return await axios.get('/progress/trainings/month')
}