import { useEffect, useState } from 'react';

//ionic
import { IonButton, IonGrid, IonText, useIonRouter } from '@ionic/react';

//css
import './NextCampus.css';

//hooks
import { useGetCampus } from 'hooks/campus/useGetCampus';

//components
import NextSingleCampus from './NextSingleCampus';

const NextCampus: React.FC = () => {
  const limit = 1
  const [campus, handleGetCampus] = useGetCampus();
  const navigation = useIonRouter()

  useEffect(() => {
    handleGetCampus({ limit });
  }, [handleGetCampus]);

  return (
    <IonGrid className="nc-container">
      <IonText className="nc-title">Próximos Campus</IonText>

      {campus &&
        campus.length !== 0 &&
        campus.map((item, index) => {
          return <NextSingleCampus key={index} campus={item} />;
        })}

      <IonButton className="nc-button" fill="clear" onClick={() => navigation.push('/campus', 'forward', 'replace')}>
        Más Campus
      </IonButton>
    </IonGrid>
  );
};

export default NextCampus;
