import { useCallback } from 'react';

//services
import { postContactInfo } from 'services/user';
import { IContactInfo } from 'services/interfaces/Users';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { useSetRecoilState } from 'recoil';
import { toastState } from 'recoil/ToastAtom';

export const usePostContact = () => {
  const setToast = useSetRecoilState(toastState);

  const handlePostContact = useCallback(
    async (idUser: number, body: IContactInfo) => {
      try {
        const status = await postContactInfo(body, idUser);
        if (status === 200) {
          setToast({
            isOpen: true,
            color: 'success',
            message: 'Enviaste tu consulta, en breve te contestaremos',
            icon: CheckIcon,
            button: [
              {
                icon: CloseSvg,
                side: 'end',
                role: 'cancel',
                cssClass: 'toast-button',
              },
            ],
          });
        }
      } catch (e) {
        console.log(e, 'error');
      }
    },
    [setToast]
  );

  return { handlePostContact };
};
