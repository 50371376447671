import { IMinuteExercise } from 'recoil/exercisesAtom';
import axios from './BaseConfig';

interface IPostExercise {
  excludeExercises?: string | number;
  includeExercises?: string | number;
}

interface IPostExercisePoints { 
  trainings: IMinuteExercise[],
  idUserTraining: number
}

export interface IPostUserExercise {
  hoop: number,
  partner: number,
  duration: number
}

export const getBasisExercises = async (id: number) => {
  try {
    const res = await axios.get(`/basis/${id}/exercises`);
    return res.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const postExercise = async (body: IPostExercise) => {
  try {
    const res = await axios.post(`/exercises/exclude`, body);
    return res.status;
  } catch (e) {
    console.log('error', e);
  }
};

export const postUserExercise = async ( body: IPostUserExercise) =>{ 
  return await axios.post('/trainings', { ...body })
}

export const postExercisePoints = async(params: IPostExercisePoints)=>{ 
  return await axios.put(`/trainings/${params.idUserTraining}`, { trainings:params.trainings })
}

export const getExerciseById = async(id:string) => { 
  return await axios.get(`/exercises/${id}`)
}
