import Favorite from 'components/favorites/Favorite'
import { Step } from './Step'
import './stepper.css'
import { Exercise } from 'pages/aprendizaje/modulo/components/Exercise'

interface IStepper { 
    data: any[],
}

export const VerticalStepper: React.FC <IStepper>= ({ data })=>{ 


    return(
        <div className="stepper-container">
            {
                data.length !== 0 ?
                data.map( (element:any, index) => { 
                    
                    return( 
                    <div style={{ width:'100%', display:'flex' }} key={element.idExercise}>
                        <Step index={index} data={data}  children={<Exercise exercises={data} index={index} exercise={element} />} state={element.completed} />
                    </div>)
                })
                :
                null
            }
        </div>
    )
}