import { useState } from 'react';

//hooks
import { useGetSessions } from 'hooks/sessions/useGetSessions';
import { useGetUserSessions } from 'hooks/sessions/useGetUserSessions';

//components
import { SingleSession } from './SingleSession';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

//ionic
import { IonText, useIonViewDidEnter } from '@ionic/react';

//css
import './next-sessions.css';
interface Sesiones {
  idSession: number;
  title: string;
  category: string;
  label: string;
  contactInfo: string;
  description: string;
  eventURL: string;
  eventDate: number;
  duration: number;
  idCoach: number;
  fullName: string;
  role: string;
  keyCoach: string;
  videoURL: string | null;
  imageURL: string;
}

export const NextSessions: React.FC = () => {
  const [sessions, handleGetSessions] = useGetSessions();
  const userInfo = useRecoilValue(userInfoState);
  const { handleGetUserSessions, sessions: sessionByUser } = useGetUserSessions();

  useIonViewDidEnter(() => {
    handleGetSessions({ future: 1 });
    handleGetUserSessions(Number(userInfo.idUser), { future: 1 });
  });

  function obtenerMesYAnio(fecha: number): string {
    const meses: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaObj: Date = new Date(fecha);
    const mes: number = fechaObj.getMonth();
    const anio: number = fechaObj.getFullYear();
    return `${meses[mes]} ${anio}`;
  }

  function agruparSesionesPorMes(sesiones: Sesiones[]): { [mes: string]: Sesiones[] } {
    const sesionesAgrupadas: { [mes: string]: Sesiones[] } = {};
    sesiones.forEach((sesion) => {
      const mes: string = obtenerMesYAnio(sesion.eventDate);
      if (!sesionesAgrupadas[mes]) {
        sesionesAgrupadas[mes] = [];
      }
      sesionesAgrupadas[mes].push(sesion);
    });

    return sesionesAgrupadas;
  }

  const sesiones = agruparSesionesPorMes(sessions);

  const arraySesionesAgrupadas = Object.entries(sesiones).map(([mes, sesiones]) => ({
    mes,
    sesiones,
  }));

  return (
    <>
      {arraySesionesAgrupadas &&
        arraySesionesAgrupadas.length !== 0 &&
        arraySesionesAgrupadas.map(({ mes, sesiones }) => {
          return (
            <>
              <IonText className="month-sesiones">{mes}</IonText>
              {sesiones &&
                sesiones.length !== 0 &&
                sesiones.map((item, index) => {
                  return <SingleSession reservedSession={sessionByUser} session={item} key={index} />;
                })}
            </>
          );
        })}
    </>
  );
};
