import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonMenuButton } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

export const MainEntrenamiento: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar style={{ padding: '10px' }}>
            <article style={{ display: 'flex' }}>
              <IonButtons slot="start">
                <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs" />
              </IonButtons>
              <p style={{ fontSize: 16, marginLeft: 10, fontWeight: 700 }}>Entrenamiento rápido</p>
            </article>
          </IonToolbar>
        </IonHeader>
      </IonContent>
    </IonPage>
  );
};
