import React from 'react';

//ionic
import { IonModal, IonGrid, IonImg, IonRow, IonText, IonButton, useIonViewDidLeave } from '@ionic/react';

//icons
import close_modal from '../../../../assets/close_modal.svg';

import './cancelar-suscripcion.css';
import { useCancelAutomaticRenewal } from 'hooks/subscription/useCancelAutomaticRenewal';
import { CancelarSubscripcionContent } from './CancelarSubscripcionContent';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { stripeErrorAPI } from 'recoil/StripeErrorAtom';
import { SubscriptionSuccesfulCancel } from 'pages/subscription/SubscriptionSuccesfulCancel';

interface ICancelarSuscripcionModal {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
}

function CancelarSuscripcionModal({ showModal, setShowModal }: ICancelarSuscripcionModal) {

  const { handleCancelAutomaticRenewal } = useCancelAutomaticRenewal()
  const [stripe, setStripe] = useRecoilState(stripeErrorAPI)

  useIonViewDidLeave(()=>{ 
    setStripe({ code:null, declineCode:''})
  })

  const dismiss = ()=>{ 
    setStripe({ code:null, declineCode:''})
    setShowModal(false)
  }
  
  return (
    <IonModal isOpen={showModal} className="ul-modal" onDidDismiss={dismiss}>
      <IonGrid className="wrapper">
        <IonRow className="cs-icon-row">
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>
        {
          stripe.code === "cancelSuccess" ?
          <SubscriptionSuccesfulCancel /> 
          :
          <CancelarSubscripcionContent setShowModal={setShowModal} handleCancel={handleCancelAutomaticRenewal} />

        }
      </IonGrid>
    </IonModal>
  );
}

export default CancelarSuscripcionModal;
