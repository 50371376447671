//ionic
import { IonRow, IonGrid, IonText, useIonRouter, IonIcon, IonCol } from '@ionic/react';

//css
import './SingleSession.css';
import { checkmarkCircle } from 'ionicons/icons';

interface Session {
  idSession: number;
  title: string;
  category: string;
  label: string;
  contactInfo: string;
  description: string;
  eventDate: number;
  fullName: string;
  role: string;
}
interface SingleLiveSessionProps {
  session: Session;
  reservedSession: any;
}

export const SingleSession: React.FC<SingleLiveSessionProps> = ({ reservedSession, session }) => {
  const timestamp = session && session.eventDate;

  const fechaOriginal = new Date(timestamp);

  const nombresDias = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

  const nombreDia = nombresDias[fechaOriginal.getDay()];
  const dia = fechaOriginal.getDate();
  const mes = fechaOriginal.getMonth() + 1;

  const fechaFormateada = `${nombreDia} ${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}`;

  const navigation = useIonRouter();

  const title = session && session.title;

  function eliminar_duplicados(arr: Session[]): Session[] {
    const idsUnicos: number[] = [];
    const resultados: Session[] = [];

    arr.forEach((obj) => {
      if (!idsUnicos.includes(obj.idSession)) {
        idsUnicos.push(obj.idSession);
        resultados.push(obj);
      }
    });

    return resultados;
  }

  const sesiones = eliminar_duplicados(reservedSession);

  return (
    <IonGrid className="ls-box" onClick={() => navigation.push(`/sesiones/${session.idSession}`, 'forward', 'push')}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IonRow className="ls-box-first-child" style = {{width: "20%"}}>
          <IonText className="ls-date">{fechaFormateada}</IonText>
        </IonRow>

        <IonRow className="ls-box-second-child" style = {{width: "80%"}}>
          <IonText className="ls-chat-name">{title}</IonText>

          <IonText class="ls-chat-description">
            {session.fullName}: {session.role}
          </IonText>
        </IonRow>
      </div>
      {sesiones && sesiones.length !== 0
        ? sesiones.map((element: any) => {
            if (Number(element.idSession) === Number(session.idSession)) {
              return (
                <IonGrid className="ls-reserved">
                  <IonRow className="ls-box-first-child">
                    <div className="box-reserved-session">
                      <IonIcon icon={checkmarkCircle} style={{ width: 18, height: 18, fill: '#168404', marginLeft: 5, marginRight: 5 }} />
                      <IonText style={{ fontSize: 14, fontWeight: 700, color: '#168404', whiteSpace: 'nowrap' }}> Sesión Reservada </IonText>
                    </div>
                  </IonRow>
                </IonGrid>
              );
            } else {
              return null;
            }
          })
        : null}
    </IonGrid>
  );
};
