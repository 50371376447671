import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { MainHeader } from 'components/Header/MainHeader';
import { LoginSignUp } from 'pages/login/Login';
import { useRecoilValue } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';
import { IonRouterOutlet } from '@ionic/react';
import { LoginHome } from 'pages/login/LoginHome';
import { LoginSignIn } from 'pages/login/LoginSignIn';
import { loadingAtom } from 'recoil/loadingAtom';
import { LoadingScreen } from 'components/loading/LoadingScreen';
import { RecuperarContraseña } from 'pages/recuperar-contraseña/RecuperarContraseña';

export const AppRoutes = () => {

const token = useRecoilValue(tokenState)
const loadingScreen = useRecoilValue(loadingAtom)
const isOpened = localStorage.getItem('begin')

    return (
        <>
        {
            token.jwtToken ?
                loadingScreen ?
                <LoadingScreen subTitle='Espera unos segundos' title='Creando tu perfil...' />
                :
                <MainHeader />
            :
            <IonReactRouter>
                <IonRouterOutlet id="main">
                    <Route path="/login/home" component={LoginHome} />
                    <Route exact path="/login/signin" component={LoginSignIn} />
                    <Route exact path="/login/signup" component={LoginSignUp} />
                    <Route exact path="/recuperar-contraseña" component={RecuperarContraseña} />
                    {
                        isOpened === "true" ?
                            <Redirect from="/" to="/login/signin" exact />
                        :
                            <Redirect from="/" to="/login/home" exact />
                    }
                </IonRouterOutlet>
            </IonReactRouter>  

        }
        </>
    )
}
