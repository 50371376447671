
export const helpFaq = {
    appHuevo:[
        {
            title:'¿ En qué consiste esta APP?',
            text:"La App CHS es nuestra nueva plataforma digital que tiene como objetivo principal democratizar el acceso al aprendizaje, formación y entrenamiento de calidad y alto nivel, de forma centralizada, ordenada y progresiva para jugadores y entrenadores de básquetbol.        Te ofrece un Programa de Aprendizaje completo en todos los fundamentos del juego para aprender de manera efectiva, mejorar habilidades y aspectos relacionados a la formación y desarrollo.Además, podrás crear luego tus propios Entrenamientos Rápidos personalizados a medida adaptados a tus necesidades específicas y disponibilidad para practicar lo aprendido. También, la posibilidad de poder inscribirte en las Sesiones en Vivo grupales programadas, sobre diferentes temáticas y aspectos del juego, tendencias, y más."  
        }
    ],
    aprendizaje:[
        {
            title:"¿En qué consiste el Programa de Aprendizaje?",
            text:"Consiste en una herramienta completa, centralizada, ordenada y progresiva de aprendizaje basada en nuestra metodología de la mano del gran staff del Campus e invitados. Un programa que incluye lo mejor de toda nuestra experiencia y trayectoria, para tu desarrollo y formación en todos los fundamentos del básquetbol y poder aprender de manera efectiva, mejorar habilidades, aumentar tu rendimiento y nivel como jugador y/o entrenador."
        },
        {
            title:"¿Por cuál fundamento del Programa debo empezar?",
            text:"Puedes comenzar por el fundamento que tu prefieras, lo importante es que tu aprendizaje y formación sea de manera equilibrada en todos ellos para ser un jugador y/o entrenador completo."
        },
        {
            title:"¿Cómo me conviene avanzar en el Programa?",
            text:"Los fundamentos principales agrupados en bloques dentro del Programa de Aprendizaje, como la mayoría de los módulos que los componen, no son correlativos, sino complementarios. Esto significa que el avance gradual, equilibrado y en paralelo por los diferentes módulos es lo que te recomendamos."
        },
        {
            title:"¿Cuánto más rápido avance es mejor?",
            text:"No. Lo importante es la cantidad y calidad del tiempo que inviertes en aprendizaje y entrenamiento, no qué tan rápido lo hagas. Es importante que tengas objetivos de resultado, pero más importante aún es que tengas objetivos de rendimiento que te ayuden a alcanzar esos resultados. Todo el contenido es muy valioso, por lo que, afianzar, incorporar, y naturalizar todos los aspectos y detalles en el recorrido del programa es lo que aumentará tu nivel."
        },
        {
            title:"¿Puedo volver a repetir un video o módulo?",
            text:"Es justamente lo que debes hacer. La repetición, y la práctica constante desde una fuente de calidad es la mejor manera para que mejores día a día."
        },
        {
            title:"¿Tiene el contenido del programa un tiempo límite disponible?",
            text:"Mientras te encuentres suscrito a la App, siempre tendrás disponible todo el contenido del que has avanzado. El repaso, la repetición y asimilación del aprendizaje, desde dónde y cuándo quieras, es lo que queremos ofrecerte y que tengas disponible en todo momento."
        },
        {
            title:"¿Puedo guardar videos y/o partes del programa que prefiera para acceder rápidamente?",
            text:"Todos y cada uno de los videos que componen el Programa de Aprendizaje, pueden guardarse como Favoritos con sólo marcar en el ícono de corazón que se encuentra en el mismo. Esto te va a permitir poder agrupar y encontrar rápidamente tus videos preferidos, los que más te sirven o que quieras volver a ver y practicar."
        },
        {
            title:"¿Para qué sirven las “Notas”?",
            text:"Siempre que aprendas, te capacites y perfecciones, es recomendable que tomes notas de los aspectos importantes o que te quieres llevar contigo. Si bien con la App todo lo encontrarás al alcance de manera centralizada, ordenada y progresiva, nunca está demás las anotaciones personales que te ayuden respecto a cada instancia y aspecto trabajado. Esto además, te ayuda a reforzar, incorporar y hacer foco en detalles en los que poner énfasis."
        }
    ],
    entrenamiento:[
        {
            title:"¿Para qué sirve la sección de Entrenamiento Rápido?",
            text:"Como jugador en formación o en perfeccionamiento, no es recomendable solo una buena explicación y enseñanza si luego no se lleva a la práctica. Tampoco su inversa, ir a la práctica sin conocer los detalles claves, la situación real, para qué sirve y cómo trabajarlo. Por lo tanto, la manera óptima es que complementes ambas, y a medida que avanzas en el Programa de Aprendizaje, entrenar, practicar y perfeccionar lo aprendido de manera personalizada. Esto es, que puedas crear tus propias sesiones de entrenamiento generadas automáticamente a partir de las habilidades y técnicas que elijas, y según las variables claves que selecciones: Tiempo disponible, con aro o sin aro, con compañero o sin compañero."
        },
        {
            title:"¿Cuándo y cómo puedo comenzar mis entrenamientos?",
            text:"Puedes comenzar a entrenar lo aprendido una vez que avances lo mínimo suficiente en el Programa de Aprendizaje. El avance en el programa te irá habilitando ejercicios vinculados para que estén disponibles en la generación de tus entrenamientos. Estos los puedes visualizar en la Lista de Ejercicios disponibles."
        },
        {
            title:"¿Cuánto tiempo debo entrenar? ¿Cuál es la duración conveniente?",
            text:"Si tienes tiempo disponible, entre 60-45 minutos enfocado en un buen entrenamiento está muy bien. Si tienes menos tiempo, no te preocupes, adaptaremos el entrenamiento al tiempo que tengas como las habilidades y fundamentos que quieras entrenar. ¡2/3  entrenamientos a la semana con la APP, sumado a los que ya haces con tu equipo está muy bien, si puedes hacerlo todos los días, mejor aún!!"
        },
        {
            title:"¿Cuántos fundamentos puedo seleccionar para un entrenamiento de 15, 30, 45 y 60 minutos?",
            text:"15 minutos no es mucho tiempo, por lo tanto, en este caso recomendamos sólo hacer foco en 1 ó 2 fundamentos. Para un entrenamiento de 30 y 45  min, puedes seleccionar hasta 3 fundamentos. Para 60 min de entrenamiento hasta 4 fundamentos o habilidades distintas."
        },
        {
            title:"¿Por qué se despliega una previsualización de los ejercicios antes de comenzar el entrenamiento?",
            text:"La previsualización al crear entrenamiento y previo a su comienzo, es la manera de que puedas repasar y familiarizarte con los ejercicios a realizar para evitar frenarte con frecuencia, y puedas ejecutar tu práctica de manera fluida. También una vez comenzado el entrenamiento, puedes pausar para volver a la lista de ejercicios y revisarlos."
        },
        {
            title:"¿Por qué si selecciono con aro y compañero, hay ejercicios sin aro e individuales en mi entrenamiento?",
            text:"Esto es para no crearte tu entrenamiento personalizado con aro o compañero si no lo dispones. En cambio, todos los ejercicios sin aro e individuales puedes ejecutarlos teniendo aro y en grupo."
        },
        {
            title:"¿Qué son los detalles claves?",
            text:"Estos son los puntos y aspectos esenciales en los que te recomendamos poner énfasis en cada temática, técnica o ejercicio para su correcta ejecución y aprendizaje."
        },
        {
            title:"¿Puedo acceder a la explicación completa del ejercicio para refrescar la técnica?",
            text:"Tanto antes de comenzar el entrenamiento como durante el mismo, tienes la posibilidad de acceder directamente al video de aprendizaje completo. Lo ideal es visualizar la lista de ejercicios previamente y en caso de ser necesario reveer el video completo de aprendizaje, hacerlo antes de comenzar tu entrenamiento para que éste sea más dinámico."
        },
        {
            title:"¿Debo intentar entrenar todas las habilidades a pesar de que mi posición sea poste/ interno?",
            text:"¡Claro! Las posiciones en el básquetbol moderno cada vez están menos marcadas y los jugadores deben ser cada vez más completos. Cuanto más completo seas en todos los aspectos y situaciones del juego, mayor será tu nivel como jugador."
        },
        {
            title:"¿Es posible cambiar el tiempo de preparación para cada ejercicio?",
            text:"Efectivamente, puedes hacerlo desde la sección de tu Perfil seleccionando “Tiempo de preparación”."
        },
        {
            title:"He completado mi primer entrenamiento, ¿Ahora qué debo hacer?",
            text:"Continuar por este camino. Mejorar tu rendimiento e incorporar nuevas habilidades, requiere tiempo en constante aprendizaje, práctica y entrenamiento. Los mejores jugadores del mundo son los que más invierten en práctica día a día y su progreso. ¡Sigue trabajando para lograr tus propios objetivos de rendimiento!"
        },
        {
            title:"¿Puedo guardar un Entrenamiento para repetirlo en otro momento?",
            text:"La APP no lo permite, ya que te recomendamos trabajar continuamente en nuevos entrenamientos para no adaptarte fácilmente a lo mismo, y poder convertirte en un jugador versátil y completo."
        }
    ],
    sesiones:[
        {
            title:"¿ En qué consisten las Sesiones en Vivo?",
            text:"Sesiones grupales en tiempo real sobre diferentes temáticas, aspectos del juego, tendencias, y más. Para reservar tu lugar, lo haces desde esta sección, donde verás el calendario programado de las próximas sesiones con suficiente antelación. Allí, además de días y horarios, encontrarás su descripción e introducción, ¡No te pierdas la posibilidad de esta experiencia y aprendizaje interactuando en vivo con expertos!"
        },
        {
            title:"¿Cómo accedo y participo en la sesión que reservé?",
            text:"Al reservar tu participación a una Sesión en Vivo te llegará el link correspondiente para su acceso al correo ingresado en el momento de reservar la sesión. Cabe aclarar, que estas sesiones son online pero en vivo y no quedan grabadas, por lo tanto, es imprescindible ingresar en el enlace recibido en el día y horario programado."
        },
        {
            title:"¿Qué sucede si por algún motivo no puedo asistir a la Sesión en Vivo que había reservado?",
            text:'¡No te preocupes! En ese caso podrás cancelar la sesión reservada hasta 30 minutos antes del inicio de la misma. Al cancelarla vas a tener disponible una sesión en vivo ya pagada en tu cuenta para poder reservar un lugar en otra posterior. Esto lo haces ingresando a "Mis sesiones en vivo" dentro de tu perfil, buscando la sesión correspondiente y apretando el botón de “Cancelar sesión."'
        }
    ],
    proximoCampus:[
        {
            title:"¿Qué son los Próximos Campus?",
            text:"Los Campus son la experiencia plena de nuestra enseñanza. La vivencia pura en nuestros eventos donde convivimos por esos días en torno al basketball. Más de 35 años de trayectoria ininterrumpida lo respaldan, tras la misión de promover la tecnificación y perfeccionamiento en basketball, gracias a un staff de elite nacional e internacional, siempre bajo los principios estandartes de responsabilidad, respeto y enseñanza."
        }
    ],
    progreso:[
        {
            title:"¿Dónde puedo encontrar un seguimiento de mi actividad realizada?",
            text:"La pestaña Progreso te brindará un resumen de toda tu actividad en toda y cada sección de la App. De esta manera, podrás tener un seguimiento claro de tu avance, y ver claramente en qué has trabajado y en qué podrías trabajar más."
        },
        {
            title:"¿Qué son y para qué sirven los Puntos Campus Huevo Sanchez?",
            text:"Significan la medida de tu progreso, lo ganado por lo invertido en aprendizaje y entrenamiento. A medida que sumes puntos irás aumentando tu nivel. Por cada video del Programa de Aprendizaje ganas 5 puntos, por cada minuto de entrenamiento realizado ganas 1 punto, y por cada participación en las Sesiones en Vivo 200 puntos. ¡A nosotros nos gusta retar a los jugadores a ganar 1000 puntos por mes!"
        }
    ],
    cuenta:[
        {
            title:"¿Cuáles son los planes de suscripción?",
            text:"Actualmente existen dos tipos de planes de suscripción: Plan Trimestral $USD 24,99: Todos los beneficios del plan Anual por tres meses. Plan Anual $USD 64,99: Acceso ilimitado al Programa de Aprendizaje, Entrenamientos, y Sesiones en Vivo.."
        },
        {
            title:"¿Cómo puedo editar y actualizar los datos de mi cuenta y perfil?",
            text:'Se puede editar y actualizar los datos en cualquier momento desde la sección "Mi cuenta" dentro del Perfil. Si creaste tu cuenta con redes sociales, no vas a poder editar tu Email y Contraseña, ya que estará ligado a dicha cuenta.'
        },
        {
            title:"¿Puedo compartir mi actividad en Facebook y/o Instagram?",
            text:"(Se puede?)"
        },
        {
            title:"¿Cómo darse de baja?",
            text:'Puedes darte de baja en cualquier momento desde la sección “Mi suscripción” dentro de “Perfil”. Allí, hay un botón "Cancelar suscripción”.'
        }
    ]
}