import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonSegment, IonSegmentButton, IonText, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, arrowForward, chevronBack, chevronForward } from 'ionicons/icons';

interface SegmentButtonProps {
  value: string;
  activeTab: string;
  label: string;
  activeStyles: React.CSSProperties;
}

interface ITabsSegmentProps {
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}

const SegmentButton: React.FC<SegmentButtonProps> = ({ value, activeTab, label, activeStyles }) => {
  return (
    <IonSegmentButton className="segment-button" value={value}>
      <IonText style={activeTab === value ? activeStyles : {}} className="tab-label">
        {label}
      </IonText>
    </IonSegmentButton>
  );
};

const TabsSegment: React.FC<ITabsSegmentProps> = ({ activeTab, setActiveTab }) => {
  const active_styles = { color: '#D64200', fontWeight: 700 };
  const segmentRef = useRef<HTMLIonSegmentElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [scrollLeft, setscrollLeft] = useState(0)
  const [widthContainer, setwidthContainer] = useState(0)
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const segments = [
    { value: 'aprendizaje', label: 'Aprendizaje' },
    { value: 'entrenamiento-rapido', label: 'Entrenamiento Rápido' },
    { value: 'sesiones', label: 'Sesiones en Vivo' },
  ];

  useIonViewDidEnter(()=>{
    if(segmentRef.current){
      setScrollWidth(segmentRef.current?.scrollWidth)
      
    }
    if(containerRef.current){
      setwidthContainer(Number(containerRef.current?.scrollWidth))
    }
  })

  const scrollSegment = ()=>{
    setscrollLeft(Number(segmentRef.current?.scrollLeft))
  }
  
  return (
    <div className="tabs-container" ref={containerRef}>
      {Math.round( scrollWidth - scrollLeft) !== widthContainer && 
      <div style={{ position:'absolute', zIndex:100000, right:2, background:'#F3F3F3', height:45, width:45, display:'flex', alignItems:'center', justifyContent:'center' }}>
        <IonIcon icon={chevronForward} color='medium' style={{ width:22, height:22 }}></IonIcon>
      </div>}
      {scrollLeft !== 0 && 
      <div style={{ position:'absolute', zIndex:100000, left:2, background:'#F3F3F3', height:45, width:45, display:'flex', alignItems:'center', justifyContent:'center' }}>
        <IonIcon icon={chevronBack} color='medium' style={{ width:22, height:22 }}></IonIcon>
      </div>}
      <IonSegment onScroll={scrollSegment} ref={segmentRef} scrollable={true} value={activeTab} onIonChange={(e) => handleTabChange(String(e.detail.value!))}>
        {segments.map((segment) => (
          <SegmentButton key={segment.value} value={segment.value} activeTab={activeTab} label={segment.label} activeStyles={active_styles} />
        ))}
      </IonSegment>
    </div>
  );
};

export default TabsSegment;
