import axios from './BaseConfig';

//interface
import { IUserCreate, IUserEdit, IContactInfo } from './interfaces/Users';

export const createWithCredentials = async (body: IUserCreate) => {
  try {
    const res = await axios.post('/users', { ...body });
    return res;
  } catch (error) {
    console.log('error', error);
  }
};

export const postContactInfo = async (body: IContactInfo, idUser: number) => {
  try {
    const res = await axios.post(`/users/${idUser}/contact`, { ...body });
    return res.status;
  } catch (error) {
    console.log('error', error);
  }
};

export const getUserInfo = async (id: number) => {
  try {
    const res = await axios.get(`/users/${id}`);
    return res.data;
  } catch (error) {
    alert(error);
  }
};

export const editUser = async (id: number, body: IUserEdit) => {
  try {
    const res = await axios.put(`/users/${id}`, { ...body });
    return res.status;
  } catch (error) {
    alert(error);
  }
};

export const getUserLevels = async () => {
  return await axios.get('users/levels');
};

export const deleteUser = async(id:number)=>{ 

  return await axios.delete(`/users/${id}`)
}
