import { useRef } from 'react';

//ionic
import { IonButton, IonIcon, IonModal, IonText } from '@ionic/react';

//css
import './modalNoSubscription.css';

//icons
import { closeOutline } from 'ionicons/icons';

interface IModalNoSubscription {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setOpenSub: (isOpen: boolean) => void;
}

export const ModalNoSubscription: React.FC<IModalNoSubscription> = ({ isOpen, setOpen, setOpenSub }) => {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    setOpen(false);
    modal.current?.dismiss();
  }

  return (
    <IonModal id="modal-sub" ref={modal} onDidDismiss={dismiss} isOpen={isOpen}>
      <div className="wrapper">
        <div className="modal-header">
          <IonIcon onClick={dismiss} color="dark" style={{ width: 24, height: 24 }} slot="icon-only" icon={closeOutline}></IonIcon>
        </div>
        <div className="modal-title">
          <IonText className="ns-title"> Sesiones en Vivo </IonText>
        </div>
        <div className="modal-content">
          <IonText className="ns-subtitle">Ten en cuenta:</IonText>
          <IonText className="ns-subtitle-2" style={{ marginTop: '4px' }}>
            Las sesiones en vivo no estan incluidas en tu cuenta gratis.
          </IonText>
        </div>
        <div className="modal-footer">
          <IonText className="ns-subtitle-2"> Suscribete a nuestros planes y accede a las Sesiones en Vivo. </IonText>
        </div>

        <IonButton
          className="ns-button"
          fill="clear"
          onClick={() => {
            setOpen(false);
            setOpenSub(true);
          }}
        >
          Suscribirme
        </IonButton>
      </div>
    </IonModal>
  );
};
