//ionic
import { useIonRouter } from '@ionic/react';
import { alertCircle } from 'ionicons/icons';

//recoil
import { useSetRecoilState } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';
import { toastState } from 'recoil/ToastAtom';
import { statusAtom } from 'recoil/statusAtom';
import { loadingAtom } from 'recoil/loadingAtom';

//services
import { createWithCredentials } from 'services/user';
import { IUserCreate } from 'services/interfaces/Users';

//assets
import CloseSvg from 'assets/md-close.svg';
import { usePostImageUser } from 'hooks/media/usePostImageUser';
import { IUserImage } from 'services/interfaces/MediaBody';
import { dataURLtoFile } from 'pages/login/components/image-cropper/helper/DataUrlToFile';
import { userState } from 'recoil/UserImage';

export const useCreateUser = () => {
  const navigation = useIonRouter();
  const setToast = useSetRecoilState(toastState);
  const setToken = useSetRecoilState(tokenState);
  const setLoading = useSetRecoilState(loadingAtom);
  const setStatus = useSetRecoilState(statusAtom);
  const setImageUser = useSetRecoilState(userState)
  const [ handlePostMedia ] = usePostImageUser()

  const handleCreateUser = async (user: IUserCreate, userImage:any) => {
    const response = await createWithCredentials(user);
    if (response?.status === 200) {
      setToken({
        jwtToken: response.data.jwtToken,
        userId: response.data.userId,
      });
      setStatus(response.status);
      localStorage.setItem('token', response.data.jwtToken);
      
      setLoading(true);
      if(userImage.photo !== ""){
        let file = dataURLtoFile(userImage, `${Math.random().toString(36).slice(2)}`)
        let body:IUserImage = { 
          fileName: Math.random().toString(36).slice(2),
          id: response.data.userId
        }
        await handlePostMedia(body, file)

        setImageUser({photo:''})
      }


      setTimeout(() => {
        setLoading(false);
        navigation.push('/tabs/home', 'forward', 'replace');
      }, 3500);
    } else {
      setToast({
        isOpen: true,
        color: 'danger',
        message: 'Ya existe un usuario con este email',
        icon: alertCircle,
        button: [
          {
            icon: CloseSvg,
            side: 'end',
            role: 'cancel',
            cssClass: 'toast-button',
          },
        ],
      });
    }
  };

  return { handleCreateUser };
};
