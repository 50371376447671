import { useState } from 'react';
//ionic
import { IonGrid, IonText, IonIcon, useIonRouter, IonAvatar, IonProgressBar } from '@ionic/react';

//components
import Rating from './Rating';
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';

//icons
import { lockClosed, timeOutline, star, starOutline } from 'ionicons/icons';

//components
import RatingModal from './RatingModal';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

interface IModule {
  module: {
    idModule: number;
    name: string;
    idBasis: number;
    createdAt: number;
    deletedAt: number | null;
    totalExercises: number;
    completedExercises: number;
    rating: string;
    note: string;
  };
  index: number;
}

export const SingleModule: React.FC<IModule> = ({ module, index }) => {
  const [showModal, setShowModal] = useState(false);

  const { totalExercises, completedExercises, name, rating, note } = module;

  const navigation = useIonRouter();

  const user = useRecoilValue(userInfoState);
  const { idSuscription } = user;

  const handleSubscription = () => {
    if (!idSuscription && module.idModule !== 10) {
      return (
        <IonText className="avatar h-ion-avatar ba-circle-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IonIcon icon={lockClosed} className="ba-lock-icon" />
        </IonText>
      );
    }

    return (
      <IonText className="avatar h-ion-avatar ba-circle-text">
        {completedExercises} de {totalExercises}
      </IonText>
    );
  };

  const handleBorder = () => {
    if (index === 0 && idSuscription) return { borderTop: 'none' };
    return { borderTop: '1px solid #C2C4C8' };
  };

  return (
    <IonGrid className="ba-single-module-container" style={{ ...handleBorder() }}>
      <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'end' }}>
        <div>
          {!idSuscription && module.idModule !== 10 && <IonText className="ba-included-w-subscription">Incluido con suscripción</IonText>}

          <div onClick={() => (idSuscription || module.idModule === 10) && setShowModal(true)}>
            {Array.from({ length: 5 }).map((_, index) => {
              const ratingValue = index + 1;
              return <IonIcon key={index} icon={ratingValue <= Number(rating) ? star : starOutline} style={{ color: '#ED4D07' }} />;
            })}
          </div>

          <IonText onClick={() => (idSuscription || module.idModule === 10) && navigation.push(`/modulo/${module.idBasis}/${module.idModule}`, 'forward', 'replace')} className="ba-module-name">
            {name}
          </IonText>
          {/* <div className="flex" style={{ alignItems: 'center', marginTop: '5px' }}>
            <IonIcon icon={timeOutline} />
            <IonText className="f-time">3:11</IonText>
          </div> */}
        </div>

        <div className="progress-avatar">
          <IonProgressBar className="progress-bar" value={completedExercises / totalExercises}></IonProgressBar>
          {handleSubscription()}
        </div>
      </div>

      <RatingModal module={module} showModal={showModal} setShowModal={setShowModal} />
    </IonGrid>
  );
};
