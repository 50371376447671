import React, { useCallback, useState } from 'react'
import { getTrainingMonth } from 'services/progress'

export const useGetTrainingMonth = () => {
    
    const [trainingMonth, setTrainingMonth] = useState<any>([])

    const handleGetTrainingMonth = useCallback(
      () => {
        getTrainingMonth().then( response =>{ 

            if(response.status === 200){
                const data = response.data

                const arrayMonth:any = []
                
                Object.keys(data).forEach( key =>{ 
                    if(Number(key) !== 0){
                        arrayMonth.push({data:data[key].moduleName, minutes:data[key].minutes})
                    }
                })

                for( let i=0; i <= 4; i++){
                    if(!arrayMonth[i]){
                        arrayMonth.push({minutes: 0,
                        data: ""})
                    }

                }
                let arrayMinutes = arrayMonth.map((element:any) => Number(element.minutes))

                setTrainingMonth(arrayMonth)
            }
        })  
      },
      [],
    )
    
  return {
    trainingMonth,
    handleGetTrainingMonth
  }
}
