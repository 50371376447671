import { atom } from "recoil";

interface IPlanSelected { 
    id: Number | null
}

export const planSelectedState = atom({
    key: 'planSelected',
    default:{
        id:null
    } as IPlanSelected,
  });