import { atom } from 'recoil';

export const modalWarningAtom = atom({
  key: 'modalWarning',
  default: true,
});

export const modalRecommendationAtom = atom({
  key: 'modalRecommendation',
  default: true,
});

export const modalShowHome = atom({
  key: 'modalHome',
  default: false,
});
