import { useCallback } from 'react';

//recoil
import { toastState } from 'recoil/ToastAtom';
import { useSetRecoilState } from 'recoil';

//services
import { postRating } from 'services/aprendizaje';

//interfaces
import { IRatingBody } from 'services/interfaces/IRatingBody';

//icons
import CloseSvg from '../../assets/md-close.svg';
import CheckIcon from '../../assets/check_icon.svg';

//recoil
import { ratingStatusAtom } from 'recoil/ratingStatusAtom';

export const usePostRating = () => {
  const setToast = useSetRecoilState(toastState);
  const setRatingStatus = useSetRecoilState(ratingStatusAtom);

  const handlePostRating = useCallback(
    async (idBasis: number, idModule: number, body: IRatingBody) => {
      const status = await postRating(idBasis, idModule, body);
      if (status === 200) {
        setRatingStatus(status);
        setToast({
          isOpen: true,
          color: 'success',
          message: 'Guardaste la puntuación del módulo',
          icon: CheckIcon,
          button: [
            {
              icon: CloseSvg,
              side: 'end',
              role: 'cancel',
              cssClass: 'toast-button',
            },
          ],
        });
      }
    },
    [setToast, setRatingStatus]
  );

  return { handlePostRating };
};
