import { useIonRouter } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { useSetRecoilState } from 'recoil'
import { toastState } from 'recoil/ToastAtom'
import { tokenState } from 'recoil/tokenAtom'
import { IAuthWithGoogle } from 'services/interfaces/AuthLogin'
import { authWithGoogle } from 'services/login'
import CloseSvg from 'assets/md-close.svg'
import { statusAtom } from 'recoil/statusAtom'

export const useAuthWithGoogle = () => {

    const setToken = useSetRecoilState(tokenState)
    const navigation = useIonRouter()
    const setToast = useSetRecoilState(toastState)
    const setStatus = useSetRecoilState(statusAtom)

    const handleAuthWithGoogle = async( body:IAuthWithGoogle) =>{
      
        const result = await authWithGoogle(body)
        if(result){
            setToken({
                jwtToken: result.jwtToken,
                userId: result.profile.idUser
            })
            localStorage.setItem('googleToken', body.token)
            localStorage.setItem("token", result.jwtToken)
            navigation.push('/tabs/home', 'forward', 'replace')
        }else{
            setToast({
              isOpen:true,
              color:"danger",
              message:"Ya existe un usuario con este email",
              icon:alertCircle,
              button:[{
                icon:CloseSvg,
                side:'end',
                role:'cancel',
                cssClass:'toast-button'
              }]
            })
          }
    }

    return { handleAuthWithGoogle }
}
