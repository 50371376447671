import { IonContent, IonIcon, IonModal } from "@ionic/react"
import { useOpenModal } from "hooks/modal/useOpenModal"
import { closeOutline } from "ionicons/icons";
import { useRef } from "react";
import ReactPlayer from "react-player";
import './modalvideo.css'

interface IModalVideo {
    url: string,
    trigger: string
}

export const ModalVideo: React.FC <IModalVideo>= ({ url, trigger })=>{ 

    const { IsOpen, handleCloseModal } = useOpenModal()

    const modal = useRef<HTMLIonModalElement>(null);

    function dismiss() {
        handleCloseModal()
        modal.current?.dismiss();
    }

    return(
        <IonModal id="modal-video" trigger={`${trigger}`} ref={modal} onDidDismiss={dismiss}>
            <IonContent>
                <div onClick={(e)=> e.stopPropagation()} style={{ width:'100%', position:'absolute', backgroundColor:'transparent', height:60, zIndex:100000 }}></div>
                <div onClick={(e)=> e.stopPropagation()} style={{ width: '100%', position: 'absolute', backgroundColor: 'transparent', height: 60, zIndex: 100000, bottom: 0, right: 0 }}></div>
                <ReactPlayer 
                    className='modalReactPlayer'
                    url={url}
                    width={'100%'} 
                    height={210}
                    playsinline={true} 
                    controls
                    config={{ 
                        youtube:{
                            playerVars: { modestbranding: 1, showInfo: 0, fs: 0, controls: 1, autohide: 1, rel: 0 },
                        }
                    }}  
                />
            </IonContent>
        </IonModal>
    )
}