//react
import { useEffect, useRef } from 'react';

//ionic
import { IonText, IonImg, useIonRouter, IonPopover, IonContent } from '@ionic/react';

//css
import './sidebar.css';

//icons
import accordion_arrow from '../../assets/accordion_arrow.svg';

//hooks
import { useGetTrainingTimes } from 'hooks/entrenamiento-rapido/useGetTrainingTimes';

//helper
import { maxTime } from 'helper/handleQuickTrainingTimes';

interface AccordionProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({ show, setShow, onClick }) => {
  const handleShow = () => setShow((previousState) => !previousState);

  const navigation = useIonRouter();

  const { handleGetTrainingTimes, trainingTimes } = useGetTrainingTimes();

  useEffect(() => {
    handleGetTrainingTimes();
  }, [handleGetTrainingTimes]);

  const maxTimeRef = useRef(maxTime(trainingTimes));

  useEffect(() => {
    if (trainingTimes) maxTimeRef.current = maxTime(trainingTimes);
  }, [trainingTimes]);

  return (
    <div className="sb-mt-24">
      <div className="sb-accordion-first-child" onClick={handleShow}>
        <IonText className="sb-ion-text">Entrenamiento Rápido</IonText>

        <IonImg src={accordion_arrow} alt="arrow" style={{ transform: !show && 'rotate(180deg)' }} />
      </div>

      <div className={`sb-accordion-second-child${show ? ' show' : ''}`}>
        <IonText
          className="sb-ion-subtext"
          onClick={() => {
            if (maxTimeRef.current >= 15) {
              navigation.push('/crear-entrenamiento', 'root', 'push');
              onClick();
            }
          }}
          style={{ marginTop: '20px' }}
          id={maxTimeRef.current >= 15 ? '' : 'click-trigger-1'}
        >
          Crear Entrenamiento
        </IonText>
        <IonPopover trigger={maxTimeRef.current >= 15 ? '' : 'click-trigger-1'} triggerAction="click">
          <IonContent className="ion-padding pop-over-entrenamiento">Aún no avanzaste lo suficiente en el Programa para crear un entrenamiento rápido</IonContent>
        </IonPopover>
        <IonText
          className="sb-ion-subtext"
          style={{ marginTop: '16px' }}
          onClick={() => {
            if (maxTimeRef.current >= 1) {
              navigation.push('/ejercicios-disponibles', 'root', 'push');
              onClick();
            }
          }}
          id={maxTimeRef.current >= 1 ? '' : 'popover-entrenamientos-1'}
        >
          Ejercicios disponibles
        </IonText>

        <IonPopover trigger={maxTimeRef.current >= 1 ? '' : 'popover-entrenamientos-1'} triggerAction="click">
          <IonContent className="ion-padding pop-over-entrenamiento">Aún no avanzaste lo suficiente en el Programa para tener Ejercicios Disponibles</IonContent>
        </IonPopover>
      </div>
    </div>
  );
};
