import React, { useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { userLevelsAtom } from 'recoil/User'
import { getUserLevels } from 'services/user'

export const useGetUserLevels = () => {

    const setUserLevel = useSetRecoilState(userLevelsAtom)

    const handleGetUserLevels = useCallback(
      () => {
        getUserLevels().then( response =>{ 
            if(response.status === 200){
                const data = response.data
                
                setUserLevel(data)
            }
        })
      },
      [],
    )
    
    return{
        handleGetUserLevels
    }
}
