import { IonButton, IonContent, IonIcon, IonText, useIonRouter } from "@ionic/react"
import { checkmarkCircle } from "ionicons/icons"
import './subscriptionSuccesful.css';
import { useRecoilValue } from "recoil";
import { planSelectedState } from "recoil/planAtom";

export const SubscriptionSuccesful : React.FC = ()=>{ 

    const planSelected = useRecoilValue(planSelectedState)

    return(
            <div className="checkout-sucess-subscription">
                <article style={{ width:'80%', textAlign:'center'}}>
                <IonText style={{ fontSize:22, fontWeight:700, fontFamily:'Poppins' }}> Te suscribiste al Plan {planSelected.id === 3 ? 'Trimestral' : 'Anual' } </IonText>
                </article>
                <article style={{ marginTop:26, marginBottom:26 }}>
                    <IonIcon style={{ width:90, height:90, fill:'#168404' }} icon={checkmarkCircle}  />
                </article>
                <article style={{ marginBottom:92, width:300, textAlign:'center' }}>
                    <IonText color={'dark'} style={{ fontSize:16, fontWeight:400 }}>
                        {
                            planSelected.id === 1 ?
                            'Ya tienes acceso ilimitado al Programa de Aprendizaje y al Entrenamiento Rápido.'
                            :
                            'Ya tienes acceso ilimitado al Programa de Aprendizaje y al Entrenamiento Rápido.'
                        }
                         </IonText>
                </article>
            </div>
    )
}