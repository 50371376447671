interface IImageProp{ 
    width:string,
    height:string
}

export const MedalCompleteIcon: React.FC <IImageProp> = ({ width, height})=>{ 

    return(
        <svg width={width} height={height} viewBox="0 0 73 115" fill="none">
            <path d="M66.6852 84.6573C66.6852 101.328 53.1705 114.843 36.4998 114.843C19.8291 114.843 6.31445 101.328 6.31445 84.6573C6.31445 67.9867 19.8291 54.4706 36.4998 54.4706C53.1705 54.4706 66.6852 67.9867 66.6852 84.6573Z" fill="#E7B02C"/>
            <path d="M60.2691 84.6574C60.2691 97.7841 49.6265 108.425 36.4998 108.425C23.3731 108.425 12.7305 97.7841 12.7305 84.6574C12.7305 71.5294 23.3731 60.8881 36.4998 60.8881C49.6265 60.8881 60.2691 71.5294 60.2691 84.6574Z" fill="#C38025"/>
            <path d="M41.0946 50.9174H50.2853L27.6106 1.14441e-05H18.4199L41.0946 50.9174Z" fill="#9D292E"/>
            <path d="M41.0948 50.9174H31.9055L9.22949 1.14441e-05H18.4201L41.0948 50.9174Z" fill="#E4E4E5"/>
            <path d="M22.7147 50.9174H31.9054L9.22943 1.14441e-05H0.0400391L22.7147 50.9174Z" fill="#139AC2"/>
            <path d="M31.9055 50.9174H22.7148L45.3895 1.14441e-05H54.5802L31.9055 50.9174Z" fill="#D1353C"/>
            <path d="M31.9053 50.9174H41.0946L63.7706 1.14441e-05H54.5799L31.9053 50.9174Z" fill="white"/>
            <path d="M50.2854 50.9174H41.0947L63.7707 1.14441e-05H72.9601L50.2854 50.9174Z" fill="#4ABADB"/>
            <path d="M6.31445 84.6573C6.31445 101.328 19.8291 114.843 36.4998 114.843V54.4706C19.8291 54.4706 6.31445 67.9867 6.31445 84.6573Z" fill="#F0CC76"/>
            <path d="M12.7305 84.6574C12.7305 97.7841 23.3731 108.425 36.4998 108.425V60.8881C23.3731 60.8881 12.7305 71.5294 12.7305 84.6574Z" fill="#DCA82A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.5 92.6107L45.0192 97.7526L42.7585 88.0616L50.2852 81.5412L40.3736 80.7003L36.5 71.5608L32.6264 80.7003L22.7148 81.5412L30.2415 88.0616L27.9808 97.7526L36.5 92.6107Z" fill="#F0CC76"/>
            <path d="M36.5 92.6107L45.0192 97.7526L42.7585 88.0616L50.2852 81.5412L40.3736 80.7003L36.5 71.5608V92.6107Z" fill="#E7B02C"/>
        </svg>
    )
}