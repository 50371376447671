import { IonButton, IonText } from "@ionic/react"

interface ICancelSubContent { 
    handleCancel: ()=> void,
    setShowModal: (e:boolean)=> void
}

export const CancelarSubscripcionContent: React.FC <ICancelSubContent> = ({ handleCancel, setShowModal})=>{ 

    return(
        <>
        <IonText className="cs-cancel-suscription">Cancelar suscripción</IonText>
        <IonText className="cs-cancel-suscription-subtext">
        ¿Realmente quieres cancelar la renovación automática de tu cuenta? Se dará de baja tu suscripción al finalizar el período actual.
        </IonText>

        <div className="cs-important-container">
          <IonText className="cs-important-text">Importante:</IonText>
          <IonText className="cs-important-subtext">
            Si cancelas, perderás acceso a todo el contenido pago de la app, incluido las técnicas que tengas marcadas como favoritas y las notas asociadas.{' '}
          </IonText>
        </div>

        <IonButton className="cs-confirm-button" fill="clear" onClick={handleCancel}>
          Sí, cancelar Suscripción
        </IonButton>

        <IonButton fill="clear" className="cs-mantain-button" onClick={() => setShowModal(false)}>
          No, mantener Suscripción
        </IonButton>
        </>
    )
}