//ionic
import { IonHeader, IonAvatar, IonImg, IonIcon, IonMenuToggle, IonGrid, IonToolbar, isPlatform, getPlatforms, IonMenuButton } from '@ionic/react';

//svg
import logo_navbar from '../../assets/logo_navbar.svg';

//css
import './Header.css';

//icons
import { menu } from 'ionicons/icons';

//components
import { AddAvatar } from 'components/Icons/customized avatars/AddAvatar';

//recoil
import { useRecoilValue } from 'recoil';
import { tokenState } from 'recoil/tokenAtom';
import { useEffect } from 'react';

//hooks
import { useGetUserById } from 'hooks/users/useGetUserById';
import { usePostMedia } from 'hooks/media/usePostMedia';

interface IHeadPopover {
  triger: string;
}

const Header: React.FC<IHeadPopover> = ({ triger }) => {
  const token = useRecoilValue(tokenState);
  const userId = token && token.userId;
  const { handleGetUserById, user } = useGetUserById();
  const [handlePostMedia, url] = usePostMedia();
  const key = user && user.key;

  useEffect(() => {
    handleGetUserById(Number(userId));
  }, []);

  useEffect(() => {
    if (user.idUser && key) {
      handlePostMedia({ key });
    }
  }, [user, key, handlePostMedia]);

  return (
    <IonHeader>
      <IonToolbar className="h-ion-header" style={{ paddingBottom: isPlatform('ios') ? '16px' : '0px', height: isPlatform('android') ? '64px' : 'auto'}}>
        <IonGrid className="h-ion-grid">
          <IonMenuToggle>
            <IonIcon icon={menu} className="h-ion-menu-button" />
          </IonMenuToggle>
          <IonImg className="h-ion-img" src={logo_navbar} />
        </IonGrid>
        <IonAvatar slot='end' className="h-ion-avatar" id={triger}>
          {
            url ?
              <IonImg src={url} />
            : user.pictureURL ?
            <IonImg src={user.pictureURL} />
            :
            <AddAvatar width="36px" height="36px" />
          }
        </IonAvatar>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
