import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import './seleccionAyuda.css';

export const SeleccionAyuda: React.FC = ()=>{

    return(
        <div style={{ width:'100%', display:'flex', justifyContent:'center', marginTop:20 }}>
        <IonAccordionGroup>
            <article style={{ marginTop:15 }}>
            <IonRouterLink routerLink="/help/faq">
                <IonAccordion color="white" value="first" className="accordion-parent" toggleIcon={chevronForward}>
                    <IonItem slot="header">
                        <IonLabel className="accordion-label">Preguntas a Huevo y Staff</IonLabel>
                    </IonItem>
                </IonAccordion>
            </IonRouterLink>
            </article>
            <article style={{ marginTop:15 }}>
                <IonRouterLink routerLink="/help/terms">
                    <IonAccordion color="white" value="third" className="accordion-parent" toggleIcon={chevronForward}>
                        <IonItem slot="header">
                            <IonLabel className="accordion-label">Términos y Condiciones</IonLabel>
                        </IonItem>
                    </IonAccordion>
                </IonRouterLink>
            </article>
            <article style={{ marginTop:15 }}>
                <IonRouterLink routerLink="/help/polish">
                    <IonAccordion color="white" value="fourth" className="accordion-parent" toggleIcon={chevronForward}>
                            <IonItem slot="header">
                                    <IonLabel className="accordion-label">Politica de Privacidad</IonLabel>
                            </IonItem>
                    </IonAccordion>
                </IonRouterLink>
            </article>
            <article style={{ marginTop:15 }}>
                <IonRouterLink routerLink="/help/contact">
                    <IonAccordion color="white" value="fifth" className="accordion-parent" toggleIcon={chevronForward}>
                        <IonItem slot="header">
                            <IonLabel className="accordion-label">Contacto</IonLabel>
                        </IonItem>
                    </IonAccordion>
                </IonRouterLink>
            </article>
        </IonAccordionGroup>
        </div>
    )
}