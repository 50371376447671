import { IonIcon, IonModal, IonText } from "@ionic/react"
import { useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './stripeModalPayment.css'
import { closeOutline } from "ionicons/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { stripeErrorAPI } from "recoil/StripeErrorAtom";
import { StripePaymentError } from "pages/sesiones/components/session-checkout/stripePaymentError";
import { StripePaySubscriptionForm } from "./StripePaySubscriptionForm";
import { planSelectedState } from "recoil/planAtom";
import { SubscriptionSuccesful } from "pages/subscription/SubscriptionSuccesful";
import { useGetPaymentInfo } from "hooks/subscription/useGetPaymentInfo";

interface IStripeModalPayent{ 
    isOpen:boolean,
    setIsOpen: (isOpen:boolean)=> void
}

export const StripeModalPaymentSubscription: React.FC <IStripeModalPayent> = ({ isOpen, setIsOpen })=> { 

    const modalRef = useRef(null)
    const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY), { locale:'es-419'});
    const [stripeError, setStripeError] = useRecoilState(stripeErrorAPI)
    const planSelected = useRecoilValue(planSelectedState)
    const { handleGetPaymentInfo, paymentInfo } = useGetPaymentInfo()

    useEffect(()=>{
      handleGetPaymentInfo()
    },[])
    
    const handleDisMiss = ()=>{ 
        setIsOpen(false)
        setStripeError({
            code:null,
            declineCode:null
        })
    }
    return (
        <IonModal ref={modalRef} id={ stripeError.code === "succeeded" ? "stripe-form-modal-subs-success" : "stripe-form-modal-subs" } isOpen={isOpen} onDidDismiss={handleDisMiss}>
            <Elements stripe={stripePromise}>
                <div className="modal-content-stripe">
                    <div className="modal-header-close">
                        <IonIcon 
                            onClick={handleDisMiss} 
                            color="dark" 
                            style={{ width:30, height:30 }} 
                            slot="icon-only" 
                            icon={closeOutline}></IonIcon>
                    </div>
                    {
                        !stripeError.code ?
                        <>
                            <div style={{ width:'100%', marginBottom:10 }}>
                                <IonText className="ion-text-header"> Plan de Suscripción { planSelected.id === 3 ? 'Trimestral' : 'Anual'} </IonText>
                            </div>
                            <div className="header-stripe-modal">
                                <IonText className="ion-text-header"> Total a pagar: </IonText>
                                <IonText className="ion-text-header"> {planSelected.id === 3 ? `USD $ ${paymentInfo.length !== 0 && paymentInfo[2].amount }` : `USD $ ${paymentInfo.length !== 0 && paymentInfo[0].amount }`} </IonText>
                            </div>
                            <StripePaySubscriptionForm setIsOpen={setIsOpen} />
                        </>
                        :
                            stripeError.code === "succeeded" ?
                            <SubscriptionSuccesful />
                        :
                            <StripePaymentError stripeError={stripeError} />
                    }
                    
                </div>
            </Elements>
        </IonModal>
    )
}