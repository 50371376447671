import axios from './BaseConfig';

export interface IGetFundamentals { 
    hoop:number,
    partner:number
}

export interface ITrainingExercises { 
    hoop:number,
    partner:number,
    duration:number,
    fundamentals:string,
    limit:number
}

export const getTrainingTimes = ()=>{ 
    return axios.get('/trainings/times')
}

export const getFundamentals = (params:IGetFundamentals)=>{ 
    return axios.get(`/trainings/fundamentals?hoop=${params.hoop}&partner=${params.partner}`)
}

export const getQuickTrainingExercises = async( params: ITrainingExercises ) => { 
    const { duration, fundamentals, hoop, partner, limit } = params
    return await axios.get(`/trainings?hoop=${hoop}&partner=${partner}&duration=${duration}&fundamentals=${fundamentals}&limit=${limit}`)
}