import { IonFab, IonFabButton, IonIcon, IonText, useIonRouter } from "@ionic/react";
import { Divider } from "components/Divider";
import { handleVideoTime } from "helper/handleVideoTime";
import { usePostUserExercisePoint } from "hooks/entrenamiento-rapido/usePostUserExercisePoint";
import { useOpenModal } from "hooks/modal/useOpenModal";
import { IExercisesQuick } from "interfaces/IEntraniento";
import { pause, play, playSkipBack, playSkipForward } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { exerciseMinutesDoneAtom, exercisePointsAtom, timerExerciseAtom } from "recoil/exercisesAtom";

interface ITimerProgressBar{ 
    totalTime: number,
    basisName: string,
    name: string,
    setIsPlaying: (isPlaying:boolean) => void,
    listExercise: IExercisesQuick[],
    index: number,
    setIndex: (index:number)=> void,
    setCurrentTime: (index:any)=> void,
    currentTime: number,
    setProgress: (progress:any) => void,
    isReady: boolean,
    showCountdown: boolean,
    setIsReady:(el:boolean)=>void,
    progress: number
}

export const TimerProgressBar: React.FC <ITimerProgressBar> = ({ progress, setIsReady, isReady, showCountdown, currentTime, setCurrentTime,setProgress, setIndex, index, listExercise, setIsPlaying, totalTime, basisName, name }) => {

  const [isRunning, setIsRunning] = useState(false);
  const indexRef = useRef<number>(0)
  const navigation = useIonRouter()
  const setExercisePoints = useSetRecoilState(exercisePointsAtom)
  const setTimerIsRunning = useSetRecoilState(timerExerciseAtom)
  const { handleOpenModal } = useOpenModal()
  
    useEffect(() => {
      let timer:any = null;
      if (isRunning && currentTime > 0) {
        timer = setInterval(() => {
          setCurrentTime((prevTime:any) => prevTime - 1);
          setProgress((prevTime:any) => prevTime + 1)
        }, 1000);
      }else if(currentTime === 0){ 
        handleOpenModal('finishedQuickTraining')
        navigation.push('/tabs/home','back', 'replace')
      }

      if(currentTime % 60 === 0 && progress !== 0 && isRunning){
        setExercisePoints( prevState => prevState + 1)
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [currentTime, isRunning, totalTime, showCountdown]);

    useEffect(() => {
      if(showCountdown){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }else if(!showCountdown && isReady){
        setTimerIsRunning(true)
        setIsRunning(true)
        setIsPlaying(true)
      }else if(isReady){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }else if(!isReady){
        setTimerIsRunning(false)
        setIsRunning(false)
        setIsPlaying(true)
      }
    }, [showCountdown, isReady])
    
  
    const minutes = Math.floor(currentTime / 60);
    const seconds = currentTime % 60;
  
    const progressPercentage = ((totalTime - currentTime) / totalTime) * 100;
    var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    const progressBarStyle = {
      width: `${progressPercentage}%`,
      backgroundColor: '#D64200',
      height: isMobile ? '80px' : '50px',
    };
  
    const handleStop = () => {
      setTimerIsRunning(false)
      setIsRunning(false);
      setIsPlaying(false)
    };
  
    const handleResume = () => {
      setTimerIsRunning(true)
      setIsPlaying(true)
      setIsRunning(true);
    };

    const handleBackVideo = ()=>{ 
      setIsPlaying(false)
      setIsRunning(false);
      setIsReady(false)
      setProgress(0)
      indexRef.current = index - 1
      setIndex(index - 1)
      setCurrentTime( handleVideoTime(indexRef.current, totalTime))
    }
    

    const handleNextVideo = ()=>{ 
      setIsPlaying(false)
      setIsRunning(false);
      setIsReady(false)
      setProgress(0)
      indexRef.current = index + 1
      setIndex(index + 1)
      setCurrentTime( handleVideoTime(indexRef.current, totalTime))
    }
  
    return (
      <>
        <div style={{ width: '100%', border: '1px solid black', backgroundColor:'#393A3F' }}>
          <div style={progressBarStyle}>
            <article style={{ width:'100vw', display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}}>
                <div style={{ height:'100%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'center', width:'100%', marginLeft:30}}>
                    <IonText color={'white'} style={{ fontSize: isMobile ? '16px':'12px', fontWeight:500}}>{basisName}</IonText>
                    <IonText color={'white'} style={{ fontSize: isMobile ? '22px' : '16px', fontWeight:700, width:'90%' }}>{name}</IonText>
                </div>
                <div style={{ marginRight:20 }}>
                    <IonText color={'white'} style={{ fontSize:'22px', fontWeight:700 }}>
                        {minutes.toString().padStart(2, '0')}:
                        {seconds.toString().padStart(2, '0')}
                    </IonText>
                </div>
            </article>
          </div>
        </div>
        <div style={{ width:'100%', padding:'0.8rem', background:'#F3F3F3'}}>
          <IonText 
                  id={String(listExercise[index].basisName)}
                  onClick={(e)=>{
                    setTimerIsRunning(false)
                    setIsRunning(false);
                    setIsPlaying(false)
                   }} 
                  color={'orange'} style={{ fontSize:14, fontWeight:400, marginTop:10, textDecoration:'underline' }}> Ver video completo </IonText>
        </div>
        <div style={{ display:'flex', width:'100%', padding:'24px', justifyContent:'center' }}>
            <IonFab style={{ display:'flex', justifyContent:'center', alignItems:'end', width:'100%'}}>
              <IonFabButton disabled={index === 0} style={{ width:48, height:48 }} color={'orange'} onClick={handleBackVideo}>
                <IonIcon icon={playSkipBack}></IonIcon>
              </IonFabButton>
              {
                isRunning ? (
                    <IonFabButton style={{ width:58, height:58, marginRight:20, marginLeft:20 }} color={'orange'} onClick={handleStop}>
                      <IonIcon icon={pause}></IonIcon>
                    </IonFabButton>
              ) : (
                <IonFabButton disabled={showCountdown || !isReady} style={{ width:58, height:58, marginRight:20, marginLeft:20 }} color={'orange'} onClick={handleResume}>
                  <IonIcon icon={play}></IonIcon>
                </IonFabButton>
              )
              }
              <IonFabButton disabled={listExercise.length - 1 === index} style={{ width:48, height:48 }} color={'orange'} onClick={handleNextVideo}>
                <IonIcon icon={playSkipForward}></IonIcon>
              </IonFabButton>
            </IonFab>
        </div>
      </>
    );
  };
  