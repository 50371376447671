import { IToast } from "interfaces/IToast";
import { atom } from "recoil";

export const toastState = atom({
    key: 'toast',
    default:{
        isOpen:false,
        color:'',
        message: ''
    } as IToast,
  });