import { useState } from 'react';

//ionic
import { IonText, IonGrid, IonImg } from '@ionic/react';

//css
import './favorites-block.css';

//interfaces
import { IStylesMap, IFavorite } from 'interfaces/IFavorite';

//icons
import like_icon from '../../assets/like_icon.svg';
import white_accordion_arrow from '../../assets/white_accordion_arrow.svg';
import Favorite from './Favorite';

const stylesMap: IStylesMap = {
  'Técnica Individual': { left: '38%' },
  'Táctica individual': { left: '38%' },
  Defensa: { left: '28%' },
  Tiro: { left: '23%' },
  Pase: { left: '23%' },
};

interface IFavoritesBlock {
  blockName: string;
  blockData: IFavorite[];
}

const FavoritesBlock: React.FC<IFavoritesBlock> = ({ blockName, blockData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow((previousState) => !previousState);

  function transform_word() {
    const formattedWord = blockName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    if (formattedWord === 'tecnica individual' || formattedWord === 'tactica individual') {
      return formattedWord.replace(/\s+/g, '-');
    }

    return formattedWord;
  }

  function ordenarArray(arr: IFavorite[]) {
    const sortedArray = arr.slice().sort((a, b) => {
      if (a.nameModule === b.nameModule) {
        return a.idExercise - b.idExercise;
      } else {
        return a.nameModule < b.nameModule ? -1 : 1;
      }
    });

    return sortedArray;
  }

  const arrayOrdenado = ordenarArray(blockData);

  return (
    <>
      <IonGrid className={`f-first-image-container ${transform_word()}`} style={{ borderRadius: show ? '4px 4px 0 0' : '4px' }}>
        <div className="sb-accordion-first-child" onClick={handleShow}>
          <IonImg className="f-image-icon" src={like_icon} />
          <IonText className="f-image-text" style={{ ...stylesMap[blockName] }}>
            {blockName}
          </IonText>
          <IonImg src={white_accordion_arrow} className="f-arrow" alt="arrow" style={{ transform: show ? 'rotate(180deg)' : 'none' }} />
        </div>
      </IonGrid>

      <div className={`f-accordion-second-child${show ? ' show' : ''}`}>
        {arrayOrdenado &&
          arrayOrdenado.length !== 0 &&
          arrayOrdenado.map((favorite, index) => {
            return <Favorite favorite={favorite} key={index} />;
          })}
      </div>
    </>
  );
};

export default FavoritesBlock;
