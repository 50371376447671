import { IExercisesQuick, IFundamentals } from "interfaces/IEntraniento";
import { atom } from "recoil";

export interface IEntrenamiento { 
    duracion: Number,
    tieneAro: String,
    tieneCompanero: String
  }

export const trainingState = atom({
    key: 'training',
    default:{
        duracion: 0,
        tieneAro: "",
        tieneCompanero: ""
    } as IEntrenamiento,
  });

export const basisState = atom({
  key: 'basis',
  default:[] as IFundamentals[],
});

export const listExerciseAtom = atom({
  key:'listExercise',
  default:[] as IExercisesQuick[]
})

export const keyAccordionAtom = atom({
  key:'keyAccordion',
  default: ""
})

export const indexTrainingAtom = atom({
  key:'indexTraining',
  default: 0
})

export const trainingBeganAtom = atom({
  key:'trainingBegan',
  default: false
})