import { IonButton, IonToast } from '@ionic/react';

//components
import { Fieldset } from 'components/FieldSet/FieldSet';
import { contactValidation } from './ContactValidations';
import { FieldSetNumber } from 'components/FieldSet/FieldSetNumber';
import { FieldSetTextArea } from 'components/FieldSet/FieldSetTextArea';

//formik
import { Form, Formik } from 'formik';

//services
import { IContactHelp } from 'services/interfaces/Contact';

//hooks
import { usePostContact } from 'hooks/users/usePostContact';

//recoil
import { userInfoState } from 'recoil/User';
import { toastState } from 'recoil/ToastAtom';
import { useRecoilState, useRecoilValue } from 'recoil';

export const ContactForm: React.FC = () => {
  const user = useRecoilValue(userInfoState);
  const { idUser } = user;

  const { handlePostContact } = usePostContact();

  const [toast, setToast] = useRecoilState(toastState);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          phone: '',
          note: '',
        }}
        validationSchema={contactValidation}
        enableReinitialize={true}
        onSubmit={(values) => {
          const formValues: IContactHelp = {
            email: values.email,
            note: values.note,
            phone: values.phone,
          };

          handlePostContact(Number(idUser), formValues);
        }}
      >
        {({ setFieldTouched, dirty, isValid, values, errors, touched, setFieldValue }: any) => (
          <Form className='contactForm' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <FieldSetNumber
                name="phone"
                type="number"
                label="Whatsapp"
                placeholder="+ 54 9 11 2381 9674"
                value={values.phone}
                errors={errors.phone}
                setFieldTouched={setFieldTouched}
                touched={touched.phone}
                isTouched={true}
                setFieldValue={setFieldValue}
              />
              <Fieldset
                name="email"
                type="email"
                label="Email"
                placeholder="ruiz.felipe@yahoo.com"
                value={values.email}
                errors={errors.email}
                setFieldTouched={setFieldTouched}
                touched={touched.email}
                isTouched={true}
              />
              <FieldSetTextArea
                name="note"
                type="note"
                label="Consulta"
                placeholder=""
                value={values.note}
                errors={errors.note}
                setFieldTouched={setFieldTouched}
                touched={touched.note}
                isTouched={true}
              />
            </article>
            <article style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IonButton
                disabled={!values.phone || !values.note || !values.email}
                type="submit"
                color={!values.phone || !values.note || !values.email ? 'medium' : 'orange'}
                fill="solid"
                style={{ width: 300, fontWeight: 700 }}
              >
                Enviar consulta
              </IonButton>
            </article>
          </Form>
        )}
      </Formik>

      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        icon={toast.icon}
        cssClass={'css-success-toast'}
        buttons={toast.button}
        onDidDismiss={() => setToast({ isOpen: false })}
        duration={2000}
      />
    </>
  );
};
