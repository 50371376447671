import { useCallback } from 'react';

//services
import { postMediaUser, putMediaToS3, putMediaUser } from 'services/media';

//interface
import { IUserImage } from 'services/interfaces/MediaBody';
import { useGetUserById } from 'hooks/users/useGetUserById';

export const useEditImageUser = () => {

    const { handleGetUserById } = useGetUserById()

    const handleEditMedia = useCallback(async (body: IUserImage, file:any) => {
        let fileToPost = { 
            id: body.id,
            fileName: body.fileName
        }
        const resp = await postMediaUser(fileToPost);

        if( resp ){ 
            const res = await putMediaToS3(resp, file)

            if(res){ 
                await putMediaUser(fileToPost)
                handleGetUserById(body.id)
            }
        }
    }, []);

    return {handleEditMedia};
};