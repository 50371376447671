import { useCallback } from 'react';

//services
import { postModule } from 'services/module';

export const usePostModule = () => {
  const handlePostModule = useCallback(async (idUser: number, idModule: number) => {
    try {
      await postModule(idUser, idModule);
    } catch (e) {
      console.log(e, 'error');
    }
  }, []);

  return { handlePostModule };
};
