import { useEffect, useRef, useState } from 'react';

//ionic
import { IonButtons, IonContent, IonGrid, IonHeader, IonPage, IonText, IonToolbar, useIonRouter, IonIcon, IonMenuButton } from '@ionic/react';

//icons

import { arrowBackOutline } from 'ionicons/icons';

//css
import './ejercicios-disponibles.css';
import 'components/favorites/favorites-block.css';

//interfaces

import { SingleBlock } from '../../../components/entrenamiento/SingleBlock';

export const EjerciciosDisponibles: React.FC = () => {
  const navigation = useIonRouter();

  const blockName = [
    { blockName: 'Técnica Individual', id: 5 },
    { blockName: 'Táctica individual', id: 4 },
    { blockName: 'Defensa', id: 2 },
    { blockName: 'Tiro', id: 1 },
    { blockName: 'Pase', id: 3 },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <article style={{ display: 'flex', width: 300 }}>
            <IonButtons slot="start" onClick={() => navigation.push('/tabs/home', 'forward', 'pop')}>
              <IonIcon style={{ width: 24, height: 24, marginLeft: 10 }} icon={arrowBackOutline} />
            </IonButtons>
            <p style={{ fontSize: 16, marginLeft: 10, fontWeight: 700 }}>Ejercicios Disponibles</p>
          </article>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className="ed-container">
          <IonText className="ed-text">Revisa las técnicas disponibles para tus entrenamientos rápidos y cambia su estado a no disponible si quieres dejar de incluirlas.</IonText>

          {blockName.map((block, index) => {
            return <SingleBlock block={block} key={index} />;
          })}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
