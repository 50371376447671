import { useCallback } from 'react';

//hooks
import { modalKeys, modalOptions } from './modalOptions';

//recoil
import { useRecoilState } from 'recoil';
import { modalOptionsState } from 'recoil/modalOptionsAtom';

export const useOpenModal = () => {
  const [IsOpen, setIsOpen] = useRecoilState(modalOptionsState);

  const handleOpenModal = useCallback(
    (openKey: string) => {
      switch (openKey) {
        case modalKeys.finishedModule:
          setIsOpen((prevState) => {
            return { ...prevState, finishedModule: true };
          });
          break;

        case modalKeys.finishedExercise:
          setIsOpen((prevState) => {
            return { ...prevState, finishedExercise: true };
          });
          break;

        case modalKeys.changePlan:
          setIsOpen((prevState) => {
            return { ...prevState, changePlan: true };
          });
          break;
        case modalKeys.ejerciciosInsuficientes:
          setIsOpen((prevState) => {
            return { ...prevState, ejerciciosInsuficientes: true };
          });
          break;
        case modalKeys.finishedTraining:
          setIsOpen((prevState) => {
            return { ...prevState, finishedTraining: true };
          });
          break;
        case modalKeys.abandonoEntrenamiento:
          setIsOpen((prevState) => {
            return { ...prevState, abandonoEntrenamiento: true };
          });
          break;
        case modalKeys.perfomanceGoals:
          setIsOpen((prevState) => {
            return { ...prevState, perfomanceGoals: true };
          });
          break;
        case modalKeys.exerciseStatus:
          setIsOpen((prevState) => {
            return { ...prevState, exerciseStatus: true };
          });
          break;
        case modalKeys.finishedQuickTraining:
          setIsOpen((prevState) => {
            return { ...prevState, finishedQuickTraining: true };
          });
          break;
        case modalKeys.completeVideo:
          setIsOpen((prevState) => {
            return { ...prevState, completeVideo: true };
          });
          break;
        case modalKeys.openCertificate:
          setIsOpen((prevState) => {
            return { ...prevState, openCertificate: true };
          });
          break;
        case modalKeys.multipleExerciseFinished:
          setIsOpen((prevState) => {
            return { ...prevState, multipleExerciseFinished: true };
          });
          break;
        case modalKeys.ratingModal:
          setIsOpen((prevState) => {
            return { ...prevState, ratingModal: true };
          });
          break;
        case modalKeys.borrarCuenta:
          setIsOpen((prevState) => {
            return { ...prevState, borrarCuenta: true };
          });
          break;
        case modalKeys.cuentaBorrada:
          setIsOpen((prevState) => {
            return { ...prevState, cuentaBorrada: true };
          });
          break;

        default:
          break;
      }
    },
    [setIsOpen]
  );

  const handleCloseModal = useCallback(() => {
    setIsOpen(modalOptions);
  }, [setIsOpen]);

  return { IsOpen, handleOpenModal, handleCloseModal };
};
