import { useCallback, useState } from 'react';

//services
import { getExerciseById } from 'services/aprendizaje';

interface IExercise {
  idExercise: number | null;
  name: string;
  idModule: number | null;
  createdAt: number | null;
  deletedAt: number | null;
  note: string | null;
  idNote: string | null;
  videoURL: string;
  details: string;
}

export const useGetExerciseById = () => {
  const [exercise, setExercise] = useState<IExercise>({ idExercise: null, name: '', idModule: null, createdAt: null, deletedAt: null, note: '', idNote: null, videoURL: '', details: '' });

  const handleGetExerciseById = useCallback(async (id: number) => {
    const resp = await getExerciseById(id);
    setExercise(resp);
  }, []);

  return { exercise, handleGetExerciseById };
};
