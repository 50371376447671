import { IonButton, IonContent, IonHeader, IonRange, IonToolbar } from "@ionic/react"
import { useCallback, useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { ImageState, userState } from "recoil/UserImage"
import { IArea, Icrop } from "./crop.interface"
import Cropper from "react-easy-crop";
import './imageCropper.css';
import getCroppedImg from "./helper/cropImage"
import { useEditImageUser } from "hooks/media/useEditImageUser"
import { dataURLtoFile } from "./helper/DataUrlToFile"
import { IUserImage } from "services/interfaces/MediaBody"
import { userInfoState } from "recoil/User"

export const ImageCropper: React.FC <any> = ( {modal, isEdit=false } )=>{ 

    const Base64Img = useRecoilValue(ImageState)
    const [crop, setCrop] = useState<Icrop>({ x:0, y:0 });
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<IArea>()
    const setCroppedImage = useSetRecoilState(userState)
    const { handleEditMedia } = useEditImageUser()
    const userInfo = useRecoilValue(userInfoState)

    const [first, setfirst] = useState(true)

    const onCropComplete = useCallback((croppedArea:IArea, croppedAreaPixels:IArea) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
            Base64Img,
            croppedAreaPixels,
            0
            )
            setCroppedImage({photo: croppedImage})
            if(isEdit){
                let photoSelected = {
                    photo: croppedImage
                }
                let file = dataURLtoFile(photoSelected, `${Math.random().toString(36).slice(2)}`)
                let body:IUserImage = { 
                  fileName: Math.random().toString(36).slice(2),
                  id: Number(userInfo.idUser)
                }
                await handleEditMedia(body, file)
            }

            modal.dismiss()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    useEffect(() => {
      setTimeout(() => {
        setfirst(false)
      }, 1000);
    }, [])
    

    return ( 
        <>
            <IonHeader>
                <IonToolbar>
                    <p style={{ fontFamily:'Poppins', fontStyle:'normal', fontSize:22 }}> Recorte </p>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="crop-container">
                    { !first &&<Cropper
                        image={Base64Img}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 4}
                        cropShape="round"
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        objectFit="horizontal-cover"
                        style={{ containerStyle:{ top:200, height:300, backgroundColor:'black' }}}
                    />}
                </div>
                <div className="controls">
                    <IonRange max={3} min={1} step={0.1} value={zoom} onIonChange={({ detail }) => setZoom(Number(detail.value))} />
                </div>
                <div className="button-controls">
                    <IonButton onClick={showCroppedImage} style={{ width:300, height:40 }} color={'orange'}>
                        Guardar Foto
                    </IonButton>
                </div>

            </IonContent>
        </>

    )
}