import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonText, IonToolbar, useIonRouter, useIonViewDidEnter, useIonViewDidLeave, IonMenuButton } from "@ionic/react";
import { LoadingScreen } from "components/loading/LoadingScreen";
import { arrowBackOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

export const LoadingQuickTraining : React.FC = ()=>{ 

  const navigation = useIonRouter()

  const handleRedirect = ()=> { 
    setTimeout(() => {
        navigation.push('/quick-training', 'forward', 'push')
    }, 2500);

  }

  useIonViewDidEnter(()=>{
    handleRedirect()
  })
  
  
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <div className="ce-header-container">
                <IonButtons slot="start">
                  <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/fundamentos" />
                </IonButtons>
                <IonText className="ce-header-title">Entrenamiento Rápido</IonText>
              </div>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <LoadingScreen subTitle="Espera unos minutos" title="Creando tu entrenamiento..." />
          </IonContent>
        </IonPage>
      );
}