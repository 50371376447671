import { useCallback, useState } from 'react';

//services
import { getSessions } from 'services/sessions';

//interface
import { SessionParams } from 'services/interfaces/SessionParams';

export const useGetSessions = (): [[], (params: SessionParams) => Promise<void>] => {
  const [sessions, setSessions] = useState<[]>([]);

  const handleGetSessions = useCallback(async (params: SessionParams) => {
    const resp = await getSessions(params);
    setSessions(resp);
  }, []);

  return [sessions, handleGetSessions];
};
