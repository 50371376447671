import axios from "./BaseConfig";
import { IAuthWithApple, IAuthWithCredentials, IAuthWithFacebook, IAuthWithGoogle, IPasswordReset, IPasswordResetHttp, IResetPassword} from "./interfaces/AuthLogin";

export const authWithCredentials = async (body:IAuthWithCredentials) => {
  try {
    const res = await axios.post("/auth", {...body});
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const authWithGoogle = async (body:IAuthWithGoogle) => {
  try {
    const res = await axios.post("/auth/google", {...body});
    return res.data;
  } catch (e) {
  }
};

export const authWithFacebook = async (body:IAuthWithFacebook) => {
  try {
    const res = await axios.post("/auth/facebook", {...body});
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const authWithApple = async( body: IAuthWithApple) =>{ 
  return await axios.post("/auth/apple", { ...body })
}

export const resetPassword = async(body: IResetPassword) => {
  return await axios.post('/auth/resetPassword', { ...body })
 }

 export const resetPasswordUser = async(body: IPasswordResetHttp) => {
  return await axios.post('/auth/passwordRecovery', { ...body })
 }