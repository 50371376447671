import { useEffect } from 'react';

//ionic
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonText, IonMenuButton } from '@ionic/react';

//icons
import { arrowBackOutline } from 'ionicons/icons';

//css
import './mis-sesiones.css';

//components
import EmptyState from 'components/EmptyState/EmptyState';
import SingleLiveSession from 'components/Home/LiveSessions/SingleLiveSession';

//recoil
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/User';

//hooks
import { useGetUserSessions } from 'hooks/sessions/useGetUserSessions';

interface Session {
  idSession: number;
  title: string;
  category: string;
  label: string;
  contactInfo: string;
  description: string;
  eventDate: number;
  fullName: string;
  role: string;
}

interface GroupedSessions {
  [monthAndYear: string]: Session[];
}

export const MisSesiones: React.FC = () => {
  const user = useRecoilValue(userInfoState);
  const { idUser } = user;

  const { sessions: futureSessions, handleGetUserSessions: handleGetFutureUserSessions } = useGetUserSessions();
  const { sessions: pastSessions, handleGetUserSessions: handleGetPastUserSessions } = useGetUserSessions();

  useEffect(() => {
    if (idUser) {
      handleGetFutureUserSessions(idUser, { future: 1 });
      handleGetPastUserSessions(idUser, { future: 0 });
    }
  }, [handleGetFutureUserSessions, handleGetPastUserSessions, idUser]);

  function capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  function groupSessionsByMonth(sessions: Session[]): GroupedSessions {
    return sessions.reduce((acc, session) => {
      const eventDate = new Date(session.eventDate);
      const month = eventDate.toLocaleString('es', { month: 'long' });
      const capitalizedMonth = capitalizeWord(month);
      const year = eventDate.getFullYear().toString();

      const monthAndYear = `${capitalizedMonth} ${year}`;

      if (!acc[monthAndYear]) {
        acc[monthAndYear] = [];
      }

      acc[monthAndYear].push(session);

      return acc;
    }, {} as GroupedSessions);
  }

  const groupedFutureSessions = groupSessionsByMonth(futureSessions);
  const groupedPastSessions = groupSessionsByMonth(pastSessions);

  const handleEmptyState = (title: string | React.ReactNode, subtitle: string | React.ReactNode, marginTop?: string) => {
    return <EmptyState title={title} subtitle={subtitle} marginTop={marginTop} />;
  };

  const emptyFutureSessionsText = (
    <>
      Todavía no tienes sesiones
      <br />
      agendadas
    </>
  );

  const emptyPastSessionsText = (
    <>
      Todavía no tienes sesiones en tu
      <br />
      historial
    </>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div className="p-header-container">
            <IonButtons slot="start">
              <IonBackButton className="ioBackButton" icon={arrowBackOutline} color="dark" text={''} defaultHref="/tabs" />
            </IonButtons>
            <IonText className="p-header-title">Mis sesiones</IonText>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <div className="ms-container">
          <div className="ms-child">
            <div className="ms-title-container">
              <IonText className="ms-title">Próximas Sesiones</IonText>
            </div>
            <div className="ms-child" style={{ margin: '0 32px 40px 32px' }}>
              {Object.entries(groupedFutureSessions) && Object.entries(groupedFutureSessions).length !== 0
                ? Object.entries(groupedFutureSessions).map(([monthAndYear, sessions]) => (
                    <div key={monthAndYear} style={{ marginTop: '24px' }}>
                      <IonText className="ms-month-year">{monthAndYear}</IonText>

                      {sessions.map((session, index) => (
                        <SingleLiveSession session={session} key={index} />
                      ))}
                    </div>
                  ))
                : handleEmptyState(emptyFutureSessionsText, 'Accede a la lista completa de sesiones para elegir y reservar tu lugar', '24px')}
            </div>
          </div>

          <div className="ms-child">
            <div className="ms-title-container">
              <IonText className="ms-title">Historial</IonText>
            </div>

            <div className="ms-child" style={{ margin: '0 32px 40px 32px' }}>
              {Object.entries(groupedPastSessions) && Object.entries(groupedPastSessions).length !== 0
                ? Object.entries(groupedPastSessions).map(([monthAndYear, sessions]) => (
                    <div key={monthAndYear} style={{ marginTop: '24px' }}>
                      <IonText className="ms-month-year">{monthAndYear}</IonText>

                      {sessions.map((session, index) => (
                        <SingleLiveSession session={session} key={index} />
                      ))}
                    </div>
                  ))
                : handleEmptyState(emptyPastSessionsText, 'Accede a la lista completa de sesiones para elegir y reservar tu lugar', '24px')}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
