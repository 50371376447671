import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonText, IonToolbar, useIonRouter, IonMenuButton } from "@ionic/react"
import { arrowBackOutline, checkmarkCircle } from "ionicons/icons"
import './sessionCheckoutSuccessful.css'

//recoil
import { useRecoilValue } from "recoil"
import { emailAtom } from "recoil/EmailAtom"

export const SessionCheckoutSucessful: React.FC = ()=>{ 

    const navigation = useIonRouter()

    const email = useRecoilValue(emailAtom)

    return ( 
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <article style={{ display:'flex', width: 300 }}>
                <IonButtons slot="start" onClick={()=> navigation.push('/sesiones', 'forward' , 'push')}>
                    <IonIcon style={{ width:24, height:24, marginLeft:10 }} icon={arrowBackOutline} />
                </IonButtons>
                <p style={{ fontSize: 16, marginLeft:10, fontWeight:700 }}>Sesiones en Vivo</p>
              </article>
            </IonToolbar>
          </IonHeader>
        <IonContent fullscreen>
            <div className="checkout-sucess">
                <article>
                    <IonText style={{ fontSize:22, fontWeight:700, fontFamily:'Poppins' }}> Reservaste la sesión </IonText>
                </article>
                <article style={{ marginTop:26, marginBottom:26 }}>
                    <IonIcon style={{ width:90, height:90, fill:'#168404' }} icon={checkmarkCircle}  />
                </article>
                <article style={{ marginBottom:92, textAlign:'center' }}>
                    <IonText color={'dark'} style={{ fontSize:16, fontWeight:400 }}> Te enviaremos los datos de acceso al mail: {email} </IonText>
                </article>
                <article style={{ }}>
                    <IonButton fill="clear" color={'orange'} style={{ width:220, height:40, border:'1px solid #898E9C', borderRadius:20 }} onClick={()=> navigation.push('/sesiones', 'none', 'push' )}>
                        Ver mis sesiones
                    </IonButton>
                </article>
            </div>
        </IonContent>
      </IonPage>
    )
}