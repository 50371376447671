interface IErrorMessageText {
  errorMessage: string;
  color: string;
  value: string;
  touched: boolean;
}

export const ErrorMessageText = ({ touched, value, errorMessage, color }: IErrorMessageText) => {
  const handleText = () => {
    if (touched && !value && errorMessage) {
      return errorMessage;
    } else {
      return errorMessage;
    }
  };

  return <p style={{ fontSize: 12, color, width: 300 }}>{handleText()}</p>;
};
