import React, { useCallback, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { stripeErrorAPI } from 'recoil/StripeErrorAtom'
import { IStripeChangeSubscription } from 'services/interfaces/IStripe'
import { putSubscriptionPlan } from 'services/subscription'

export const usePutSubscriptionPlan = () => {

  const setStripeErrorAPI = useSetRecoilState(stripeErrorAPI)

    const handlePutSubscriptionPlan = useCallback(
      async(body:IStripeChangeSubscription) => {
       const result = await putSubscriptionPlan(body).then( response =>{ 
          let data = response.data

          if(response.status === 200){
            return data
          }
        }).catch( error => { 
          if(error.response.status === 404){
            const data = error.response.data
            setStripeErrorAPI({
                code: data.msg.code,
                declineCode: data.msg.declineCode
            })
          }
      })

        return result
      },
      [],
    )
    
  return {
    handlePutSubscriptionPlan
  }
}
