import React, { useCallback, useState } from 'react'
import { getProgressHistory } from 'services/progress'

export const useGetProgressHistory = () => {

    const [progressHistory, setprogressHistory] = useState([])

    const handleGetProgressHistory = useCallback(
      (limit:number) => {
        getProgressHistory().then( response => {

            if(response.status === 200){

                const data = response.data
                const arrayParsed:any = []

                const filteredKeys = Object.keys(data).slice(0,limit)

                filteredKeys.forEach( (key )=>{
                    arrayParsed.push({ name: key, data: data[key]})
                })
                setprogressHistory(arrayParsed)
            }
        })
      },
      [],
    )
    
  return {
    progressHistory,
    handleGetProgressHistory
  }
}
