import { useState } from 'react';

//iconic
import { IonGrid, IonText, IonButton } from '@ionic/react';

//css
import './anual-premium.css';

//components
import CancelarSuscripcionModal from '../Modales/CancelarSuscripcion/CancelarSuscripcionModal';
import { userInfoState } from 'recoil/User';
import { useRecoilValue } from 'recoil';
import { TermsConditionModal } from 'components/modal/TermsConditionModal';
import { PolishPrivacyModal } from 'components/modal/PolishPrivacyModal';

export const AnualPremium: React.FC = () => {
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const eventDate = new Date();
  const month = eventDate.toLocaleString('es', { month: 'long' });
  const day = eventDate.toLocaleString('es', { day: 'numeric' });
  const capitalizedMonth = capitalizeWord(month);
  const year = eventDate.getFullYear().toString();
  const dayNumber = eventDate.getDate();
  const userInfo = useRecoilValue(userInfoState);
  const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear().toString();

  function capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <IonGrid className="ap-container">
      <IonText className="ap-title">Anual Premium</IonText>
      <IonText className="ap-subtitle">• Acceso ilimitado al Programa de Aprendizaje, Entrenamientos, y Sesiones en Vivo.</IonText>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '48px' }}>
        {userInfo.canceledAt ? 
          <IonText className="ab-duration">Plan Anual - pago automático cancelado</IonText>
          :
          <IonText className="ab-duration">Plan Anual con pago automático</IonText>
        }

        <IonText className="ap-date">
          Inicio: {day} de {capitalizedMonth} {year}
        </IonText>

        <IonText className="ap-date">
          {userInfo.canceledAt ? `Fin: ${dayNumber} de ${capitalizedMonth} ${nextYear}` : `Proximo pago programado: ${dayNumber} de ${capitalizedMonth} ${nextYear}`}
        </IonText>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '30px' }}>
        {userInfo.canceledAt ? 
          null
         : (
          <IonButton className="ap-renovation-button" fill="clear" onClick={() => setCancelSubscription(true)}>
            Cancelar suscripción
          </IonButton>
        )}
      </div>

      <IonText className="ap-subscribe-text">La suscripción se paga una vez por año. Tiene renovación automática.</IonText>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <IonText id="terms-premium-modal" className="ap-terms">
          Términos y condiciones
        </IonText>
        <span className="ap-terms" style={{ textDecoration: 'none', margin: '0 4px' }}>
          -
        </span>
        <IonText id="polish-premium-modal" className="ap-terms">
          Política de Privacidad
        </IonText>
      </div>
      <TermsConditionModal triger="terms-premium-modal" />
      <PolishPrivacyModal triger="polish-premium-modal" />
      <CancelarSuscripcionModal showModal={cancelSubscription} setShowModal={setCancelSubscription} />
    </IonGrid>
  );
};
