import { useEffect, useState } from "react";
import './countdowntimer.css'
import { timerExerciseAtom } from "recoil/exercisesAtom";
import { useRecoilValue } from "recoil";
import { orientationAtom } from "recoil/OrientationAtom";

interface ICountDownTimer { 
    minutes: number
}

export const CountDownTimer: React.FC <ICountDownTimer>= ({ minutes })=>{ 

    const [time, setTime] = useState(minutes * 60);
    const isRunning = useRecoilValue(timerExerciseAtom)
    const orientation = useRecoilValue(orientationAtom)

  useEffect(() => {
    if (isRunning && time > 0) {
      const timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [time, isRunning]);

  const minutesRemaining = Math.floor(time / 60);
  const secondsRemaining = time % 60;

    return(
        <div className="cdtTimer-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px' }}>
            <p style={{ fontSize:19, fontWeight:700 }}>
                {minutesRemaining.toString().padStart(2, '0')}:
                {secondsRemaining.toString().padStart(2, '0')}
            </p>
        </div>
    )
}