import React, { useRef } from 'react';

//ionic
import { IonModal, IonGrid, IonText, IonButton, IonImg, IonRow, useIonRouter } from '@ionic/react';

//icons
import close_modal from '../../../assets/close_modal.svg';

//css
import './abandono-entrenamiento.css';

//assets
import silbato from 'assets/silbato.svg';
import { useSetRecoilState } from 'recoil';
import { basisState, indexTrainingAtom, listExerciseAtom, trainingBeganAtom, trainingState } from 'recoil/TrainingAtom';
import { useOpenModal } from 'hooks/modal/useOpenModal';
import { usePostUserExercisePoint } from 'hooks/entrenamiento-rapido/usePostUserExercisePoint';
import { exerciseMinutesDoneAtom } from 'recoil/exercisesAtom';

interface IModal {
  IsOpen: boolean;
  handleClose: () => void;
}

function ModalAbandonoEntrenamiento({ IsOpen, handleClose }: IModal) {
  const modal = useRef<HTMLIonModalElement>(null);
  const navigation = useIonRouter()
  const setIndexTraining = useSetRecoilState(indexTrainingAtom)
  const setTrainingBegan = useSetRecoilState(trainingBeganAtom)
  const { handleOpenModal } = useOpenModal()

  function dismiss() {
    handleClose();
    modal.current?.dismiss();
  }

  const handleRedirect = () => dismiss();

  const handleLeaveTraining = ()=> { 
        setIndexTraining(0)
        setTrainingBegan(false)
        handleOpenModal('finishedQuickTraining')
        navigation.push("/crear-entrenamiento", 'forward', 'pop');
  }

  return (
    <IonModal isOpen={IsOpen} ref={modal} className="ul-modal">
      <IonGrid className="wrapper">
        <IonRow className="ul-icon-row">
          <IonImg onClick={dismiss} src={close_modal} alt="cross icon" />
        </IonRow>

        <IonGrid className="ae-container">
          <IonText className="ae-well-done">¡Cuidado!</IonText>
          <IonText className="ae-subtitle">El entrenamiento no está terminado. Si te vas de esta sección, el entrenamiento se terminará.</IonText>

          <div className="flex" style={{ justifyContent: 'center' }}>
            <IonImg src={silbato} className="ae-img" />
          </div>

          <IonButton className="ls-button" fill="clear" style={{ marginTop: '24px' }} onClick={handleLeaveTraining}>
            Abandonar Entrenamiento
          </IonButton>

          <IonButton type="submit" fill="clear" style={{ marginTop: '8px' }} className="ae-button" onClick={handleRedirect}>
            Continuar Entrenamiento
          </IonButton>
        </IonGrid>
      </IonGrid>
    </IonModal>
  );
}

export default ModalAbandonoEntrenamiento;
