//ionic
import { IonGrid, IonText, useIonRouter } from '@ionic/react';

//enum
import { LearningProgramEnum } from 'enums/learning.program';

import './LearningProgram.css';

type LearningProgramKey = keyof typeof LearningProgramEnum;

const LearningProgram: React.FC = () => {
  const navigation = useIonRouter();

  const handleRedirect = (program: LearningProgramKey) => navigation.push(`/tabs/aprendizaje/${LearningProgramEnum[program]}`, 'forward', 'push');

  return (
    <IonGrid className="lp-container">
      <IonGrid className="lp-first-child">
        <IonText className="lp-title">Programa de Aprendizaje</IonText>
        <IonText className="lp-subtitle">Aprende en todos los fundamentos del juego</IonText>
      </IonGrid>

      <IonGrid className="lp-images-container">
        <IonGrid className="lp-first-image-container" onClick={() => handleRedirect('tecnica_individual')}>
          <IonText className="lp-image-text">Técnica Individual</IonText>
        </IonGrid>
        <IonGrid className="lp-second-image-container" onClick={() => handleRedirect('tactica_individual')}>
          <IonText className="lp-image-text">Táctica Individual</IonText>
        </IonGrid>
      </IonGrid>

      <IonGrid className="lp-images-container-1">
        <IonGrid className="lp-third-image-container" onClick={() => handleRedirect('defensa')}>
          <IonText className="lp-image-text">Defensa</IonText>
        </IonGrid>
        <IonGrid className="lp-fourth-image-container" onClick={() => handleRedirect('tiro')}>
          <IonText className="lp-image-text">Tiro</IonText>
        </IonGrid>
        <IonGrid className="lp-fifth-image-container" onClick={() => handleRedirect('pase')}>
          <IonText className="lp-image-text">Pase</IonText>
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
};

export default LearningProgram;
