import { Field } from 'formik';
import { ErrorMessageText } from './ErrorMessage';

interface IFieldSet {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: any;
  errors: any;
  touched: any;
  setFieldTouched: any;
  isTouched: boolean;
}

export const Fieldset = ({ touched, name, label, type, placeholder, value, errors, setFieldTouched, isTouched }: IFieldSet) => {
  const handleBorderColor = () => {
    if (touched && value && errors) {
      return '#B62D25';
    } else if (touched && !value && errors) {
      return '#B83A02';
    } else {
      return '#626875';
    }
  };

  const handleTextColor = () => {
    if (touched && value && errors) {
      return '#B62D25';
    } else if (touched && !value && errors) {
      return '#393A3F';
    } else {
      return '#393A3F';
    }
  };

  return (
    <article style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width:'100%' }}>
      <label style={{ marginBottom: 10, fontSize: 14, fontWeight: 400 }} htmlFor={name}>
        {label}
      </label>
      <Field
        onClick={() => {
          if (isTouched) setFieldTouched(name, true);
        }}
        style={{ backgroundColor:'white', width: '100%', height: 45, borderRadius: 24, borderWidth: '1.6px', borderColor: handleBorderColor(), outline: 'none' }}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
      />
      {touched && errors && <ErrorMessageText value={value} touched={touched} color={handleTextColor()} errorMessage={errors} />}
    </article>
  );
};
