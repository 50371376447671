import React, { useCallback, useState } from 'react'
import { getTrainingChart } from 'services/progress'

export const useGetTrainingChart = () => {

    const [trainingChart, setTrainingChart] = useState<any[]>([])

    const handleGetTrainingChart = useCallback(
      () => {
        getTrainingChart().then( response =>{ 

            if(response.status === 200){
                const data = response.data
                const arrayParsed:any = []

                const filteredKeys = Object.keys(data)

                filteredKeys.forEach( (key )=>{
                    arrayParsed.push({ name: key, data: data[key]?.moduleName, minutes: data[key]?.minutes })
                })
                setTrainingChart(arrayParsed)
            }
        })
      },
      [],
    )
    
  return {
    trainingChart,
    handleGetTrainingChart
  }
}
